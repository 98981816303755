// import npm packages;
import React                    from "react";
import PropTypes                from "prop-types";
import { AutoComplete, Button } from "antd";
import "bootstrap/dist/js/bootstrap.bundle.min";
import "bootstrap/dist/css/bootstrap.min.css";
import UserAvatar               from "react-user-avatar";
// import local files
import Footer                 from './../footer';
import withRouter             from '../../../../shared/withRouter';
import * as config            from "../../../../shared/config";
import { jwtDecode }          from "../../../../shared/jwt";
import { getToken }           from "../../../../shared/util";
import GoogleDriveSync        from '../../../../shared/GoogleDriveSync';
import { updateRefreshToken } from '../../../../shared/updateRefreshToken';
import {convertJSON2CSS}      from "../../../User/components/setUserBackgroud"
import {removeClass}          from "../../../../shared/removeClass";
import srcImage from "./../../../../Assets/Images/search.svg"
import settingsIcon from "./../../../../Assets/Images/settings.svg"
import logoutIcon from "./../../../../Assets/Images/logout.svg"

const { Option } = AutoComplete;

class HeaderComponent extends React.Component {
  static propTypes = {
    isSignedIn: PropTypes.string,
    signUpError: PropTypes.bool,
    signOut: PropTypes.func,
    signIn: PropTypes.func,
    signUp: PropTypes.func,
    auth: PropTypes.object
  };

  constructor(props) {
    super(props);
    this.installerData = {};
    this.option = [];
    this.data = {};
    this.GoogleDriveSync = new GoogleDriveSync();
    this.image = "";

    this.state = {
      active: false,
      isMount: false,
      searchID: '',
      firstName: '',
      isProfileCreated: false,
      settingLink: '',
      companySettingLink: '',
      companySettingMobLink:'',
      dataSource: null,
      isShow: false,
    }
  }

  get token() {
    return getToken();
  }

  componentDidUpdate(prevProps) {
    if (this.props.router.location.pathname !== prevProps.router.location.pathname) {
      let { isShow, active, searchID, firstName, dataSource } = this.state;
      if (isShow || active) {
        isShow = false;
        active = false;
      }
      if (
        !this.props.router.location.pathname.includes('/homeowner')
        && (
          searchID !== ''
          || firstName !== ''
          || dataSource !== null
        )
      ) {
        searchID = '';
        firstName = '';
        dataSource = null;
      }
      this.setState({
        isShow,
        active,
        searchID,
        firstName,
        dataSource
      })
      document.body.classList.remove('overflowHidden')
    }
  }

  checkTokenInterval() {
    const token = getToken()
    let expTime = this.installerData && this.installerData.exp ? Number(`${this.installerData.exp}000`) : 0
    const remainingTime = expTime - new Date().getTime() - 60000;
    if (!expTime || new Date().getTime() > expTime || remainingTime < 1) {
      this.signOut()
    } else {
      const timeInterval = localStorage.getItem('timeInterval');
      if (timeInterval) {
        clearInterval(timeInterval);
      }
      this.countdown = setInterval(() => {
        this.refreshToken(token);
      }, remainingTime);
      localStorage.setItem('timeInterval', this.countdown);
    }
  }

  componentWillMount() {
    const token = getToken();
    this.installerData = jwtDecode(token);
    this.companyId = this.props.router.params.companyId || this.installerData.companyId;
    this.slug      = this.props.router.params.slug || this.installerData.slug;
    
    this.data = {
      companyId: this.companyId,
      installerId: this.installerData.installerId || 1,
    };
    this.slug = this.props.router.params.slug || this.installerData.slug;
    this.checkTokenInterval();
    convertJSON2CSS(this.slug, "installer");

    if (token) this.props.requestInstallerDataById(this.data);
  }

  componentWillReceiveProps(nextProps, nextstate) {
    if (
      nextProps.installerData
      && nextProps.installerData.data
    ) {
      if (
        !nextProps.installerData.data.isProfileCreated
        && nextProps.router.location.pathname !== `/${this.slug}/${nextProps.installerData.data.companyId}/setting`
      ) {
        nextProps.router.navigate(`/${this.slug}/${nextProps.installerData.data.companyId}/setting`);
      }
      this.setState({
        isProfileCreated: nextProps.installerData.data.isProfileCreated,
        settingLink: `/${this.slug}/${nextProps.installerData.data.companyId}/setting`,
        companySettingLink: `/${this.slug}/${nextProps.installerData.data.companyId}/dashboard`,
        companySettingMobLink: `/${this.slug}/${nextProps.installerData.data.companyId}/companysetting`,
        installerId: nextProps.installerData.data.id
      })
    }

    if (nextProps.searchData && nextProps.searchData.data) {
      this.setState({
        dataSource: nextProps.searchData.data
      });
    }

    if (
      nextProps.image &&
      nextProps.image.status === 200 &&
      nextProps !== this.props
    ) {
      this.image = nextProps.image.data.logo;
    }
  }

  onSelect = (option) => {
    const searchID = option.props.children[0].props.children
    const firstName = option.props.value
    this.setState({
      searchID: searchID,
      firstName: firstName,
      dataSource: null
    }, () => {
      this.props.receiveSearchData({});
      localStorage.removeItem('pathname')
      this.props.installerData && this.props.installerData.data && this.props.installerData.data.isProfileCreated ?
        firstName && this.props.router.navigate(
          `/${this.slug}/${this.companyId}/dashboard/${searchID}/homeowner`,
          { firstName }
        )
        :
        this.state.settingLink && this.props.router.navigate(this.state.settingLink)
    })
  }

  refreshToken = async () => {
    const { auth, installerData } = this.props;
    if (
      installerData
      && installerData.data
      && installerData.data.email
    ) {
      await updateRefreshToken(installerData.data.email, auth.info.refreshToken);
    }
  }

  handleChange = value => {
    if (value.length === 0) {
      this.setState({
        searchID: '',
        firstName: value,
        dataSource: null
      })
      this.forceUpdate();
      return
    }
    this.setState({
      searchID: '',
      firstName: value
    })
  }

  handleSearch = value => {
    if (value.length > 2) {
      let data = {
        value: encodeURIComponent(value),
        companyId: this.companyId,
        installerId: this.state.installerId
      };
      this.props.requestSearchData(data);
    }
  };

  signOut = () => {
    let slug = localStorage.getItem("slug")
    this.props.router.navigate(`/`+slug)
    const timeInterval = localStorage.getItem('timeInterval');
    if (timeInterval) {
      clearInterval(timeInterval);
    }
    localStorage.clear();
    localStorage.setItem("slug", slug)
    localStorage.setItem('token', '');
    this.GoogleDriveSync.handleSignoutClick();
    this.props.signOut();
    document.body.classList.add('bg-color');
    removeClass("installer-view");
    removeClass("sider-collapse");
  };

  coloapsable = () => {
    const currentState = this.state.active;
    this.setState({
      active: !currentState,
      isMount: !this.state.isMount,
      isShow: !this.state.isShow
    });
    document.body.classList.toggle('overflowHidden')
  }

  renderOptions() {
    // const BhodiLogo = this.props.image;
    if (
      this.state.dataSource
    ) {
      if (
        this.state.dataSource.length
        && this.state.dataSource.length !== 0
      ) {
          return (
            this.state.dataSource.map(item => {
              return <Option key={item.id} value={`${item.firstName}-${item.email}`}>
                <span className="searchId">{item.id}</span>
                <p className="certain-search-item-count">
                  {item.firstName} {" "+item.lastName} - {item.email}
                </p>
              </Option>
            })
          );
      } else {
        return [(
            <Option key={0} value=''>
              <span className="searchId"></span>
              <p className="certain-search-item-count">
                No results
              </p>
            </Option>
        )]
      }
    } else {
      return [];
    }
  }

  renderInstallerInital(installerData){
    let photo = installerData.data && installerData.data.photo;
    if (photo && photo.includes('installer-avatar')) {
      photo = null;
    }
    let installerName =  installerData.data && (installerData.data.name || installerData.data.companyName);
    return(
      photo ? <img
      className="dashboard-profile-image"
      src={photo}
      alt='profile-pic'
    /> :
      <UserAvatar className="dashboard-profile-image premiumIconBackGround" size="40" name={ installerName || 'Installer'} />
    )
  }

  render() {
    const { installerData,match } = this.props;

    if(!installerData || !installerData.data){
      return <></>
    }

    let hideAddButton = installerData.data && installerData.data.formSetting && installerData.data.formSetting.hideAddButton;

    let activeOption = match && match.url;
    if (!this.token) {
      return null;
    }

    return (
      <React.Fragment>
        <div className="nav-header-wrapper dashboard-nav-wrapper position-fixed">
          {
            !this.installerData.companyId &&
              <div className="admin-view">Admin View
                <i className="close-icon fa fa-times" aria-hidden="true" onClick={() => {
                  this.props.setMetricsType(null);
                  var d = document.getElementsByTagName('body');
                  if (d && d[0]) {
                    d[0].className = "";
                  }
                  this.props.router.navigate(`/admin/${this.companyId}/companysetting`)
                }}
                ></i>
              </div>
         }
          <nav className="navbar navbar-expand-lg navbar-light bg-light">
            { 
              this.installerData.role && !this.installerData.role.includes("admin") && (installerData && installerData.data && installerData.data.isProfileCreated ?
                <a
                  className="navbar-brand default"
                  onClick={() => {
                    this.props.setMetricsType(null);
                    this.props.router.navigate(`/${this.slug}/${this.companyId}/dashboard`)
                  }}
                />
                :
                <a
                  className="navbar-brand default"
                />)
            }
            <button
              type="button"
              data-toggle="collapse"
              data-target="#navbarSupportedContent"
              aria-controls="navbarSupportedContent"
              aria-expanded="false"
              aria-label="Toggle navigation"
              onClick={this.coloapsable.bind(this)}
              className={`navbar-toggler ${this.state.active ? 'cross-icon' : ''}`}
            >
              <span className="navbar-toggler-icon" />
            </button>
            <div
              className={this.state.isShow ? "collapse navbar-collapse show" : "collapse navbar-collapse"}
              id="navbarSupportedContent"
            >
              <ul className="mr-auto display-none"> </ul>
              <div className="my-2 my-lg-0">

                <ul className="navbar-nav">
                  <li className="nav-item dashboar-search">
                    <form className="form-inline">
                      <div className="search-wrap">
                        <img
                          src={srcImage}
                          alt='search'
                        />
                        <AutoComplete
                          className="searchHomeowner certain-category-search"
                          dropdownClassName="certain-category-search-dropdown"
                          dropdownMatchSelectWidth={false}
                          placeholder={config.SEARCH_HOMEOWNER}
                          onChange={ this.handleChange }
                          onSelect={(value, option) => { this.onSelect(option) }}
                          onSearch={this.handleSearch}
                          style={{ width: 300 }}
                        >
                        {
                          this.renderOptions()
                        }
                        </AutoComplete>
                      </div>
                    </form>
                  </li>
                  <li
                    className="nav-item nav-add-link"
                    onClick={() => {
                      this.props.installerData
                        && this.props.installerData.data
                        && this.props.installerData.data.isProfileCreated
                        && installerData
                        && installerData.data.id ?
                          this.props.router.navigate(`/${this.slug}/${this.companyId}/campaign/dashboard`) :
                            this.state.settingLink && this.props.router.navigate(this.state.settingLink)
                    }}
                  >
                    <a className={`nav-link ${activeOption && activeOption.includes('campaign') && 'active'}`}>
                      <span>Marketing campaigns{" "}</span>
                    </a>
                  </li>
                    {
                      !hideAddButton && 
                      <li
                        className="nav-item nav-add-link"
                        onClick={() => {
                          this.props.installerData
                            && this.props.installerData.data
                            && this.props.installerData.data.isProfileCreated
                            && installerData
                            && installerData.data.id ?
                              this.props.router.navigate("/" + this.slug + "/" + this.companyId + "/addhomeowner") :
                                this.state.settingLink && this.props.router.navigate(this.state.settingLink)
                        }}
                      >
                        <a className={`nav-link addhomeowner`}>
                          +{" "}
                          <span>Add homeowner{" "}</span>
                        </a>
                      </li>
                    }

                  <li className="nav-item dropdown">
                    <a
                      className={`nav-link dropdown-toggle ${!this.installerData.companyId ? 'disabled':''}`}
                      id="navbarDropdown"
                      role="button"
                      data-toggle="dropdown"
                      aria-haspopup="true"
                      aria-expanded="false"
                      aria-disabled={ true }
                      onClick={() => {
                        this.setState({
                          isShow: !this.state.isShow
                        })
                      }}
                    >
                      { this.renderInstallerInital(installerData) }
                      <span className="dashboard-profile-name">
                        {
                          installerData && installerData.data ?
                            installerData.data.companyName :
                              ""
                        }
                      </span>
                    </a>
                    <div
                      className={`dropdown-menu box-Shadow ${this.state.isShow ? 'show' : ''}`}
                      aria-labelledby="navbarDropdown"
                    >
                      <div className="drop-up-arrow"></div>
                      <div className="media">
                        <div>
                          <h5 className="mt-0">
                            <a>
                              {
                                installerData
                                  && installerData.data ?
                                  installerData.data.name
                                  :
                                  ""
                              }
                            </a>
                          </h5>
                          <a>
                            {
                              installerData
                                && installerData.data ?
                                installerData.data.email
                                :
                                ""
                            }
                          </a>
                        </div>
                      </div>
                      <a className="dropdown-item"
                        onClick={() => {
                          installerData &&
                            installerData.data &&
                            this.state.settingLink &&
                            this.props.router.navigate(
                              this.state.settingLink
                            )
                        }}
                      >
                        <span>Profile settings</span>
                      </a>

                      <a className="dropdown-item"
                        onClick={() => {
                          installerData &&
                            installerData.data &&
                            this.state.companySettingLink &&
                            this.props.router.navigate(this.state.companySettingLink, {companyMetrics: true})
                        }}
                      >
                        <span>Insights</span>
                      </a>

                      <a className="dropdown-item" onClick={this.signOut}>
                        <span>Log out</span>
                      </a>
                    </div>
                  </li>
                </ul>

                <div className="row">
                  <div className="menu-mobile col-12">
                    <div className="media">
                      <h5>MENU OPTIONS</h5>
                      { this.renderInstallerInital(installerData) }
                      <div className="media-body">
                        <h5 className="mt-0 mb-0">
                          <a className="font-weight-400 font-size18">
                            {
                              installerData
                                && installerData.data ?
                                installerData.data.name
                                :
                                ""
                            }
                          </a>
                        </h5>
                        <a className="font-weight-300 ">
                          {
                            installerData
                              && installerData.data ?
                              installerData.data.email
                              :
                              ""
                          }
                        </a>
                      </div>
                    </div>
                    <div className="row margt60 setting-mob">
                      <a
                        className="setting dropdown-item font-weight-400 font-size18"
                        onClick={() => {
                          installerData && this.state.settingLink && this.props.router.navigate(
                            this.state.settingLink
                          )
                          const currentState = this.state.active;
                          this.setState({ active: !currentState, isShow: false });
                          document.body.classList.remove('overflowHidden')
                        }}
                      >
                        <span className="drop-icon">
                          <img
                            src={settingsIcon}
                            alt='setting-icon'
                          />
                        </span>
                        <span className="setting-gray">Personal Settings</span>
                      </a>                     
                    </div>
                      <div className="row margt60 setting-mob logout ">
                        <a className="dropdown-item font-weight-400 font-size18" onClick={this.signOut}>
                          <span className="drop-icon">
                            <img src={logoutIcon} alt='logout-icon' />
                          </span>
                          <span className="setting-gray"> Log out</span>
                        </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </nav>
        </div>
        <Footer  {...this.props} />
      </React.Fragment>
    );
  }
}

export default withRouter(HeaderComponent);
