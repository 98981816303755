import { Services } from "../../../Api/services";
import envConfig from '../../../environments/index';

const COMPANY_BASE_URL = envConfig.envConfig.apiGateway.BASE_URL.COMPANY;
const HOME_OWNER_BASE_URL = envConfig.envConfig.apiGateway.BASE_URL.HOME_OWNER;

export const getCompanyById = async data => {
  const url = COMPANY_BASE_URL + "company/" + data+'?admin='+1;
  let res = await Services.getCall(url)
  return { data: res.data, status: res.status }
};

export const editInstallerApi = async data => {
  let bodayData = {
    "name": data.company,
    "address": {
      "address1"  : data.address1,
      "address2"  : data.address2,
      "city"      : data.city,
      "state"     : data.state,
      "country"   : data.country,
      "postalCode": data.postalCode
    },
    "mobileLogo": data.mobileLogo,
    "icon": data.icon,
    "isProjectTrack": data.isProjectTrack,
    "isTest"  : data.isTest,
    "isNPS"  : data.isNPS,
    "contactName": data.adminName,
    "contactPhone": data.adminPhone ? parseInt(data.adminPhone.replace(/[^0-9]/g, "")) : undefined,
    "contactEmail": data.contactEmail,
    "companyUrl":data.companyUrl,
    "calculatorUrl":data.calculatorUrl,
    "meetingUrl":data.meetingUrl,
    "phone": data.adminPhone ? parseInt(data.adminPhone.replace(/[^0-9]/g, "")) : undefined,
    "googleReviewUrl":data.googleReviewUrl?data.googleReviewUrl:null
  };
  const url = COMPANY_BASE_URL + "company/" + data.companyId;
  let res = await Services.putCall(url, bodayData)
  return { data: res.data, status: res.status }
};


export const editInstallerManagementApi = async (data, meterData) => {
  const powerMeterData = meterData.map((data) => {
    delete data['Key_id'];
    return data;
  })
  let bodayData = {
    "crm": (data.crmName && data.crmName.includes('crm'))  ? [{
      "crmId": data.crmName.replace('crm',''),
      "key": data.CRMapiKey,
      "identifier": data.CRMidentifier
    }] : [],
    "projectManagement": (data.crmName && data.crmName.includes('pm'))  ? [{
      "projectId": data.crmName.replace('pm',''),
      "key": data.CRMapiKey,
      "identifier": data.CRMidentifier
    }] : [],
    "eversignCredentials":{
      businessId : data.eversignBusinessId || null,
      apiKey     : data.eversignApiKey || null,
      companyId  : data.id
    },
    "powerMeter": powerMeterData,
    "document": data.docStorage  ? [{
      "documentId": data.docStorage,
      "filter": data.docAPIkey,
    }] : []
  }
  const url = COMPANY_BASE_URL + "company/" + data.id + "/management";
  let res = await Services.putCall(url, bodayData)
  return { data: res.data, status: res.status }
};


export const uploadExcelApi = async data => {
  const url = `${HOME_OWNER_BASE_URL}/company/${data.id}/homeowner/importFile`;
  const bodyData = data.csvUrl
  bodyData["email"] = data.email
  let res = await Services.postCall(url, bodyData);
  return { data: res.data, status: res.status };
};

export const driveUploadInS3 = async data =>{
  const url = `${HOME_OWNER_BASE_URL}company/uploaddrive`;

  let res = await Services.postCall(url, data);
  return { data: res.data, status: res.status };
}
