import { Services }    from "../../../Api/services";
import * as config     from "../../../shared/config";
import { convertDate } from "../../../shared/convertDate";
import {checkIsMobile} from "../../../shared/checkIsMobile"
import * as moment     from "moment";

export const getHomeownerById = async data => {
  let url = `${config.HOME_OWNER_BASE_URL}company/${data.companyId}/homeowner/${data.homeownerId}`;
  
  if(data.role && data.role.includes('secondary')){
    url = url + `?role=${data.role}`
  }
  let res = await Services.getCall(url, true);
  return { data: res.data };
};

export const getHomeownerEnergyReport = async data => {
  let month = data.date ? moment(data.date).format("YYYY-MM") : moment().format("YYYY-MM");
  month     = checkIsMobile() ? '10day' : month;
  let url = `${config.ENERGY_REPORT_BASE_URL}homeowner/${data.homeownerId}/energy/report?sync=${data.sync || false}&monthHistory=${month}&requestMonthHistory=${data.requestMonthHistory}`;
  if (data.date && data.date !== convertDate(new Date())) {
    url = `${url}&date=${data.date}`;
    if(data.offset) {
      url = `${url}&offset=${data.offset}`
    }
  } else if(data.offset) {
    url = `${url}&offset=${data.offset}`
  }
  let res = await Services.getCall(url, true);
  return { data: res.data };
};

export const getHomeownerSpecialCardData = async data => {
  let url = `${config.ENERGY_REPORT_BASE_URL}homeowner/${data.homeownerId}/energy/report?feedType=${data.feedType}&feedTime=${data.feedTime}`;
  let res = await Services.getCall(url, true);
  return { data: res.data };
};

export const getUpdateHomeOwner = async data => {
  let url = `${config.HOME_OWNER_BASE_URL}company/${
    data.companyId
    }/homeowner/${data.homeownerId}`;
  const bodyData = {
    solarUnit: {
      transitionStatus: {
        time: new Date().getTime(),
        status: true
      }
    }
};
let res = await Services.putCall(url, bodyData, true);
  return { data: res.data };
};

export const getHomeownerDeviceMagicData = async data=>{
  let task = [];
  data && data.map(ele=>{
    ele = typeof(ele) === "string" ? JSON.parse(ele)  : ele;
    let url = `${config.S3_17TW_BUCKET}/deviceMagic/${ele.magicId}.json`;
    let res = Services.getCall(url, false);
    task.push(res);
  });

  return Promise.all(task).then(r=>{
    let res = [];
    r.map(ele=>{
      res.push(ele.data.answers)
    });
    return {data:res};
  })
}
