// import npm packages
import { call, put } from 'redux-saga/effects';

// import local files
import {
  getHomeOwnerApi,
  getUpdateHomeOwnerApi,
  getDeleteHomeOwnerApi,
  getReferralData,
  getSurveyData,
  getSocialShareData,
  getEngagementScoreData,
  getHomeownerAddUserData
} from '../../screens/Installer/api/homeOwner';
import {
  receiveHomeOwner,
  receiveUpdateHomeOwner,
  receiveReferralData,
  receiveSurveyData,
  receiveSocialShareData,
  receiveEngagementScore,
  receiveHomeownerAddUser
} from '../../screens/Installer/actions/homeOwner';
import handleError from '../../shared/errorHandler';
import { setLoader } from '../../screens/Common/Loader/action';

function* getHomeOwner(action) {
  try {
    let data = {};
    if (JSON.stringify(action.data) !== '{}') {
      data = yield call(getHomeOwnerApi, action.data);
    }

    yield put(setLoader({ loader: false }));
    yield put(receiveHomeOwner(data));
  } catch (e) {
    yield put(setLoader({ loader: false }));
    handleError(e);
  }
}

function* getUpdateHomeOwner(action) {
  try {
    const data = yield call(getUpdateHomeOwnerApi, action.data);

    yield put(setLoader({ loader: false }));
    yield put(receiveUpdateHomeOwner(data));
  } catch (e) {
    yield put(setLoader({ loader: false }));
    handleError(e);
  }
}

function* getDeleteHomeOwner(action) {
  try {
    const data = yield call(getDeleteHomeOwnerApi, action.data);
    yield put(setLoader({ loader: false }));
    yield put(receiveUpdateHomeOwner(data));
  } catch (e) {
    yield put(setLoader({ loader: false }));
    handleError(e);
  }
}

function* getReferralCount(action) {
  try {
    const data = yield call(getReferralData, action.data);

    // yield put(setLoader({loader: false}));
    yield put(receiveReferralData(data));
  } catch (e) {
    yield put(setLoader({ loader: false }));
    handleError(e);
  }
}

function* getSurveyCount(action) {
  try {
    const data = yield call(getSurveyData, action.data);

    // yield put(setLoader({loader: false}));
    yield put(receiveSurveyData(data));
  } catch (e) {
    yield put(setLoader({ loader: false }));
    handleError(e);
  }
}

function* getSocialShareCount(action) {
  try {
    const data = yield call(getSocialShareData, action.data);

    // yield put(setLoader({loader: false}));
    yield put(receiveSocialShareData(data));
  } catch (e) {
    yield put(setLoader({ loader: false }));
    handleError(e);
  }
}

function* getEngagementScore(action) {
  try {
    const data = yield call(getEngagementScoreData, action.data);

    // yield put(setLoader({loader: false}));
    yield put(receiveEngagementScore(data));
  } catch (e) {
    yield put(setLoader({ loader: false }));
    handleError(e);
  }
}

function* getHomeownerAddUser(action){
  try {
    const data = yield call(getHomeownerAddUserData, action.data);
    yield put(receiveHomeownerAddUser(data));
  } catch (e) {
    yield put(setLoader({ loader: false }));
    handleError(e);
  }
}


export const homeOwner = {
  getReferralCount,
  getSurveyCount,
  getSocialShareCount,
  getHomeOwner,
  getUpdateHomeOwner,
  getDeleteHomeOwner,
  getEngagementScore,
  getHomeownerAddUser
};
