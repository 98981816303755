import React, { Component } from "react";
import * as AWS from "aws-sdk"
import { Button } from "antd";
import { LoadingOutlined } from '@ant-design/icons';
import envConfig from "../../environments/index";

AWS = require('aws-sdk/dist/aws-sdk-react-native');

AWS.config.region = envConfig.envConfig.cognito.Region;
let x = { key: 'cognito-idp.us-east-1.amazonaws.com/' + envConfig.envConfig.cognito.UserPoolId }
let y = {};
y[x.key] = localStorage.getItem("token");
AWS.config.credentials = new AWS.CognitoIdentityCredentials({
    IdentityPoolId: envConfig.envConfig.cognito.IdentityPoolId,
    Logins: y
})

const antIcon = <LoadingOutlined className="dashboard-loading" style={{ fontSize: 60 }} spin />;
class CompanyLogoUpload extends Component {
    state = {
        imageName: "",
        imageFile: "",
        response: "",
        desktopUrl: this.props.desktopImageUrl ? this.props.desktopImageUrl : null,
        mobileUrl: this.props.moboImageUrl ? this.props.moboImageUrl : null,
        iconUrl: this.props.icon ? this.props.icon : null
    };

    componentDidUpdate(prevProps) {
        if (!prevProps.moboImageUrl && this.props.moboImageUrl) {
            this.setState({
                mobileUrl: this.props.moboImageUrl
            })
        }
        if (!prevProps.desktopImageUrl && this.props.desktopImageUrl) {
            this.setState({
                desktopUrl: this.props.desktopImageUrl,
            })
        }

        if (!prevProps.icon && this.props.icon) {
            this.setState({
                iconUrl: this.props.icon
            })
        }

        if (this.props.isClear && !prevProps.isClear) {
            this.setState({
                mobileUrl: "",
                desktopUrl: "",
                iconUrl: "",
                response: "",
                imageName: "",
                imageFile: ""
            })
        }
    }

    initializeS3() {
        AWS.config.region = envConfig.envConfig.cognito.Region;
        let x = { key: 'cognito-idp.us-east-1.amazonaws.com/' + envConfig.envConfig.cognito.UserPoolId }
        let y = {};
        y[x.key] = localStorage.getItem("token");
        AWS.config.credentials = new AWS.CognitoIdentityCredentials({
            IdentityPoolId: envConfig.envConfig.cognito.IdentityPoolId,
            Logins: y
        })
        let s3 = new AWS.S3();
        return s3;
    }

    uploadImageonS3 = () => {
        const { type } = this.props;
        let oldFileName = this.upload.files[0].name.split(".")
        let extension = oldFileName[oldFileName.length - 1]
        let fileName = `${this.props.fileName}.${extension}`
        let s3 = this.initializeS3();
        s3.upload({
            Bucket: envConfig.envConfig.s3.BUCKET,
            Key: `public/logo/${fileName}`,
            Body: this.upload.files[0],
            ContentType: "image/*",
        })
            .promise()
            .then(result => {
                this.props.setLoader({loader:false});
                this.upload = null;
                this.props.saveUploadUrl(type, result.Location);                
            })
            .catch(err => {
                this.setState({ response: `Cannot uploading file: ${err}`});
            });
    };

    uploadImage = async () => {
        if (!(this.state.response.length > 10)) {
            if (this.state.imageName.length !== 0) {
               this.props.setLoader({loader:true});
                this.uploadImageonS3()
            }
        }
    };

    handleImage = (e) => {
        this.setState({ response: '' });
        if(this.upload.files[0]){
            this.setState({
                imageFile: this.upload.files[0],
                imageName: this.upload.files[0].name,
            });
        }
    };   

    render() {
        return (
           <>
                <div style={{display: "inline"}}>
                <span className="cloud-upload-img"></span>
                    <input
                    className="file-upload"
                        type="file"
                        accept={`.${this.props.fileType}`}
                        ref={ref => (this.upload = ref)}
                        onChange={e => this.handleImage(e)}
                        disabled={(this.props.slug === "" || this.props.disabled) ? "disabled" : false}
                    />
                </div>
                <div>{this.state.imageName}</div>
                {this.props.url && <img alt={this.props.type} src={this.props.url} style={this.props.style} />}
               {
               this.state.imageName &&
                    <Button type="button"
                        onClick={this.uploadImage}
                        className="btn image-upload"
                    > Upload File </Button>
                }

            </>
        );
    }
}
export default CompanyLogoUpload;