// import npm packages
import React, { Component }     from 'react';
import { Spin, Popover, Modal } from 'antd';
import { LoadingOutlined }      from '@ant-design/icons';
import { connect }              from 'react-redux';
import { bindActionCreators }   from 'redux';
import NumberFormat             from "react-number-format";

// import local files
import { AddHardware }  from "../companySetting/AddCompanyHardware";
import Dashboard          from './homeOwnerDashboard';
import EditHomeOwner      from './EditHomeOwner';
import MonitoringScreen   from './MonitoringScreen'
import {
  requestAddHomeOwner,
  receiveAddHomeOwner}    from '../../actions/addHomeOwner';

import {
  requestReferralData,
  receiveReferralData,
  requestSurveyData,
  receiveSurveyData,
  requestSocialShareData,
  receiveSocialShareData,
  requestEngagementScore,
  receiveEngagementScore,
  requestUpdateHomeOwner,
  receiveUpdateHomeOwner,
  requestHomeownerAddUser,
  receiveHomeownerAddUser
} from '../../actions/homeOwner';
import {requestCompanyJourney}     from '../../actions/companyJourney';
import { requestCompanyIdData }    from './../../actions/addHomeOwner';
import {
  requestLoggedInHomeownerAction,
  receiveLoggedInHomeownerAction,
  requestHomeownerEnergyData,
  receiveHomeownerEnergyData,
  requestDeviceMagic,
  receiveDeviceMagic
} from '../../../homeowner/actions/homeOwner';
import {
  requestHomeownersList,
  receiveHomeownersList
} from '../../../homeowner/actions/solar';
import {
  requestHomeownerSystemGlance,
  receiveHomeownerSystemGlance,
  requestHomeownerCampaignData
} from '../../../homeowner/actions/dashboard';
import {
  requestGetEversignData,
  receiveGetEversignData
} from '../../../homeowner/actions/eversign';
import { setLoader } from '../../../Common/Loader/action';
import { requestInstallerDetails } from '../../../homeowner/actions/contact';
import {
  requestPostReferralDetails,
  receivePostReferralDetails
} from '../../../homeowner/actions/referralDetails';
import { setImages }                from '../../../Common/ShowImageFromDrive/action';
import withRouter                   from '../../../../shared/withRouter'
import { REPORT_SYNC }              from '../../../../shared/config';
import  SharePointSync              from '../../../../shared/SharePointSync';
import { receiveEgaugePowerMeter }  from '../../actions/testMeterConnection';
import { openNotificationWithIcon } from '../../../../shared/notification';
import {addHomeownerAdditionalUser} from "../../api/homeOwner";
import ErrorPage            from '../../../Common/ErrorPage';
import DeleteHomeOwner     from './DeleteHomeOwner';
import LandingPage         from '../../../Common/LandingPage';
import {NotificationModal} from "../../../Common/NotificationModal";
import addIcon from "../../../../Assets/Images/addIcon.svg"
import shareReferIcon from '../../../../Assets/Images/share_referrals.svg'
import surveysIcon from '../../../../Assets/Images/surveys.png'
import fingerTouchIcon from '../../../../Assets/Images/finger_touch.png'

class HomeOwner extends Component {
  constructor(props) {
    super(props);
    this.email = '';
    this.companyId = '';
    this.SharePointSync = new SharePointSync();
    this.state = {
      addPopUp: false,
      isEdited: false,
      location: '',
      engagementScore: 0,
      referralsCount: 0,
      socialSharesCount: 0,
      surveysCount: 0,
      isDashboard: true,
      apiResponse: false,
      isMonitoringScreen:true,
      sharePointFile: false,
      deleteHomeOwnerVisibility: false,
      homeOwnerExist : true,
      notificationTrigger : false,
      email: '',
      firstName: '',
      lastName: '',
      phone:null,
      address:{},
    };
  }

  componentWillMount() {
    this.slug = this.props.router.params.slug;
    this.props.receiveAddHomeOwner({});
  }

  componentDidMount() {
    this.companyId = this.props.router.params.companyId;
    this.hitApis();
  }

  componentDidUpdate(prevProps) {
    if (this.props.router.location.pathname !== prevProps.router.location.pathname) {
      this.props.receiveAddHomeOwner({});
      this.props.receiveDeviceMagic([]);
      this.props.receiveEgaugePowerMeter(null)
      this.setState(
        {
          isDashboard: true,
          isApiResponse: false,
          isMonitoringScreen:true,
          sharePointFile : false,
          homeOwnerExist:true,
          email: '',
          phone:null,
          firstName: '',
          lastName: '',
          address: {},
        },
        () => {
          this.hitApis();
        }
      );
    }
  }

  componentWillReceiveProps(nextProps) {
    if (
      nextProps.homeOwnerData.getUpdateHomeOwner
      && nextProps.homeOwnerData.getUpdateHomeOwner.status
      && nextProps.homeOwnerData.getUpdateHomeOwner.status === 200
    ) {
      nextProps.receiveUpdateHomeOwner({})
      
      const data = {
        homeownerId: nextProps.router.params.id,
        companyId: this.companyId
      };
      nextProps.requestLoggedInHomeownerAction(data);
    }
    let engagementScore = 0,
      referralsCount = 0,
      socialSharesCount = 0,
      surveysCount = 0;

    if(nextProps.loggedInUser.statusCode===404){
      this.setState({homeOwnerExist: false})
    } else if (
      JSON.stringify(nextProps.loggedInUser) !== '{}' &&
      !this.state.isApiResponse
    ) {
      let params = this.props.router.params;
      params['fileName'] = (nextProps.loggedInUser.solarUnit.activeJourney && nextProps.loggedInUser.solarUnit.activeJourney.s3Key) || 'default';
      params.toolSearch = true;
      this.props.requestDeviceMagic(nextProps.loggedInUser.homeownerData.magicFile);
      this.setState({
        isApiResponse: true,
        homeOwnerExist : true,
      },()=>{
        this.props.requestCompanyJourney(params);
        this.props.setLoader({loader:false})
      });
    }

    // check for engagement score
    if (
      nextProps.homeOwner.getEngagementScore &&
      nextProps.homeOwner.getEngagementScore.data &&
      nextProps.homeOwner.getEngagementScore.status &&
      nextProps.homeOwner.getEngagementScore.status === 200
    ) {
      engagementScore =
        nextProps.homeOwner.getEngagementScore.data.engagementScore || 0;
    }

    // check for referral count
    if (
      nextProps.homeOwner.getReferral &&
      nextProps.homeOwner.getReferral.data &&
      nextProps.homeOwner.getReferral.status &&
      nextProps.homeOwner.getReferral.status === 200
    ) {
      referralsCount = nextProps.homeOwner.getReferral.data.referralsCount || 0;
    }

    // check for socialShares Count
    if (
      nextProps.homeOwner.getSocialShareData &&
      nextProps.homeOwner.getSocialShareData.data &&
      nextProps.homeOwner.getSocialShareData.status &&
      nextProps.homeOwner.getSocialShareData.status === 200
    ) {
      socialSharesCount =
        nextProps.homeOwner.getSocialShareData.data.socialSharesCount || 0;
    }

    // check for surveys Count
    if (
      nextProps.homeOwner.getSurveyData &&
      nextProps.homeOwner.getSurveyData.data &&
      nextProps.homeOwner.getSurveyData.status &&
      nextProps.homeOwner.getSurveyData.status === 200
    ) {
      surveysCount = nextProps.homeOwner.getSurveyData.data.surveysCount || 0;
    }

    if (JSON.stringify(nextProps.syncCrmData) !== '{}' ){
      nextProps.setLoader({ loader: false});
      nextProps.receiveAddHomeOwner({})
      this.filterCRMData(nextProps.syncCrmData.data,nextProps.loggedInUser);
    }

    this.setState({
      engagementScore,
      referralsCount,
      socialSharesCount,
      surveysCount
    });


    if (this.state.location === '') {
      this.setState({
        location: nextProps.router.location.pathname
      });
    } else if (this.state.location !== nextProps.router.location.pathname) {
      this.setState({
        isEdited: false,
        location: nextProps.router.location.pathname
      });
    }
  }

  componentWillUnmount() {
    this.setState({
      isEdited: false,
      isMonitoringScreen:false
    });
  }


  hitApis() {
    const data = {
      homeownerId: this.props.router.params.id,
      companyId: this.companyId
    };

    if (data.homeownerId && data.companyId) {
      this.props.setImages({});
      this.props.setLoader({ loader: true });

      this.props.requestCompanyIdData(this.companyId);

      this.props.receiveReferralData({});
      this.props.requestReferralData(data);

      this.props.receiveSurveyData({});
      this.props.requestSurveyData(data);

      this.props.receiveSocialShareData({});
      this.props.requestSocialShareData(data);


      this.props.receiveHomeownerSystemGlance({});
      this.props.requestHomeownerSystemGlance(data);

      this.props.requestHomeownerCampaignData(data);

      this.props.receiveLoggedInHomeownerAction({});
      this.props.requestLoggedInHomeownerAction(data);

      this.props.receiveHomeownersList({});
      this.props.requestHomeownersList(data);

      this.props.receiveHomeownerEnergyData({});
      this.props.requestHomeownerEnergyData({
        homeownerId: data.homeownerId,
        sync: REPORT_SYNC,
        requestMonthHistory: true
      });
      this.props.requestGetEversignData(data);

      this.props.receiveAddHomeOwner({});
      this.props.requestHomeownerAddUser(data);
    }
  }

  updateState = (values, closePopUp) => {
    if (!closePopUp) {
      this.setState({ addPopUp: false });
    } else {
      let {homeownerData} = this.props.loggedInUser
      let body = {
        homeownerId : this.props.router.params.id,
        companyName : homeownerData && homeownerData.companyName,
        companyId   : this.companyId,
        firstName   : values.firstName,
        middleName  : values.middleName,
        lastName    : values.lastName,
        state       : homeownerData.address && homeownerData.address.state,
        email       : values.email,
        phone       : values.phone.replace(/[^A-Z0-9]/ig, "")
      }
      this.props.setLoader({loader:true});
      addHomeownerAdditionalUser(body).then(r => {
        this.props.setLoader({ loader: false });
        if (r && r.status === 200) {
          this.setState({ notificationTrigger: true, userData: r.data })
          this.props.requestHomeownerAddUser(homeownerData);
        }
      }).catch(r => {
        this.props.setLoader({ loader: false });
        openNotificationWithIcon('error', `The user was not added because there is already a Bodhi account for ${values.email}`, 'Error');
      });
    }
  }

  switchDashboard() {
    this.setState({
      isEdited: !this.state.isEdited,
      isMonitoringScreen : false
    });
    this.forceUpdate();
  }

  handleDeleteHomeownerPopupVisibility = status => {
    this.setState({
      deleteHomeOwnerVisibility: status
    });
  };

  deletePopUpProp = () => {
    this.setState({
      notificationTrigger: false
    });
  }

  renderPopUp() {
    let { userData } = this.state;
    return (
      <NotificationModal deletePopUpProp={this.deletePopUpProp} name={`${userData.firstName} ${userData.lastName}`}
        email={userData && userData.email} imageName={'check-yellow.svg'}
      />
    )
  }

  render() {
    let {loggedInUser,companySlugData}=this.props;
    let homeownerData =  loggedInUser.homeownerData;
    let { firstName, lastName ,phone,address,email,formSetting} = (loggedInUser && loggedInUser.homeownerData)|| {};
    let name = '';
    let companyName = homeownerData&&homeownerData.companyName ? homeownerData.companyName :'';
    const antIcon = <LoadingOutlined style={{ fontSize: 60 }} spin />;
    if (firstName && lastName) {
      name = `${firstName} ${lastName}`;
    }

    if (!this.state.isMonitoringScreen && loggedInUser.documentData && loggedInUser.documentData.source == 'sharepoint') {
      let files = loggedInUser.documentData.driveData;
      this.props.setImages(files);
    } else if(!this.state.sharePointFile && loggedInUser.documentData && loggedInUser.documentData.source == 'sharepoint'){
        this.setState({
          sharePointFile: true
        },()=>{
          this.props.setImages({});
        })
    }

    if(homeownerData){
    return (
      <Spin
        spinning={this.props.loader}
        indicator={antIcon}
        style={{ color: 'red' }}
      >
        <React.Fragment>
          {
          this.state.addPopUp && 
          <AddHardware
            popWidth={700} formSetting = {formSetting}
            viewName="additional user" headerImg="add_person_yellow.svg" 
            updateState={this.updateState} addUserForm={true} {...this.state}/>
          }
          {this.state.notificationTrigger && this.renderPopUp()}
        <title>{name} - {companyName+'-'} Bodhi</title>
          <div>
            <section className="profile-detail-box">
              <div className="container">
                <div className="row justify-content-md-center view content-center">
                  <p className="mobile-view-only homeowner-account mid-loc">
                    Homeowner view
                  </p>
                  <div className="col-md-6">
                    <div className="row">
                      <div className="col-md-5 col-xs name-adddres-box">
                        <h1>{firstName} <br />
                          {lastName}</h1>
                        <div className="address-box">
                          <strong>{address && address.address1} <br />
                          {address && address.city},  {address && address.state} {address && address.zipCode} <br/>
                          </strong>

                          <NumberFormat
                           
                            type="tel"
                            className="border-0"
                            format={(formSetting && formSetting.phone)}
                            disabled= {true}
                            value ={phone}
                            style = {{
                              fontWeight: 400
                            }}
                          /><br/>

                          {email}
                        </div>
                      </div>
                      <div className="col-md-7 col-xs personal-detail-box">
                        {
                          homeownerData && homeownerData.salespersonEmail && 
                          <div className="detail-box mb-2">
                            <p> Salesperson</p>
                            <p>{homeownerData && (homeownerData.salespersonName || homeownerData.salespersonEmail)}</p>
                          </div>
                        }

                        <div className="detail-box mb-2">
                          <p> Project Manager</p>
                          <p>{homeownerData && (homeownerData.installerName || homeownerData.installerEmail)}</p>
                        </div>

                    
                        <div className="detail-box">
                          <p> Additional Users</p>
                          {this.renderAdditionaluser()}
                        </div>
                        <div className="add-user" onClick={() => this.setState({ addPopUp: true })}>
                          <img className="add-btn" src={addIcon}/>
                          <div className="text">Add User</div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-6 no-padding-box p-0">
                    <div className="white-box box-Shadow">

                      <div className="col-md-4 seprator-right">
                        <div className="headline pl-1">Referrals</div>
                        <div className="icon">
                          <img className="referral" src={shareReferIcon} />
                          <span className="stats">{this.state.referralsCount}</span>
                        </div>

                      </div>
                      <div className="col-md-4 seprator-right">
                        <div className="headline pl-1">Surveys</div>
                        <div className="icon">
                          <img className="survey" src={surveysIcon} />
                          <span className="stats">{this.state.surveysCount}</span>
                        </div>

                      </div>
                      <div className="col-md-4">
                        <div className="headline pl-1">Social Shares</div>
                        <div className="icon">
                          <img className="share" src={fingerTouchIcon}/>
                          <span className="stats">{this.state.socialSharesCount}</span>
                        </div>

                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>
            {!this.state.deleteHomeOwnerVisibility && (
              <section>
                <div className="container">
                  <div className="row">

                    <section className="tab-wrapper">
                      <div className="container">
                        <div className="row">
                          <div className="col-sm-12">
                            <ul>
                              <li className="home-owner">
                                <a
                                  onClick={() => {
                                    this.setState({
                                      isMonitoringScreen: !this.state.isMonitoringScreen,
                                      sharePointFile : this.state.isMonitoringScreen,
                                      isEdited: false
                                    });
                                  }}
                                >
                                  <span className="icon"></span>
                                  <span>{this.state.isMonitoringScreen ? 'Homeowner view' : 'Overview'}</span>
                                </a>
                              </li>
                              {
                                 (this.slug !='creative' && this.slug !='altair') && 
                                <li className="sys-crm"><a
                                  onClick={() => this.syncCRM()}
                                >
                                  <span className="icon"></span>
                                  <span>Sync CRM</span>
                                </a>
                                </li>
                              }
                              <li className="edit-detail"><a onClick={() => {
                                if (!this.state.isEdited) {
                                  this.setState({
                                    isEdited: true,
                                    isMonitoringScreen: false
                                  });
                                }
                              }}>
                                <span className="icon"></span>
                                <span>Edit details</span>
                              </a>
                              </li>
                              <li className="delete"><a onClick={() => {
                                this.handleDeleteHomeownerPopupVisibility(
                                  true
                                );
                              }}>
                                <span className="icon"></span>
                                <span>Delete</span>
                              </a>
                              </li>
                            </ul>
                          </div>
                        </div>
                      </div>
                    </section>
                    <div className="homeowner-view-wrapper w-100">

                      {this.state.isMonitoringScreen ? <MonitoringScreen
                        {...this.props}
                        homeOwnerId={this.props.router.params.id}
                        receiveGetEversignData={
                          this.props.receiveGetEversignData
                        }
                        requestGetEversignData={
                          this.props.requestGetEversignData
                        }
                        requestAddHomeOwner={this.props.requestAddHomeOwner}
                        companyDetail={this.props.companyDetail}
                        setLoader={this.props.setLoader}
                        systemGlance={this.props.systemGlance}
                        energyReport={this.props.energyReport}
                        receiveHomeownerEnergyData={
                          this.props.receiveHomeownerEnergyData
                        }
                        requestHomeownerEnergyData={
                          this.props.requestHomeownerEnergyData
                        }
                        cloudImages={this.props.cloudImages}
                        setImages={this.props.setImages}
                        loggedInUser={this.props.loggedInUser}
                        isDashboard={this.state.isDashboard}
                        installerDetails={this.props.installerDetails}
                        referralDetails={this.props.referralDetails}
                        homeownersList={this.props.homeownersList}
                        location={this.props.router.location}
                        loadDashboard={() => {
                          this.setState({
                            isDashboard: false
                          });
                        }}
                      />
                        : !this.state.isEdited ? (

                          <Dashboard
                            receiveGetEversignData={
                              this.props.receiveGetEversignData
                            }
                            requestGetEversignData={
                              this.props.requestGetEversignData
                            }
                            companyDetail={this.props.companyDetail}
                            setLoader={this.props.setLoader}
                            systemGlance={this.props.systemGlance}
                            energyReport={this.props.energyReport}
                            receiveHomeownerEnergyData={
                              this.props.receiveHomeownerEnergyData
                            }
                            requestHomeownerEnergyData={
                              this.props.requestHomeownerEnergyData
                            }
                            cloudImages={this.props.cloudImages}
                            setImages={this.props.setImages}
                            loggedInUser={this.props.loggedInUser}
                            isDashboard={this.state.isDashboard}
                            homeOwnerId={this.props.router.params.id}
                            installerDetails={this.props.installerDetails}
                            referralDetails={this.props.referralDetails}
                            homeownersList={this.props.homeownersList}
                            location={this.props.router.location}
                            resourceData = {this.props.resourceData}
                            eversignDetails={this.props.eversignDetails}
                            loadDashboard={() => {
                              this.setState({
                                isDashboard: false
                              });
                            }}
                            {...this.props}
                          />
                        ) : (
                            <EditHomeOwner
                              {...this.props}
                              requestAddHomeOwner={this.props.requestAddHomeOwner}
                              homeOwnerId={this.props.router.params.id}
                              switchDashboard={() => this.switchDashboard()}
                            />
                          )}
                    </div>
                  </div>
                </div>
              </section>
            )}
            {this.state.deleteHomeOwnerVisibility && (
              <DeleteHomeOwner
                handleDeleteHomeownerPopupVisibility={
                  this.handleDeleteHomeownerPopupVisibility
                }
              />
            )}
          </div>
        </React.Fragment>
      </Spin>
    );
  }
    else {
      if (!this.state.homeOwnerExist) return <ErrorPage />;
      else {
          return <LandingPage loadingIcon={companySlugData && companySlugData.mobileLogo}/>;
      }
  }
    }

    renderAdditionaluser() {
      let {additionalUser} =this.props;

      return additionalUser && additionalUser.map((ele, index) => {
        let phone = ele.phone;
        const content = (
          <div>
            <p><strong>Phone Number</strong>: {phone &&('('+phone.slice(0, 3) + ") "+phone.slice(3, 6)+"-"+phone.slice(6,phone.length))}</p>
            <p><strong>Email</strong>: {ele.email}</p>
          </div>
        );
    
        return (<React.Fragment key={index}>
          <Popover placement="bottomLeft" trigger="click" content={content}>
            <span
              className="action-btn"
              style={{
                "cursor": "pointer"
              }}
            >{
              ele.userName
            }</span>
            {
              index + 1 != additionalUser.length && this.renderSpace()
            }
          </Popover>
        </React.Fragment>)
      })
    }

    renderSpace(){
      return(
        <>,{" "}</>
      )
    }

  // sync crm process 
  syncCRM = () => {
    const { loggedInUser } = this.props;
    let toolContactId = '';
    let toolSolarUnitId = '';
    if (loggedInUser && loggedInUser.homeownerData && loggedInUser.solarUnit) {
      if (loggedInUser.homeownerData.toolContactId)
        toolContactId = loggedInUser.homeownerData.toolContactId;

      if (loggedInUser.solarUnit.toolSolarUnitId)
        toolSolarUnitId = loggedInUser.solarUnit.toolSolarUnitId;
    }
    const data = {
      id        : loggedInUser.homeownerData.id,
      email     : encodeURIComponent(loggedInUser.homeownerData.email),
      syncCRM   : true,
      activeJourney : (loggedInUser.solarUnit.activeJourney && loggedInUser.solarUnit.activeJourney.s3Key) || 'default',
      companyId : this.companyId,
      toolSolarUnitId,
      toolContactId
    };
   
    this.props.setLoader({ loader: true})
    this.props.requestAddHomeOwner(data);
  };


  filterCRMData = (syncCrmData,homeOwnerData) =>{ 
    let solarUnit = syncCrmData && syncCrmData.solarUnit ? JSON.parse(JSON.stringify(syncCrmData.solarUnit)) :{};
    let companyData = this.props.companyId.data;
    if (syncCrmData && syncCrmData.message) {
      openNotificationWithIcon('error', syncCrmData.message, 'Error');
      this.props.receiveAddHomeOwner({})
    } else if (
      homeOwnerData
      && syncCrmData
      && syncCrmData.homeownerData
    ) {
      Object.keys(syncCrmData.homeownerData).forEach(key => {
        if (syncCrmData.homeownerData[key] && key !== 'address') {
          homeOwnerData.homeownerData[key] = syncCrmData.homeownerData[key]
        } else if (key === 'address') {
          Object.keys(syncCrmData.homeownerData.address).forEach(addressKey => {
            if (syncCrmData.homeownerData.address[addressKey]) {
              homeOwnerData.homeownerData.address[addressKey] = syncCrmData.homeownerData.address[addressKey];
            }
          })
        }
      })

      Object.keys(solarUnit).forEach(key => {
        if (solarUnit[key]) {
          homeOwnerData.solarUnit[key] = solarUnit[key];
        }
      })

      let moduleBrandList = solarUnit['moduleBrand'] && companyData.moduleBrands.filter(module => {
        return module.name && module.name.toString().toLowerCase().replace(/ /g, '') === solarUnit['moduleBrand'].toLowerCase().replace(/ /g, '');
      })[0];

      let inverterBrandList = solarUnit['inverterBrand'] && companyData.inverterBrands.filter(inverter => {
        return inverter.name && inverter.name.toString().toLowerCase().replace(/ /g, '') === solarUnit['inverterBrand'].toLowerCase().replace(/ /g, '');
      })[0];
      var utilityList;
      solarUnit['electricUtilityName'] && companyData.electricutility.filter(ele => {
        let matchArr = ele.abbr ? ele.abbr.replace(/ /g, '').toLowerCase().split(',') : [];
        matchArr.push(ele.name.replace(/ /g, '').toLowerCase());
        if (matchArr.indexOf(solarUnit['electricUtilityName'].replace(/ /g, '').toLowerCase()) > -1) {
          utilityList = ele;
        }
      });
      let temp;
      let monitorType = solarUnit['meterType'] && companyData.powerMeter.filter(meter => {

      meter.filter && meter.filter.split(',').forEach(ele=>{
          if(ele.toLowerCase().replace(/ /g, '') === solarUnit['meterType'].toLowerCase().replace(/ /g, ''))
            temp = meter;
        });
        if(temp)
          return temp;
        
      })[0];

      let data = {
        companyId   : this.companyId,
        homeOwnerId : homeOwnerData.homeownerData.id,
        slug : this.slug,
        bodyData: {
          firstName           : homeOwnerData.homeownerData.firstName,
          middleName          : homeOwnerData.homeownerData.middleName,
          lastName            : homeOwnerData.homeownerData.lastName,
          phone               : Number(homeOwnerData.homeownerData.phone),
          address1            : homeOwnerData.homeownerData.address.address1,
          address2            : homeOwnerData.homeownerData.address.address2,
          ahj                 : homeOwnerData.solarUnit.ahj,
          city                : homeOwnerData.homeownerData.address.city,
          state               : homeOwnerData.homeownerData.address.state,
          zipcode             : homeOwnerData.homeownerData.address.zipCode,
          toolContactId       : homeOwnerData.homeownerData.toolContactId,
          projectManager      : homeOwnerData.homeownerData.installerId,
          ratePlan            : homeOwnerData.solarUnit.utilityRateId,
          moduleModel         : homeOwnerData.solarUnit.moduleModel,
          numberOfModules     : homeOwnerData.solarUnit.moduleCount,
          systemSize          : homeOwnerData.solarUnit.systemSize,
          systemPrice         : homeOwnerData.solarUnit.systemPrice,
          scaniflyUrl          : homeOwnerData.solarUnit.scaniflyUrl,
          annualConsumption   : homeOwnerData.solarUnit.annualConsumption,
          annualProduction    : homeOwnerData.solarUnit.annualProduction,
          projectAnnualSavings: homeOwnerData.solarUnit.saving ? homeOwnerData.solarUnit.saving : 0,
          electricityUtility  : utilityList ? utilityList.electricUtilityId : homeOwnerData.solarUnit.electricUtilityId,
          inverterBrand       : inverterBrandList ? inverterBrandList.id  : homeOwnerData.solarUnit.inverterBrandId,
          moduleBrand         : moduleBrandList   ? moduleBrandList.id    : homeOwnerData.solarUnit.moduleBrandId,
          crmMapping          : solarUnit.crmMapping,
          monitorType         : monitorType ? monitorType.meterId : homeOwnerData.solarUnit.meterId,
          meterID             : homeOwnerData.solarUnit.meterconId ? homeOwnerData.solarUnit.meterconId : homeOwnerData.solarUnit.meterID,
          meterUrl            : homeOwnerData.solarUnit.meterUrl,
          connectionStatus    : homeOwnerData.solarUnit.connectionStatus,
          toolSolarUnitId     : homeOwnerData.solarUnit.toolSolarUnitId,
          installationDate    : homeOwnerData.solarUnit.installationDate,
          touchHistory        : homeOwnerData.solarUnit.touchHistory,
          companyS3Data       : this.props.companyJourney
        }
      };
      if(syncCrmData.documents && syncCrmData.documents.length>0){
        var documentSource = syncCrmData.documentSource || 'serviceFusion';
        data['documentId'] = {
          source: documentSource,
          key: documentSource+'_'+homeOwnerData.solarUnit.toolSolarUnitId,
          files: {[documentSource+'_'+homeOwnerData.solarUnit.toolSolarUnitId] : syncCrmData.documents},
          isSharedDrive : documentSource.includes('monday') ? false :true
        }
        data['fusionDocuments'] = syncCrmData.documents;
      }
      if(syncCrmData.solarUnit && syncCrmData.solarUnit.documentId){
        data['documentId'] = {
          source: 'google',
          key: syncCrmData.solarUnit.documentId.split('/')[syncCrmData.solarUnit.documentId.split('/').length-1]
        }
      }
      this.props.requestUpdateHomeOwner(data);
    }
  }
}

const mapStatetoProps = state => {
  return {
    magicData: state.homeownerLoggedInUser.getHomeownerDeviceMagicReducer.data || [],
    companySlugData : state.user.slugImage && state.user.slugImage.data && state.user.slugImage && state.user.slugImage.data,
    companyJourney: state.companyBodhiJourney && state.companyBodhiJourney.bodhiJourneyReducer
            && state.companyBodhiJourney.bodhiJourneyReducer.data&&
            state.companyBodhiJourney.bodhiJourneyReducer.data[0]||{},
    resourceData : state.companyBodhiJourney && state.companyBodhiJourney.bodhiJourneyReducer
    && state.companyBodhiJourney.bodhiJourneyReducer.data&&
    state.companyBodhiJourney.bodhiJourneyReducer.data[2],
    syncCrmData: state.addhomeOwner.homeOwner,
    loader: state.loader.loader.loader,
    activeCampaign:state.homeownerDashboard.homeownerCampaignData.data ||[],
    additionalUser : state.homeOwner.getHomeownerAddUser && state.homeOwner.getHomeownerAddUser.data,
    systemGlance: state.homeownerDashboard.homeownerSystemGlance.data || {},
    energyReport:
      state.homeownerLoggedInUser.homeownerEnergyReportReducer.data || {},
    loggedInUser:
      state.homeownerLoggedInUser.homeownerLoggedInUserReducer.data || {},
    homeownersList: state.homeownersList.homeownersList.data || {},
    cloudImages:
      JSON.stringify(state.cloudImages.cloudImages) !== '{}'
        ? state.cloudImages.cloudImages
        : {},
    homeOwner: {
      getReferral: state.homeOwner.getReferralData,
      getSurveyData: state.homeOwner.getSurveyData,
      getSocialShareData: state.homeOwner.getSocialShareData,
      getEngagementScore: state.homeOwner.getEngagementScore
    },
    homeOwnerData: state.homeOwner,
    installerDetails: state.homeownerContact.homeownerInstallerDetails.data,
    referralDetails: state.homeownerReferralDetails,
    companyId: state.addhomeOwner.companyId,
    companyDetail:
      state.homeownerResources.homeownerResoucesCompanyDetail &&
      state.homeownerResources.homeownerResoucesCompanyDetail.data &&
      state.homeownerResources.homeownerResoucesCompanyDetail.data.companyDetail
        ? state.homeownerResources.homeownerResoucesCompanyDetail.data
            .companyDetail
        : {},
    eversignDetails:
      state.homeownerGetEversign.homeownerGetEversignDocument.data
  };
};

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      requestAddHomeOwner,
      receiveAddHomeOwner,

      receiveReferralData,
      requestReferralData,

      receiveSurveyData,
      requestSurveyData,

      receiveSocialShareData,
      requestSocialShareData,

      receiveEngagementScore,
      requestEngagementScore,

      requestHomeownerEnergyData,
      receiveHomeownerEnergyData,

      receiveHomeownerSystemGlance,
      requestHomeownerSystemGlance,

      requestHomeownerCampaignData,

      receiveLoggedInHomeownerAction,
      requestLoggedInHomeownerAction,

      setLoader,
      setImages,

      requestCompanyIdData,
      receiveEgaugePowerMeter,

      requestUpdateHomeOwner,
      receiveUpdateHomeOwner,

      requestInstallerDetails,

      requestPostReferralDetails,
      receivePostReferralDetails,

      receiveHomeownersList,
      requestHomeownersList,

      requestCompanyJourney,

      requestGetEversignData,
      receiveGetEversignData,

      requestHomeownerAddUser,
      receiveHomeownerAddUser,

      requestDeviceMagic,
      receiveDeviceMagic
    },
    dispatch
  );

export default connect(
  mapStatetoProps,
  mapDispatchToProps
)(withRouter(HomeOwner));
