//  get logged-in home owner data
    export const REQUEST_LOGGED_IN_HOMEOWNER = "REQUEST_LOGGED_IN_HOMEOWNER";
    export const RECEIVE_LOGGED_IN_HOMEOWNER ="RECEIVE_LOGGED_IN_HOMEOWNER";
    export const RECEIVE_HOMEOWNER_DATA ="RECEIVE_HOMEOWNER_DATA";

//  notify installer for homeowner 1st login
    export const REQUEST_HOMEOWNER_INSTALLER_NOTIFIY = "REQUEST_HOMEOWNER_INSTALLER_NOTIFIY" 

//  get homeowner energy report
    export const REQUEST_HOMEOWNER_ENERGY_REPORT = "REQUEST_HOMEOWNER_ENERGY_REPORT";
    export const RECEIVE_HOMEOWNER_ENERGY_REPORT ="RECEIVE_HOMEOWNER_ENERGY_REPORT";
    export const RECEIVE_HOMEOWNER_SPECIAL_CARDDATA="RECEIVE_HOMEOWNER_SPECIAL_CARDDATA";
    export const REQUEST_HOMEOWNER_SPECIAL_CARDDATA="REQUEST_HOMEOWNER_SPECIAL_CARDDATA";
    export const REQUEST_UPDATE_HOMEOWNER = 'REQUEST_UPDATE_HOMEOWNER';
    export const RECEIVE_UPDATE_HOMEOWNER = 'RECEIVE_UPDATE_HOMEOWNER';

    export const REQUEST_DEVICE_MAGIC = "REQUEST_DEVICE_MAGIC";
    export const RECEIVE_DEVICE_MAGIC = "RECEIVE_DEVICE_MAGIC";