// import npm packages
import React,{Component}  from 'react';
import withRouter         from '../../../../shared/withRouter';
import { getToken }       from '../../../../shared/util';
import { checkIsMobile }  from "../../../../shared/checkIsMobile"
import SolarCommunity     from '../../../homeowner/components/shared/solarCommunity';
import SystemAtGlance     from '../../../homeowner/components/shared/systemAtGlance';
import HomeownerDashboard from '../../../homeowner/container/dashboard/dashboard';
import Resources          from '../../../homeowner/container/resources/resources';
import FAQ                from '../../../homeowner/container/faq';
import Settings           from '../../../homeowner/container/settings';
import Contact            from '../../../homeowner/container/contact/contact';
import BodhiJourney       from '../../../homeowner/components/shared/bodhiJourney';
import Documents          from '../../../homeowner/container/documents';
import LandingPage        from '../../../Common/LandingPage';
import contactIcon        from "./../../../../Assets/Images/contact.svg"
import settingsIcon       from "./../../../../Assets/Images/settings.svg"
import documentsIcon      from './../../../../Assets/Images/documents.svg'
import questionMarkIcon   from './../../../../Assets/Images/question_mark.svg'
import edResourcesIcon    from './../../../../Assets/Images/Ed_resources_blue.svg'
import settingsIconBlue   from './../../../../Assets/Images/setting-icon-blue.svg'
import phoneChatIcon      from './../../../../Assets/Images/phone_chat.svg'
import logoutIconBlue     from './../../../../Assets/Images/logout_blue.svg'

class Dashboard extends Component {
  constructor(props) {
    super(props);
    this.slug = '';
    this.state = {
      isSlidesReset: true,
      isDashboard: true,
      select:
        localStorage.getItem('tabName') === 'document'
          ? 'document'
          : 'dashboard',
      isShow: false,
      dasboardActive: 'home-blue.svg',
      searchActive: 'sun-icon.svg',
      addhomeownerActive: 'group7.png',
      solarCommunityActive: 'global-icon.png',
      homeOwnerHeight: ''
    };
  }

  componentWillReceiveProps(nextProps) {
    this.setHeight();
    if (nextProps.isDashboard && !this.state.isDashboard) {
      this.setState({
        isDashboard: true,
        select: 'dashboard'
      });
    }
  }

  componentWillMount() {    
    this.slug = this.props.router.params.slug || localStorage.getItem("slug");
    this.setIcons();
  }

  componentDidUpdate(prevProps) {
    if (this.props.router.location.pathname !== prevProps.router.location.pathname) {
      this.setIcons();
      this.setState({
        isGlanceShow: false,
        isSolarShow: false
      });
    }
  }

  setHeight() {
    let homeOwner = null;
    if (document.getElementById('homeownerview-mobile'))
      homeOwner = document.getElementById('homeownerview-mobile').offsetHeight;
    if (homeOwner && this.state.homeOwnerHeight !== homeOwner) {
      this.setState({
        homeOwnerHeight: homeOwner
      });
    }
  }
  coloapsablehomeowner = () => {
    document.body.classList.toggle('hideCommunity');
    const currentState = this.state.active;
    this.setState({ active: !currentState });
    this.setState({
      isMount: !this.state.isMount,
      isShow: !this.state.isShow
    });
  };

  setIcons() {
    let { dasboardActive, searchActive, solarCommunityActive } = this.state;

    if (this.state.select === 'dashboard') {
      dasboardActive = 'home-blue.svg';
    } else {
      dasboardActive = 'shapeM.png';
    }

    if (this.state.select === 'systemGlance') {
      searchActive = 'sun-icon.svg';
    } else {
      searchActive = 'sun_rays.svg';
    }

    if (this.state.select === 'solarCommunity') {
      solarCommunityActive = 'global-icon-active.svg';
    } else {
      solarCommunityActive = 'global-icon.png';
    }
    this.setState({
      dasboardActive,
      searchActive,
      solarCommunityActive
    });
  }

  slideReset() {
    this.setState({
      isSlidesReset: false
    });
  }
  renderDashboard = () => {
    const {
      systemGlance,
      loggedInUser,
      referralDetails,
      cloudImages,
      setImages
    } = this.props;
    let mobileImage = (loggedInUser.homeownerData && loggedInUser.homeownerData.icon) ? loggedInUser.homeownerData.icon : "https://prod-17terawatts.s3.amazonaws.com/public/logo/bothi-mobile-logo.png";
    let companyName =  loggedInUser.homeownerData && loggedInUser.homeownerData.companyName;
    let installerName = loggedInUser.homeownerData && (loggedInUser.homeownerData.installerName ||loggedInUser.homeownerData.installerEmail)
    let navLogo = checkIsMobile() ? mobileImage : (systemGlance.solarGlance && systemGlance.solarGlance.logo)
    return (
      <React.Fragment>
        <div className="white-box w-100 border0 box-Shadow">
          <div
            id="homeOwnerWrapper"
            className="pddl30 pddr30 pddb30 pm-home-owner-wrapper wrapper-installer-data"
          >
            <header>
              <div className="nav-header-wrapper dashboard-nav-wrapper body-header">
                <nav className="navbar navbar-expand-lg navbar-light bg-light border-bottom-0">
                  {this.props.installerData &&
                    this.props.installerData.data &&
                    this.props.installerData.data.isProfileCreated ? (
                      <a
                        className="navbar-brand homeownerView"
                        onClick={() => {
                          this.props.router.navigate(`/${this.slug}/dashboard`);
                        }}
                        style={{
                          "backgroundImage": `url(${navLogo})`
                        }}
                      />
                    ) : (
                      <a className="navbar-brand homeownerView"  style={{
                        "backgroundImage": `url(${navLogo})`
                      }}/>
                    )}
                  <button
                    type="button"
                    data-toggle="collapse"
                    data-target="#navbarhomeOwnerContent"
                    aria-controls="navbarhomeOwnerContent"
                    aria-expanded="false"
                    aria-label="Toggle navigation"
                    onClick={this.coloapsablehomeowner.bind(this)}
                    className={`navbar-toggler ${
                      this.state.active ? 'cross-icon' : ''
                    }`}
                  >
                    <span className="navbar-toggler-icon" />
                  </button>
                  <div
                    className={
                      this.state.isShow
                        ? 'collapse navbar-collapse show '
                        : 'collapse navbar-collapse display-block'
                    }
                    id="navbarhomeOwnerContent"
                  >
                    <div className=" my-2 my-lg-0">
                      <ul className="navbar-nav desktop-homewoner-nav text-right">
                        <li className="nav-item">
                          <a
                            className={
                              this.state.select === 'dashboard'
                                ? 'size nav-link active'
                                : 'size nav-link'
                            }
                            id="home-tab"
                            data-toggle="tab"
                            href="#dasboard"
                            role="tab"
                            aria-controls="home"
                            aria-selected="true"
                            onClick={() => {
                              this.setState({
                                isSlidesReset: true,
                                isDashboard: true,
                                select: 'dashboard'
                              });
                            }}
                          >
                            HOME
                          </a>
                        </li>
                        {
                          this.slug !=='creative' && 
                          <li className="nav-item">
                            <a
                              className={
                                this.state.select === 'document'
                                  ? 'size nav-link active'
                                  : 'size nav-link'
                              }
                              id="profile-tab"
                              data-toggle="tab"
                              href="#document"
                              role="tab"
                              aria-controls="profile"
                              aria-selected="false"
                              onClick={() => {
                                this.setState({
                                  isSlidesReset: true,
                                  isDashboard: false,
                                  select: 'document'
                                });
                                this.props.loadDashboard();
                              }}
                            >
                              DOCUMENTS
                          </a>
                          </li>
                        }
                        
                        <li className="nav-item">
                          <a
                            className={
                              this.state.select === 'resource'
                                ? 'size nav-link active'
                                : 'size nav-link'
                            }
                            id="contact-tab"
                            data-toggle="tab"
                            href="#resource"
                            role="tab"
                            aria-controls="contact"
                            aria-selected="false"
                            onClick={() => {
                              this.setState({
                                isSlidesReset: true,
                                isDashboard: false,
                                select: 'resource'
                              });
                              this.props.loadDashboard();
                            }}
                          >
                            RESOURCES
                          </a>
                        </li>
                        <li className="nav-item">
                          <a
                            className={
                              this.state.select === 'faq'
                                ? 'size nav-link active'
                                : 'size nav-link'
                            }
                            id="contact-tab"
                            data-toggle="tab"
                            href="#faq"
                            role="tab"
                            aria-controls="contact"
                            aria-selected="false"
                            onClick={() => {
                              this.setState({
                                isSlidesReset: true,
                                isDashboard: false,
                                select: 'faq'
                              });
                              this.props.loadDashboard();
                            }}
                          >
                            FAQ
                          </a>
                        </li>
                        <li className="nav-item nav-add-link home-owner-nav pad10">
                          <a
                            className={"size shareSun"}
                          >
                            SHARE THE SUN
                          </a>
                        </li>
                        <li className="nav-item dropdown">
                        <a
                          className="nav-link dropdown-toggle"
                          id="navbarDropdown"
                          role="button"
                          data-toggle="dropdown"
                          aria-haspopup="true"
                          aria-expanded="false"
                        >
                          <img className="dashboard-profile-image comp-icon"
                            src={mobileImage}
                            // alt='Mobile profile-pic'
                          />
                          <span className="dashboard-profile-name">{companyName}</span>
                        </a>
                    <div
                      className="dropdown-menu box-Shadow"
                      aria-labelledby="navbarDropdown"
                    >
                      <div className="drop-up-arrow"></div>
                      <a
                            className="dropdown-item homeowner"
                            onClick={() => {
                              this.setState({
                                isSlidesReset: true,
                                isDashboard: false,
                                select: 'contact'
                              });
                              this.props.loadDashboard();
                            }}
                          >
                        <span className="drop-icon homeowner">
                          <img
                            src={contactIcon}
                            alt='contact-icon'
                          />
                        </span>
                        <span className="dropHeader">Contact {companyName}</span>
                        <div className="text">Project Manager</div>
                        <div className="text">{installerName}</div>
                      </a>

                      <a
                            className="dropdown-item"
                            onClick={() => {
                              this.setState({
                                isSlidesReset: true,
                                isDashboard: false,
                                select: 'settings'
                              });
                              this.props.loadDashboard();
                            }}
                          >
                        <span className="drop-icon">
                          <img
                            src={settingsIcon}
                            alt='setting-icon'
                          />
                        </span>
                        <span className="dropHeader">Change Settings</span>
                      </a>
                    </div>
                  </li>
                      </ul>

                      {/* mobile-menu    */}
                      <div className="row">
                        <div id="menuMobile" className="menu-mobile">
                          <div className="row">
                            <div
                              className="col-6 menu-box"
                              onClick={() => {
                                this.setState({
                                  isSlidesReset: true,
                                  isDashboard: false,
                                  select: 'document'
                                });
                                this.props.loadDashboard();
                                const currentState = this.state.active;
                                this.setState({
                                  active: !currentState,
                                  isShow: false
                                });
                              }}
                            >
                              <img
                                src={documentsIcon}
                                alt="documents-icon"
                              />
                              <span className="menu-text">DOCUMENTS</span>
                            </div>
                            <div className="col-6 menu-box">
                              <span
                                onClick={() => {
                                  this.setState({
                                    isSlidesReset: true,
                                    isDashboard: false,
                                    select: 'faq'
                                  });
                                  this.props.loadDashboard();
                                  const currentState = this.state.active;
                                  this.setState({
                                    active: !currentState,
                                    isShow: false
                                  });
                                }}
                              >
                                <img
                                  src={questionMarkIcon}
                                  alt="question_mark-icon"
                                />
                                <span className="menu-text">FAQs</span>
                              </span>
                            </div>
                            <div className="col-6 menu-box border-t-0 border-b-0">
                              <span
                                onClick={() => {
                                  this.setState({
                                    isSlidesReset: true,
                                    isDashboard: false,
                                    select: 'resource'
                                  });
                                  this.props.loadDashboard();
                                  const currentState = this.state.active;
                                  this.setState({
                                    active: !currentState,
                                    isShow: false
                                  });
                                }}
                              >
                                <img
                                  src={edResourcesIcon}
                                  alt="setting-icon-blue"
                                />
                                <span className="menu-text">
                                  EDUCATIONAL RESOURCES
                                </span>
                              </span>
                            </div>
                            <div className="col-6 menu-box border-t-0 border-b-0">
                              <span
                                onClick={() => {
                                  this.setState({
                                    isSlidesReset: true,
                                    isDashboard: false,
                                    select: 'settings'
                                  });
                                  this.props.loadDashboard();
                                  const currentState = this.state.active;
                                  this.setState({
                                    active: !currentState,
                                    isShow: false
                                  });
                                }}
                              >
                                <img
                                  src={settingsIconBlue}
                                  alt="setting-icon-blue"
                                />
                                <span className="menu-text">SETTINGS</span>
                              </span>
                            </div>
                            <div className="col-6 menu-box">
                              <span
                                onClick={() => {
                                  this.setState({
                                    isSlidesReset: true,
                                    isDashboard: false,
                                    select: 'contact'
                                  });
                                  this.props.loadDashboard();
                                  const currentState = this.state.active;
                                  this.setState({
                                    active: !currentState,
                                    isShow: false
                                  });
                                }}
                              >
                                <img
                                  src={phoneChatIcon}
                                  alt="phone_chat-icon"
                                />
                                <span className="menu-text">
                                  CONTACT YOUR PROJECT MANAGER
                                </span>
                              </span>
                            </div>
                            <div className="col-6 menu-box">
                              <span onClick={this.signOut}>
                                <img
                                  src={logoutIconBlue}
                                  alt="setting-icon-blue"
                                />
                                <span className="menu-text">LOGOUT</span>
                              </span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </nav>
              </div>
            </header>
            <div>
              {
                JSON.stringify(this.props.loggedInUser) === '{}' ? (
                  <LandingPage />
                ) : (
                  <section
                    id="homeownerview-mobile"
                    className="two-box-wrapper portal-intro-wrapp "
                  >
                    <div id="container" className="container">
                      <div className="homerowner-view">
                        <div className="row">
                          <div className="col-md-5 desktop-view-only col-12 offset-md-1">
                            <div className="progress-wrapp max-width-card-380-center">
                              <BodhiJourney {...this.props} isInstaller={true} />

                            <div className="whtie-heading-accordion">
                              <div id="accordion">
                              <div className="card">
                                  <div className="card-header" id="headingTwo">
                                    <h5 className="mb-0">
                                      <a
                                        href="#"
                                        className={`up-arra premiumBackgroundText ${
                                          this.state.isSolarShow
                                            ? ''
                                            : 'collapsed'
                                        }`}
                                        data-toggle="collapse"
                                        data-target="#collapseTwo"
                                        aria-expanded="false"
                                        aria-controls="collapseTwo"
                                        onClick={() => {
                                          this.setState({
                                            isSolarShow: !this.state.isSolarShow
                                          });
                                        }}
                                      >
                                        <span>SHARE THE SUN</span>
                                        <span className="arrow-wrap pull-right">
                                          <span className="up-arrow">
                                            <i
                                              className="fa fa-angle-up"
                                              data-unicode="f106"
                                            />
                                          </span>
                                          <span className="down-arrow premiumBackgroundText">
                                            <i
                                              className="fa fa-angle-down"
                                              data-unicode="f107"
                                            />
                                          </span>
                                        </span>
                                      </a>
                                    </h5>
                                  </div>
                                  <div
                                    id="collapseTwo"
                                    className={`collapse ${
                                      this.state.isSolarShow ? 'show' : ''
                                    }`}
                                    aria-labelledby="headingTwo"
                                    data-parent="#accordion"
                                  >
                                    <div className="card-body map-bg">
                                      <SolarCommunity
                                        loggedInUser={loggedInUser}
                                        referralDetails={referralDetails}
                                        {...this.props}
                                        isInstaller={true}
                                      />
                                    </div>
                                  </div>
                                </div>
                                <div className="card margb30">
                                  <div className="card-header" id="headingOne">
                                    <h5 className="mb-0">
                                      <a
                                        href="#"
                                        className={`up-arra ${
                                          this.state.isGlanceShow
                                            ? ''
                                            : 'collapsed'
                                        }`}
                                        data-toggle="collapse"
                                        data-target="#collapseOne"
                                        aria-expanded="true"
                                        aria-controls="collapseOne"
                                        onClick={() => {
                                          this.setState({
                                            isGlanceShow: !this.state
                                              .isGlanceShow
                                          });
                                        }}
                                      >
                                        <span className="ho-setting-acc-heading premiumBackgroundText">
                                          YOUR SYSTEM AT A GLANCE
                                        </span>
                                        <span className="arrow-wrap pull-right">
                                          <span className="up-arrow premiumBackgroundText">
                                            <i
                                              className="fa fa-angle-up"
                                              data-unicode="f106"
                                            />
                                          </span>
                                          <span className="down-arrow premiumBackgroundText">
                                            <i
                                              className="fa fa-angle-down"
                                              data-unicode="f107"
                                            />
                                          </span>
                                        </span>
                                      </a>
                                    </h5>
                                  </div>
                                  <div
                                    id="collapseOne"
                                    className={`card-body collapse paddt0 paddb0 ${
                                      this.state.isGlanceShow ? 'show' : ''
                                    }`}
                                    aria-labelledby="headingOne"
                                    data-parent="#accordion"
                                  >
                                    <SystemAtGlance
                                      location={this.props.router.location}
                                      installerDetails={
                                        this.props.installerDetails
                                      }
                                      systemGlance={systemGlance}
                                      loggedInUser={loggedInUser}
                                      cloudImages={cloudImages}
                                      setImages={setImages}
                                      setLoader={this.props.setLoader}
                                      isInstaller={true}
                                      {...this.props}
                                    />
                                  </div>
                                </div>
                               
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="col-md-5 col-12 ">
                          <div
                            className="tab-content max-width-card-400-center"
                            id="myTabContent"
                          >
                            <div
                              className={
                                this.state.select === 'dashboard'
                                  ? 'tab-pane fade show active'
                                  : 'tab-pane fade show'
                              }
                              id="dasboard"
                              role="tabpanel"
                              aria-labelledby="home-tab"
                            >
                              <HomeownerDashboard
                                {...this.props}
                                isInstaller={true}
                              />
                            </div>
                            <div
                              className={
                                this.state.select === 'resource'
                                  ? 'tab-pane fade show active'
                                  : 'tab-pane fade show'
                              }
                              id="resource"
                              role="tabpanel"
                              aria-labelledby="profile-tab"
                            >
                              <Resources
                                slideReset={() => {
                                  this.slideReset();
                                }}
                                companyDetail={this.props.systemGlance}
                                systemGlance={this.props.systemGlance}
                                setLoader={this.props.setLoader}
                                isSlidesReset={this.state.isSlidesReset}
                                isInstallerDashboard={true}
                                loggedInUser={loggedInUser}
                                homeOwnerId={this.props.homeOwnerId}
                                resourceData = {this.props.resourceData}
                              />
                            </div>
                            <div
                              className={
                                this.state.select === 'faq'
                                  ? 'tab-pane fade show active'
                                  : 'tab-pane fade show'
                              }
                              id="faq"
                              role="tabpanel"
                              aria-labelledby="contact-tab"
                            >
                              <FAQ
                                setLoader={this.props.setLoader}
                                homeOwnerId={this.props.homeOwnerId}
                              />
                            </div>
                            <div
                              className={
                                this.state.select === 'settings'
                                  ? 'tab-pane fade show active'
                                  : 'tab-pane fade show'
                              }
                              id="settings"
                              role="tabpanel"
                              aria-labelledby="contact-tab"
                            >
                              <Settings {...this.props} isInstaller={true} />
                            </div>
                            <div
                              className={
                                this.state.select === 'document'
                                  ? 'tab-pane fade show active'
                                  : 'tab-pane fade show'
                              }
                              id="document"
                              role="tabpanel"
                              aria-labelledby="contact-tab"
                            >
                              {this.state.select === 'document' ? (
                                <Documents
                                  {...this.props}
                                  documentTab = {true}
                                  setHeight={() => {
                                    this.setHeight();
                                  }}
                                  isInstaller={true}
                                />
                              ) : (
                                ''
                              )}
                            </div>
                            <div
                              className={
                                this.state.select === 'contact'
                                  ? 'tab-pane fade show active'
                                  : 'tab-pane fade show'
                              }
                              id="contact"
                              role="tabpanel"
                              aria-labelledby="contact-tab"
                            >
                              <Contact
                                installerDetails={this.props.installerDetails}
                                homeOwnerId={this.props.homeOwnerId}
                                loggedInUser={this.props.loggedInUser}
                                isInstaller={true}
                              />
                            </div>
                            <div
                              className={
                                this.state.select === 'systemGlance'
                                  ? 'tab-pane fade show active'
                                  : 'tab-pane fade show'
                              }
                              id="systemGlance"
                              role="tabpanel"
                              aria-labelledby="contact-tab"
                            >
                              <SystemAtGlance
                                location={this.props.router.location}
                                installerDetails={this.props.installerDetails}
                                systemGlance={systemGlance}
                                loggedInUser={loggedInUser}
                                cloudImages={cloudImages}
                                setImages={setImages}
                                setLoader={this.props.setLoader}
                                isInstaller={true}
                              />
                            </div>
                            <div
                              className={
                                this.state.select === 'solarCommunity'
                                  ? 'tab-pane fade show active'
                                  : 'tab-pane fade show'
                              }
                              id="solarCommunity"
                              role="tabpanel"
                              aria-labelledby="contact-tab"
                            >
                              <SolarCommunity
                                {...this.props}
                                isInstaller={true}
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="homeownerFooter">
                     {this.renderHomeownerFooter}
                    </div>
                  </div>
                </section>
              )}
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  };
  render() {
    if (getToken()) {
      return this.renderDashboard();
    } else {
      this.props.router.navigate('/');
      window.location.reload();
      return null;
    }
  }

  renderHomeownerFooter = () =>{
    return(
      <div className="footer-17">
      <img
        src={require(`./../../../../Assets/Images/${
          this.state.dasboardActive
        }`)}
        onClick={() => {
          this.setState(
            {
              isSlidesReset: true,
              isDashboard: true,
              select: 'dashboard'
            },
            () => {
              this.setIcons();
            }
          );
        }}
        alt="Dashboard"
      />
      <img
        src={require(`./../../../../Assets/Images/${
          this.state.searchActive
        }`)}
        onClick={() => {
          this.setState(
            {
              isSlidesReset: true,
              isDashboard: false,
              select: 'systemGlance'
            },
            () => {
              this.setIcons();
            }
          );
        }}
        alt="systemGlance"
      />
      <img
        src={require(`./../../../../Assets/Images/${
          this.state.solarCommunityActive
        }`)}
        style={{ width: '32px' }}
        onClick={() => {
          this.setState(
            {
              isSlidesReset: true,
              isDashboard: false,
              select: 'solarCommunity'
            },
            () => {
              this.setIcons();
            }
          );
        }}
        alt="solarCommunity"
      />
    </div>
    )
  }
}

export default withRouter(Dashboard);
