// import npm packages
import { Dropbox } from 'dropbox';
import envConfig from './../environments/index';

// import local files

class DropBoxSync {
  constructor() {
    this.result = {};
    this.flag = {};
  }

  handleClientLoad(path) {
    const dbx = new Dropbox({
      clientId: envConfig.envConfig.dropbox.clientId,
      fetch
    });
    return Promise.resolve(dbx.getAuthenticationUrl(`${path}/dropboxAuth`));
  }

  initializeDropbox(dropboxAccessToken) {
    if (this.dbx) {
      return Promise.resolve(true);
    }
    this.dbx = new Dropbox({
      accessToken: dropboxAccessToken ,
      fetch
    });
    return Promise.resolve(true);
  }

  async dropBoxFolderList(path,dropboxToken) {
    let folderList = [];
    !this.dbx && await this.initializeDropbox(dropboxToken);
    return this.dbx && this.dbx
      .filesListFolder({
        path: path || '',
        limit: 2000
      })
      .then(response => {
        if (response['entries'].length > 0) {
          response['entries'].forEach(ele => {
            if (ele['.tag'] === 'folder') {
              folderList.push({
                name: ele.name,
                path: ele['path_display'],
                id: ele['id']
              });
            }
          });
          return Promise.resolve(folderList);
        }
      })
      .catch(error => {
        localStorage.setItem('pathname', window.location.pathname);
        if (error.status === 400) {
          return this.handleClientLoad(window.location.origin);
        }
      });
  }

  dropBoxListFiles(path, filter) {
    this.flag = {};
    this.result = {};
    return this.dbx
      .filesListFolder({
        path,
        recursive: true,
        limit: 2000,
        include_media_info: true
        // shared_link: {url: path}
      })
      .then(response => {
        return this.filterDropboxFile(response, filter);
      })
      .catch(error => {
        return this.dropboxError(error);
      });
  }

  dropboxError(error) {
    localStorage.setItem('pathname', window.location.pathname);
    // localStorage.setItem('isFileSync',true);
    if (
      error.status === 409 &&
      error.error &&
      error.error.error_summary.toLowerCase().includes('path/not_found/')
    ) {
      return { status: error.status, message: 'Path not found' };
    }
    if (error.status === 400) {
      return this.handleClientLoad(window.location.origin);
    }
  }

  filterDropboxFile(response, filter) {
    const folder = response.entries;
    if (folder.length) {
      folder.forEach((file, i) => {
        let temp = file['path_display'].replace('/' + file['name'], '');
        temp = temp.split('/');
        temp = temp[temp.length - 1];
        if (file['.tag'] === 'file') {
          if (!this.result[temp]) {
            this.result[temp] = [temp];
          }
          if (filter) {
            let filterArray = filter.split(',');
            filterArray.forEach(ele => {
              if (file.name.toLowerCase().includes(ele.toLowerCase())) {
                Object.keys(this.flag).forEach(ele => {
                  if (
                    file.name &&
                    file.name
                      .toLowerCase()
                      .replace(/ /g, '')
                      .includes(ele)
                  ) {
                    this.flag[ele] = true;
                  }
                });
                this.result[temp].push({
                  name: file.name,
                  id: file.id,
                  path_display: file.path_display
                });
              }
            });
          } else {
            Object.keys(this.flag).forEach(ele => {
              if (
                file.name &&
                file.name
                  .toLowerCase()
                  .replace(/ /g, '')
                  .includes(ele)
              ) {
                this.flag[ele] = true;
              }
            });

            this.result[temp].push({
              name: file.name,
              id: file.id,
              path_display: file.path_display
            });
          }
        }
      });
      //    this.responseFile(this.result, this.flag);
    }
    if (response['has_more']) {
      return this.filesListFolderContinue(
        this.dbx,
        response.cursor,
        filter
      ).then(r => {
        return this.responseFile(this.result, this.flag);
      });
    } else {
      return this.responseFile(this.result, this.flag);
    }
  }

  filesListFolderContinue(dbx, cursor, filter) {
    return new Promise((res, rej) => {
      dbx
        .filesListFolderContinue({
          cursor: cursor
        })
        .then(response => {
          // if(response.has_more){
          //     this.filesListFolderContinue(dbx, cursor, filter)
          // }
          return res(this.filterDropboxFile(response, filter));
        })
        .catch(error => {
          return this.dropboxError(error);
        });
    });
  }

  getPreview(fileUrl) {
    return this.dbx
      .sharingCreateSharedLinkWithSettings({ path: fileUrl })
      .then(response => {
        return {
          name       : response.name,
          filePath   : fileUrl,
          previewUrl : response.url.replace('dl=0', 'raw=1')
        };
      })
      .catch(error => {
        if (error && error.status && error.status === 409) {
          return {
            name       : error.error.error.shared_link_already_exists.metadata.name,
            filePath   : fileUrl,
            previewUrl : error.error.error.shared_link_already_exists.metadata.url.replace('dl=0', 'raw=1')
          };
        }
      });
  }

  responseFile(result, flag) {
    let missingFile = [];
    for (var i in flag) {
      if (!flag[i]) {
        missingFile.push(i);
      }
    }
    for (var i in flag) {
      if (i === 'layout' && flag[i]) {
        const index = missingFile.findIndex(val => val === 'siteplan');
        missingFile.splice(index, 1);
      }
      if (i === 'siteplan' && flag[i]) {
        const index = missingFile.findIndex(val => val === 'layout');
        missingFile.splice(index, 1);
      }
      if (i === 'contract' && flag[i]) {
        const index = missingFile.findIndex(val => val === 'agreement');
        missingFile.splice(index, 1);
      }
      if (i === 'agreement' && flag[i]) {
        const index = missingFile.findIndex(val => val === 'contract');
        missingFile.splice(index, 1);
      }
    }

    let r = {
      fileList: result,
      missingFile: missingFile
    };
    return r;
  }
  
  getDropBoxFilesList(path, filter) {
    let arr = [];
    return new Promise((resolve, rej) => {
      this.dropBoxListFiles(path, filter).then(res => {
        if (typeof res === 'string' && res.includes(window.origin)) {
          window.location.replace(res)
        } else if(res.status===409){
          rej(false);
        } else {
          res && Object.keys(res && res.fileList).map((key) => {
            res.fileList[key].forEach(file => {
              if (file.path_display) {
                arr.push(this.getPreview(file.path_display));
              }
            })
          });

          Promise.all(arr).then(result => {
            resolve({previewUrls:result,files: res.fileList});
          });
        }
      })
    })
  }
}

export default DropBoxSync;
