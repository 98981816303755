import React, { Component }            from 'react';
import {Form,Input,Select,Checkbox}    from 'antd';
import { connect }                     from 'react-redux';
import {bindActionCreators}            from 'redux';
import NumberFormat                    from "react-number-format";
import {requestCompanyDetailSet,
    requestCompanyDetail}              from '../../actions/profile';
import { receiveCompanyJourney }       from "../../actions/companyJourney";
import {requestEditInstallerApiData,
        recieveEditInstallerApiData
}   from "../../../Admin/Actions/editInstaller";
import withRouter                      from '../../../../shared/withRouter';
import {requestStateListApiData}       from "../../../Admin/Actions/dashboard"
import CompanyLogoUpload               from "../../../Common/CompanyLogoUpload";
import { DeleteModal }                 from "../../../Common/DeleteModal";
import {setLoader}                     from '../../../Common/Loader/action';
import {deleteCompany}                 from "../../../Admin/Api/dashboard"
import {ReactComponent as CaretIcon}   from "../../../../Assets/Images/path-2.svg";

const FormItem = Form.Item;
const Option = Select.Option;

class CompanyDetailForm extends Component {
    constructor(props) {
        super(props);
        this.state = {
            MuploadUrl: undefined,
            deleteTrigger : false,
            IconuploadUrl: undefined
        };
    }

    componentDidUpdate(prevProps) {
        if (this.props.router.location.pathname !== prevProps.router.location.pathname) {
            this.setState({MuploadUrl:undefined,IconuploadUrl:undefined});
        }
    }

    componentWillMount() {
        let {companyData : {companyDetail}} = this.props;
        !companyDetail && this.props.setLoader({ loader: true });
        this.props.receiveCompanyJourney({});
        this.props.requestStateListApiData({companyId:this.props.companyId});
    }

    componentWillReceiveProps(nextProps) {
        let {companyData : {companyDetail}} = nextProps;

        if(companyDetail && companyDetail.mobileLogo && (!this.state.MuploadUrl)){
            this.props.setLoader({ loader: false });
            this.setState({
                MuploadUrl: this.state.MuploadUrl || (companyDetail && companyDetail.mobileLogo),
                IconuploadUrl: this.state.IconuploadUrl || (companyDetail && companyDetail.icon),
            });
        }        

        if (this.state.isNPS=== undefined){
            this.setState({isNPS : this.state.isNPS || (companyDetail && companyDetail.isNPS)})
        }

        if (nextProps.dashboard.editInstaller && nextProps.dashboard.editInstaller.status === 200) {
            nextProps.receiveCompanyJourney({});
            nextProps.requestCompanyDetail({companyId:this.props.companyId,admin:this.props.isAdmin});
            nextProps.recieveEditInstallerApiData({});
        }
    }
  
    handleSubmit = values => {
        values["mobileLogo"] = this.state.MuploadUrl;
        values['companyId'] = this.props.companyId;
        values['icon'] = this.state.IconuploadUrl;
        this.props.setLoader({ loader: true })
        this.props.requestEditInstallerApiData(values)
    };

    deletePopUpProp = (value) => {
        const { setLoader,companyList,companyData : {companyDetail} } = this.props;
        if (value == 'cancel') {
          this.setState({deleteTrigger: false});
        }
        else {
            setLoader({ loader: true });
            this.setState({ deleteTrigger: false }); 
            deleteCompany({companyId:companyDetail.id}).then(r=>{
                companyList && companyList.splice(companyList.findIndex(e => e.companyId === companyDetail.id),1);
                this.props.router.navigate("/admin/dashboard");
            });
        }
      }

    renderDeletePopUp = () => {
        const { companyData : {companyDetail} } = this.props;
        let headerText = `DO YOU REALLY WANT TO DELETE ${companyDetail && companyDetail.name}'s COMPANY?`;
        let subText = "Deleting this company cannot be undone";
        return (
            <DeleteModal deletePopUpProp={this.deletePopUpProp} headerText={headerText} subText={subText} />
        )
    }

    renderInfo() {
        const {
            companyStates,
            installerData,
            companyData : {companyDetail}
        } = this.props;

        let slug = companyDetail && companyDetail.slug;

        return (
            <>
                <div className="internal-pnl-cmn">
                    <Form onFinish={this.handleSubmit}>

                        <div className="internal-cmn-form">

                            <div className="row">
                                <div className="col-md-6">
                                    <div className="cmn-label-field-input">
                                        <div className="form-group cmn-form-grp-input">
                                            <label htmlFor="company name" className="lbl-cmn-field">Company Name</label>
                                            <FormItem
                                                hasFeedback
                                                name="company"
                                            rules = { [{ required: true }, { pattern: /[a-zA-Z0-9][a-zA-Z0-9 ]*/ }] }
                                                initialValue = { companyDetail
                                                        && companyDetail.name }
                                            >
                                                <Input type="text" placeholder="" />
                                            </FormItem>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-4">
                                    <div className="cmn-label-field-input">
                                        <div className="form-group cmn-form-grp-input">
                                            <label htmlFor="company name" className="lbl-cmn-field">Bodhi URL</label>
                                            <Input value={companyDetail && `${window.location.origin}/${companyDetail.slug}`} disabled={true}/>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-3">
                                    <div className="cmn-label-field-input">
                                        <div className="form-group cmn-form-grp-input">
                                            <label htmlFor="company name" className="lbl-cmn-field">Website</label>
                                            <FormItem
                                                hasFeedback
                                                name="companyUrl"
                                                initialValue = { companyDetail
                                                        && companyDetail.companyUrl }
                                                rules = { [{ required: true }, { pattern: /((([A-Za-z]{3,9}:(?:\/\/)?)(?:[-;:&=\+\$,\w]+@)?[A-Za-z0-9.-]+|(?:www.|[-;:&=\+\$,\w]+@)[A-Za-z0-9.-]+)((?:\/[\+~%\/.\w-_]*)?\??(?:[-\+=&;%@.\w_]*)#?(?:[\w]*))?)/ }] }
                                            >
                                                <Input id="companyUrl" type="text" />
                                            </FormItem>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-3">
                                    <div className="cmn-label-field-input">
                                        <div className="form-group cmn-form-grp-input">
                                            <label htmlFor="company name" className="lbl-cmn-field">Country</label>
                                            <FormItem
                                                hasFeedback
                                                name="country"
                                                initialValue = { companyDetail
                                                        && companyDetail.address
                                                        && companyDetail.address.country }
                                                rules = { [{ required: true }] }
                                            >
                                                <Select suffixIcon = {<CaretIcon/>}
                                                    placeholder="Select Country"
                                                    optionFilterProp="children"
                                                    onChange={(e)=>{
                                                        this.props.requestStateListApiData({companyName:e});
                                                    }}
                                                >
                                                    <Option value="United States" key="United States" >United States</Option>
                                                    <Option value="Singapore" key="Singapore" >Singapore</Option>
                                                </Select>
                                            </FormItem>
                                        </div>
                                    </div>
                                </div>

                                <div className="col-md-5">
                                    <div className="cmn-label-field-input">
                                        <div className="form-group cmn-form-grp-input">
                                            <label htmlFor="company name" className="lbl-cmn-field">Address</label>
                                            <FormItem
                                                hasFeedback
                                                name="address1"
                                                initialValue = { companyDetail
                                                        && companyDetail.address
                                                        && companyDetail.address.address1 }
                                                rules = { [{ required: true }, { pattern: /[a-zA-Z0-9#][#a-zA-Z0-9 ]*/ }] }
                                            >
                                                <Input type="text" placeholder="" />
                                            </FormItem>
                                        </div>
                                    </div>
                                </div>

                                <div className="col-md-3">
                                    <div className="cmn-label-field-input">
                                        <div className="form-group cmn-form-grp-input">
                                            <label htmlFor="company name" className="lbl-cmn-field">city</label>
                                            <FormItem
                                                hasFeedback
                                                name="city"
                                                initialValue = { companyDetail
                                                        && companyDetail.address
                                                        && companyDetail.address.city }
                                                rules = { [{ required: true }] }
                                            >
                                                <Input type="text" placeholder="" />
                                            </FormItem>
                                        </div>
                                    </div>
                                </div>
                                {
                                    companyStates.length > 0 &&
                                    <div className="col-md-3">
                                        <div className="cmn-label-field-input">
                                            <div className="form-group cmn-form-grp-input">
                                                <label htmlFor="company name" className="lbl-cmn-field">state / Province</label>
                                                <FormItem
                                                    hasFeedback
                                                    name="state"
                                                    initialValue = { companyDetail
                                                            && companyDetail.address
                                                            && companyDetail.address.state }
                                                    rules = { [{ required: true }] }
                                                >
                                                    <Select suffixIcon = {<CaretIcon/>}
                                                        placeholder="Select"
                                                        showSearch
                                                        optionFilterProp="children"
                                                        filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                                                    >
                                                        <Option value="0" disabled > Select State</Option>
                                                        {
                                                            companyStates.map((stateList, i) =>
                                                                <Option value={stateList.code} key={stateList.id} >{stateList.code}</Option>
                                                            )}
                                                    </Select>
                                                </FormItem>
                                            </div>
                                        </div>
                                    </div>
                                }

                                <div className="col-md-4">
                                    <div className="cmn-label-field-input">
                                        <div className="form-group cmn-form-grp-input">
                                            <label htmlFor="company name" className="lbl-cmn-field">postal code</label>
                                            <FormItem
                                                hasFeedback
                                                name="postalCode"
                                                initialValue = { companyDetail
                                                        && companyDetail.address
                                                        && companyDetail.address.postalCode }
                                                rules = { [{ required: true }] }
                                            >
                                                <Input id="postalCode" placeholder="" />
                                            </FormItem>
                                        </div>
                                    </div>
                                </div>

                                <div className="col-md-3">
                                    <div className="cmn-label-field-input">
                                        <div className="form-group cmn-form-grp-input d-inline-flex">
                                            <label htmlFor="company name" className="lbl-cmn-field textNone">Project tracking only</label>
                                            <FormItem
                                                style={{
                                                    'display': 'inline-block',
                                                    'margin': '15px 0 0 15px'
                                                }}
                                                name="isProjectTrack"
                                                valuePropName="checked"
                                                initialValue = { companyDetail
                                                        && companyDetail.isProjectTrack }
                                            >
                                                <Checkbox />
                                            </FormItem>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-3">
                                    <div className="cmn-label-field-input">
                                        <div className="form-group cmn-form-grp-input d-inline-flex">
                                            <label htmlFor="NPS survey" className="lbl-cmn-field">NPS surveys</label>
                                            <FormItem
                                                style={{
                                                    'display': 'inline-block',
                                                    'margin': '15px 0 0 15px'
                                                }}
                                                name="isNPS"
                                                valuePropName="checked"
                                                initialValue = { companyDetail
                                                        && companyDetail.isNPS }
                                            >
                                                <Checkbox onChange={(e)=>{
                                                    this.setState({isNPS:e.target.checked})
                                                }}/>
                                            </FormItem>
                                        </div>
                                    </div>
                                </div>
                                {
                                    this.props.isAdmin &&
                                    <div className="col-md-3">
                                        <div className="cmn-label-field-input">
                                            <div className="form-group cmn-form-grp-input  d-inline-flex">
                                                <label htmlFor="company name" className="lbl-cmn-field">Test Account</label>
                                                <FormItem
                                                    style={{
                                                        'display': 'inline-block',
                                                        'margin': '15px 0 0 15px'
                                                    }}
                                                    name="isTest"
                                                    valuePropName = "checked"
                                                    initialValue = { companyDetail
                                                            && companyDetail.isTest }
                                                >
                                                    <Checkbox />
                                                </FormItem>
                                            </div>
                                        </div>
                                    </div>
                                }
                                {
                                    this.state.isNPS &&
                                    <div className="col-md-9">
                                    <div className="cmn-label-field-input">
                                        <div className="form-group cmn-form-grp-input">
                                            <label htmlFor="Google review url" className="lbl-cmn-field">Google review URL</label>
                                            <FormItem
                                                hasFeedback
                                                name="googleReviewUrl"
                                                initialValue = { companyDetail
                                                        && companyDetail.googleReviewUrl }
                                                rules = { [{ required: true }] }
                                            >
                                                <Input type="text" placeholder="Google review buisness url" />
                                            </FormItem>
                                        </div>
                                    </div>
                                </div>
                                }
                            </div>
                            {/* {
                                this.props.isAdmin &&
                                <div className="row">
                                    <div className="col-md-3 delete" onClick={() => this.setState({ deleteTrigger: true })}>
                                        <i className="fa fa-trash fa-2x" aria-hidden="true"></i>
                                        <div>Delete Company</div>

                                    </div>
                                </div>
                            }                             */}
                        </div>

                        <div className="internal-cmn-form pdt-35">
                            <div className="form-heading"> Company admin info </div>
                            <div className="row">
                                <div className="col-md-3">
                                    <div className="cmn-label-field-input">
                                        <div className="form-group cmn-form-grp-input">
                                            <label htmlFor="company name" className="lbl-cmn-field">contact person</label>
                                            <FormItem
                                                hasFeedback
                                                name="adminName"
                                                initialValue = { companyDetail
                                                        && companyDetail.contactName }
                                                rules = { [{ required: true }, { pattern: /[a-zA-Z0-9][a-zA-Z0-9 ]*/ }] }
                                            >
                                                <Input type="text" placeholder="" />
                                            </FormItem>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-4">
                                    <div className="cmn-label-field-input">
                                        <div className="form-group cmn-form-grp-input">
                                            <label htmlFor="company name" className="lbl-cmn-field">email</label>
                                            <FormItem
                                                hasFeedback
                                                name="contactEmail"
                                                initialValue = { companyDetail
                                                        && companyDetail.contactEmail }
                                                rules = { [{ type: 'email' }, { required: true }] }
                                            >
                                                <Input type="text" placeholder=""/>
                                            </FormItem>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-3">
                                    <div className="cmn-label-field-input">
                                        <div className="form-group cmn-form-grp-input">
                                            <label htmlFor="company name" className="lbl-cmn-field">phone number</label>
                                            <FormItem
                                                hasFeedback
                                                name="adminPhone"
                                                initialValue = { companyDetail
                                                        && companyDetail.contactPhone }
                                                rules = { [{ required: false }] }
                                            >
                                                <NumberFormat   type="tel" format={installerData && installerData.formSetting && installerData.formSetting.phone} mask=" " className="ant-input" placeholder="(   )  -  " />
                                            </FormItem>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="internal-cmn-form pdt-25">
                            <div className="form-heading"> Company logos </div>
                            <div className="company-and-their-logo">
                                <div className="row">
                                    <div className="form-group col-5">
                                        <label htmlFor="exampleFormControlInput1">Login screen & emails</label>

                                        <CompanyLogoUpload
                                            {...this.props}
                                            from="edit"
                                            fileType="png"
                                            type="MuploadUrl"
                                            slug={slug}
                                            fileName = {`${slug}_emailIcon`}
                                            isClear={false}
                                            saveUploadUrl={this.getSource}
                                            style={{ maxWidth: "150px", maxHeight: "45px", marginTop: "5px", display: "block" }}
                                            url={this.state.MuploadUrl}
                                        />
                                    </div>
                                    <div className="form-group col-3">
                                        <label htmlFor="exampleFormControlInput1">Bodhi app header</label>

                                        <CompanyLogoUpload
                                            isClear={this.clear}
                                            {...this.props}
                                            from="edit"
                                            fileType="jpg"
                                            type="IconuploadUrl"
                                            fileName = {`${slug}_appIcon`}
                                            slug={slug}
                                            saveUploadUrl={this.getSource}
                                            style={{ maxWidth: "300px", maxHeight: "45px", marginTop: "5px", display: "block" }}
                                            url={this.state.IconuploadUrl}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="form-submitted-btn">
                            <button type="button" className="btn btn-secondary"> Cancel </button>
                            <button type="submit" className="btn btn-primary"> Save </button>
                        </div>
                    </Form>
                </div>
            </>

        )
    }

    getSource = (type, url) => {
        this.setState({
            [type]: url
        });
    };
    
  render() {  
    return(<>
        <div className="sys-DesktopView">
            {this.state.deleteTrigger && this.renderDeletePopUp()}
            {this.renderInfo()}
        </div>
      </>)
  }
}

const mapStatetoProps = state => {
  return {
    setCompanyDetail: state.setting.setCompanyDetail,
    dashboard: state.adminOption.data,
    companyStates: (state.adminOption.data && state.adminOption.data.stateList && state.adminOption.data.stateList.data) || []
  };
};

const mapDispatchToProps = dispatch =>
  bindActionCreators({ 
    requestStateListApiData,
    requestCompanyDetail,
    requestEditInstallerApiData,
    recieveEditInstallerApiData,
    requestCompanyDetailSet,
    receiveCompanyJourney,
    setLoader,
 }, dispatch);

export default connect(
  mapStatetoProps,
  mapDispatchToProps
)(withRouter(CompanyDetailForm));
