// import npm modules
import React, { Component } from 'react';
import { Form, Input, Select } from 'antd';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

// import local files
import {requestCompanyDetailSet} from '../../actions/profile';
import {setLoader}               from '../../../Common/Loader/action';
import phaseInfoJSON             from '../../../Common/phaseInfo.json';
import {ReactComponent as CaretIcon} from "../../../../Assets/Images/path-2.svg";

const FormItem = Form.Item;
const { TextArea } = Input;
const Option = Select.Option;

const PhaseInfoForm = props => {

  const [form] = Form.useForm()

  const {
    handleSubmit,
    companyElecricUtility,
    activePhaseIndex,
    activePhaseName,
    journeyPhaseData,
    activePhaseIndexHandler,
    utilityIndex,
    phaseName,
    cancelHandler
  } = props

  return (
    <Form form={form} onFinish={handleSubmit}>
      <div className="row v-h-center detail-info-box">
        <div className="col-sm-4 text-right-form display-mob">
          <label
            htmlFor="exampleFormControlTextarea1"
            className="detail-info-label font-weight-300 "
          >
            CHOOSE A UTILITY
          </label>
        </div>
        <div className="col-sm-8 form-group mt-4">
          <label
            className="form-group bold-text caret-icon"
            style={{ marginBottom: '0' }}
          >
            Electric Utility
          </label>
          <FormItem
            hasFeedback
            name="electricityUtility"
            rules = { [{
                required: false
            }] }
            initialValue = { companyElecricUtility &&
                companyElecricUtility[0] &&
                companyElecricUtility[0].id }
          >
            <Select
              className="form-control"
              onChange={props.companyElecricUtilityChangeHandler}
              defaultValue={0}
            >
              {companyElecricUtility.map((item, index) => {
                return (
                  <Option value={item.id} key={item.id}>
                    {item.name}
                  </Option>
                );
              })}
            </Select>
          </FormItem>
        </div>
      </div>
      <div className="row v-h-center detail-info-box">
        <ul className="nav nav-tabs">
          <li className="nav-item seprator-right">
            <a
              className={"nav-link "+ (activePhaseIndex === 1 ? 'active' : '') }
              onClick={() => activePhaseIndexHandler(1, "Design")}
            >
              {(journeyPhaseData && journeyPhaseData.design && journeyPhaseData.design.name) || 'Design'}
            </a>
          </li>
          <li className="nav-item seprator-right">
            <a
              className={"nav-link "+ (activePhaseIndex === 2 ? 'active' : '' )}
              onClick={() => activePhaseIndexHandler(2, "Permitting")}
            >
              {(journeyPhaseData && journeyPhaseData.permit && journeyPhaseData.permit.name) || 'Permitting'}
            </a>
          </li>
          <li className="nav-item seprator-right">
            <a
              className={"nav-link "+ (activePhaseIndex === 3 ? 'active' : '') }
              onClick={() => activePhaseIndexHandler(3, "The Build")}
            >
              {(journeyPhaseData && journeyPhaseData.thebuild && journeyPhaseData.thebuild.name) || 'The build'}
            </a>
          </li>
          <li className="nav-item seprator-right">
            <a
              className={"nav-link "+ (activePhaseIndex === 4 ? 'active' : '') }
              onClick={() => activePhaseIndexHandler(4, "Inspection")}
            >
              {(journeyPhaseData && journeyPhaseData.inspection && journeyPhaseData.inspection.name) || 'Inspection'}
            </a>
          </li>
          <li className="nav-item">
            <a
              className={"nav-link "+ (activePhaseIndex === 5 ? 'active' : '') }
              onClick={() => activePhaseIndexHandler(5, "Powering Up")}
            >
              {(journeyPhaseData && journeyPhaseData.poweringup && journeyPhaseData.poweringup.name) || 'Powering up'}
            </a>
          </li>
        </ul>
        <div className="text">
            {activePhaseName + " Message"}
        </div>
        <div className="col-sm-12">
          <FormItem hasFeedback>
            <TextArea
              rows={9}
              placeholder="Type message here...."
              value={
                companyElecricUtility.length !== 0 &&
                companyElecricUtility[utilityIndex] && 
                companyElecricUtility[utilityIndex]
                [phaseName[activePhaseIndex]]
                  ? companyElecricUtility[
                      utilityIndex
                    ][phaseName[activePhaseIndex]]
                  : phaseInfoJSON[phaseName[activePhaseIndex]]
              }
              onChange={props.phaseMessageHandler}
            />
          </FormItem>
        </div>
      </div>
      <div className="row">
        <div className="text-center margt40 margin-b-20 width-100 btn-mobiel comp">
          <button
            className="btn btn-secondary"
            onClick={() => props.cancelHandler(form)}
            type="button"
          >
            Cancel
            </button>
          <button className="btn btn-primary">
            Save
            </button>
        </div>
      </div>
    </Form>
  )
}

class DetailedPhaseInfoForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      id: 0,
      companyElecricUtility: [],
      utilityIndex: 0,
      activePhaseIndex : 1,
      activePhaseName : "Design"
    };
    this.phaseName ={
      1 : "DesignDetail",
      2 : "PermittingDetail",
      3 : "BuildDetail",
      4 : "InspectionDetail",
      5 : "PoweringUpDetail"
    }
    this.handleChange = this.handleChange.bind(this);
  }

  componentWillReceiveProps(nextProps) {
    if (
      nextProps.companyData &&
      nextProps.companyData.companyDetail &&
      nextProps.companyData.companyDetail.companyElecricUtility &&
      this.state.companyElecricUtility.length === 0
    ) {
      this.setState({
        companyElecricUtility:
          nextProps.companyData.companyDetail.companyElecricUtility
      });
    }
  }

  componentWillMount(){
    if (
      this.props.companyData &&
      this.props.companyData.companyDetail &&
      this.props.companyData.companyDetail.companyElecricUtility &&
      this.state.companyElecricUtility.length === 0
    ) {
      this.setState({
        companyElecricUtility:
        this.props.companyData.companyDetail.companyElecricUtility
      });
    }
  }

  handleChange(value) {
    const utilityIndex = this.state.companyElecricUtility.findIndex(
      utility => utility.id === value
    );
    this.setState({
      id: value,
      utilityIndex
    });
  }

  handleSubmit = values => {
    let companyId = this.props.companyId;
    values['companyId'] = companyId;
    values['id'] = this.state.id;
    if (this.state.companyElecricUtility[this.state.utilityIndex])
    values['electricUtilityInfo'] = [{
      BuildDetail: this.state.companyElecricUtility[
        this.state.utilityIndex
      ].BuildDetail,
      DesignDetail: this.state.companyElecricUtility[
        this.state.utilityIndex
      ].DesignDetail,
      InspectionDetail: this.state.companyElecricUtility[
        this.state.utilityIndex
      ].InspectionDetail,
      PermittingDetail: this.state.companyElecricUtility[
        this.state.utilityIndex
      ].PermittingDetail,
      PoweringUpDetail: this.state.companyElecricUtility[
        this.state.utilityIndex
      ].PoweringUpDetail,
      electricUtilityId: this.state.companyElecricUtility[
        this.state.utilityIndex
      ].electricUtilityId,
      id: this.state.companyElecricUtility[this.state.utilityIndex].id
    }];

    const bodyData = {
      data:{  
        electricUtilityInfo: values['electricUtilityInfo'] 
      },
      companyId: companyId
    };

    this.props.requestCompanyDetailSet(bodyData);

    this.setState({
      companyElecricUtility: []
    });

    this.props.setLoader({ loader: true });
  };

  cancelHandler = form => {
    form.resetFields();
    this.props.DelayedcancelHandler();
  };

  handleChangeTextArea = (type, index, val) => {
    const { companyElecricUtility } = this.state;
    companyElecricUtility[index][type] = val;
    this.setState({
      companyElecricUtility
    });
  };

  render() {
    const {
      companyData : {companyDetail}
    } = this.props;

    let journeyPhaseData  = companyDetail.journey && companyDetail.journey[0] && JSON.parse(companyDetail.journey[0]).journeyPhase;

    return (
      <>
        <div className="content-box box-Shadow">
          <div className='mob-box'>Choose A Utility</div>
          <PhaseInfoForm
            handleSubmit={this.handleSubmit}
            companyElecricUtility={this.state.companyElecricUtility}
            companyElecricUtilityChangeHandler={this.handleChange}
            activePhaseName={this.state.activePhaseName}
            activePhaseIndex={this.state.activePhaseIndex}
            activePhaseIndexHandler={(phaseIndex, name) => {
              this.setState({
                activePhaseIndex: phaseIndex,
                activePhaseName: name
              })
            }}
            journeyPhaseData={journeyPhaseData}
            utilityIndex={this.state.utilityIndex}
            phaseName={this.phaseName}
            phaseMessageHandler={e => {
              this.handleChangeTextArea(
                [this.phaseName[this.state.activePhaseIndex]],
                this.state.utilityIndex,
                e.target.value
              );
            }}
            cancelHandler={form => {
              this.cancelHandler(form)
            }}
          />
        </div>
      </>
    );
  }
}

const mapStatetoProps = state => {
  return {
    auth: state.auth,
    setCompanyDetail: state.setting.setCompanyDetail
  };
};

const mapDispatchToProps = dispatch =>
  bindActionCreators({ requestCompanyDetailSet,setLoader }, dispatch);

export default connect(
  mapStatetoProps,
  mapDispatchToProps
)(DetailedPhaseInfoForm);
