import { compare } from 'compare-versions';
import React, { Component } from 'react';
import { Spin } from "antd";

import { Services } from './Api/services'
import { appMetaEndpoint } from './constants'

import { bodhiVersionTimestampKey } from './constants'
const timeOffsetForRefresh = 24 * 60 * 60 * 1000 // 24 hour period, in milliseconds

/**
 * The class is based on and essentially ports an npm package using React hooks but adds a cached timestamp
 * and mitigation against inadvertent looping
 * 
 * @see https://github.com/CagriAldemir/react-cache-buster
 */
class VersionController extends Component {

    isCheckingVersion = false

    constructor(props) {
        super(props)

        this.state = {
            loading: true,
            isLatestVersion: false,
            gettingVersion: true,
            hasBeenRefreshed: this.wasRecentlyRefreshedCheck()
        }
    }

    wasRecentlyRefreshedCheck() {
        const timestamp = this.getRefreshKey()
        if (!timestamp) {
            this.persistRefreshKey()
            return false
        }
        return Date.now() - timestamp < timeOffsetForRefresh
    }

    log = (message, isError) => {
        this.props.isVerboseMode && (isError ? console.error(message) : console.log(message));
    }

    checkCacheStatus = async () => {

        this.isCheckingVersion = true

        const { currentVersion, isVerboseMode } = this.props

        try {
            const res = await Services.getCall(appMetaEndpoint)

            const { version: metaVersion } = res.data

            if (this.newVersionExists(metaVersion, currentVersion)) {
                this.log(`There is a new version (v${metaVersion}). Should force refresh.`);
                this.setState({
                    loading: false,
                    isLatestVersion: false,
                    gettingVersion: false
                });
            } else {
                this.log('There is no new version. No cache refresh needed.');
                this.setState({
                    loading: false,
                    isLatestVersion: true,
                    gettingVersion: false
                });
            }
        } catch (error) {
            this.log('An error occurred while checking cache status.', true);
            this.log(error, true);

            //Since there is an error, if isVerboseMode is false, the component is configured as if it has the latest version.
            !isVerboseMode &&
                this.setState({
                    loading: false,
                    isLatestVersion: true,
                    hasBeenRefreshed: true
                });
        }
    }

    getRefreshKey() {
        return localStorage.getItem(bodhiVersionTimestampKey)
    }

    persistRefreshKey(timestamp) {
        localStorage.setItem(bodhiVersionTimestampKey, timestamp || Date.now())
    }

    newVersionExists = (metaVersion, currentVersion) => {
        return compare(metaVersion, currentVersion, '>');
    }

    clearCacheAndReload = async () => {
        try {
            if (window?.caches) {
                const { caches } = window;
                const cacheNames = await caches.keys();
                for (const cacheName of cacheNames) {
                    caches.delete(cacheName);
                }
                this.log('The cache has been deleted.');
                this.persistRefreshKey()
                window.location.reload()
            } else {
                this.log('The cache API is not available, using current version.');
                this.setState({
                    hasBeenRefreshed: true
                });                
            }
        } catch (error) {
            this.log('An error occurred while deleting the cache.', true);
            this.log(error, true);
        }
    }

    render() {
        const { isEnabled, children, onCacheClear, loadingComponent } = this.props

        const { loading, isLatestVersion, gettingVersion, hasBeenRefreshed } = this.state

        /**
         * [!] mitigate against browser looping over `this.clearCacheAndReload` with `hasBeenRefreshed`
         */
        if (!isEnabled || hasBeenRefreshed) {
            return children
        } else {
            if (!this.isCheckingVersion) {
                this.checkCacheStatus()
            }
        }

        if (loading) {
            return <Spin
                spinning={ true }
                indicator={ loadingComponent }
                style={{ color: "#04bfff" }}
            >
                { children }
            </Spin>
        }

        if (!loading && !isLatestVersion && !gettingVersion) {
            onCacheClear && onCacheClear()
            this.clearCacheAndReload()
            return null
        }

        return children
    }

}

export default VersionController