import React        from 'react';
import {Helmet}     from "react-helmet";
import {envAppIcon} from "../../User/components/setUserBackgroud"
import Header       from '../../../screens/Installer/components/header'
import SiderMenu    from "../../Installer/components/Sider/index"

const installerLayout = ({ component: Component, ...params }, props) => {
    document.body.classList.remove('homeowner-bg');
    document.body.classList.remove('bg-color');
    if(document.getElementById('beacon-container')){
      document.getElementById('beacon-container').style.display='block';
    }
    return(
        <React.Fragment>
            <SiderMenu />
            <header>
            <Header {...props} />
            </header>
            {addScript(props)}
            <Component {...props} {...params} />
        </React.Fragment>
    )
  };

  function addScript(props) {
    return (
      <Helmet>
        <link rel="shortcut icon" href={`https://prod-17terawatts.s3.amazonaws.com/public/logo/favicon.ico`} />
        <link rel="apple-touch-icon" href={`https://prod-17terawatts.s3.amazonaws.com/public/logo/favicon.ico`} sizes="72x72" />
        <script type="text/javascript">
          {`!function (e, t, n) {
        function a() {
          var e = t.getElementsByTagName("script")[0], n = t.createElement("script");
          n.type = "text/javascript", n.async = !0, n.src = "https://beacon-v2.helpscout.net", e.parentNode.insertBefore(n, e);
        }
        if (e.Beacon = n = function (t, n, a) { e.Beacon.readyQueue.push({ method: t, options: n, data: a }) }, n.readyQueue = [], "complete" === t.readyState) return a(); e.attachEvent ? e.attachEvent("onload", a) : e.addEventListener("load", a, !1)
      }
        (window, document, window.Beacon || function () { })`}
        </script>
        <script type="text/javascript">{`window.Beacon('init', 'be4037d0-1769-4c71-b3fc-f20936a0f566')`}</script>
      </Helmet>
    )
  }

  export default installerLayout;