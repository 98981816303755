import React, { Component } from 'react';
import { Form, Input }      from 'antd';
import { connect }          from 'react-redux';
import {bindActionCreators} from 'redux';
import {requestCompanyDetailSet} from '../../actions/profile';
import {setLoader}               from '../../../Common/Loader/action';

const FormItem = Form.Item;

const MobileNotificationForm = props => {

  const [form] = Form.useForm()

  const {
    handleSubmit,
    companyData,
    monitoringPMHandler,
    monitoringHOHandler,
    generatingPMHandler,
    generatingHOHandler
  } = props

  return (
      <Form form={form} onFinish={handleSubmit}>
        <div className="section-from-box detail-info row justify-content-center">
          <div className="form-group col-12">
            <div className="font-weight-400" style={{ marginBottom: '1px' }}>
              SYSTEM NOTIFICATIONS
            </div>
            <small style={{ marginBottom: '24px', display: 'inline-block' }}>
              Set days before sending notifications
            </small>
          </div>

          <div className="system_info col-12">
            <div className="system_info-details">
              <h4>DAYS BETWEEN CONTACT </h4>
              <div className="form-wrap d-flex justify-content-end">
                <label>Days between connections with homeowner</label>
                <FormItem
                  hasFeedback
                  name="contactCutoff"
                  rules = { [{ required: false }, { pattern: '^[0-9]*$' }] }
                  initialValue = { companyData &&
                      companyData.companyDetail &&
                      companyData.companyDetail.contactCutoff
                       ? companyData.companyDetail.contactCutoff.toString()
                        : null }
                >
                  <Input
                    type="text"
                    className="form-control ant-input"
                    id="dBC"
                   />
                </FormItem>
              </div>
            </div>  

            <hr />

            <div className="system_info-details">
              <h4>MONITORING NOT REPORTING</h4>
              <div className="form-wrap d-flex justify-content-end">
                <label>Project Manager</label>
                <FormItem
                  hasFeedback
                  name="monitoringPM"
                  rules = { [{ required: false }, { pattern: '^[0-9]*$' }] }
                  initialValue = { companyData &&
                      companyData.companyDetail &&
                      companyData.companyDetail.monitoringCutoff &&
                      companyData.companyDetail.monitoringCutoff.installer
                        ? companyData.companyDetail.monitoringCutoff.installer.toString()
                        : null }
                >
                    <Input
                      type="text"
                      className="form-control ant-input"
                      id="mpm"
                      onChange={monitoringPMHandler}
                    />
                </FormItem>
              </div>
              <div className="form-wrap d-flex justify-content-end">
                <label>Home Owner</label>
                <FormItem
                  hasFeedback
                  name="monitoringHO"
                  rules = { [{ required: false }, { pattern: '^[0-9]*$' }] }
                  initialValue = { companyData &&
                      companyData.companyDetail &&
                      companyData.companyDetail.monitoringCutoff &&
                      companyData.companyDetail.monitoringCutoff.homeowner
                        ? companyData.companyDetail.monitoringCutoff.homeowner.toString()
                        : null }
                >
                  <Input
                    type="text"
                    className="form-control ant-input"
                    id="mho"
                    onChange={monitoringHOHandler}
                  />
                </FormItem>
              </div>
            </div>

            <hr />

            <div className="system_info-details">
              <h4>SYSTEM NOT GENERATING</h4>
              <div className="form-wrap d-flex justify-content-end">
                <label>Project Manager</label>
                <FormItem
                  hasFeedback
                  name="generatingPM"
                  rules = { [{ required: false }, { pattern: '^[0-9]*$' }] }
                  initialValue = { companyData &&
                      companyData.companyDetail &&
                      companyData.companyDetail.generatingCutoff &&
                      companyData.companyDetail.generatingCutoff.installer
                        ? companyData.companyDetail.generatingCutoff.installer.toString()
                        : null }
                >
                  <Input
                    type="text"
                    className="form-control ant-input"
                    id="gho"
                    onChange={generatingPMHandler}
                  />
                </FormItem>
              </div>
              <div className="form-wrap d-flex justify-content-end">
                <label>Home Owner</label>
                <FormItem
                  hasFeedback
                  name="generatingHO"
                  rules = { [{ required: false }, { pattern: '^[0-9]*$' }] }
                  initialValue = { companyData &&
                      companyData.companyDetail &&
                      companyData.companyDetail.generatingCutoff &&
                      companyData.companyDetail.generatingCutoff.homeowner
                        ? companyData.companyDetail.generatingCutoff.homeowner.toString()
                        : null }
                >
                  <Input
                    type="text"
                    className="form-control ant-input"
                    id="gho"
                    onChange={generatingHOHandler}
                  />
                </FormItem>
              </div>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="text-center margt40 margin-auto width-100 btn-mobiel comp">
            <button
              className="btn btn-secondary"
              onClick={() => props.cancelHandler(form)}
              type="button"
            >
              Cancel
            </button>
            <button className="btn btn-primary">
              Save
            </button>
          </div>
        </div>
      </Form>
  )
}

const DesktopNotificationForm = props => {

  const { handleSubmit, companyData, renderPowerCutoff } = props
  const [form] = Form.useForm()

  return (
    <div className="content-section-box mt-4">
      <Form form={form} onFinish={handleSubmit}>

        <p className="offset-md-6 bold-text">Days between contact</p>
        <div className="form-group row">
          <label
            className="col-sm-6 col-form-label"
          >
            Days between connections with homeowners
          </label>

          <div className="col-sm-6">
            <FormItem
              hasFeedback
              name="contactCutoff"
              rules = { [{ required: false }, { pattern: '^[0-9]*$' }] }
              initialValue = { companyData &&
                    companyData.companyDetail &&
                    companyData.companyDetail.contactCutoff
                    ? companyData.companyDetail.contactCutoff.toString()
                    : null }
            >
                <Input
                  type="text"
                  className="form-control ant-input"
                  id="dBC"
                />
            </FormItem>
          </div>
        </div>

            {
              companyData && companyData.companyDetail
              && !companyData.companyDetail.isProjectTrack
              && renderPowerCutoff(companyData)
            }

        <div className="row">
          <div className="text-center margt40 margin-auto width-100 btn-mobiel comp">
            <button
              className="btn btn-secondary"
              onClick={() => props.cancelHandler(form)}
              type="button"
            >
              Cancel
            </button>
            <button className="btn btn-primary">
              Save
            </button>
          </div>
        </div>
      </Form>
    </div>
  )
}

class SystemNotificationsForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      generatingCutoff: (this.props.companyData && this.props.companyData.companyDetail && 
      this.props.companyData.companyDetail.generatingCutoff)||{},
      monitoringCutoff: (this.props.companyData && this.props.companyData.companyDetail && 
        this.props.companyData.companyDetail.monitoringCutoff)||{}
    };
  }

  componentDidMount() {
    this.setState({
      generatingCutoff: (this.props.companyData && this.props.companyData.companyDetail && 
      this.props.companyData.companyDetail.generatingCutoff)||{},
      monitoringCutoff: (this.props.companyData && this.props.companyData.companyDetail && 
        this.props.companyData.companyDetail.monitoringCutoff)||{}
    })
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.installerData && JSON.stringify(nextProps.installerData) !== '{}') {
      nextProps.setLoader({loader: false});
    }
    if (
      nextProps.companyData &&
      nextProps.companyData.companyDetail &&
      nextProps.companyData.companyDetail.monitoringCutoff 
      // Object.entries(this.state.monitoringCutoff).length === 0
    ) {
      this.setState({
        monitoringCutoff: nextProps.companyData.companyDetail.monitoringCutoff
      });
    }
    if (
      nextProps.companyData &&
      nextProps.companyData.companyDetail &&
      nextProps.companyData.companyDetail.generatingCutoff
      // Object.entries(this.state.generatingCutoff).length === 0
    ) {
      this.setState({
        generatingCutoff: nextProps.companyData.companyDetail.generatingCutoff
      });
    }
  }
  
  handleSubmit = values => {
    values['companyId'] = this.props.companyId;
    values['generatingCutoff'] = {
      installer: this.state.generatingCutoff.installer,
      homeowner: this.state.generatingCutoff.homeowner
    };
    values['monitoringCutoff'] = {
      installer: this.state.monitoringCutoff.installer,
      homeowner: this.state.monitoringCutoff.homeowner
    };

    this.props.requestCompanyDetailSet({
      data: {
        companyInfo: {              
          generatingCutoff: values['generatingCutoff'],
          monitoringCutoff: values['monitoringCutoff'],
          contactCutoff: values.contactCutoff        
        }
      },
      companyId: this.props.companyId
    });
    this.props.setLoader({ loader: true });
  };

  cancelHandler = form => {
    form.resetFields();
    this.props.DelayedcancelHandler();
  };

  renderDesktopView() {
    const {
      companyData
    } = this.props;

    return (
      <div className="content-box box-Shadow">
        <div className="mob-box">Set days before sending notifications</div>
          <DesktopNotificationForm
            handleSubmit={this.handleSubmit}
            companyData={companyData}
            renderPowerCutoff={this.renderPowerCutoff}
            cancelHandler={this.cancelHandler}
          />
      </div>
    )
  }

  renderPowerCutoff(companyData) {
    return(
      <>
        <div className="form-box">
          <div className="form-group row">
            <label className="col-sm-5 col-form-label"></label>
            <div className="col-sm-4 text-center  bold-text">Project Manager</div>
            <div className="col-sm-3 text-center p0  bold-text">Homeowner</div>
          </div>
        </div>
        <div className="form-box">
          <div className="form-group row">
            <label className="col-sm-6 col-form-label">Monitoring not reporting</label>
            <div className="col-sm-3">
              <FormItem
                hasFeedback
                name="monitoringPM"
                rules = { [{ required: false }, { pattern: '^[0-9]*$' }] }
                initialValue = { companyData &&
                      companyData.companyDetail &&
                      companyData.companyDetail.monitoringCutoff &&
                      companyData.companyDetail.monitoringCutoff.installer
                      ? companyData.companyDetail.monitoringCutoff.installer.toString()
                      : null }
              >
                  <Input
                    type="text"
                    className="form-control ant-input"
                    id="mpm"
                    onChange={e => {
                      let monitoringCutoff = this.state.monitoringCutoff;
                      monitoringCutoff['installer'] = +e.target.value;
                      this.setState({
                        monitoringCutoff
                      });
                    }}
                  />
              </FormItem>
            </div>

            <div className="col-sm-3">
              <FormItem
                hasFeedback
                name="monitoringHO"
                rules = { [{ required: false }, { pattern: '^[0-9]*$' }] }
                initialValue = { companyData &&
                      companyData.companyDetail &&
                      companyData.companyDetail.monitoringCutoff &&
                      companyData.companyDetail.monitoringCutoff.homeowner
                      ? companyData.companyDetail.monitoringCutoff.homeowner.toString()
                      : null }
              >
                  <Input
                    type="text"
                    className="form-control ant-input"
                    id="mho"
                    onChange={e => {
                      let monitoringCutoff = this.state.monitoringCutoff;
                      monitoringCutoff['homeowner'] = +e.target.value;
                      this.setState({
                        monitoringCutoff
                      });
                    }}
                  />
              </FormItem>
            </div>
          </div>
        </div>
        <div className="form-box">
          <div className="form-group row">
            <label className="col-sm-6 col-form-label">System not generating</label>
            <div className="col-sm-3">
              <FormItem
                hasFeedback
                name="generatingPM"
                rules = { [{ required: false }, { pattern: '^[0-9]*$' }] }
                initialValue = { companyData &&
                      companyData.companyDetail &&
                      companyData.companyDetail.generatingCutoff &&
                      companyData.companyDetail.generatingCutoff.installer
                      ? companyData.companyDetail.generatingCutoff.installer.toString()
                      : null }
              >
                <Input
                  type="text"
                  className="form-control ant-input"
                  id="gho"
                  onChange={e => {
                    let generatingCutoff = this.state.generatingCutoff;
                    generatingCutoff['installer'] = +e.target.value;
                    this.setState({
                      generatingCutoff
                    });
                  }}
                />
              </FormItem>
            </div>

            <div className="col-sm-3">
              <FormItem
                hasFeedback
                name="generatingHO"
                rules = { [{ required: false }, { pattern: '^[0-9]*$' }] }
                initialValue = { companyData &&
                      companyData.companyDetail &&
                      companyData.companyDetail.generatingCutoff &&
                      companyData.companyDetail.generatingCutoff.homeowner
                      ? companyData.companyDetail.generatingCutoff.homeowner.toString()
                      : null }
              >
                  <Input
                    type="text"
                    className="form-control ant-input"
                    id="gho"
                    onChange={e => {
                      let generatingCutoff = this.state.generatingCutoff;
                      generatingCutoff['homeowner'] = +e.target.value;
                      this.setState({
                        generatingCutoff
                      });
                    }}
                  />
              </FormItem>
            </div>
          </div>
        </div>
      </>
    )
  }

  renderMobileView() {

    const {
      companyData
    } = this.props;

    return (
      <MobileNotificationForm
        handleSubmit={this.handleSubmit}
        cancelHandler={this.cancelHandler}
        companyData={companyData}
        monitoringPMHandler={e => {
          let monitoringCutoff = this.state.monitoringCutoff;
          monitoringCutoff['installer'] = +e.target.value;
          this.setState({
            monitoringCutoff
          });
        }}
        monitoringHOHandler={e => {
          let monitoringCutoff = this.state.monitoringCutoff;
          monitoringCutoff['homeowner'] = +e.target.value;
          this.setState({
            monitoringCutoff
          });
        }}
        generatingPMHandler={e => {
          let generatingCutoff = this.state.generatingCutoff;
          generatingCutoff['installer'] = +e.target.value;
          this.setState({
            generatingCutoff
          });
        }}
        generatingHOHandler={e => {
          let generatingCutoff = this.state.generatingCutoff;
          generatingCutoff['homeowner'] = +e.target.value;
          this.setState({
            generatingCutoff
          });
        }}
      />
    )
  }

  render() {  
    return(<>
      <div className="sys-DesktopView">
        {
          this.renderDesktopView()
        }
      </div>
      </>
    )
  }
}

const mapStatetoProps = state => {
  return {
    auth: state.auth,
    setCompanyDetail: state.setting.setCompanyDetail
  };
};

const mapDispatchToProps = dispatch =>
  bindActionCreators({ requestCompanyDetailSet,setLoader }, dispatch);

export default connect(
  mapStatetoProps,
  mapDispatchToProps
)(SystemNotificationsForm);
