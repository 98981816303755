// import npm packages
import { call, put } from "redux-saga/effects";

// import local files
import { createEversignDocument, getSavedEversignData } from "../../screens/homeowner/api/eversign";
import handleError from "../../shared/errorHandler";
import { setLoader } from "../../screens/Common/Loader/action";
import { receiveGetEversignData, receiveCreateEversignDocument } from "../../screens/homeowner/actions/eversign";
import { openNotificationWithIcon } from '../../shared/notification';

function* getCreateEversignDocument(action) {
	try {
		const data = yield call(createEversignDocument, action.data);
		yield put(setLoader({ loader: false }));
		yield put(receiveCreateEversignDocument(data));
	} catch (e) {
		yield put(setLoader({ loader: false }));
		handleError(e);
	}
}

function* getEversignData(action) {
	try {
		const data = yield call(getSavedEversignData, action.data);
		yield put(setLoader({ loader: false }));
		yield put(receiveGetEversignData(data));
	} catch (e) {
		yield put(setLoader({ loader: false }));
		handleError(e);
	}
}

export const homeownerEversign = {
	getCreateEversignDocument,
	getEversignData
};