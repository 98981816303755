import axios from "axios";
import { setHeader } from "./../shared/util";
export const getToken = () => localStorage.getItem("token");

export class Services {
  static getCall(url, auth,token) {
    if (!auth) {
      return axios.get(url);
    } else {
      return axios.get(url, setHeader(token));
    }
  }

  static postCall(url, params, auth,token) {
    return axios.post(url, params,setHeader(token));
  }

  static putCall(url, params, auth) {
    return axios.put(url, params, setHeader());
  }

  static deleteCall(url, auth, bodyData) {
    if (bodyData) {
      return axios.delete(url, {
        headers: {
          Authorization: "Bearer " + getToken()
        },
        data: bodyData
      });
    } else {
      return axios.delete(url, setHeader());
    }
  }
}
