// import npm packages
import React, { Component } from "react";
import { connect } from 'react-redux'
import { bindActionCreators } from "redux";
import { Form, Select, Tooltip, Checkbox } from "antd";

// import local files
import { requestUpdateInstallerById, requestInviteEmployes } from '../../actions/profile'
import { requestInstallerData } from '../../actions/addHomeOwner';
import { requestUpdateCompanyDetail } from '../../actions/profile';
import {ReactComponent as CaretIcon}  from "../../../../Assets/Images/path-2.svg";

import imgInfo from "../../../../Assets/Images/info.png"

const Option = Select.Option;

class EmployeeAccountForm extends Component {
  constructor(props) {
    super(props);
     this.state = {
      isRender: false,
      installer: {},
      defaultInstaller:{}
    }
  }

  componentWillMount(){
    this.props.setLoader({loader:true});
    this.props.requestInstallerData(this.props.companyId);
  }

  componentDidMount(){
    if(
      this.props.getInstaller.data
      && this.props.getInstaller.data.length
      && this.props.getInstaller.data.length !== 0
    ) {
      this.setEmployeeState(this.props);
    }
  }

  setEmployeeState(propsData){
    const { installer } = this.state;
      let defaultInstaller = {};
      propsData.getInstaller.data.forEach(installerData => {
        installer[installerData.id] = installerData.installerRole;
        if(installerData.defaultInstaller && !installerData.isDisable){
          defaultInstaller = installerData;
        }
      });
      this.props.setLoader({loader:false});
      this.setState({
        installer,
        defaultInstaller
      })
  }

  componentWillReceiveProps(nextProps) {
    if(
      nextProps.getInstaller.data
      && nextProps.getInstaller.data.length
      && nextProps.getInstaller.data.length !== 0
    ) {
      this.setEmployeeState(nextProps);
    }
    if ((nextProps.inviteResponse && nextProps.inviteResponse !== this.props.inviteResponse 
      ) || (this.state.isRender)) {
      this.setState({
        isRender: false
      }, () => {
        this.props.requestInstallerData(this.props.companyId);
        this.props.setLoader({loader: false});
      })
    }
  }

  isRevoked = payload => {
    let data = {
      bodyData: { isDisable: !payload.isDisable },
      companyId: this.props.companyId,
      installerId: payload.id,
      isRevoked: true,
      message: payload.isDisable ? 'User has been restored successfully' : 'User has been revoked successfully',
    }
    this.setState({
      isRender: true,
    });
    this.props.setLoader({loader: true});
    this.props.requestUpdateInstallerById(data)
  }

  handleSelectChange = (e, index) => {
    let data = {
      bodyData: { installerRole: e },
      companyId: this.props.companyId,
      installerId: index.id,
      message: "User's Role has updated successfully"
    }
    const { installer } = this.state;
    installer[index.id] = e;
    this.setState({
      isRender: true,
      installer,
    });
    this.props.setLoader({loader: true});
    this.props.requestUpdateInstallerById(data)
  };

  onChangereferral = (e, data) => {
    let empData = {
      bodyData: { referralReport: e.target.checked },
      companyId: this.props.companyId,
      installerId: data.id,
      message: `User ${e.target.checked? 'selected' : 'de-selected'} for Referral Report`
    }
    this.setState({
      isRender: true,
    });
    this.props.setLoader({loader: true});
    this.props.requestUpdateInstallerById(empData)

  }

  onChangeweekly = (e, data) => {
    let empData = {
      bodyData: { weeklyReport: e.target.checked },
      companyId: this.props.companyId,
      installerId: data.id,
      message: `User ${e.target.checked? 'selected' : 'de-selected'} for Weekly Report`
    }
    this.setState({
      isRender: true,
    });
    this.props.setLoader({loader: true});
    this.props.requestUpdateInstallerById(empData)

  }
  handleSelectChangePM = (value) => {
    let e = value ? JSON.parse(value) : {};
    let temp = {
      bodyData: { email: e.email?e.email:"" },
      companyId: this.props.companyId,
    }   
    this.setState({
      defaultInstaller:e,
    });
     this.props.requestUpdateCompanyDetail(temp);
  };

  renderInstallerInfo(data){
    return (
      data.name ?
        <Tooltip overlayClassName="black-tooltip" title={data.email}>
          <td className="metrics-data">{data.name}</td>
        </Tooltip>
        :
        <td className="metrics-data">{data.email}</td>
    )
  }

  renderEmployeeAccountRow = (data, index) => {
    return (
      <tr key={data.id} className={data.isDisable ? "metrics-row disabledEmpAcc" : "metrics-row"} >
        {this.renderInstallerInfo(data)}
        <td className="metrics-data">
          <section className="white-box-select margin-0 acc-type-value" style={{width:"60%","margin":'0 auto'}}>
            <Select  suffixIcon = {<CaretIcon/>} value={this.state.installer[data.id] && this.state.installer[data.id] === 1 ? "Admin" : "User"} onChange={(e) => this.handleSelectChange(e, data)}>
              <Option value="1">Admin</Option>
              <Option value="2">User</Option>
            </Select>
          </section>
        </td>
        <td className="metrics-data">
            <label className="container">
              <Checkbox onChange={(e) => this.onChangeweekly(e, data)} checked={data.weeklyReport}></Checkbox>
            </label>
        </td>
        <td className="metrics-data">
            <label className="container">
              <Checkbox onChange={(e) => this.onChangereferral(e, data)} checked={data.referralReport} ></Checkbox>
            </label>
        </td>
        <td className="metrics-data">
            <label className="container">
              <Checkbox onChange={(e) => this.isRevoked(data)} checked={!data.isDisable} ></Checkbox>
            </label>
        </td>
      </tr>
    )
  }

  renderDefaultPM = (data) => {
    const defaultPM=this.state.defaultInstaller;
    return (
      <Select  suffixIcon = {<CaretIcon/>} className="dropdown_widthPM" value={defaultPM.name ? defaultPM.name : defaultPM.email} onChange={(e) => this.handleSelectChangePM(e)}>
        {
          data.map(installers => (
            <Option value={JSON.stringify(installers)} key={installers.id}>
              {installers.name} {installers.name ? "-" : ""} {installers.email}
            </Option>

          ))
        }
        <Option value={""} key={""}>None</Option>
      </Select>
    )
  }

  render() {
    return (
      <>
        <div className="table-box-header"><div className="mob-box" style={{backgroundColor:"white"}}>Current users</div></div>
        <div onSubmit={this.handleSubmit}>
          <React.Fragment>
              <hr className="mob-enable" style={{marginBottom:'0'}}></hr>
              <div className="employee-account-info emp-header table-responsive">
                <table className="metrics-table">
                  <thead>
                    <tr> 
                      <th className="table-header seprator-right">Name
                      </th>
                    <Tooltip overlayClassName="black-tooltip" title="Admin account roles can change company and homeowner settings. User account roles can view them only.">
                      <th className="table-header seprator-right">Account role
                        <img src={imgInfo}/>
                      </th>
                    </Tooltip>
                    <Tooltip overlayClassName="black-tooltip" title="Receive weekly email reports showing new homeowner accounts, referrals, and survey responses.">
                      <th className="table-header seprator-right">Weekly report
                        <img src={imgInfo}/>
                      </th>
                    </Tooltip>
                    <Tooltip overlayClassName="black-tooltip" title="Receive daily email reports showing all referrals submitted for each day.">
                      <th className="table-header seprator-right">Referral report
                        <img src={imgInfo} />
                      </th>
                    </Tooltip>
                    <Tooltip overlayClassName="black-tooltip" title="Inactive accounts cannot log into Bodhi.">
                      <th className="table-header seprator-right">Active
                        <img src={imgInfo}/>
                      </th>
                    </Tooltip>
                    </tr>
                  </thead>
                  <tbody>
                    {this.props.getInstaller
                      && this.props.getInstaller.data ? this.props.getInstaller.data.map((item, index) => (
                        this.renderEmployeeAccountRow(item, index)
                      )) : null}
                  </tbody>
                </table>
              </div>
              <div className="col-sm-12 text-center mb-4">
                <span>Default PM</span>
                  {this.props.getInstaller
                    && this.props.getInstaller.data ? 
                      this.renderDefaultPM(this.props.getInstaller.data)
                   : null}
              </div>
          </React.Fragment>
        </div>
      </>
    );
  }
}

const mapStatetoProps = state => {
  return {
    updateInstallerById: state.setting.updateInstallerById,
    getInstaller: state.addhomeOwner.getInstaller || {},
    inviteResponse: state.setting.inviteEmployees
  }
}

const mapDispatchToProps = dispatch => (
  bindActionCreators({
    requestUpdateInstallerById,
    requestInstallerData,
    requestInviteEmployes,
    requestUpdateCompanyDetail,
  }, dispatch)

)

export default connect(mapStatetoProps, mapDispatchToProps)(EmployeeAccountForm)

