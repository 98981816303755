import React, { Component }         from 'react';
import { Input, Form, Select,Radio,
     Button,Table,Collapse }        from "antd";
import { connect }                  from 'react-redux';
import { bindActionCreators }       from 'redux';
import moment                       from "moment";
import { requestCompanyDetailSet }  from '../../actions/profile';
import {requestPowerMeterApiData }  from "../../../Admin/Actions/dashboard";
import {ReactComponent as CaretIcon}from "../../../../Assets/Images/path-2.svg";

import {requestEditInstallerManagementApiData,
        recieveEditInstallerManagementApiData,
        requestDriveUpload,
}                           from "../../../Admin/Actions/editInstaller";
import {setLoader}          from '../../../Common/Loader/action';
import GoogleDriveSync      from "../../../../shared/AdminGoogleDriveSync";
import SharePointSync       from "../../../../shared/SharePointSync"
import {JSONToCSVConvertor} from "../../../../shared/jsontoCSV.ts";
import { openNotificationWithIcon } from '../../../../shared/notification';
import addIcon from "../../../../Assets/Images/addIcon.svg"

const FormItem = Form.Item;
const Option = Select.Option;
const { Panel } = Collapse;

class IntegrationDetailForm extends Component {
    constructor(props) {
        super(props);
        this.GoogleDriveSync = new GoogleDriveSync();
        this.SharePointSync  = new SharePointSync();
        this.crmOption = [];
        this.state = {
            eversignType : null,
            meterRows: []
        };
    }

    componentWillMount() {
        let { companyData :{companyEversign,cpm,companyMeter} } = this.props;
        if (!this.state.meterRows.length) {
            const meterData = companyMeter && companyMeter.map((data, index) => {
                return { ...data, key_id: `${Date.now()}row${index}` }
            })
            this.setState({
                meterRows: meterData
            });
        }
        if(!this.state.eversignType && companyEversign &&  companyEversign[0] && companyEversign[0].apiKey){
            this.setState({eversignType:2})
        } else if(!this.state.eversignType && companyEversign &&  (!companyEversign[0] || !companyEversign[0].apiKey)){
            if(cpm && (!cpm[0] || cpm[0].projectId !=3))
            this.setState({eversignType:1})
        }

        if((JSON.stringify(this.props.dashboard.powerMeter)=='{}')){
            this.props.requestPowerMeterApiData();
            // this.props.setLoader({loader:true});
        }
    }

    componentWillReceiveProps(nextProps) {
        if(nextProps.dashboard.EditInstallerManagment && nextProps.dashboard.EditInstallerManagment.status===200){
            nextProps.setLoader({loader:false});
            nextProps.recieveEditInstallerManagementApiData({});
        }
    }

    handleSubmit = values => {
        let {companyId,setLoader,companyData:{companyDetail}} = this.props;
        const { meterRows } = this.state;

        setLoader({loader: true});    
        values["id"] = companyId;
        this.props.requestEditInstallerManagementApiData(values, companyDetail.isProjectTrack ? [] : meterRows);
    };

    cancelHandler = () => {

    };

    syncDriveDocuments = () => {
        let {companyData} = this.props;
        let folders = this.state.driveFolders;
        this.props.setLoader({ loader: true });

        if(companyData.companyDocument[0].documentId ==7){
            let token = companyData.companyDocument[0].token;
            this.SharePointSync.adminFolderList(folders,token,(classFolderList)=>{
                this.props.setLoader({ loader: false });
                this.syncDriveResponse = classFolderList;
                openNotificationWithIcon('info', `Total folder selected are ${folders.length} & sync are ${Object.keys(classFolderList).length}`, 'Sync Response');
            });
        } else {
            this.GoogleDriveSync.handleClientLoad(folders, (classFolderList) => {
                this.props.setLoader({ loader: false });
                if (!classFolderList) {
                    console.log("Not Authorized To Access Google Account.");
                    return;
                }
                if (classFolderList && (JSON.stringify(classFolderList) != '{}')) {
                    this.syncDriveResponse = classFolderList;
                }
            });
        }                 
    };

    downloadDriveDocuments = () => {
        const jsonToCSVConvertor = new JSONToCSVConvertor();
        for (var j in this.syncDriveResponse) {
            jsonToCSVConvertor.convertDriveData(this.syncDriveResponse[j], j, true);
        }
    }

    uploadDriveDocuments = () => {
        this.props.requestDriveUpload(this.syncDriveResponse);
    }

    googleDriveBlock = () => {
        let columns = [];
        let folderData = this.props.companyData &&  this.props.companyData.driveId;
        let cognitoSyncData = this.props.companyData  && this.props.companyData.cognitoData;

        if (folderData && folderData.length > 0) {
            folderData.forEach(element => {
                let match = cognitoSyncData.filter(ele => {
                    let key = `public/googleDrive/${element.key}.json`
                    return key === ele.Key
                })[0];
                if (match) {
                    element['lastSync'] = match.LastModified;
                    element['lastSyncTimestamp'] = moment(match.LastModified).unix()
                }
            });
            columns = [
                { title: "email", dataIndex: "email" },
                {
                    title: "last sync", dataIndex: "lastSync", defaultSortOrder: 'descend',
                    sorter: (a, b) => {
                        return a.lastSyncTimestamp - b.lastSyncTimestamp;
                    },
                },
                { title: "touchName", dataIndex: "touchName" },
                { title: "last Notification(UTC::00:00)", dataIndex: "last updated" },
                { title: "key", dataIndex: "key" },
            ]
        }

        const rowSelection = {
            onChange: (selectedRowKeys, selectedRows) => {
                this.setState({ driveFolders: selectedRows });
            }
        };
        return (
            <>
                <div className="border-btm margb35 margt20" />
                <div>
                    {folderData && folderData.length > 0 &&
                        <Collapse>
                            <Panel header="Google Shared Drive Sync" key="1">
                                <div>
                                    <Table
                                        rowSelection={{
                                            type: "checkbox",
                                            ...rowSelection,
                                        }}
                                        scroll={{ x: true }}
                                        columns={columns}
                                        dataSource={folderData}
                                    />
                                </div>
                                <Button className="syncButton"
                                    onClick={this.syncDriveDocuments}
                                >Sync DRIVE</Button>

                                {/* <Button className="syncButton"
                                    onClick={this.downloadDriveDocuments}
                                >Download DRIVE CSV </Button> */}

                                <Button className="syncButton"
                                    onClick={this.uploadDriveDocuments}
                                >UPLOAD DRIVE S3 </Button>

                            </Panel>
                        </Collapse>
                    }
                </div>
            </>
        )
    }

    renderDocument(type,data,eversignData){
        const { dashboard } = this.props;
        return(
            <>
            <div className="form-heading"> {type} </div>
                <div className="row">
                    <div className="col-md-4">
                        <div className="cmn-label-field-input">
                            <div className="form-group cmn-form-grp-input">
                                <label htmlFor="company name" className="lbl-cmn-field">Document storage</label>
                                <FormItem
                                    name="docStorage"
                                    initialValue = {
                                        data && data[0] && data[0].documentId
                                    }
                                    rules = { [{ required: true }] }
                                >
                                    <Select placeholder="Select" suffixIcon = {<CaretIcon/>}>
                                      <Option value="0">
                                        {" "}
                                        Select
                                      </Option>
                                      {dashboard &&
                                        dashboard.powerMeter &&
                                        dashboard.powerMeter.data &&
                                        dashboard.powerMeter.data.documentManagement &&
                                        dashboard.powerMeter.data.documentManagement.map(
                                          (docStorage, i) => (
                                            <Option
                                              value={docStorage.id}
                                              key={docStorage.id}
                                            >
                                              {docStorage.name}
                                            </Option>
                                          )
                                        )}
                                    </Select>
                                </FormItem>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-4">
                        <div className="cmn-label-field-input">
                            <div className="form-group cmn-form-grp-input">
                                <label htmlFor="company name" className="lbl-cmn-field">Document filter</label>
                                <FormItem
                                    hasFeedback
                                    name="docAPIkey"
                                    initialValue = { data && data[0] && data[0].filter }
                                >
                                    <Input type="text" placeholder="e.g Filter1, Filter2" />
                                </FormItem>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row">
                    {
                        this.state.eversignType ?
                        <Radio.Group className="col-md-12" value={this.state.eversignType} onChange={this.selectEversign}>
                        <div className="col-md-12 mt-4 p-0">
                            <Radio value={1}> Use Bodhi's Eversign account</Radio>
                        </div>
                        <div className="col-md-12 mt-2 p-0">
                            <Radio value={2}> Use company's Eversign account</Radio>
                        </div>
                        {
                            this.state.eversignType == 2 &&
                            <div className="row mt-4">
                                <div className="col-md-5">
                                    <div className="cmn-label-field-input">
                                        <div className="form-group cmn-form-grp-input">
                                            <FormItem
                                                hasFeedback
                                                name="eversignBusinessId"
                                                initialValue= { eversignData && eversignData[0] && eversignData[0].businessId }
                                                rules = { [{required:true}] }
                                            >
                                                <Input type="text" placeholder="Business Id" />
                                            </FormItem>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-5">
                                    <div className="cmn-label-field-input">
                                        <div className="form-group cmn-form-grp-input">
                                            <FormItem
                                                hasFeedback
                                                name= "eversignApiKey"
                                                initialValue = { eversignData && eversignData[0] && eversignData[0].apiKey }
                                                rules = { [{required:true}] }
                                            >
                                                <Input type="text" placeholder="Api Key" />
                                            </FormItem>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        }
                    </Radio.Group> :
                    <div className="col-md-12" style={{color:"red"}}>
                        E-signatures are not supported with the NetSuite at this time.
                    </div>
                    }
                </div>               
            </>
        )
    }

    selectEversign = (e) => {
        this.setState({eversignType:e.target.value});
    }

    checkDisable = (id) => {
        const matchIndex = this.state.meterRows.findIndex((ele) => ele['meterId'] === id)
        if (matchIndex === -1)
            return false;
        else
            return true;
    }

    isRequired = (meterID, type) => {
        if (type === 'key' && (meterID === 3 || meterID === 2)) {
            return true;
        } else if (type === 'identifier' && meterID === 3) {
            return true;
        }
        return false;
    }

    addMeterRow = () => {
        const { meterRows } = this.state;
        if (this.props.dashboard.powerMeter.data.powerMeter.length === meterRows.length)
            return null;
        else {
            const newRow = {
                key_id: `${Date.now()}row${meterRows.length}`,
                meterId: null,
                key: null,
                identifier: null
            }
            this.setState((state) => ({
                meterRows: [...state.meterRows, newRow]
            }))
        }
    }

    renderAddMeterRow = () => {
        const {meterRows} = this.state;
        if (meterRows && this.props.dashboard.powerMeter.data.powerMeter && this.props.dashboard.powerMeter.data.powerMeter.length !== meterRows.length) {
            return (
                <div className="col-md-6 p-0 mt-3 link-Color" onClick={this.addMeterRow}>
                    <img className="plus" src={addIcon} />
                    Add monitoring integration
                </div>)
        }
    }

    renderMinusMeterRow = (index) => {      
        const {meterRows} = this.state; 
        return (
            <div className="col-sm-1 minus-btn mb-4">
                <div
                    className="plus-add-unity"
                    onClick={() => this.setState((state) => ({
                        meterRows: [...state.meterRows.slice(0, index), ...state.meterRows.slice(index + 1)]
                    }))}
                >-</div>
            </div>
        )
    }

    handleMeterInputChange = (event, index, select) => {
        if (select) {
            this.setState((state) => ({
                meterRows: [...state.meterRows.slice(0, index), { ...state.meterRows[index], meterId: event }, ...state.meterRows.slice(index + 1)]
            }))
        }
        else {
            const { name, value } = event.target;
            this.setState((state) => ({
                meterRows: [...state.meterRows.slice(0, index), { ...state.meterRows[index], [name]: value }, ...state.meterRows.slice(index + 1)]
            }))
        }
    }

    renderPowerMeter(type, data) {
        const {
            dashboard : {powerMeter}
        } = this.props;

        if(!powerMeter || !powerMeter.data || !powerMeter.data.powerMeter){
            return;
        }
        return(
            <>
            <div className="form-heading"> {type} </div>
                <div className="row">
                    <div className="col-md-2">
                        <div className="cmn-label-field-input">
                            <div className="form-group cmn-form-grp-input">
                                <label htmlFor="company name" className="lbl-cmn-field">Integration</label>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-4">
                        <div className="cmn-label-field-input">
                            <div className="form-group cmn-form-grp-input">
                                <label htmlFor="company name" className="lbl-cmn-field">Key</label>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-2">
                        <div className="cmn-label-field-input">
                            <div className="form-group cmn-form-grp-input">
                                <label htmlFor="company name" className="lbl-cmn-field">Identifier</label>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-3">
                        <div className="cmn-label-field-input">
                            <div className="form-group cmn-form-grp-input">
                                <label htmlFor="company name" className="lbl-cmn-field">Aliases</label>
                            </div>
                        </div>
                    </div>
                </div>
            {
                data && data.map((row, index)=>{
                    return (
                        <div className="row" key={index}>
                        <div className="col-md-2">
                            <div className="cmn-label-field-input">
                                <div className="form-group cmn-form-grp-input">
                                    <FormItem
                                        hasFeedback
                                        name={ `meterId${row['key_id']}${index}` }
                                        initialValue = { row['meterId'] }
                                        rules = { [{ required: true }] }
                                    >
                                        <Select suffixIcon = {<CaretIcon/>}
                                            id={`meterId${row['key_id']}${index}`}
                                            placeholder="Select"
                                            name="meterId"
                                            onSelect={(event) => this.handleMeterInputChange(event, index, true)}
                                            disabled={
                                                    this.state.meterRows
                                                    && this.state.meterRows[index]
                                                    && this.state.meterRows[index].meterId
                                                    && this.props.dashboard.getCompanyById
                                                    && this.props.dashboard.getCompanyById.data
                                                    && this.props.dashboard.getCompanyById.data.companyMeter.findIndex(meter => meter.meterId === this.state.meterRows[index].meterId) !== -1 ?
                                                    true  : false
                                            }
                                            required
                                        >
                                            {
                                                powerMeter.data.powerMeter.map((powermeter) =>
                                                    <Option className="options" value={powermeter.id} key={`${row['key_id']}${powermeter.id}`} disabled={this.checkDisable(powermeter.id)}  >{powermeter.name}</Option>
                                                )
                                            }
                                        </Select>
                                    </FormItem>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-4">
                            <div className="cmn-label-field-input">
                                <div className="form-group cmn-form-grp-input">
                                        <FormItem
                                            hasFeedback
                                            name= { `meterApiKey${row['key_id']}${index}` }
                                            initialValue = { row['key'] }
                                            rules = { [{ required: this.isRequired(this.state.meterRows[index].meterId, 'key') }] }
                                        >
                                            <Input id={`APIKey${row['key_id']}${index}`} name="key" type="text" value={row['key']} onChange={(event) => this.handleMeterInputChange(event, index)} disabled={!row['meterId']} />
                                        </FormItem>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-2">
                            <div className="cmn-label-field-input">
                                <div className="form-group cmn-form-grp-input">
                                        <FormItem
                                            hasFeedback
                                            name= {`meterIdentifier${row['key_id']}${index}`}
                                            initialValue = { row['identifier'] }
                                            rules = { [{ required: this.isRequired(this.state.meterRows[index].meterId, 'identifier') }] }
                                        >
                                            <Input name="identifier" id={`identifier${row['key_id']}${index}`} type="text" value={row['identifier']} onChange={(event) => this.handleMeterInputChange(event, index)} disabled={!row['meterId']} />
                                        </FormItem>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-3">
                            <div className="cmn-label-field-input">
                                <div className="form-group cmn-form-grp-input">
                                        <FormItem
                                            hasFeedback
                                            name= { `meterApiFilter${row['key_id']}${index}` }
                                            initialValue = { row['filter'] }
                                            rules = { [{ required: false }] }
                                        >
                                            <Input name="filter" id={`filter${row['key_id']}${index}`} type="text" value={row['filter']} onChange={(event) => this.handleMeterInputChange(event, index)} disabled={!row['meterId']} />
                                        </FormItem>
                                </div>
                            </div>
                        </div>
                        {this.renderMinusMeterRow(index)}
                       
                    </div>
                    )
                })
            }
            {this.renderAddMeterRow()}
            </>
        )
    }

    renderCRM(type, crmData, pmData) {
        let data;
        const {
            dashboard,
            isAdmin
        } = this.props;
        if(dashboard.powerMeter && dashboard.powerMeter.data && this.crmOption.length==0){
            dashboard.powerMeter.data.customerManagement.map((ele)=>{
                if(!ele.id.toString().includes('crm'))
                ele.id = ele.id+"crm";
            })
            dashboard.powerMeter.data.projectManagement.map((ele)=>{
                if(!ele.id.toString().includes('pm'))
                ele.id = ele.id+"pm";
            })
            this.crmOption = dashboard.powerMeter.data.customerManagement.concat(dashboard.powerMeter.data.projectManagement);
            this.crmOption = this.crmOption.sort((a, b) => a['label'].toLowerCase().localeCompare(b['label'].toLowerCase()));
        }

        if (crmData && crmData.length > 0) {
            crmData[0]['ID'] = crmData[0]['crmId'] + 'crm';
            data = crmData;
        } else  if(pmData && pmData.length>0){
            pmData[0]['ID'] = pmData[0]['projectId'] + 'pm';
            data = pmData;
        }

        return(
            <>
            <div className="form-heading"> {type} </div>
                <div className="row">
                    <div className="col-md-4">
                        <div className="cmn-label-field-input">
                            <div className="form-group cmn-form-grp-input">
                                <label htmlFor="company name" className="lbl-cmn-field">Integration</label>
                                <FormItem
                                    hasFeedback
                                    name= "crmName"
                                    initialValue = { data && data[0] && data[0].ID }
                                >
                                    <Select suffixIcon = {<CaretIcon/>} placeholder="Select" disabled={!isAdmin ? true : false}>
                                      <Option value="0" >
                                        {" "}
                                        Select
                                    </Option>
                                      {this.crmOption &&
                                          this.crmOption.map(
                                          (customermgmt, i) => (
                                            <Option
                                              value={customermgmt.id}
                                              key={customermgmt.id}
                                            >
                                              {customermgmt.label}
                                            </Option>
                                          )
                                        )}
                                    </Select>
                                </FormItem>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-4">
                        <div className="cmn-label-field-input">
                            <div className="form-group cmn-form-grp-input">
                                <label htmlFor="company name" className="lbl-cmn-field">API key</label>
                                <FormItem
                                    hasFeedback
                                    name="CRMapiKey"
                                    initialValue= { data && data[0] && data[0].key }
                                    rules = { [
                                            {
                                                required:  false
                                            }
                                        ] }
                                >
                                    <Input type="text" placeholder="" disabled={!isAdmin} />
                                </FormItem>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-4">
                        <div className="cmn-label-field-input">
                            <div className="form-group cmn-form-grp-input">
                                <label htmlFor="company name" className="lbl-cmn-field">Identifier</label>
                                <FormItem
                                    hasFeedback
                                    name="CRMidentifier"
                                    initialValue = { data &&
                                            data[0] &&
                                            data[0].identifier }
                                    rules = { [{ required: false }] }
                                >
                                    <Input type="text" placeholder="" disabled={!isAdmin}/>
                                </FormItem>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        )
    }

    renderInfo() {
        const { companyData } = this.props;

        return (
            <>
                <div className="internal-pnl-cmn">
                    <Form onFinish={this.handleSubmit}>
                        {
                            companyData && (
                                <div className="internal-cmn-form">
                                    {this.renderCRM('Customer & project management system', companyData.crm, companyData.cpm)}
                                </div>
                            )
                        }                        

                        <div className="internal-cmn-form">
                            {this.renderDocument('Documents & e-signatures',companyData && companyData.companyDocument, companyData && companyData.companyEversign)}
                        </div>

                    {
                        companyData.companyDetail && !companyData.companyDetail.isProjectTrack &&
                            <div className="internal-cmn-form">
                                {this.renderPowerMeter('Monitoring', this.state.meterRows)}
                            </div>
                    }                       

                        <div className="form-submitted-btn">
                            <button type="submit" className="btn btn-primary"> Save </button>
                        </div>
                    </Form>
                    {this.googleDriveBlock()}
                </div>
            </>
        )
    }

    getSource = (type, url) => {
        this.setState({
            [type]: url
        });
    };

    render() {
        return (<>
            <div className="sys-DesktopView">
                {this.renderInfo()}
            </div>
        </>)

    }
}

const mapStatetoProps = state => {
    return {
        dashboard: state.adminOption.data,
    };
};

const mapDispatchToProps = dispatch =>
    bindActionCreators({ 
        setLoader,
        requestDriveUpload,
        requestCompanyDetailSet,
        requestPowerMeterApiData,
        requestEditInstallerManagementApiData,
        recieveEditInstallerManagementApiData
    }, dispatch);

export default connect(
    mapStatetoProps,
    mapDispatchToProps
)(IntegrationDetailForm);
