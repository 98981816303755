import React                    from "react";
import { Tooltip, Input, Form, Select,Checkbox, 
    Button, Spin, Modal }       from "antd";
import { LoadingOutlined }      from '@ant-design/icons';
import NumberFormat             from 'react-number-format';
import { bindActionCreators }   from "redux";
import { connect }              from 'react-redux';
import withRouter               from '../../../../shared/withRouter';
import { setLoader }                from '../../../Common/Loader/action';
import {openNotificationWithIcon}   from "../../../../shared/notification";
import {ReactComponent as CaretIcon}from "../../../../Assets/Images/path-2.svg";
import {
    requestStateListApiData,
    requestSlugStatusApiData,
    requestAddCompanyApiData,
    recieveAddCompanyApiData,
    recieveSlugStatusApiData}       from '../../Actions/dashboard'
import yellowCheckIcon              from "../../../../Assets/Images/check-yellow.svg"

const FormItem = Form.Item;
const Option = Select.Option;

const antIcon = (
    <LoadingOutlined
        className="dashboard-loading"
        style={{ fontSize: 60 }}
        spin
    />
);

function ResetButton() {
    const form = Form.useFormInstance();
    const reset = () => {
        document.body.classList.add("clear-form")
        form.resetFields()
    }
    return (<button type="button" className="btn btn-secondary" onClick={reset}> Cancel </button>)
}

class AddCompanyForm extends React.Component {
    constructor(props) {
        super(props);
        this.validateStatus = '';
        this.slugValue = '';
        this.requiredCRM = false;
        this.requiredProjectManagement = false;
        this.state = {
            successPopUp:false,
            errorObj: {},
        };
    }

    componentWillMount() {
        this.props.recieveAddCompanyApiData({});
    }

    componentWillReceiveProps(nextProps, nextState) {
        if (nextProps && nextProps.dashboard && nextProps.dashboard.slugStatus && nextProps.dashboard.slugStatus.status) {
            if (nextProps.dashboard.slugStatus.status === 200) {
                this.validateStatus = "success";
                this.forceUpdate();
            }
            if (nextProps.dashboard.slugStatus.status !== 200) {
                this.validateStatus = "error"
                this.forceUpdate();
            }
        }
        if (nextProps && nextProps.dashboard && nextProps.dashboard.addCompany && nextProps.dashboard.addCompany.status && nextProps.dashboard.addCompany.clicked) {
            this.props.setLoader({ loader: false });
            
            if (nextProps.dashboard.addCompany.status === 200) {
                this.validateStatus = '';
                // nextProps.form.resetFields();
                this.clear = true;
                if (!this.state.apiSuccess) {
                    this.slugValue = ''

                    this.setState({
                        apiSuccess: true,
                        isSubmit: false,
                        apiSuccess: false,
                        successPopUp :true
                    })
                }
            }
            else if (nextProps.dashboard.addCompany.status !== 200) {
                let addCompanyError = nextProps.dashboard.addCompany.data.message;
                nextProps.recieveAddCompanyApiData({});
                openNotificationWithIcon('error',addCompanyError,'Error');
                this.forceUpdate();
            }
        }
    }

    addCompanySucessPopUp(){
        let newCompany = this.props.dashboard &&
        this.props.dashboard.addCompany &&
        this.props.dashboard.addCompany.data;

        return(
            <Modal
                className="add-utility popUp"
                visible={this.state.successPopUp}
                footer={null}
                onCancel={()=>{
                    this.setState({successPopUp:false});
                    this.props.router.navigate(`/admin/${newCompany && newCompany.id}/companysetting`);
                }}
            >
            <div className="text-center ">
              <div className="tick-box text-center">
                <img
                  src={yellowCheckIcon}
                  alt="17terawatts"
                />
              </div>
              <h3>SUCCESS!</h3>
              <p>
                {newCompany ? newCompany.name : ""}'s account was created!
              </p>
              <div className="button-wrapper btn-box text-center">
                <button
                  onClick={()=>{
                    this.setState({successPopUp:false});
                    this.props.router.navigate(`/admin/${newCompany && newCompany.id}/companysetting`);
                }}
                  className="btn  btn-warning"
                >
                  <i className="fa fa-check-circle" aria-hidden="true" />
                  &nbsp; DONE
                  </button>
              </div>
            </div>
          </Modal>
        )
    }

    handleSlugStatus = e => {
        document.body.classList.remove("clear-form")
        const regex = /^[A-Za-z0-9]+$/;
        this.slugValue = e.target.value;
        if (this.slugValue.length > 1 && regex.test(this.slugValue)) {
            this.validateStatus = "validating"
            this.clear = false;
            this.forceUpdate();

            this.props.requestSlugStatusApiData(e.target.value);
        }
        else {
            this.props.recieveSlugStatusApiData({})
            this.validateStatus = "error";
            this.forceUpdate();
        }
    }

    /*
    reset = () => {
        document.body.classList.add("clear-form")
        const form = Form.useFormInstance();
        form.resetFields()
        this.clear = true;
        this.validateStatus = "";
        this.slugValue = "";
    }
    */

    // check the range of number
    checkNumberRange(value, range, type) {
        const { errorObj } = this.state;
        if (value.length !== range) {
            errorObj.adminPhone = 'error'
        } else {
            errorObj.adminPhone = ''
        }
        this.setState({ errorObj });
    }

    renderAddInstaller = () => {
        const { dashboard } = this.props;
        const { meterRows, selectedCrmName } = this.state;

        return (

            <div className="container" style={{ 'marginTop': '185px' }}>

                <div className="white-box-wrapper box-Shadow">
                    <Form onFinish={ this.handleCreate }>
                        <div className="paddr50 paddl50 paddb50" id="add-installer">
                            <div className="internal-cmn-form">
                                <h2 className="form-heading"> Add Company</h2>
                                <div className="row">
                                    <div className="form-group  col-md-5">
                                        <label className="add-company-label">Company name<span className="requiredSign"> * </span></label>
                                        <FormItem hasFeedback name="company" rules = { [{ required: true }, { pattern: /[a-zA-Z0-9][a-zA-Z0-9 ]*/ }] }>
                                                <Input id="company" type="text" />
                                        </FormItem>
                                    </div>
                                    <div className="form-group  col-md-4 offset-md-1">
                                        <label className="add-company-label">Slug<span className="requiredSign"> * </span></label>
                                        <Tooltip placement="topRight" title={dashboard && dashboard.slugStatus && dashboard.slugStatus.data && dashboard.slugStatus.data.message} visible={dashboard && dashboard.slugStatus && dashboard.slugStatus.status && dashboard.slugStatus.status !== 200 && this.validateStatus === "error"} >
                                            <FormItem hasFeedback validateStatus={this.slugValue !== "" ? this.validateStatus : (this.state.errorObj.slug ? this.state.errorObj.slug : '')} name="slug" rules = { [{ required: true }, { min: 2 }, { pattern: /^[A-Za-z0-9]+$/ }] }>
                                                    <Input type="text" id="slug" onChange={(e) => this.handleSlugStatus(e)} />
                                            </FormItem>
                                        </Tooltip>
                                    </div>
                                </div>

                                <div className="row">
                                <div className="form-group col-md-3">
                                    <div className="cmn-label-field-input">
                                        <div className="form-group cmn-form-grp-input">
                                            <label htmlFor="company name" className="lbl-cmn-field">Country<span className="requiredSign"> * </span></label>
                                            <FormItem hasFeedback name="country" rules = { [{ required: true }] }>
                                                <Select
                                                    placeholder="Select Country"
                                                    optionFilterProp="children"
                                                    onChange={(e) => {
                                                        this.props.requestStateListApiData({companyName:e});
                                                    }}
                                                >
                                                    <Option value="United States" key="United States">United States</Option>
                                                    <Option value="Singapore" key="Singapore">Singapore</Option>
                                                </Select>
                                            </FormItem>
                                        </div>
                                    </div>
                                </div>
                                    <div className="form-group col-md-5 offset-md-3">
                                        <label className="add-company-label">Street address<span className="requiredSign"> * </span></label>
                                        <FormItem hasFeedback name="address1" rules = { [{ required: true }, { pattern: /[a-zA-Z#0-9][a-z#A-Z0-9 ]*/ }] }>
                                            <Input id="address1" type="text" />
                                        </FormItem>
                                    </div>
                                </div>

                                <div className="row">
                                    <div className="form-group col-md-5">
                                        <label className="add-company-label">City<span className="requiredSign"> * </span></label>
                                        <FormItem hasFeedback name="city" rules = { [{ required: true }, { pattern: /[a-zA-Z0-9][a-zA-Z0-9 ]*/ }] }>
                                            <Input id="city" type="text" />
                                        </FormItem>
                                    </div>
                                    {
                                        dashboard && dashboard.stateList && dashboard.stateList.data && 
                                        dashboard.stateList.data.length > 0 &&
                                        <div className="form-group col-md-2 offset-md-1">
                                            <label className="add-company-label">State<span className="requiredSign"> * </span></label>
                                            <FormItem hasFeedback name="state" rules = { [{ required: true }] }>
                                                <Select  suffixIcon = {<CaretIcon/>}
                                                    placeholder="Select"
                                                    showSearch
                                                    optionFilterProp="children"
                                                    filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                                                >
                                                    {
                                                        dashboard && dashboard.stateList && dashboard.stateList.data && dashboard.stateList.data.map((stateList) =>
                                                            <Option value={stateList.code} key={stateList.id} >{stateList.code}</Option>
                                                        )}
                                                </Select>
                                            </FormItem>
                                        </div>
                                    }
                                    <div className="form-group col-md-2 offset-md-1">
                                        <label className="add-company-label">Postal code<span className="requiredSign"> * </span></label>
                                        <FormItem hasFeedback name="postalCode" rules = { [{ required: true }] }>
                                            <Input id="postalCode"  className="ant-input" placeholder="" />
                                        </FormItem>
                                    </div>
                                </div>

                                <div className="row">
                                    <div className="form-group col-md-5">
                                        <label className="add-company-label">Contact <span className="requiredSign"> * </span></label>
                                        <FormItem hasFeedback name="adminName" rules = { [{ required: true }, { pattern: /[a-zA-Z0-9][a-zA-Z0-9 ]*/ }] }>
                                            <Input type="text" />
                                        </FormItem>
                                    </div>
                                    <div className="form-group col-md-4 offset-md-1">
                                        <label className="add-company-label">Phone number</label>
                                        <FormItem hasFeedback validateStatus={this.state.errorObj.adminPhone || ''} name="adminPhone" rules = { [{ required: false }] }>
                                            <NumberFormat
                                                type="tel"
                                                format="(###) ###-####"
                                                mask=" " className="ant-input" placeholder="(   )  -  "
                                                onChange={(e) => {
                                                    this.checkNumberRange(e.target.value.replace(/[^0-9]/g, ""), 10, 'adminPhone')
                                                }}
                                            />
                                        </FormItem>
                                    </div>
                                </div>

                                <div className="row">
                                    <div className="form-group col-md-5">
                                        <Tooltip overlayClassName="black-tooltip" title="This will not send a Bodhi invitation to the installer." placement="top">
                                            <label className="add-company-label">Contact email<span className="requiredSign"> * </span></label>
                                        </Tooltip>
                                        <FormItem hasFeedback name="adminEmail" rules={ [{ type: 'email' }, { required: true }] }>
                                            <Input type="text" />
                                        </FormItem>
                                    </div>
                                  
                                    <div className="form-group col-md-3 offset-md-1">
                                        <label className="add-company-label">Project tracking only</label>
                                        <FormItem name="isProjectTrack">
                                            <Checkbox onChange={(e)=>{
                                               {this.setState({isProjectTrack:e.target.checked})}
                                            }}/>
                                        </FormItem>
                                    </div>

                                </div>
                            </div>

                            <div className="form-submitted-btn">
                                <ResetButton>Cancel</ResetButton>
                                <button type="submit" className="btn btn-primary"> Save </button>
                            </div>

                        </div>
                    </Form>
                </div>
            </div>
        )
    }

    handleCreate = (values) => {
        document.body.classList.remove("clear-form")

        if (
            JSON.stringify(this.state.errorObj) !== '{}'
            && (
                this.state.errorObj.adminPhone
                || this.state.errorObj.slug
            )
        ) {
            return null;
        }
        let data = values;
        data['isProjectTrack'] = this.state.isProjectTrack;
        this.button = true;
        this.setState({ isSubmit: true });
        this.props.setLoader({ loader: true });
        this.props.requestAddCompanyApiData(data);
    }

    handleCreateFailed = ({ errorFields }) => {
        document.body.classList.remove("clear-form")

        const { errorObj } = this.state;

        /*
        if (err && err.adminPhone) {
            errorObj.adminPhone = 'error'
        } else {
            errorObj.adminPhone = ''
        }
        if (err && err.slug) {
            errorObj.slug = 'error'
        } else {
            errorObj.slug = ''
        }
        this.setState({
            errorObj
        })
        */

        this.isVisible = false;
    }

    render() {
        if (localStorage.getItem("token")) {
            return (<Spin
                spinning={this.props.loader}
                indicator={antIcon}
                style={{ color: "#04bfff" }}
            >
                { this.renderAddInstaller()}
                {this.state.successPopUp && this.addCompanySucessPopUp()}
            </Spin>)
        } else {
            this.props.router.navigate("/");
            return null;
        }
    }

}

const mapStatetoProps = state => {
    return {
        loader: state.loader.loader.loader,
        dashboard: state.adminOption.data,
    }
}

const mapDispatchToProps = dispatch =>
    bindActionCreators({
        setLoader,
        requestStateListApiData,
        requestSlugStatusApiData,
        recieveSlugStatusApiData,
        recieveAddCompanyApiData,
        requestAddCompanyApiData
    }, dispatch);

export default connect(mapStatetoProps, mapDispatchToProps)(withRouter(AddCompanyForm))
