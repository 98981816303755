// import npm packages
import React, { Component } from "react";
import { connect }          from "react-redux";
import { LoadingOutlined }  from '@ant-design/icons';
import { Spin }             from "antd";
import {bindActionCreators} from "redux";

// import local files
import CompanyMetrics       from "../../../Installer/components/metrics/companyMetrics";
import DashboardStats       from "./CompanyStats";
import withRouter           from '../../../../shared/withRouter';
import { getToken }         from "../../../../shared/util";
import { setLoader }        from '../../../Common/Loader/action';
import {requestAdminListData}  from "../../Actions/header";
import {receiveCompanyDetail}  from "../../../Installer/actions/profile"

const antIcon = <LoadingOutlined
    style={{ fontSize: 60 }}
    spin
/>

class AdminDashboard extends Component {
  constructor(props) {
    super(props);
    this.slug = "";
    this.email="";
    this.state= {
      metricsData   : null,
      isPopUpVisible: false,
      metricFilterParam   : {},
      metricRef     : null,
      metricsHeader : []
    }
  }

  componentDidMount() {
    this.props.receiveCompanyDetail({});
    this.props.requestAdminListData('dashboard');
  }
  
  componentWillReceiveProps(nextProps) {
    if (
      nextProps.companyData
      && nextProps.companyData.list
      && nextProps.companyData.list.length>0
    )  {
      this.props.setLoader({ loader: false });
      let metricsHeader = nextProps.companyData.list[0];
      let metricsData = nextProps.companyData.list;
      this.setState({ metricsData, metricsHeader });
      
    }
  }

  renderDashboard = () => {

    return (
      <Spin
        spinning={false}
        indicator={antIcon}
        style={{ color: "#04bfff" }}
      >
        <React.Fragment>
        <title>Dashboard - {this.props.companyName && this.props.companyName.data && this.props.companyName.data.name+'-'} Bodhi</title>
          <div>
            {<DashboardStats {...this.props}/>}
            {this.renderCompanyMetrics()}
          </div>
        </React.Fragment>
      </Spin>
    );
  };


  render() {
    if (getToken()) {
      return this.renderDashboard();
    } else {
      this.props.router.navigate("/");
      window.location.reload();
      return null;
    }
  }

  changeMetricQueryParam = (operation,value) =>{  
    let {metricsQuery,metricColumnParam,metricFilterParam} = this.state
    let temp  = JSON.parse(JSON.stringify( metricsQuery));

    switch(operation){
      case 'skip':
          if(value< 0){
            temp['skip'] = (temp['skip'] > 0) ? temp['skip'] + value : 0;
          }
          else 
            temp['skip'] = temp['skip'] + value;
      break;

      case 'limit':
          temp['limit'] = value  ? value : "25";
      break;

      case 'sort':
          temp['sort'] = value;
      break;

      case 'param':
        metricColumnParam = value;
      break;

      case 'filter':
          metricFilterParam = value;
          temp['skip'] =0
      break;
    }

    let totalSkip = temp['skip'] * temp['limit'];
    if (totalSkip >= metricsQuery['count']) {
      return;
    }else
    {
      metricsQuery = temp;
      this.setState({ metricsQuery,metricColumnParam,metricFilterParam }, () => {
        this.receiveMetricData();
      })
    }
  }

  changeMetricState = (toltalCount) => {
    let { metricsQuery, metricFilterParam } = this.state

    metricsQuery = {
      skip: 0,
      limit: "25",
      count: toltalCount
    }
    metricFilterParam = {}

    this.setState({ metricsQuery, metricFilterParam }, () => {
     
    })
  }

  getmetricRef = (metricRef) => {
    this.setState({
      metricRef
    })
  }

  renderCompanyMetrics = () => {

    if (this.state.metricRef) {
      this.state.metricRef.setState({
        isCoulmnDisplay: false,
        currentShow: null,
        filterValue: null,
        stateMetricFilterParam: {},
      })
    }
    let {  metricsData, metricsHeader } = this.state;
    return <CompanyMetrics filterHide={true}  {...this.props} metricsType={'companies'} metricsData={metricsData}  metricsHeader ={ metricsHeader} metricsParam = {{}} metricFilterParam={{}}/>
  }
}

const mapStatetoProps = (state) => {
  return {
    companyData : state.adminHeader && state.adminHeader.listCompany && state.adminHeader.listCompany.data || []
  };
};

const mapDispatchToProps = dispatch => (
  bindActionCreators({
    setLoader,
    receiveCompanyDetail,
    requestAdminListData
  }, dispatch)
)
export default withRouter(connect(mapStatetoProps, mapDispatchToProps)(AdminDashboard));