// import npm packages
import { Spin, Modal }          from "antd";
import { LoadingOutlined }      from '@ant-design/icons';
import { connect }              from "react-redux";
import { bindActionCreators }   from "redux";
import React, { Component }     from "react";

import {requestGetCampaignData,
    receiveGetCampaignDetail}   from "../../actions/campaign";
import {deleteCampaign,
        createCampaign}         from "../../api/campaign"
import { setLoader }            from '../../../Common/Loader/action';
import withRouter               from '../../../../shared/withRouter'
import { getToken }             from "../../../../shared/util";
import {metricsReportData}      from "../../../Installer/api/dashboard";
import CompanyMetrics           from "../../../Installer/components/metrics/companyMetrics";
import {openNotificationWithIcon}  from "../../../../shared/notification";
import { DeleteModal }             from "../../../Common/DeleteModal";

const antIcon = <LoadingOutlined style={{ fontSize: 60 }} spin />;

class CampaignDashboard extends Component {
    constructor(props) {
      super(props);

      this.state = {
          campaignData : null,
          deleteTrigger : false,
          metricsType   : 'campaign',
          metricsData   : {},
          metricsQuery  : {"campaign":{"skip":0,"limit":"25","count":0.1}},
          metricColumnParam   : [],
          metricFilterParam   : {"campaign":{}},
          metricRef     : null,
          metricsHeader : {}
      };
    }
  
    componentWillMount() {
      window.scroll({top: 0});
      this.slug = this.props.router.params.slug || localStorage.getItem("slug");
    }

    componentDidMount(){
        this.companyId = this.props.router.params.companyId;
        if (!this.state.campaignData) {
            this.props.setLoader({loader:true});
            this.props.requestGetCampaignData({companyId:this.companyId});
            this.props.receiveGetCampaignDetail({});
        }
    }
  
    componentWillReceiveProps(nextProps) {
        if (nextProps.campaignData && JSON.stringify(nextProps.campaignData)!=='{}') {
           this.setState({campaignData:nextProps.campaignData})
        }
    }  


    renderCompanyMetrics = () => {
        if (this.state.metricRef) {
            this.state.metricRef.setState({
                isCoulmnDisplay: false,
                currentShow: null,
                filterValue: null,
                stateMetricFilterParam: {},
                metricColumnParam: (this.state.metricColumnParam && this.state.metricColumnParam.length > 0) ? this.state.metricColumnParam : ["homeownerId", "Name", "Phase", "Last Notification", "email", "Phone number", "isDisable", "Address"]
            })
        }
        let { metricsType, metricsData, metricFilterParam, metricsHeader } = this.state;


        return <CompanyMetrics hidePagination ={true} campaignDashboardAction={this.campaignDashboardAction} campaignStatus = {this.campaignStatus} campaignLink={this.campaignLink} getmetricRef={this.getmetricRef} {...this.props} metricsType={metricsType} metricsData={metricsData[metricsType]||this.props.campaignData} metricsHeader={metricsHeader[metricsType]}
            changeMetricQueryParam={this.changeMetricQueryParam} metricsParam={{"skip":0,"limit":"25","count":0.1}} metricFilterParam={metricFilterParam[metricsType] ? metricFilterParam[metricsType] : {}} />
    }

    campaignDashboardAction = (action, campaignId) => {
      switch(action){
        case 'delete':
          this.setState({
            deleteTrigger:true,
            actionCampaignId:campaignId
          });
        break;

        case 'clone':
          let obj = {
            companyId : this.companyId,
            campaignId: campaignId,
            clone     : true
          };

          this.props.setLoader({loader:true});
          createCampaign(obj).then(r => {
            this.props.setLoader({loader:false});
            if(r && r.status==200){
              openNotificationWithIcon('success','Campaign is Cloned','Success');
              this.props.router.navigate(`/${this.slug}/campaign/create?campaignId=${r.data.id}&campaignStatus=${1}`);
            }
          })
          
        break;
      }
    }

    deletePopUpProp = (value) =>{
      let { actionCampaignId } = this.state;
      if (value=='cancel') {
        this.setState({
          deleteTrigger: false
        });
      } else {
        this.setState({ deleteTrigger: false });
        this.props.setLoader({loader:true});

        let obj = {
            companyId : this.companyId,
            campaignId: actionCampaignId
        };
        deleteCampaign(obj).then(r => {
            if (r && r.status==200) {
                openNotificationWithIcon('success','Campaign is Deleted','Success');
                this.props.requestGetCampaignData({companyId:this.companyId});
            }
        })
      }
  }

  renderDeletePopUp = () => {
    let headerText = 'DO YOU REALLY WANT TO DELETE THIS CAMPAIGN?';
    let subText = "Deleting this campaign cannot be undone";
    return(
         <DeleteModal deletePopUpProp={this.deletePopUpProp} headerText={headerText} subText={subText}/>
    )
}

    getmetricRef = (metricRef) => {
        this.setState({
          metricRef
        })
    }

    changeMetricQueryParam = (operation, value) => {
        let { metricsQuery, metricColumnParam, metricFilterParam } = this.state;
        let metricsType ='campaign';
        let temp = metricsQuery['campaign'];
        switch (operation) {
            case 'skip':
                if (value < 0) {
                    temp['skip'] = (temp['skip'] > 0) ? temp['skip'] + value : 0;
                }
                else
                    temp['skip'] = temp['skip'] + value;
                break;

            case 'limit':
                temp['limit'] = value ? value : "25";
                break;

            case 'sort':
                temp['sort'] = value;
                break;

            case 'param':
                metricColumnParam = value;
                break;

            case 'filter':
                metricFilterParam[metricsType] = value;
                temp['skip'] = 0
                break;
        }

        let totalSkip = temp['skip'] * temp['limit'];
        if (totalSkip >= metricsQuery[metricsType]['count']) {
            return;
        } else {
            metricsQuery[metricsType] = temp;
            this.setState({ metricsQuery, metricColumnParam, metricFilterParam }, () => {
                this.receiveMetricData();
            })
        }
      }

      receiveMetricData = () => {
        let { metricsType, metricsQuery, metricColumnParam, metricFilterParam, metricsData, metricsHeader } = this.state;
        let response;
        this.props.setLoader({ loader: true });
        metricsReportData({ companyId:this.companyId, metricsType, metricsQuery: metricsQuery[metricsType], metricColumnParam, metricFilterParam: metricFilterParam[metricsType] }).then(apiResponse => {
          switch (metricsType) {
    
            case 'active accounts':
              response = apiResponse.data;
              break;
    
            case 'referrals':
              response = apiResponse.data;
              break;
    
            case 'surveys':
              response = apiResponse.data;
              break;
    
            case 'social shares':
              response = apiResponse.data && apiResponse.data.socialSharesCount;
              break;

            case 'campaign':
                response = apiResponse.data;
            break;
          };
          this.props.setLoader({ loader: false });
          if (!response || response.length == 0) {
            metricsQuery[metricsType]['skip'] = 0;
            let prevData = metricsData[metricsType] ? metricsData[metricsType][0] : null
            metricsHeader[metricsType] = prevData ? Object.keys(prevData) : metricsHeader[metricsType];
          }
          metricsData[metricsType] = response
          this.setState({ metricsData, metricsHeader });
        })
      }

    campaignLink = (value, campaignId) => {
        let status;
        switch(value) {
            case 1:
                status = this.props.router.navigate(`/${this.slug}/${this.companyId}/campaign/${campaignId}`)
            break;
            default:
                status = this.props.router.navigate(`/${this.slug}/${this.companyId}/campaign/create?campaignId=${campaignId}&campaignStatus=${1}`);
            break
        }
        return status;
    }

    campaignStatus(value) {
        let status;
        switch(value){
            case 1:
                status = 'Sent';
            break;
            case 2:
                status =  'Scheduled';
            break
            case 3:
                status =  'Draft';
            break;
        }
        return status;
    }


  
    renderCampaignDashboard = () => {
      let loader = this.state.isLoader || this.props.loader;
      return <React.Fragment>
        <title>Campaigns - Bodhi</title>
        <div>
          <Spin
            spinning={loader}
            indicator={antIcon}
            style={{ color: "#04bfff" }}
          >
            <section className="camp-dashboard-wrapper">
              {this.state.deleteTrigger && this.renderDeletePopUp()}
              {this.renderCompanyMetrics()}

            </section>
          </Spin>
        </div>
      </React.Fragment>;
    };
  
    render() {
      if (getToken()) {
        return this.renderCampaignDashboard();
      } else {
        this.props.router.navigate("/");
        window.location.reload();
        return null;
      }
    }
  }

const mapStatetoProps = state => {
    return {
      loader: state.loader.loader.loader,
      campaignData: state.companyCampaign && state.companyCampaign.companyGetCampaignReducer
            && state.companyCampaign.companyGetCampaignReducer.data || null
     };
  };
  
  const mapDispatchToProps = dispatch =>
    bindActionCreators(
      {
        setLoader,
        receiveGetCampaignDetail,
        requestGetCampaignData
      },
      dispatch
    );
  
  export default connect(
    mapStatetoProps,
    mapDispatchToProps
  )(withRouter(CampaignDashboard));
  