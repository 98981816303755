// import npm packages
import React, { Component } from 'react';
import * as moment          from "moment";
import { reducer }          from 'aws-cognito-redux-saga';
import { ReactSortable }    from "react-sortablejs";

import { Input, 
        Form, Select,
        Tooltip,
        Modal,
        Switch }            from "antd";

import { 
  requestCreateEversignDocument,
  receiveCreateEversignDocument 
}                           from "../../../homeowner/actions/eversign";
import { connect }          from 'react-redux';
import {bindActionCreators} from "redux";
import withRouter           from '../../../../shared/withRouter';
import GoogleDriveSync      from '../../../../shared/GoogleDriveSync';
import SharePointSync       from "../../../../shared/SharePointSync";
import DropBoxSync          from "../../../../shared/DropBoxSync"
import { getToken }         from '../../../../shared/util';
import { jwtDecode }        from '../../../../shared/jwt';
import { setLoader }        from '../../../Common/Loader/action';
import UploadDocument       from '../../../Common/UploadDocument';
import { requestEgaugePowerMeter,receiveHuaweiId,
          requestHuaweiId } from "./../../actions/testMeterConnection";
import {requestHomeOwner,
        requestUpdateHomeOwner,
        receiveUpdateHomeOwner}      from './../../actions/homeOwner';
import { requestEditUpdateAccount}   from "../../../homeowner/actions/settings";
import { openNotificationWithIcon}   from '../../../../shared/notification';
import {ReactComponent as CaretIcon} from "../../../../Assets/Images/path-2.svg";
import { filterHomeownerTouches,activityLogFilter}   from "../../../Common/FilterTouchHistory"
import signatureIconLg from "../../../../Assets/Images/signature_icon_large.svg"
import signatureIconSm from "../../../../Assets/Images/signature_icon_small.svg"
import threeDotIcon from "./../../../../Assets/Images/three-dot.svg"
import addIcon from "../../../../Assets/Images/addIcon.svg"

const FormItem     = Form.Item;
const Option       = Select.Option;
const { TextArea } = Input;

class MonitoringScreenPage extends Component {
  constructor(props) {
    super(props);
    this.slug  = '';
    this.GoogleDriveSync = new GoogleDriveSync();
    this.SharePointSync  = new SharePointSync();
    this.DropBoxSync     = new DropBoxSync();
    this.id = null;
    this.state = {
      powerMeter         : [],
      homeownerData      : null,
      eversignPopUp      : false,
      isTestMeterClicked : false,
      isMonitoringEdit   : true,
      isNotificationEdit : true,
      isforgotPassword   : false,
      isCloudImages      : false
    };
    this.meterUrl = {}
  }


  componentWillMount() {
    let token = getToken();
    this.decodedToken = jwtDecode(token);
    this.props.requestEgaugePowerMeter({});
    this.companyId = this.props.router.params.companyId;
    this.slug = localStorage.getItem('slug');
  
  }
  componentDidUpdate(prevProps) {
    if (this.props.router.location.pathname !== prevProps.router.location.pathname) {
      let data = {
        companyId: this.companyId,
        homeOwnerId: this.props.homeOwnerId
      };
      this.props.receiveUpdateHomeOwner({});
      this.props.requestEgaugePowerMeter({});
      this.props.requestHomeOwner(data);
      this.props.setLoader({ loader: false });
    }
  }

  componentDidMount(){
    this.props.setLoader({ loader: false });
  }
  componentWillReceiveProps(nextProps) {
    const {loggedInUser,companyId} = nextProps;
    let powerMeter = [];
    if (this.state.isforgotPassword) {
      this.setState({ isforgotPassword: false });
      openNotificationWithIcon('success', 'The new password was sent successfully.', 'Success');
    }

    if(!this.state.huaweiMeterId && nextProps.huaweiMeterId){
      this.setState({huaweiMeterId:nextProps.huaweiMeterId,meterconId : nextProps.huaweiMeterId})
    }

    if((loggedInUser && loggedInUser.documentData)&& JSON.stringify(nextProps.cloudImages)=='{}' && !this.state.isCloudImages){
      this.setState({
        isCloudImages : true
      },()=>{
        this.displayDriveFiles(loggedInUser.documentData);
      })
    }

    this.setState({ homeownerData: nextProps.loggedInUser });
    this.meterUrl = {
      1: nextProps.loggedInUser.solarUnit && (nextProps.loggedInUser.solarUnit.meterUrl || ""),
      2: 'monitoring.solaredge.com',
      3: 'api.enphaseenergy.com',
      4: 'data.sense.com',
      5: 'apsystemsema.com',
      6: 'solarweb.com',
      7: nextProps.loggedInUser.solarUnit && (nextProps.loggedInUser.solarUnit.meterUrl || ""),
    }

    if (
      nextProps.homeOwnerData.getUpdateHomeOwner
      && nextProps.homeOwnerData.getUpdateHomeOwner.status
      && nextProps.homeOwnerData.getUpdateHomeOwner.status === 200
  ) {
      // clear update api response from reducer
      nextProps.receiveUpdateHomeOwner({})
      // reset old data and get new data
      // nextProps.resetData()
  }

    if (
      nextProps.companyId.data &&
      nextProps.companyId.data.powerMeter
      && nextProps.companyId.data.powerMeter.length
      && loggedInUser.solarUnit
      && (
        loggedInUser.solarUnit.meterType
        || loggedInUser.solarUnit.meterId
      )
    ) {
      const { meterId,meterUrl,meterconId } = loggedInUser.solarUnit;
      powerMeter = nextProps.companyId.data.powerMeter.filter(item => item.meterId === meterId);
      this.setState({ powerMeter, meterType: meterId, meterUrl,meterconId })
    }

    if (nextProps
      && nextProps.createEversignDetails
      && nextProps.createEversignDetails.status === 200
    ) {
      this.props.receiveCreateEversignDocument({});
      this.setState({ 
        isApiResponse: true, 
        requestDocumentVisible: true, 
        embedded_claim_url: nextProps.createEversignDetails.data.embedded_claim 
      })
    }

  }

  filterTouchName(touchHistory) {
    const {companyJourney,additionalUser,eversignDetails,homeOwner:{getSurveyData}} = this.props;
    const arr3 =  [...touchHistory||[],...eversignDetails||[], ...additionalUser||[],...(getSurveyData.data
      && getSurveyData.data.activityFeed)||[]];
    let tempData = [];
    if (arr3.length !== 0) {
      tempData = arr3.map((data, index) => {
        if(data){
          data = typeof(data)=="string" ? JSON.parse(data) : data;
          data["index"] = index;
        return data;
        }
      }).filter(data => {
          if (data && data.createdAt && (data.rating || data.message || data.userName || data.fileName || data.isActive === true || data.fileMissing === true)) {
            return true;
          }else {
            return false;
          }
        }).sort((touch1, touch2) => {
          if (Number(touch2.createdAt) > Number(touch1.createdAt))
            return 1;
          else {
            if (Number(touch2.createdAt) === Number(touch1.createdAt)) {
              let lowerTouchName2 = companyJourney[touch2.touchName.toLowerCase().replace(/ /g,'')] && companyJourney[touch2.touchName.toLowerCase().replace(/ /g,'')].newTouchNo || 0;
              let lowerTouchName1 = companyJourney[touch1.touchName.toLowerCase().replace(/ /g,'')] && companyJourney[touch1.touchName.toLowerCase().replace(/ /g,'')].newTouchNo || 0;
              if (Number(touch2.phase) > Number(touch1.phase)) {
                return 1;
              }
              else if (Number(touch2.phase) === Number(touch1.phase) && Number(lowerTouchName2) > Number(lowerTouchName1)) {
                return 1;
              }
            }
          }
          return -1
        });
    }
    return tempData;
  }


  testConnection = (fetchMeterId) => {
    let meterUrl    = this.state.meterUrl;
    let meterId     = this.state.meterID;
    let meterconId  = this.state.huaweiMeterId || this.state.meterconId;
    let meterKey    = this.state.meterKey;
    let identifier  = this.state.identifier;

    let {
      loggedInUser :{solarUnit}
    } = this.props;


    if (!meterKey || meterKey == "") 
      meterKey = this.state.powerMeter[0] && this.state.powerMeter[0]['key'];
    
    if (!identifier)
      identifier = this.state.powerMeter[0] && this.state.powerMeter[0].identifier;

    if (!meterId)
      meterId = solarUnit && solarUnit.meterId;
    
    if(!meterconId)
      meterconId = solarUnit && solarUnit.meterconId;
    
    if(!meterUrl)
      meterUrl = (solarUnit && solarUnit.meterUrl) ? solarUnit.meterUrl : this.meterUrl[meterId];

    if(fetchMeterId && meterId && meterUrl){
      this.props.receiveHuaweiId({});
      this.props.setLoader({ loader: true });
      this.setState({huaweiMeterId:null})
      this.props.requestHuaweiId({
        meterUrl    : meterUrl,
        meterID     : meterId,
        fetchMeterId: fetchMeterId,
        key         : meterKey,
        identifier  : identifier
      })
    }
    

     else if(meterconId && meterId && meterUrl){
      this.props.setLoader({ loader: true });
      this.props.requestEgaugePowerMeter({
        meterUrl    : meterUrl,
        meterID     : meterId,
        meterconId  : meterconId,
        key         : meterKey,
        identifier  : identifier
      })
    }
    else{
      openNotificationWithIcon('error', 'Please enter meter information to sync data', 'Need Meter Details')
    }
    
    if (!this.props.loader) {
      this.setState({
        isTestMeterClicked: false
      })
    }
  }

  changeState = (data) =>{
    this.setState(data)
  }

   triggerForgotPassword = () =>{
     let { homeownerData } = this.state;
     this.setState({
       isforgotPassword : true
     },() =>{
      this.props.forgotPassword(homeownerData.homeownerData.email);
     })
   }   

  updateMonitoringCredentials = () =>{
    let {meterConnectionStatus} = this.props;

    if (meterConnectionStatus && (meterConnectionStatus.consumption || meterConnectionStatus.production)) {
      let { homeownerData } = this.state;
      let data = {
        companyId: homeownerData.homeownerData.companyId,
        homeOwnerId: homeownerData.homeownerData.id,
        saveMonitoringCredentials: true,
        bodyData: {
          firstName: homeownerData.homeownerData.firstName,
          email: homeownerData.homeownerData.email,
          solarUnit: {
            update: true,
            meterUrl: this.state.meterUrl,
            meterId: this.state.meterID,
            meterconId: this.state.huaweiMeterId || this.state.meterconId
          }
        }
      }
      this.setState({isMonitoringEdit:true});
      this.props.requestUpdateHomeOwner(data);
    }
    else {
      openNotificationWithIcon('error', 'Please recheck the meter details and contact your success manager.', 'No Meter Connection Found')
    }
  }

  updateNotification = () =>{
    let loggedInUser = this.state.homeownerData;
    this.props.setLoader({ loader: true });
    this.props.requestEditUpdateAccount({
      companyId     : loggedInUser.homeownerData.companyId,
      homeownerId   : loggedInUser.homeownerData.id,
      phone         : loggedInUser.homeownerData.phone,
      emailNotification : this.state.hasOwnProperty("emailNotification") ? this.state.emailNotification : loggedInUser.homeownerData.emailNotification,
      smsNotification   : this.state.hasOwnProperty("smsNotification") ? this.state.smsNotification : loggedInUser.homeownerData.smsNotification
    });
  }

  updateTouchHistory = () =>{
    let { homeownerData } = this.state;
    let currentTime = new Date().getTime();
    let touchHistory = [
    { "createdAt": currentTime, "phase": 1, 'isActive': true,   "index":0,  "touchName": "Sign into Bodhi and welcome"},
    { "createdAt": currentTime, "phase": 1, 'isActive': false,  "index":1,  "touchName": "Site Survey Scheduled"},
    { "createdAt": currentTime, "phase": 2, 'isActive': false,  "index":2,  "touchName": "System design finalized"},
    { "createdAt": currentTime, "phase": 2, 'isActive': false,  "index":3,  "touchName": "Utility interconnection approved"},
    { "createdAt": currentTime, "phase": 2, 'isActive': false,  "index":4,  "touchName": "AHJ permit approved"},
    { "createdAt": currentTime, "phase": 2, 'isActive': false,  "index":5,  "touchName": "HOA application approved"},
    { "createdAt": currentTime, "phase": 2, 'isActive': false,  "index":6,  "touchName": "Rebate approved"},
    { "createdAt": currentTime, "phase": 3, 'isActive': false,  "index":7,  "touchName": "Equipment ordered"},
    { "createdAt": currentTime, "phase": 3, 'isActive': false,  "index":8,  "touchName": "Equipment arrived"},
    { "createdAt": currentTime, "phase": 4, 'isActive': false,  "index":9,  "touchName": "Photos of installed panels"},
    { "createdAt": currentTime, "phase": 4, 'isActive': false,  "index":10, "touchName": "Inspection is scheduled"},
    { "createdAt": currentTime, "phase": 5, 'isActive': false,  "index":11, "touchName": "System passed inspection"},
    { "createdAt": currentTime, "phase": 6, 'isActive': false,  "index":12, "touchName": "Status Dashboard transitions to Monitoring Dashboard"},
    { "createdAt": currentTime, "phase": 6, 'isActive': false,  "index":13, "touchName": "Tax Credit Notification"}
    ];
    let data = {
      companyId: homeownerData.homeownerData.companyId,
      homeOwnerId: homeownerData.homeownerData.id,
      saveMonitoringCredentials: true,
      bodyData: {
        firstName: homeownerData.homeownerData.firstName,
        email: homeownerData.homeownerData.email,
        solarUnit: { 
          touchHistory,
          meterId: null,
          dailySyncTime:null,
          update: true,
          touchName :"Sign into Bodhi and welcome",
          phase : 1,
          updated : currentTime
        }
      }
    }
    this.props.requestUpdateHomeOwner(data);
  }

  renderMonitoringBox(loggedInUser, companyId){
    const renderPowerMeter = companyId && companyId.data
    ? companyId.data.powerMeter.map(item => (
      <Option value={item.meterId} key={item.meterId}>
        {item.name}
      </Option>
    ))
    : "";
    return(
      <div className="monitoring-profile-box">
        <h2>Monitoring System
     {this.state.isMonitoringEdit ?
            <span onClick={() => this.changeState({ isMonitoringEdit: false })} className="edit-link inactive">
              EDIT
      </span> :
            <span onClick={() => this.updateMonitoringCredentials()} className="edit-link active">
              Save
      </span>
          }
        </h2>
        <div className="monitioring-detail-box">
          <div className="meter-type">
            <p className="form-group caret-icon">Meter</p>
            <FormItem
              hasFeedback
              name="monitorType"
              initialValue = { this.state.powerMeter
                  && this.state.powerMeter[0]
                  && this.state.powerMeter[0].meterId }
            >
                <Select suffixIcon = {<CaretIcon/>}
                  className="form-control"
                  id="exampleFormControlSelect1"
                  disabled={this.state.isMonitoringEdit}
                  onChange={(e) => {
                    this.setState({ meterType: e, meterID: e, meterUrl: this.meterUrl[e], isTestMeterClicked: true },
                      () => {
                        this.props &&
                          this.props.companyId &&
                          this.props.companyId.data &&
                          this.props.companyId.data.powerMeter.forEach((val) => {
                            if (val.meterId == this.state.meterID) {
                              this.setState({
                                meterKey: val.key,
                                identifier: val.identifier
                              });
                            }
                          })
                      }
                    )
                  }
                  }
                >
                  {renderPowerMeter}
                </Select>
            </FormItem>
          </div>
          <div>
            <p className="form-group">Meter URL</p>
            <FormItem
              hasFeedback
            >
              {
                (
                  <Input
                    type="text"
                    className="form-control"
                    id="exampleInputPassword1"
                    value={(this.state.meterUrl || this.state.meterUrl == "") ? this.state.meterUrl : this.meterUrl[loggedInUser.solarUnit && loggedInUser.solarUnit.meterId]}
                    onChange={(e) => this.setState({ meterUrl: e.target.value, isTestMeterClicked: true })}
                    disabled={this.state.isMonitoringEdit}
                  />
                )}
            </FormItem>
          </div>
          <div className="meter-id">
            <p className="form-group">Meter ID</p>
            <FormItem hasFeedback>
                <Input
                  type="text"
                  className="form-control"
                  value={this.state.huaweiMeterId || this.state.meterconId}
                  id="exampleInputPassword1"
                  onChange={(e) => this.setState({ meterId: e.target.value.replace(/ /g, ''), meterconId: e.target.value, isTestMeterClicked: true })}
                  disabled={this.state.isMonitoringEdit || this.state.meterType == 7 || this.state.meterType ==8}
                />
            </FormItem>
                { (this.state.meterType == 7 || this.state.meterType ==8) && this.state.meterUrl &&
                  <a
                  style={{color: '#ff6f47'}}
                  className='d-block mt-2'
                  onClick={(e) => {
                    e.stopPropagation();
                    this.testConnection(this.state.meterType);
                  }}
                >
                  Fetch meterId
                </a>
                }
            
          </div>
          <div>
            <p className="form-group">Status</p>
            {
              this.props.meterConnectionStatus &&
              this.props.meterConnectionStatus.hasOwnProperty("production") &&
              (this.props.meterConnectionStatus.production ||
                this.props.meterConnectionStatus.consumption) &&
              <p>Online</p>
            }
            {
              this.props.meterConnectionStatus &&
              this.props.meterConnectionStatus.hasOwnProperty("production") &&
              !this.props.meterConnectionStatus.consumption &&
              !this.props.meterConnectionStatus.production &&
              <p>Offline</p>
            }
          </div>
          <button type='button'
            className='btn btn-secondary test-meter-link'
            onClick={() => this.testConnection()}
          >
            Test meter connection
          </button>
        </div>
      </div>
    )
  }

  renderDashboard = () => {
    const {
      homeOwner:{getSurveyData},
      companyId,
      cloudImages,
      systemGlance,
      loggedInUser,
      companyJourney
    } = this.props;

    let surveyActivity = (getSurveyData.data && getSurveyData.data.activityFeed) || [];

    let deviceMagicData =  loggedInUser.homeownerData && loggedInUser.homeownerData.magicFile && loggedInUser.homeownerData.magicFile.map(ele=>
      typeof(ele)==="string" ? JSON.parse(ele) : ele
    );

    let activityOrder = loggedInUser && loggedInUser.solarUnit && loggedInUser.solarUnit.touchHistory && 
    companyJourney && activityLogFilter(loggedInUser.solarUnit.touchHistory,[],'activityFeed',companyJourney,deviceMagicData,loggedInUser.solarUnit.activityLog,surveyActivity);
    let filterTouchOrder = loggedInUser && loggedInUser.solarUnit && loggedInUser.solarUnit.touchHistory && 
    companyJourney && filterHomeownerTouches(loggedInUser.solarUnit.touchHistory,[],'notifyTime',companyJourney)[0];
    let activationDate = filterTouchOrder && parseInt(filterTouchOrder.createdAt);

    let phaseName = loggedInUser.solarUnit && loggedInUser.solarUnit.activeJourney && filterTouchOrder &&
    loggedInUser.solarUnit.activeJourney.journeyPhase[filterTouchOrder.phaseName.replace(/ /g,'').toLowerCase()] &&
    loggedInUser.solarUnit.activeJourney.journeyPhase[filterTouchOrder.phaseName.replace(/ /g,'').toLowerCase()].name;

    return (
      <>
        {this.state.eversignPopUp && this.renderSignMessage()}
        <div className="monitoring">
          <section className="profile-detail-wrapper">
            <div>
              <div className="white-box box-Shadow">
                {
                  loggedInUser.homeownerData &&
                  !loggedInUser.homeownerData.isProjectTrack &&
                  this.renderMonitoringBox(loggedInUser, companyId)
                }
                <div className="notification-doc-box">
                <div className="row">
                    <div>
                      <h2>Latest Notifications</h2>
                      <div className="row">
                        <div className="col-sm-12">
                          <div className="notify-box">
                            <div>
                              <p>Phase</p>
                              <p>{filterTouchOrder &&  `${phaseName || filterTouchOrder.phaseName} - ${moment(filterTouchOrder.createdAt).format('MMM DD, YYYY')}`}</p>
                            </div>
                            <div>
                              <p> Days in phase</p>
                              <p>{activationDate && moment().diff(moment(activationDate), 'days')}</p>
                            </div>
                            <div>
                              <p>Last notification date</p>
                              <p>{activityOrder && activityOrder[0] && moment(activityOrder[0].createdAt).format('MMM DD, YYYY')}</p>
                            </div>
                            <div>
                              <p>Notification preference {
                                (this.state.isNotificationEdit ?
                                  <span onClick={() => this.changeState({ isNotificationEdit: false })} className="edit-link inactive">
                                    Edit
                                </span> :
                                  <span onClick={() => this.updateNotification()}
                                    className="edit-link active">
                                    Save
                                </span>)
                              }
                              </p>
                            </div>
                            <div className="form-group notification-btn homeowner margb0">
                              {
                                <FormItem
                                  name="smsNotification"
                                  initialValue = { loggedInUser
                                      && loggedInUser.homeownerData
                                      && this.switchValue(loggedInUser.homeownerData.smsNotification) }
                                  valuePropName="checked"
                                >
                                  <Switch disabled={this.state.isNotificationEdit} checkedChildren="ON"
                                    unCheckedChildren="OFF"
                                    onChange={(value) => { this.setState({ smsNotification: value }) }}
                                  />
                                </FormItem>}
                              <label className="paddl20 text-left notification-lable-text">Text</label>
                            </div>
                            <div className="form-group notification-btn homeowner paddl10">

                              {
                                <FormItem
                                  name="emailNotification"
                                  initialValue = { loggedInUser
                                      && loggedInUser.homeownerData
                                      && this.switchValue(loggedInUser.homeownerData.emailNotification) }
                                  valuePropName="checked"
                                >
                                  <Switch disabled={this.state.isNotificationEdit} checkedChildren="ON" unCheckedChildren="OFF" 
                                    onChange={(value) => { this.setState({ emailNotification: value }) }}
                                  />
                                </FormItem>}

                              <label className="paddl20 text-left notification-lable-text">Email</label>
                            </div>
                            <div>
                              <p>Trigger password change</p>
                              <button className="btn btn-primary forgot-password" onClick={() => {
                                this.triggerForgotPassword();
                              }}>New password</button>
                            </div>
                            {/* {this.decodedToken.email.includes('17terawatts') && <div>
                              <p>Reset Bodhi Journey</p>
                              <button className="btn btn-primary forgot-password" onClick={() => {
                                this.updateTouchHistory();
                              }}>Reset</button>
                            </div>} */}
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="col-sm-5">
                      <div className="installer-document-box">
                        <h2 className="row">Activity Feed</h2>
                        <div className="row">
                          <div className="col-sm-12 p-0">
                            <div className="mt-3">
                              {
                                loggedInUser
                                && loggedInUser.solarUnit
                                && loggedInUser.solarUnit.touchHistory
                                && activityOrder.map((touchNameData, index) => {
                                    if (index == 0 && touchNameData && touchNameData.touchName && touchNameData.fileMissing && touchNameData.fileMissing !== "false") {
                                      touchNameData.touchName = touchNameData.touchName + 'filemissing';
                                    }
                                    let touchData = companyJourney && touchNameData && touchNameData.touchName && companyJourney[touchNameData.touchName.toLowerCase().replace(/ /g, '')]
                                      && companyJourney[touchNameData.touchName.toLowerCase().replace(/ /g, '')];
                                    return this.displayTouchJourney(touchNameData, touchData)
                                  })
                              }
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-sm-4">
                      <div className="installer-document-box">
                        <h2>Documents</h2>
                        <div className="doc-content">
                          {
                            this.props.companyId.data &&
                            this.props.companyId.data.document &&
                            this.props.companyId.data.document[0] &&
                            this.slug !="ivee" &&
                            (this.props.companyId.data.document[0].documentId == 1 ||
                            this.props.companyId.data.document[0].documentId == 7) &&
                            <UploadDocument
                              isEdit={true}
                              removeFIleState={this.state.removeFIleState}
                              updateDocumentId={this.updateDocumentId}
                              setDocuement={doc => { this.setDocuement(doc) }}
                              checkFileMissing={(val, missingFiles) => { this.checkFileMissing(val, missingFiles) }}
                              {...this.props}
                            />
                          }
                          <ul>{cloudImages && loggedInUser && loggedInUser.documentData && loggedInUser.documentData.source !== 'netsuite'
                            && this.renderFiles(this.arrangeFiles(cloudImages))}</ul>
                        </div>
                      </div>
                    </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
      </>
    );
  };

  edResourceType(type){
    switch(type){
      case 'EdResource1':
        return 'Solar 101';
      case 'EdResource2':
        return 'Getting your home ready';
      case 'EdResource3':
        return 'Your solar equipment';
      case 'EdResource4':
        return 'Your monitoring system';
    }
  }

  displayTouchJourney(content, touchData) {
    if(!content || content.fileMissing=="true" || content.fileMissing==true)
      return;
    var notificationType;
    var notificationDetail;
    var time = content.createdAt;

    if (content.fileUrl) {
      notificationType   = 'Document Signed';
      notificationDetail = content.fileName
    } 
    else if(content.event && content.event.includes('EdResource')){
      notificationType = `Educational resource sent for `;
      notificationDetail = this.edResourceType(content.event);
    }
    else if (touchData && content.touchName && content.fileMissing!="true") {
      notificationType   = `Notification sent for Touch ${touchData.newTouchNo}:`;
      notificationDetail = `${touchData.display}`;
    }
    else if (content.userName) {
      notificationType   = 'Additional User Added:';
      notificationDetail = content.userName;
    } else if(content.rating && (content.phaseId==10 || content.phaseId==9)){
      notificationType = `NPS survey response provided with score of ${content.rating} and feedback:`;
      notificationDetail = `${content.message || 'N/A'}`;
     }
    else if(content.rating){
      notificationType = `Survey Submit for Phase ${content.phaseId}:`;
      notificationDetail = `Rating - ${content.rating}`;
    } else if(content.magicId){
      notificationType = `Notification sent for Device Magic End of Day:`;
      notificationDetail = `Report Id - ${content.magicId}`;
    }
    return (
      <>
        <strong>{moment(parseInt(time)).format('MMM DD, YYYY h:mmA')}</strong>
        <p> {notificationType} {notificationDetail}</p>
      </>
    )
  }

  setDocuement = (doc) => {
    if (doc && doc.key) {
      this.setState({
        removeFIleState : false,
        isSharedDrive: doc.isSharedDrive,
        files: doc.files,
        documentId: doc,
        isSynced: false,
      });
    } else {
      this.setState({
        isSynced: false,
        documentId: {
          source: '',
          key: ''
        }
      })
    }
  }

  checkFileMissing = (val, missingFiles) => {
    this.setState({
      isFileMissing: val,
      missingFiles
    });
  }

  updateDocumentId = async () => {
    this.props.setLoader({ loader: true });
    let sharepointPublicFile = this.state.documentId
    && this.state.documentId.source
    && this.state.documentId.source === "sharepoint"
    && this.state.documentId.key
    && this.state.documentId.files
    && await this.SharePointSync.makeFolderPublic(this.state.documentId.files,this.props.companyId.data.document[0].token);

    if (sharepointPublicFile && sharepointPublicFile.length > 0) {
      sharepointPublicFile = {
        isSharedDrive: true,
        key: this.state.documentId.key,
        source: this.state.documentId.source,
        files: {[this.state.documentId.key]:sharepointPublicFile}
      }
    } else if (this.state.isFileMissing && this.state.missingFiles) {
      this.props.setLoader({ loader: false });
      openNotificationWithIcon('error', `${this.state.missingFiles.join()} files is missing.Please add them.`, 'Error')
      return null;
    } else {
      sharepointPublicFile = null;
    }

    this.props.setImages(this.state.documentId.files);
    let body = {
      saveMonitoringCredentials: true,
      bodyData: {
        installerId: this.props.loggedInUser.homeownerData.installerId,
        document: sharepointPublicFile || this.state.documentId
      },
      homeOwnerId: this.props.loggedInUser.homeownerData.id,
      companyId: this.props.loggedInUser.homeownerData.companyId,
    }
    !this.state.isSynced
      && this.state.documentId
      && this.state.documentId.key
      && this.state.documentId.source === 'google'
      && this.GoogleDriveSync.makeFolderPublic(this.state.documentId.key, this.state.isSharedDrive, this.state.files);
    this.setState({ removeFIleState: true },
      () => {
        this.setState({ removeFIleState: false })
      });
    this.props.requestUpdateHomeOwner(body);
  }

  switchValue(value) {
    if (value || value === false) {
      return value
    }
    return true;
  }

  remove = (k, getFieldValue, setFieldsValue) => {
    const keys = getFieldValue('keys');
    if (keys.length === 0) {
      return;
    }
    let {list} = this.state;
    let newList = list.filter(ele=>{
      return ele.id !==k;
    });
    this.setState({list:newList});

    setFieldsValue({
      keys: keys.filter(key => key !== k),
    });
  }

  add = (id, listArray, getFieldValue, setFieldsValue) => {
    const keys = getFieldValue('keys');
    const nextKeys = keys.concat(id);
    listArray.push({id:id,order:id})
    this.setState({list:listArray},()=>{
      setFieldsValue({
        keys: nextKeys,
      });
    })
  }

  array2JSON(data){
    let newArr = [];
    data.forEach((ele, index) => {
      newArr.push({ id: index, order: ele })
    });
    this.setState({ list: newArr });
    return;
  }

  renderSignMessage(getFieldValue) {
    const { loggedInUser,installerDetails } = this.props;
    
    let keyInitialValue = [0,1];
    let initialSigner  = [
      {name:loggedInUser.homeownerData.firstName + " " +loggedInUser.homeownerData.lastName,email:loggedInUser.homeownerData.email,role:1},
      {name:loggedInUser.homeownerData.installerName,email:loggedInUser.homeownerData.installerEmail,role:2},
    ];

    if (this.decodedToken.email !== loggedInUser.homeownerData.installerEmail){
      initialSigner.push({
        name : installerDetails.name,
        email: installerDetails.email,
        role: 2
      });
      keyInitialValue.push(2);
    }

    return (
      <Modal
        visible={this.state.eversignPopUp}
        className="add-utility popUp"
        width={700}
        footer={null}
      >
        <div className="modal-header cmn-modal-header">
          <h4 className="modal-title cmn-modal-title">
            <img src={signatureIconLg} alt="" className="modal-title-logo eversign" />
            Request signatures
           </h4>
        </div>
        <Form
          onFinish={values => {       
            this.requestSignature(values);
          }}
          id="myForm"
          className="modal-body cmn-modal-body"
        >
          <div className="col-sm-12 form-group">

            <div className="row v-h-center detail-info-box mt-2">
              <p className="sign-text">Email a request to the homeowner and any additional signers to sign <b>{`${(this.state.docValues && this.state.docValues.name) || ''}`}</b></p>

              {
                ({ getFieldValue, setFieldsValue }) => {

                  const keys = getFieldValue('keys')

                  let list = this.state.list;

                  if (!list){
                    this.array2JSON(keys);
                    this.id = keys.length-1;
                  }

                  list && <><ReactSortable
                        list={list}
                        handle=".my-handle"
                        onEnd={() => {
                        }}
                        setList={(list) => {
                          this.setState({ list })
                        }}
                        style={{width:'100vw'}}
                        animation={150}
                      >
                        {list && list.map((ele,index) => {
                          let k = ele.order;
                          return  <FormItem
                          hasFeedback
                          key={k}
                          style={{width:"100%","marginTop":"13px"}}
                        >
                            <span className="my-handle mr-3">
                              <img src={threeDotIcon} alt='' />
                            </span>
                          
                          <FormItem
                            name= {`names[${k}]`}
                            validateTrigger = { ['onChange', 'onBlur'] }
                            initialValue = { initialSigner[k] && initialSigner[k].name }
                            rules = { [{
                              required: true
                            }] }
                          >
                            <Input className="col-md-3"
                              placeholder={`Name`}
                              style={{ "margin-right": "20px" }}
                              disabled = {loggedInUser.homeownerData.email==(initialSigner[k] && initialSigner[k].email) ? true : false}
                            />
                          </FormItem>

                          <FormItem
                            name= {`email[${k}]`}
                            validateTrigger = { ['onChange', 'onBlur'] }
                            initialValue = { initialSigner[k] && initialSigner[k].email }
                            rules = { [{
                              required: true
                            }] }
                          >
                            <Input
                              className="col-md-4"
                              placeholder={`Email`}
                              disabled = {loggedInUser.homeownerData.email==(initialSigner[k] && initialSigner[k].email) ? true : false}
                            />
                          </FormItem>

                          <FormItem
                            name= {`role[${k}]`}
                            validateTrigger = { ['onChange', 'onBlur'] }
                            initialValue = { initialSigner[k] && initialSigner[k].role }
                            rules = { [{
                              required: true
                            }] }
                          >
                            <Select
                              className="col-md-3"
                              style={{ 'width': '100%' }}
                              placeholder="Role"
                              disabled = {loggedInUser.homeownerData.email==(initialSigner[k] && initialSigner[k].email) ? true : false}
                            >
                              <Option value={1} key={1}>Signer</Option>
                              <Option value={2} key={2}>CC</Option>

                            </Select>
                          </FormItem>

                          {(loggedInUser.homeownerData.email!=(initialSigner[k] && initialSigner[k].email)) ? (
                            <div
                              className="plus-add-unity"
                              onClick={() => this.remove(k, getFieldValue, setFieldsValue)}
                            >-</div>
                          ) :  <div className="plus-add-unity hide">-</div>
                          }

                        </FormItem>
                        })}
                      </ReactSortable>

                      <div className="add-signer" onClick={() => {
                        this.id += 1;
                        this.add(this.id, list, getFieldValue, setFieldsValue);
                      }}>
                        <img src={addIcon} className="plus"/>
                        <span className="text link-Color">Add Signers and CCs</span>
                      </div>
                    </>

                }
              }
             
              <div className="col-12">
                <TextArea
                  rows={4}
                  className="p-2 mt-3 form-control"
                  placeholder="Include custom message (optional)"
                  onChange={(e) => {
                    this.setState({ eversignEmailMessage: e.target.value });
                  }}
                />
              </div>

              <div className="form-submitted-btn">
                <button
                  className="btn btn-secondary"
                  onClick={() => this.setState({ eversignPopUp: false })}
                  type="button"
                >
                  Cancel
                </button>

                <button
                  className="btn btn-primary"
                >
                  Prepare document
                </button>
              </div>
            </div>
          </div>
        </Form>
      </Modal>
    )
  }

  render() {
    if (getToken()) {
      return this.renderDashboard();
    } else {
      this.props.router.navigte('/');
      window.location.reload();
      return null;
    }
  }

  initializeDropbox() {
    let { loggedInUser: { homeownerData, documentData } } = this.props;
    if (homeownerData && documentData.source === "dropbox" && homeownerData.dropboxToken && !this.state.isSyncCalled) {
      this.setState({ isSyncCalled: true }, () => {
        this.props.setLoader({ loader: true });
        this.DropBoxSync.initializeDropbox(homeownerData.dropboxToken).then(async res => {
          if (res && documentData && documentData.key) {
            this.props.setLoader({ loader: false });
            this.DropBoxSync.getDropBoxFilesList(documentData.key, documentData.filter).then(response => {
              if(response ){
                this.props.setImages(response.files);
                this.setState({dropboxFile: response.previewUrls});
              }
            }).catch(error=>{
            })
          }
        });
      })
    }
  }

  displayDriveFiles = (documentData) => {
    if (!documentData){
      return;
    }
    let driveData = documentData.driveData;
    let temp = this.props.companyId.data && this.props.companyId.data.document[0] && this.props.companyId.data.document[0].token;
    if ( temp && documentData && documentData.key && documentData.source == "sharepoint") {
      let obj = JSON.parse(JSON.stringify(documentData));
      this.SharePointSync.listFiles(obj.key.replace(/#/g,'/'), null, null, temp,true).then(response => {
        if (response) {
          this.props.setImages(response.fileList);
          this.setState({
            isCloudImages: true,
            files: this.arrangeFiles(response.fileList),
            documentId: {
              key: documentData.key,
              source: documentData.source,
            }
          })
        }
      });
    } else if (driveData && (JSON.stringify(driveData) != '{}') && documentData.source != "sharepoint") {
      this.props.setLoader({ loader: false });
      let files = [];
      if(documentData.source==="solarNexus"){
        
        driveData[documentData.key] && driveData[documentData.key].map(ele=>{
          files.push({
            name : ele['document']['file_name'],
            public_url : ele['document']['url']
          })
        })
      }

      if(documentData.source==="zoho"){
        driveData[documentData.key] && driveData[documentData.key].map(ele=>{
          if(ele['$link_url'])
          files.push({
            name : ele['File_Name'],
            public_url : ele['$link_url']
          })
        })
      } else if(documentData.source==="teamwork"){
        driveData[documentData.key] && driveData[documentData.key].map(ele=>{
          if(ele['previewUrl'])
          files.push({
            name : ele['name'],
            public_url : ele['previewUrl']
          })
        })
      }

      this.props.setImages(files.length>0 ? {[documentData.key]:files} : driveData);
      this.setState({
        isCloudImages: false,
        files: this.arrangeFiles(files.length>0 ? {[documentData.key]:files} : driveData),
        documentId: {
          key: documentData.key,
          source: documentData.source,
        },
      })
    } else if(documentData.source === "dropbox"){
      this.initializeDropbox();
    }
    else if (this.props.documentTab) {
      this.props.setLoader({ loader: false });
      this.setState({
        isCloudImages: false,
        files: this.arrangeFiles(this.props.cloudImages),
        documentId: {
          key: documentData.key,
          source: documentData.source,
        },
        loader: false
      })
    }
    else if ( documentData.source =='google' && driveData && (JSON.stringify(driveData) == '{}')) {
      this.GoogleDriveSync.editListFiles(documentData.key, documentData.filter).then(response => {
        if (
          response
          && response.missingFile
          && response.missingFile.length
          && response.missingFile.length !== 0
        ) {
          openNotificationWithIcon('error', `${response.missingFile.join()} files is missing.Please add them.`, 'Error')
        }
        this.props.setLoader({ loader: false });

        if (response) {
          this.props.setImages(response.fileList);
          this.setState({
            isCloudImages: false,
            files: this.arrangeFiles(response.fileList),
            documentId: {
              key: documentData.key,
              source: documentData.source,
            },
            loader: false
          })
        }
      }).catch(err => {
        console.log('file error:', err);
      });
    }
  }

  handleCancel = e => {
    this.setState({ requestDocumentVisible: false })
  }

  renderFiles = (files) => {
    return <React.Fragment>
      {files && files.nonCollapsible && this.nonCollapsible(files.nonCollapsible)}
      {files && files.collapsible && this.collapsible(files.collapsible)}
      < Modal
        title={null}
        footer={null}
        maskClosable= {false}
        visible={this.state.requestDocumentVisible}
        onCancel={this.handleCancel}
        width='1000px'
        style={{top:'0px'}}
      >
         <label className="get-your-documents-s">GET YOUR DOCUMENTS SIGNED</label>
        <iframe src={this.state.embedded_claim_url} width="100%" height="900"></iframe>
      </Modal>
    </React.Fragment>
  }

  isEverSignValid(fileName) {
    if (fileName && /pdf/i.test(fileName.toLowerCase()))
      return true;
  }

  // render files
  nonCollapsible(files) {
    const { previewUrls } = this.state;
    const {eversignDetails,loggedInUser:{documentData}} = this.props;
    return Object.keys(files).map(file => {
      let f = this.props.loggedInUser.documentData.filter;
      let rexpression = new RegExp(f,"i");
      return files[file] && files[file].map((val, index) => {
        if (documentData && documentData.source === "serviceFusion") {
          if (f && !rexpression.test(val.name))
            return;
          if (val.doc_type === 'IMG')
            val.url = val.previewUrl = `https://servicefusion.s3.amazonaws.com/images/estimates/${val.file_location}`
          else
            val.url = val.previewUrl = `https://servicefusion.s3.amazonaws.com/userdocs/6794/${val.file_location}`
        } else if (documentData && (documentData.source === "solarNexus"|| documentData.source === "teamwork" || documentData.source === "zoho" || documentData.source === "monday")) {
          if (f && !rexpression.test(val.name))
            return;
          val.url = val.previewUrl = val.public_url;
        }
        
        else  if(documentData && documentData.source === "salesforce") {
          if (f && !rexpression.test(val.fileName))
            return;
            val.name = val.fileName;
            val.url = val.previewUrl = val.downloadURL;
        } else  if(documentData && documentData.source === "sharepoint") {
          var anchorTagClass = 'message';
        } 
        else if (index === 0 && ((!files[file][0].id) && !val.file_location))
          return '';
        return (
          <li key={val.id}>
            {((val && val.name && val.name.indexOf('Signed') > -1) || eversignDetails && eversignDetails.filter(ele => {
              return ele.isHomeSigned && (ele.fileOriginUrl == (val.previewUrl ? val.previewUrl : ((previewUrls && previewUrls[val.id]) || '')))
            })[0]) ?
              <Tooltip overlayClassName="black-tooltip" placement="top" title="This document signing is complete.">
                <i className="fa fa-check-circle" style={{"font-size":"22px","color":"#2e8540"}} aria-hidden="true" /> </Tooltip> :
              this.isEverSignValid(val.name) && <Tooltip overlayClassName="black-tooltip" placement="top" title="Request Signature">
                <img style={{ cursor: 'pointer' }} src={signatureIconSm} onClick={() => { this.setState({ eversignPopUp: true, docValues: { ...val, url: val.previewUrl ? val.previewUrl : previewUrls[val.id] || '' } }) }} alt='Request Signature' />
              </Tooltip>
            }
            <a style={{ marginLeft: '10px' }} className={anchorTagClass} href={val.url ? val.url : ((previewUrls && previewUrls[val.id]) || '#')} target='_blank'>
              {val && val.name}
            </a>
          </li>
        )
      })
    })
  }

  // render sub folder
  collapsible(files) {
    const { dropboxFile }  = this.state;
    const {eversignDetails,loggedInUser:{documentData}} = this.props;
    return Object.keys(this.sortFolder(files)).map(file => {
      if (
        files
        && files[file]
        && files[file].length < 2
        && files[file][0]
        && !files[file][0].id
      ) {
        return (
          <li key={file} className='file-error'>
            {files[file][0]}
          </li>
        )
      }
      return (
        <li key={file}>
          <strong>{files[file][0] && !files[file][0].id ? files[file][0] : ''}</strong>

          {
            files[file].sort((file1, file2) => {
              const name1 = file1 && file1.name && (file1.name.toLowerCase() || null)
              const name2 = file2 && file2.name && (file2.name.toLowerCase() || null)
              return (name1 && name2 && name1 < name2) ? -1 : (name1 && name2 && name1 > name2) ? 1 : 0;
            }).map((val, index) => {
              if (index === 0 && !files[file][0].id)
                return '';
              if (documentData && documentData.source === "sharepoint") {
               var anchorTagClass = 'message';
              }

              if (documentData && documentData.source === "dropbox"){
                val.url = dropboxFile && dropboxFile.find((ele)=>{
                  return val.path_display === ele.filePath
                })['previewUrl'];
              }

              return (
                <div className="file" key={val.id}>
                  {((val && val.name.indexOf('Signed') > -1) || eversignDetails && eversignDetails.filter(ele => {
                    return ele.isHomeSigned && (ele.fileOriginUrl == (val.previewUrl ? val.previewUrl : ''))
                  })[0])  ?  <Tooltip overlayClassName="black-tooltip"  placement="top" title="This document signing is complete.">
                <i className="fa fa-check-circle" style={{"font-size":"22px","color":"#2e8540"}}  aria-hidden="true" /> </Tooltip> :
                    this.isEverSignValid(val.name) && <Tooltip overlayClassName="black-tooltip" placement="top" title="Request Signature">
                      <img style={{ cursor: 'pointer' }} src={signatureIconSm} onClick={() => { this.setState({eversignPopUp:true, docValues: { ...val, url: val.previewUrl ? val.previewUrl : '' } })}} alt='Request Signature' />
                    </Tooltip>
                  }
                  <a className={anchorTagClass} style={{ marginLeft: '10px' }} href={val.url ? val.url : ''} target='_blank'>
                    {val && val.name}
                  </a>
                </div>
              )
            })
          }
        </li>
      )
    })
  }

  // sort folder by name
  sortFolder(files) {
    const temp = {}
    const tempArray = [];
    Object.keys(files).forEach(file => {
      if (files[file][0] && !files[file][0].id) {
        tempArray.push(files[file][0]);
      } else {
        temp[file] = files[file];
      }
    });
    tempArray.sort((val1, val2) => {
      return (val1 && val2 && val1.toLowerCase() < val2.toLowerCase()) ? -1 : (val1 && val2 && val1.toLowerCase() > val2.toLowerCase()) ? 1 : 0;
    }).forEach(val => {
      Object.keys(files).forEach(file => {
        if (files[file][0] && !files[file][0].id && files[file][0] === val) {
          temp[file] = files[file];
        }
      });
    });
    return temp;
  }

  arrangeFiles(files) {
    const temp = {
      collapsible: {},
      nonCollapsible: {}
    }
    const tempArray = [];
    Object.keys(files).forEach(file => {
      if(files[file][0]&& (files[file][0].file_location || files[file][0].publicURL) ){
        temp.nonCollapsible[file] = files[file];
      } else if(files[file][0] && files[file][0].public_url){
        temp.nonCollapsible[file] = files[file];
      }
      else if (files[file][0] && !files[file][0].id) {
        tempArray.push(files[file][0])
      } else {
        temp.nonCollapsible[file] = files[file];
      }
    });
    tempArray.sort((val1, val2) => {
      return (val1 && val2 && val1.toLowerCase() < val2.toLowerCase()) ? -1 : (val1 && val2 && val1.toLowerCase() > val2.toLowerCase()) ? 1 : 0;
    }).forEach(val => {
      Object.keys(files).forEach(file => {
        if (files[file][0] && !files[file][0].id && files[file][0] === val) {
          temp.collapsible[file] = files[file];
        }
      });
    });
    return temp
  }

  requestSignature(values) {
    if (JSON.stringify(values) !== '{}' && values.keys && values.keys.length !== 0 && values.names.length>0 && values.email.length>0) {
      const { loggedInUser } = this.props;
      const { list } = this.state;
      let eversignEmailMessage = this.state.eversignEmailMessage || `Please review and sign this important document ${(this.state.docValues && this.state.docValues.name)|| ''} concerning your solar system.`;        
      let value = this.state.docValues;
      let homeownerIndex = 0;
      let signer = [];
      let signature = [];
      let recipients = [];

      let name        = value && value.name;
      let url         = value && value.url;

      if (this.props.companyId.data.document[0].documentId == 7){
        url += '/$value';
        var token = this.props.companyId.data.document[0].token;
      }
      let firstName   = loggedInUser.homeownerData && loggedInUser.homeownerData.firstName ? loggedInUser.homeownerData.firstName : "";
      let middleName  = loggedInUser.homeownerData && loggedInUser.homeownerData.middleName ? loggedInUser.homeownerData.middleName : "";
      let lastName    = loggedInUser.homeownerData && loggedInUser.homeownerData.lastName ? loggedInUser.homeownerData.lastName : "";
      let homeownerId = loggedInUser.homeownerData && loggedInUser.homeownerData.id;
      let homeownerName = firstName + " " + middleName + " " + lastName;
      let homeownerEmail= loggedInUser.homeownerData && loggedInUser.homeownerData.email;
      let companyId   = loggedInUser.homeownerData && loggedInUser.homeownerData.companyId;
      let installerId = this.decodedToken && this.decodedToken.installerId;
      let createdAt   = parseInt(new Date().getTime() / 1000);
      let baseUrl     = window.location.origin + "/" + localStorage.getItem('slug');

      values.names && list.map((ele, index) => {
        let inputEmail = values.email[ele.id].toLowerCase().trim();

        signer.push ({
          name : values.names[ele.id].trim(),
          role : values.role[ele.id],
          email: inputEmail
        });
        if (values.role[ele.id] == 1) {
          if(inputEmail==homeownerEmail){
            homeownerIndex = signature.length;
          }
          signature.push({
            id            : signature.length + 1,
            name          : values.names[ele.id].trim(),
            email         : inputEmail,
            order         : signature.length + 1,
            message       : eversignEmailMessage,
            deliver_email : ele.id != 0 ? 1 : 0
          });
        }
        else {
          recipients.push({
            name  : values.names[ele.id].trim(),
            email : inputEmail
          })
        }
      });

      if (signature.length === 0) {
        openNotificationWithIcon('error', 'Please add atleast one signer.', 'Error');
        return;
      }

      if (!installerId) {
        openNotificationWithIcon('error', 'You are not authorised to make a request.', 'Error');
        return;
      }

      this.props.setLoader({ loader: true });
      this.props.requestCreateEversignDocument({
        companyId,
        signer,
        name,
        url,
        homeownerId,
        homeownerName,
        homeownerEmail,
        installerId,
        createdAt,
        baseUrl,
        recipients,
        homeownerIndex,
        signature,
        token,
        eversignEmailMessage
      });
      this.setState({ requestDocumentVisible: false,eversignPopUp: false })
    }
    else {
      openNotificationWithIcon('error', 'Please fill the user data', 'Error');
    }
  }

}

const mapStatetoProps = state => {
    return {
        createEversignDetails: state.homeownerGetEversign.homeownerCreateEversignDocument.data,
        companyId: state.addhomeOwner.companyId,
        companyData: state.setting.companyDetail.data,
        installerDetails : state.header.getInstaller && state.header.getInstaller.data,
        meterConnectionStatus: state.installerMeterConnection &&
        state.installerMeterConnection.installerMeterConnection,
        huaweiMeterId : state.installerMeterConnection && state.installerMeterConnection.huaweiMeterId,
        cloudImages:
        JSON.stringify(state.cloudImages.cloudImages) !== '{}'
          ? state.cloudImages.cloudImages
          : {},
    }
}

const mapDispatchToProps = dispatch =>{
  
  const boundActions = bindActionCreators({
    requestUpdateHomeOwner,
    requestEditUpdateAccount,
    requestHomeOwner,
    receiveUpdateHomeOwner,
    setLoader,
    requestHuaweiId,
    requestHuaweiId,
    receiveHuaweiId,
    requestEgaugePowerMeter,
    requestCreateEversignDocument,
    receiveCreateEversignDocument,
  }, dispatch);
  return {
    forgotPassword: username => {
      dispatch(reducer.forgotPassword(username))
    },
    ...boundActions
  }
}
export default withRouter(connect(mapStatetoProps, mapDispatchToProps)(MonitoringScreenPage));
