import { Services } from "../../../Api/services";
import * as config from "../../../shared/config";

export const createCampaign = async data => {
	const url = `${config.MARKETING_URL}company/${data.companyId}/campaign`;
	let res = await Services.postCall(url, data, true);
	return { data: res.data, status:res.status };
};

export const getSavedCampaignsData = async data => {
    const url = `${config.MARKETING_URL}company/${data.companyId}/campaign?sort=null`
	let res = await Services.getCall(url, true);
	return { data: res.data };
};

export const testCampaign = async data => {
    const url = `${config.MARKETING_URL}company/${data.companyId}/campaign/test`
	let res = await Services.postCall(url, data);
	return { status: res.status};
};

export const getCampaignById = async data =>{
	const url = `${config.MARKETING_URL}company/${data.companyId}/campaign/${data.campaignId}`
	let res = await Services.getCall(url, true);
	return { data: res.data};
}
export const deleteCampaign = async data =>{
	const url = `${config.MARKETING_URL}company/${data.companyId}/campaign/${data.campaignId}`
	let res = await Services.deleteCall(url, true,data);
	return { status: res.status};
}

export const updateCampaign = async data =>{
	const url = `${config.MARKETING_URL}company/${data.body.companyId}/campaign/${data.campaignId}`;
	let res  = await Services.putCall(url, data.body,true);
	return { status: res.status,data:res.data};
}

export const getCampaignRecipents = async data =>{
	const url = `${config.MARKETING_URL}company/${data.companyId}/campaign/receipt${data.parameter}`;
	let res  = await Services.postCall(url, {filter:data.filterData,campaignId:data.campaignId},true);
	return res.data
}

export const trackCampaignClick = async data =>{
	const url = `${config.MARKETING_URL}campaignwebhook`;
	let res  = await Services.postCall(url,data,true);
	return res.data
}