import React, { Component } from "react";
import { Input, Form, Select } from "antd";
import { Spin } from "antd";
import { LoadingOutlined } from '@ant-design/icons';
import NumberFormat from "react-number-format";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

// import local files
import { jwtDecode } from "../../../../shared/jwt";
import * as toast from "../../../../shared/notification";
import { getToken } from "../../../../shared/util";
import {
    requestApiData,
    requestAddHomeOwner,
    receiveAddHomeOwner,
    requestCompanyIdData,
    requestInstallerData
} from "../../actions/addHomeOwner";
import withRouter     from '../../../../shared/withRouter'
import { capitalize } from '../../../../shared/capitalize';
import Success from './ExistingInstallSuccess';
import {setMetricsType} from "../../actions/dashboard";
import { requestEgaugePowerMeter } from "./../../actions/testMeterConnection";
import addPersonYellowIcon from "./../../../../Assets/Images/add_person_yellow.svg"

import {ReactComponent as CaretIcon} from "../../../../Assets/Images/path-2.svg";

const FormItem = Form.Item;
const Option = Select.Option;
const antIcon = <LoadingOutlined style={{ fontSize: 60 }} spin />;

class ExistingInstallForm extends Component {
    constructor(props) {
        super(props);
        this.isSubmit = false;
        this.slug = "";
        this.state = {
            errorObj: {},
            defaultInstaller:{},
            newRatePlanList: null,
            selectedRatePlan: [],
            ratePlanList: [],
            getInstaller: [],
            installerId: '',
            stateValue: null,
            electricutilityList: [],
            powerMeter: [],
            moduleBrandList: [],
            inverterBrandList: [],
            installerList: [],
            fName: '',
            mName: '',
            lName: '',
            email: '',
            loader: false,
            isSuccess: false,
            disabled: false,
            meterconId: '',
            meterUrl: '',
            meterId: '',
            meterType: '',
            meterKey: '',
            identifier:null,
            isTestMeterClicked: false
        }
    }
    componentWillMount() {
        let token = getToken();
        this.decodedToken = jwtDecode(token);
        this.decodedToken['id'] = this.decodedToken['installerId'];
        this.companyId = this.props.router.params.companyId;
        if (!this.decodedToken.role) {
            this.setState({ disabled: true });
        }
        if (!this.state.loader) {
            this.setState({
                loader: true,
                defaultInstaller:this.decodedToken
            });
        }
        this.slug = localStorage.getItem("slug");
        this.props.requestApiData({});
        this.props.requestCompanyIdData(this.companyId);
        this.props.requestEgaugePowerMeter({});
        this.props.requestInstallerData(this.companyId);
        this.isCrmManager = false;
        this.meterUrl = {
            2 : 'monitoring.solaredge.com',
            3 : 'api.enphaseenergy.com',
            4 : 'data.sense.com',
            5 : 'apsystemsema.com',
            6 : 'solarweb.com'
        }
    }

    componentDidMount() {
        if (this.props.router.location.state.isHomeOwner) {
            const email = this.props.router.location.state.email;
            const data = {
                companyId: this.companyId,
                email: encodeURIComponent(email)
            };
            if (data.email && data.email !== "")
                this.props.requestAddHomeOwner(data);
        }
    }

    componentWillReceiveProps(nextProps) {
        let { loader } = this.state;
        if (
           ( nextProps.addhomeOwner
            && nextProps.addhomeOwner.homeOwner
            && nextProps.addhomeOwner.homeOwner.data)
            || (JSON.stringify(nextProps.meterConnectionStatus!='{}'))
        ) {
            loader = false;
        }

        // filter installer
        if (
            nextProps.addhomeOwner.getInstaller
            && nextProps.addhomeOwner.getInstaller.data
            && nextProps.addhomeOwner.getInstaller.data.length
        ) {
            let defaultInstaller;
            if (nextProps.addhomeOwner
                && nextProps.addhomeOwner.homeOwner
                && nextProps.addhomeOwner.homeOwner.data
                && nextProps.addhomeOwner.homeOwner.data.homeownerData
            )
                var crmManager = nextProps.addhomeOwner.homeOwner.data.homeownerData.projectManager;
            
             var installerList = nextProps.addhomeOwner.getInstaller.data.filter(installer => !installer.isDisable)
            for (let i = 0; i < installerList.length; i++) {
                if (installerList[i].defaultInstaller && !this.isCrmManager) {
                    defaultInstaller = installerList[i];
                }
                if (crmManager && (installerList[i].email.toLowerCase() == crmManager.toLowerCase())) {
                    defaultInstaller = installerList[i];
                    this.isCrmManager = true
             }        
            }
            if(defaultInstaller)
                this.setState({defaultInstaller});
        }


        if (
            nextProps.addhomeOwner
            && nextProps.addhomeOwner.homeOwner
            && nextProps.addhomeOwner.homeOwner.data
            && nextProps.addhomeOwner.homeOwner.data.homeownerData
            && nextProps.companyId
            && nextProps.companyId.data
        ) {

            let email = localStorage.getItem('homeOwnerEmail');
            email = nextProps.addhomeOwner.homeOwner.data.homeownerData.email

            // filter module brand
            let moduleBrandList = [];
            if (
                nextProps.companyId.data.moduleBrands
                && nextProps.companyId.data.moduleBrands.length
                && nextProps.addhomeOwner.homeOwner.data.solarUnit
                && nextProps.addhomeOwner.homeOwner.data.solarUnit.moduleBrand
            ) {
                const { moduleBrand } = nextProps.addhomeOwner.homeOwner.data.solarUnit;
                moduleBrandList = nextProps.companyId.data.moduleBrands.filter(module => {
                    return module.name.toLowerCase().replace(/ /g, '') === moduleBrand.toLowerCase().replace(/ /g, '');
                })
            }

            // filter inverter brand
            let inverterBrandList = [];
            if (
                nextProps.companyId.data.inverterBrands
                && nextProps.companyId.data.inverterBrands.length
                && nextProps.addhomeOwner.homeOwner.data.solarUnit
                && nextProps.addhomeOwner.homeOwner.data.solarUnit.inverterBrand
            ) {
                const { inverterBrand } = nextProps.addhomeOwner.homeOwner.data.solarUnit;
                inverterBrandList = nextProps.companyId.data.inverterBrands.filter(inverter => {
                    return inverter.name.toLowerCase().replace(/ /g, '') === inverterBrand.toLowerCase().replace(/ /g, '');
                });
            }

            // filter Electricutility
            let electricutilityList = [],
                ratePlanList = [],
                selectedRatePlan = [];
            if (
                nextProps.companyId.data.electricutility
                && nextProps.companyId.data.electricutility.length
                && nextProps.addhomeOwner.homeOwner.data.solarUnit
                && nextProps.addhomeOwner.homeOwner.data.solarUnit.electricUtilityName
            ) {
                const { electricUtilityName } = nextProps.addhomeOwner.homeOwner.data.solarUnit;
                electricutilityList = this.filterData(nextProps, 'electricutility', electricUtilityName, 'abbr');
                if (electricutilityList && electricutilityList[0]) {
                    nextProps.companyId.data.electricutility.forEach((item, index) => {
                        if (item.electricUtilityId === electricutilityList[0].electricUtilityId
                            && this.state.ratePlanList) {
                            ratePlanList = item.rateplan;
                            if(!this.state.newRatePlanList){
                                this.setState({
                                  newRatePlanList : ratePlanList
                                });
                            }
                            if (nextProps.addhomeOwner.homeOwner.data.solarUnit.utilityRateId) {
                                const { utilityRateId } = nextProps.addhomeOwner.homeOwner.data.solarUnit
                                selectedRatePlan = item.rateplan.filter(rate => rate.rateId === utilityRateId.toString());
                            }
                        }
                    });
                }
            }

            // filter Meter
            let powerMeter = [];
            let meterUrl;
            if (
                nextProps.companyId.data.powerMeter
                && nextProps.companyId.data.powerMeter.length
                && nextProps.addhomeOwner.homeOwner.data.solarUnit
                && nextProps.addhomeOwner.homeOwner.data.solarUnit.meterType
            ) {
                const { meterType } = nextProps.addhomeOwner.homeOwner.data.solarUnit;
                powerMeter = this.filterData(nextProps, 'powerMeter', meterType, 'filter');
                if(powerMeter && powerMeter.length>0){
                    meterUrl = this.meterUrl[powerMeter[0]['meterId']];
                }
                if(nextProps.addhomeOwner.homeOwner.data.solarUnit.meterUrl){
                    meterUrl = nextProps.addhomeOwner.homeOwner.data.solarUnit.meterUrl;
                    this.meterUrl[powerMeter[0]['meterId']] = meterUrl;
                }
            }

            // get user's State value
            let stateValue;
            if (
                nextProps.addhomeOwner
                && nextProps.addhomeOwner.homeOwner
                && nextProps.addhomeOwner.homeOwner.data
                && nextProps.addhomeOwner.homeOwner.data.homeownerData
                && nextProps.addhomeOwner.homeOwner.data.homeownerData.address
                && nextProps.addhomeOwner.homeOwner.data.homeownerData.address.state
                && nextProps.companyId
                && nextProps.companyId.data
                && nextProps.companyId.data.states
            ) {
                let crmStateLowercase = nextProps.addhomeOwner.homeOwner.data.homeownerData.address.state && nextProps.addhomeOwner.homeOwner.data.homeownerData.address.state.toLowerCase();
                const index = nextProps.companyId.data.states
                .findIndex(state => (state.name && state.name.toLowerCase() === crmStateLowercase) || (state.code.toLowerCase() ===crmStateLowercase));

                if (index !== -1) {
                    stateValue = nextProps.companyId.data.states[index].code;
                }
            }
            // set values
            this.setState({
                installerList,
                loader,
                stateValue,
                electricutilityList,
                powerMeter,
                moduleBrandList,
                inverterBrandList,
                ratePlanList,
                selectedRatePlan,
                email,
                meterUrl,
                crmHomeownerData:nextProps.addhomeOwner.homeOwner
            });
            nextProps.receiveAddHomeOwner('{}');
        }
        if (nextProps.addhomeOwner.existingInstall && nextProps.addhomeOwner.existingInstall.status && this.isSubmit) {
            let { disabled, loader } = this.state
            if (this.state.disabled)
                disabled = false;

            if (this.state.loader)
                loader = false

            this.setState({
                disabled,
                loader
            });
            let status = nextProps.addhomeOwner.existingInstall.status;
            if (!this.state.isSuccess && (status === 200 || status === 201)) {
                this.setState({
                    isSuccess: true
                })
            } else if (
                nextProps.addhomeOwner &&
                nextProps.addhomeOwner.response &&
                nextProps.addhomeOwner.response.data
            ) {
                toast.openNotificationWithIcon(
                    "error",
                    nextProps.addhomeOwner.response.data.message,
                    " Success "
                );
            }
        }
        this.setState({
            loader
        })
    }

    goToDashboard() {
        if (this.state.isSuccess) {
            this.setState({
                isSuccess: false
            })
        }
        this.props.router.navigate(`/${this.slug}/${this.companyId}/dashboard`)
    }
    filterData(nextProps, type, value, subType) {
        return nextProps.companyId.data[type].filter(item => {
            const temp = type === 'powerMeter' ?
                item[subType] ?
                    item[subType].toLowerCase().replace(/ /g, '').split(',')
                    : item['name'].toLowerCase().replace(/ /g, '').split(',')
                : item[subType].toLowerCase().replace(/ /g, '').split(',');
            if (temp && temp.findIndex(val => value && val === value.toLowerCase().replace(/ /g, '')) !== -1)
                return true;
            else
                return false;
        })
    }

    handleSubmit = values => {
        const addhomeOwner = this.state.crmHomeownerData;
        const meterStatus = this.props.meterConnectionStatus;
        if (! meterStatus || !meterStatus.production){
            toast.openNotificationWithIcon(
                "error", `Please enter and test valid meter information.`, 'Error');
                this.setState({
                    loader: false
                });
                return;
        }

        if (!this.decodedToken.role) {
            toast.openNotificationWithIcon('error', `You are not authorized.`, 'Error')
            return null;
        }

        let electricUtility = [],
            inverter = [],
            module = [],
            meter = [];
        if (this.props.companyId && this.props.companyId.data) {
            if (this.props.companyId.data.electricutility)
                electricUtility = this.props.companyId.data.electricutility.filter(val => val.electricUtilityId === values.electricityUtility)

            if (this.props.companyId.data.inverterBrands)
                inverter = this.props.companyId.data.inverterBrands.filter(val => val.id === values.inverterBrand)

            if (this.props.companyId.data.moduleBrands)
                module = this.props.companyId.data.moduleBrands.filter(val => val.id === values.moduleBrand)

            if (this.props.companyId.data.powerMeter)
                meter = this.props.companyId.data.powerMeter.filter(val => val.meterId === values.monitorType)
        }
        this.setState({
            email: values['email'],
            fName: values.firstName,
            lName: values.lastName
        })
        if (
            JSON.stringify(this.state.errorObj) !== '{}'
            && (
                this.state.errorObj.phoneNumber
                || this.state.errorObj.systemPrice
                || this.state.errorObj.annualConsumption
                || this.state.errorObj.annualProduction
                || this.state.errorObj.projectAnnualSavings
            )
        ) {
            return null;
        }
        this.isSubmit = true;
        values['meterUrl'] = this.state.meterUrl;
        values['meterId'] = values['meterId'].replace(/ /g, '');
        values['electricUtilityName'] = electricUtility[0].name;
        values['inverterName'] = inverter[0].name;
        values['moduleName'] = module[0].name;
        values['meterName'] = meter[0].name;
        values['state'] = values['state'];
        values["companyId"] = this.companyId;
        values['companyName'] = this.props.installerData.data.companyName;
        values['toolSolarUnitId'] = addhomeOwner.data
            && addhomeOwner.data.solarUnit ?
            addhomeOwner.data.solarUnit.toolSolarUnitId
            : "";
        values['toolContactId'] = addhomeOwner.data
            && addhomeOwner.data.solarUnit ?
            addhomeOwner.data.homeownerData.toolContactId
            : "";
        values["phase"] = 6;
        values["phaseCompleted"] = true;
        values["phaseTime"] = new Date().getTime();
        values["touchName"] = 'Status Dashboard transitions to Monitoring Dashboard'
        values["touchHistory"] = [];
        values['connectionStatus']= meterStatus ? meterStatus :{}

        this.setState({
            disabled: true,
            loader: true
        })
        this.props.receiveAddHomeOwner({})
        this.props.requestApiData(values);

    };

    handleChangeUtility = (e) => {
        this.props.companyId.data.electricutility.forEach((item, index) => {
            if (item.electricUtilityId === e) {

                this.setState({
                    newRatePlanList: item.rateplan
                })
            }
        })
    }

    renderOptions = (dataArray, display, id) => (
        dataArray.map(item => (
            <Option value={item[id]} key={item[id]}>
                {capitalize(item[display])}
            </Option>
        ))
    )
    stateOptions = (dataArray) => (
        dataArray.map(item => ({
            id: item.id,
            label: item.code
        }))
    )

    // check the range of number
    checkNumberRange(value, range, type) {
        const { errorObj } = this.state;
        switch (type) {
            case 'phoneNumber':
                if (value.length !== range) {
                    errorObj.phoneNumber = 'error'
                } else {
                    errorObj.phoneNumber = ''
                }
                this.setState({
                    errorObj
                })
                break;
            default:
                if (value > range) {
                    errorObj[type] = 'error'
                } else {
                    errorObj[type] = ''
                }
                this.setState({
                    errorObj
                })
                break;
        }
    }

    // Check existing meter connection 
    testExistingMeterConnection = () => {
        const homeOwnerTestMeterData = this.state.crmHomeownerData;
        let meterId = this.state.meterId;
        if (!this.state.meterId) {
            meterId = this.state.powerMeter[0] && this.state.powerMeter[0].meterId;
        }
        let meterUrl = this.state.meterUrl || homeOwnerTestMeterData.data.solarUnit.meterUrl;
        let meterconId =  this.state.meterconId || homeOwnerTestMeterData.data.solarUnit.meterconId;
        let key = this.state.meterKey;
        if(!key || key==''){
            key = this.state.powerMeter[0] && this.state.powerMeter[0].key;
        }
        let identifier = this.state.identifier;
        if (!this.state.identifier) {
            identifier = this.state.powerMeter[0] && this.state.powerMeter[0].identifier;
        }

        if(!meterUrl || !meterId || !meterconId){
            toast.openNotificationWithIcon('error', 'Please enter meter information to sync data', 'Need Meter Details');
        }

        this.setState({loader: true});

        this.props.requestEgaugePowerMeter({
            meterUrl: meterUrl,
            meterID: meterId,
            meterconId: meterconId,
            key: key,
            identifier: identifier
        });
        if(!this.state.loader){
            this.setState({
           isTestMeterClicked:false
       })
       }
    }

    renderExistingInstall = (e) => {
        const {  companyId,installerData } = this.props;
        const addhomeOwner = this.state.crmHomeownerData;
        const { loader } = this.state;
        this.formSetting = (installerData&&installerData.data&&installerData.data.formSetting) || {};
        return (
            <React.Fragment>
                <title>Add Existing Install Homeowner - {this.props.companyName} -{installerData&&installerData.data&&installerData.data.name+'-'} Bodhi</title>
                <Spin
                    spinning={loader}
                    indicator={antIcon}
                    style={{ color: "#00142D" }}
                >
                    <div
                        className="existinginstall-wrapper"
                    >
                        <section>
                            <div className="container exisiting">
                                <div className="new-client-form-wrapper add-new-home-owner box-Shadow">
                                    <h3 className="newHomeOwner">ADD AN EXISTING HOMEOWNER PROFILE</h3>

                                    <div className="white-box create-client-form-box">
                                        <h2 className="text-alignment col-md-12">
                                            <img
                                                src={addPersonYellowIcon}
                                                width={33}
                                                alt="17terawatts"
                                            />
                                            <span>Let's collect all the required information for this account</span>
                                        </h2>

                                        <Form onFinish={this.handleSubmit} className="login-form homeowner-profile">
                                            <h3 className="add-homeowner">Customer information</h3>

                                            <div className="newHomeOwnerProfile">
                                                <div className="form-group mobilespacing">
                                                    <div className="row">
                                                        <div className="col-md-4 col-12">
                                                            <div className="form-group">
                                                                <label className="mb-0 form-group">First Name</label>
                                                                <FormItem
                                                                    hasFeedback
                                                                    name="firstName"
                                                                    rules = { [
                                                                            { required: true },
                                                                            { pattern: /^([^0-9]*)$/ }
                                                                        ] }
                                                                    initialValue = { addhomeOwner && addhomeOwner.data && addhomeOwner.data.homeownerData && addhomeOwner.data.homeownerData.firstName !== ""
                                                                            && addhomeOwner.data.homeownerData.firstName }
                                                                >
                                                                    <Input
                                                                        type="text"
                                                                        className="form-control"
                                                                        id="exampleInputPassword1"
                                                                        onChange={(e) => {
                                                                            this.setState({
                                                                                fName: e.target.value
                                                                            })
                                                                        }}
                                                                    />
                                                                </FormItem>
                                                            </div>
                                                        </div>
                                                        <div className="col-md-4 col-12">
                                                            <div className="form-group">
                                                                <label className="mb-0 form-group label-center">
                                                                    Middle Initial
                                                                </label>
                                                                <FormItem
                                                                    hasFeedback
                                                                    name="middleName"
                                                                    rules = { [
                                                                            { required: false },
                                                                            { pattern: /^([^0-9]*)$/ }
                                                                        ] }
                                                                    initialValue = { addhomeOwner && addhomeOwner.data && addhomeOwner.data.homeownerData
                                                                            ? addhomeOwner.data.homeownerData.middleName
                                                                            : "" }
                                                                >
                                                                    <Input
                                                                        type="text"
                                                                        className="form-control"
                                                                        id="exampleInputPassword1"
                                                                    />
                                                                </FormItem>
                                                            </div>
                                                        </div>
                                                        <div className="col-md-4 col-12">
                                                            <div className="form-group">
                                                                <label className="mb-0 form-group">Last Name</label>
                                                                <FormItem
                                                                    hasFeedback
                                                                    name="lastName"
                                                                    rules = { [
                                                                            {
                                                                                required: true
                                                                            },
                                                                            { pattern: /^([^0-9]*)$/ }
                                                                        ] }
                                                                    initialValue = {
                                                                        addhomeOwner && addhomeOwner.data && addhomeOwner.data.homeownerData
                                                                            ? addhomeOwner.data.homeownerData.lastName
                                                                            : ""
                                                                    }
                                                                >
                                                                    <Input
                                                                        type="text"
                                                                        className="form-control"
                                                                        id="exampleInputPassword1"
                                                                        onChange={(e) => {
                                                                            this.setState({
                                                                                lName: e.target.value
                                                                            })
                                                                        }}
                                                                    />
                                                                </FormItem>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="row">
                                                    <div className="col-md-8 col-12">
                                                        <div className="form-group">
                                                            <label className="mb-0 form-group">Email Address</label>
                                                            <FormItem
                                                                hasFeedback
                                                                name="email"
                                                                rules = { [
                                                                        {
                                                                            required: true
                                                                        },
                                                                        {
                                                                            type: "email"
                                                                        }
                                                                    ] }
                                                                initialValue = { addhomeOwner && addhomeOwner.data && addhomeOwner.data.homeownerData
                                                                        ? addhomeOwner.data.homeownerData.email
                                                                        : localStorage.getItem('homeOwnerEmail') || '' }
                                                            >
                                                                <Input
                                                                    type="email"
                                                                    className="form-control"
                                                                    id="exampleInputPassword1"
                                                                    disabled
                                                                />
                                                            </FormItem>
                                                        </div>
                                                    </div>
                                                    <div className="col-md-4 col-12">
                                                        <div className="form-group">
                                                            <label className="mb-0 form-group">Phone Number</label>
                                                            <FormItem
                                                                hasFeedback
                                                                validateStatus={this.state.errorObj.phoneNumber || ''}
                                                                name="phone"
                                                                rules = { [
                                                                        {
                                                                            required: true
                                                                        }
                                                                    ] }
                                                                initialValue = { addhomeOwner && addhomeOwner.data && addhomeOwner.data.homeownerData
                                                                        ? addhomeOwner.data.homeownerData.phone
                                                                        : "" }
                                                            >
                                                                <NumberFormat
                                                                    type="tel"
                                                                    format= {this.formSetting.phone}
                                                                    mask=" "
                                                                    className="form-control ant-input"
                                                                    placeholder="(   )  -  "
                                                                    onChange={(e) => {
                                                                        this.checkNumberRange(e.target.value.replace(/[^0-9]/g, ""), this.formSetting.phoneLength, 'phoneNumber')
                                                                    }}
                                                                />
                                                            </FormItem>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="form-group">
                                                    <label className="mb-0 form-group">Street Address</label>
                                                    <FormItem
                                                        hasFeedback
                                                        name="address1"
                                                        rules = { [{ required: true }] }
                                                        initialValue = { addhomeOwner && addhomeOwner.data && addhomeOwner.data.homeownerData && addhomeOwner.data.homeownerData.address
                                                                ? addhomeOwner.data.homeownerData.address.address1
                                                                : "" }
                                                    >
                                                        <Input
                                                            type="text"
                                                            className="form-control"
                                                            id="exampleInputPassword1"
                                                        />
                                                    </FormItem>
                                                </div>

                                                <div className="row">
                                                    <div className="col-sm-5">
                                                        <div className="form-group">
                                                            <label className="mb-0 form-group">City</label>
                                                            <FormItem
                                                                hasFeedback
                                                                name="city"
                                                                rules = {
                                                                    [
                                                                        {
                                                                            required: true
                                                                        }
                                                                    ]
                                                                }
                                                                initialValue = {
                                                                    addhomeOwner && addhomeOwner.data && addhomeOwner.data.homeownerData && addhomeOwner.data.homeownerData.address
                                                                        ? addhomeOwner.data.homeownerData.address.city
                                                                        : ""
                                                                }
                                                            >
                                                                <Input
                                                                    type="text"
                                                                    className="form-control"
                                                                    id="exampleInputPassword1"
                                                                />
                                                            </FormItem>
                                                        </div>
                                                    </div>
                                                    <div className="col-sm-4">
                                                        <div className="form-group">
                                                            <label className="mb-0 form-group">State</label>
                                                            <FormItem
                                                                hasFeedback
                                                                name="state"
                                                                rules = { [
                                                                        {
                                                                            required: true
                                                                        }
                                                                    ] }
                                                                initialValue = { this.state.stateValue || '' }
                                                            >
                                                                <Select suffixIcon = {<CaretIcon/>}
                                                                    className='form-control addHomeowner'
                                                                    showSearch
                                                                    optionFilterProp="children"
                                                                    filterOption={(input, option) => {
                                                                        return option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                                                    }}
                                                                    onChange={(e) => this.setState({
                                                                        stateValue: e
                                                                    })}
                                                                >
                                                                    {this.renderOptions((companyId.data && companyId.data.states) ? companyId.data.states : [], "code", "code")}
                                                                </Select>
                                                            </FormItem>
                                                        </div>
                                                    </div>
                                                    <div className="col-sm-3">
                                                        <div className="form-group">
                                                            <label className="mb-0 form-group">Postal Code</label>
                                                            <FormItem
                                                                hasFeedback
                                                                name="zipcode"
                                                                rules = {[
                                                                        {
                                                                            required: true
                                                                        }
                                                                    ]}
                                                            >
                                                                <NumberFormat
                                                                    type="tel"
                                                                    format= {this.formSetting.postalCode}
                                                                    mask=" "
                                                                    className="form-control ant-input"
                                                                />
                                                            </FormItem>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            {/* Test Meter Connection... */}
                                            <div className="profile-detail-wrapper monitoring-profile-box">
                                            <h3>Meter information</h3>
                                                <div className="monitioring-detail-box ">
                                                    <div className="form-section-wrapper meter-type">
                                                        <div className="form-group">
                                                            <label className="form-group">Meter</label>
                                                            <FormItem
                                                                hasFeedback
                                                                name="monitorType"
                                                                initialValue = { this.state.powerMeter
                                                                        && this.state.powerMeter[0]
                                                                        && this.state.powerMeter[0].meterId }
                                                            >
                                                                <Select
                                                                    className="form-control"
                                                                    suffixIcon = {<CaretIcon/>}
                                                                    id="exampleFormControlSelect1"
                                                                    onChange={(e) => {
                                                                        this.setState({ meterType: e, meterId: e,meterUrl: this.meterUrl[e], isTestMeterClicked: true },
                                                                            () => {
                                                                                this.props &&
                                                                                    this.props.companyId &&
                                                                                    this.props.companyId.data &&
                                                                                    this.props.companyId.data.powerMeter.forEach((val) => {
                                                                                        if (val.meterId == this.state.meterId) {
                                                                                            this.setState({
                                                                                                meterKey: val.key,
                                                                                                identifier: val.identifier
                                                                                            });
                                                                                        }
                                                                                    })
                                                                            }
                                                                        )
                                                                    }
                                                                    }
                                                                >
                                                                     {this.renderOptions(companyId.data ? companyId.data.powerMeter : [], "name", "meterId")}
                                                                </Select>
                                                            </FormItem>
                                                        </div>
                                                    </div>
                                                    <div className="form-section-wrapper">
                                                        <div className="form-group">
                                                            <label className="form-group">Meter URL</label>
                                                            <FormItem hasFeedback>
                                                                <Input
                                                                    type="text"
                                                                    className="form-control"
                                                                    value = {this.state.meterUrl}
                                                                    id="exampleInputPassword1"
                                                                    onChange={(e) => this.setState({ meterUrl: e.target.value, isTestMeterClicked: true })}
                                                                />
                                                            </FormItem>
                                                        </div>
                                                    </div>
                                                    <div className="form-section-wrapper">
                                                        <div className="form-group">
                                                            <label className="form-group">Meter ID</label>
                                                            <FormItem
                                                                hasFeedback
                                                                name="meterId"
                                                                rules = { [
                                                                        { required: true },
                                                                    ] }
                                                                initialValue = { addhomeOwner && addhomeOwner.data && addhomeOwner.data.solarUnit
                                                                    ? addhomeOwner.data.solarUnit.meterconId
                                                                    : "" }
                                                            >
                                                                <Input
                                                                    type="text"
                                                                    className="form-control"
                                                                    id="exampleInputPassword1"
                                                                    onChange={(e) => this.setState({ meterconId: e.target.value.replace(/ /g, ''), isTestMeterClicked: true })}
                                                                />
                                                            </FormItem>
                                                        </div>
                                                    </div>
                                                    <div className="form-section-wrapper">
                                                        <div className="form-group">
                                                            <label className="form-group">Status</label>
                                                            {
                                                                this.props.meterConnectionStatus &&
                                                                this.props.meterConnectionStatus.hasOwnProperty("production") &&
                                                                (this.props.meterConnectionStatus.production ||
                                                                    this.props.meterConnectionStatus.consumption) &&
                                                                <p>Online</p>
                                                            }
                                                            {
                                                                this.props.meterConnectionStatus &&
                                                                this.props.meterConnectionStatus.hasOwnProperty("production") &&
                                                                !this.props.meterConnectionStatus.consumption &&
                                                                !this.props.meterConnectionStatus.production &&
                                                                <p>Offline</p>
                                                            }
                                                        </div>
                                                    </div>
                                                    <div className="text-center test-meter-box">
                                                        <a onClick={() => this.testExistingMeterConnection()} className="btn btn-secondary test-meter-link">Test meter connection</a>
                                                    </div>
                                                </div>
                                            </div>
                                            {/* End Test Meter Connection... */}

                                            <div>
                                                <h3 className="add-homeowner">Permitting & system information</h3>

                                                <div className="row">
                                                    <div className="col-md-4 col-12">
                                                        <div className="form-group">
                                                            <label className="mb-0 ">Inverter Brand</label>
                                                            <FormItem
                                                                hasFeedback
                                                                name="inverterBrand"
                                                                rules = { [
                                                                        {
                                                                            required: true
                                                                        }
                                                                    ] }
                                                                initialValue = { this.state.inverterBrandList
                                                                        && this.state.inverterBrandList[0]
                                                                        && this.state.inverterBrandList[0].id }
                                                            >
                                                                <Select  suffixIcon = {<CaretIcon/>}
                                                                    className="form-control addHomeowner"
                                                                    showSearch
                                                                    optionFilterProp="children"
                                                                    filterOption={(input, option) => {
                                                                        return option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                                                    }}
                                                                >
                                                                    {this.renderOptions(companyId.data ? companyId.data.inverterBrands : [], "name", "id")}
                                                                </Select>
                                                            </FormItem>
                                                        </div>
                                                    </div>
                                                    <div className="col-md-4 col-12">
                                                        <div className="form-group">
                                                            <label className="mb-0 ">Module Brand</label>
                                                            <FormItem
                                                                hasFeedback
                                                                name="moduleBrand"
                                                                rules = { [
                                                                        {
                                                                            required: true
                                                                        }
                                                                    ] }
                                                                initialValue = { this.state.moduleBrandList
                                                                        && this.state.moduleBrandList[0]
                                                                        && this.state.moduleBrandList[0].id }
                                                            >
                                                                <Select suffixIcon = {<CaretIcon/>}
                                                                    className="form-control addHomeowner"
                                                                    showSearch 
                                                                    optionFilterProp="children"
                                                                    filterOption={(input, option) => {
                                                                        return option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                                                    }}
                                                                >
                                                                    {this.renderOptions(companyId && companyId.data ? companyId.data.moduleBrands : [], "name", "id")}
                                                                </Select>
                                                            </FormItem>
                                                        </div>
                                                    </div>

                                                    <div className="col-md-4 col-12">
                                                        <div className="form-group">
                                                            <label>Module Model</label>
                                                            <FormItem
                                                                hasFeedback
                                                                name="moduleModel"
                                                                rules = { [
                                                                        {
                                                                            required: false
                                                                        }
                                                                    ] }
                                                                initialValue = { addhomeOwner && addhomeOwner.data && addhomeOwner.data.solarUnit
                                                                        ? addhomeOwner.data.solarUnit.moduleModel
                                                                        : "" }
                                                            >
                                                                <Input
                                                                    type="text"
                                                                    className="form-control"
                                                                    id="exampleInputPassword1"
                                                                />
                                                            </FormItem>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="row">
                                                    <div className="col-md-4 col-12">
                                                        <div className="form-group">
                                                            <label>Number of Modules</label>
                                                            <FormItem
                                                                hasFeedback
                                                                name="numberOfModules"
                                                                rules = { [
                                                                        {
                                                                            required: true
                                                                        },
                                                                        { pattern: "^[0-9]*$" }
                                                                    ] }
                                                                initialValue = { addhomeOwner
                                                                        && addhomeOwner.data
                                                                        && addhomeOwner.data.solarUnit
                                                                        && addhomeOwner.data.solarUnit.moduleCount ?
                                                                        addhomeOwner.data.solarUnit.moduleCount.toString()
                                                                        : "" }
                                                            >
                                                                <Input
                                                                    type="text"
                                                                    className="form-control"
                                                                    id="exampleInputPassword1"
                                                                />
                                                            </FormItem>
                                                        </div>
                                                    </div>

                                                    <div className="col-md-4 col-12">
                                                        <div className="form-group">
                                                            <label>Total System Size</label>
                                                            <FormItem
                                                                hasFeedback
                                                                name="systemSize"
                                                                rules = { [
                                                                        {
                                                                            required: false
                                                                        }
                                                                    ] }
                                                                initialValue = { addhomeOwner && addhomeOwner.data && addhomeOwner.data.solarUnit
                                                                        ? addhomeOwner.data.solarUnit.systemSize
                                                                        : "" }
                                                            >
                                                                <NumberFormat
                                                                    mask=" "
                                                                    type="tel"
                                                                    className="form-control ant-input"
                                                                />
                                                            </FormItem>
                                                        </div>
                                                    </div>

                                                    <div className="col-md-4 col-12">
                                                        <div className="form-group">
                                                            <label>System Price</label>
                                                            <FormItem
                                                                hasFeedback
                                                                validateStatus={this.state.errorObj.systemPrice || ''}
                                                                name="systemPrice"
                                                                rules = {[
                                                                        {
                                                                            required: false
                                                                        }
                                                                    ]}
                                                                initialValue = { addhomeOwner && addhomeOwner.data && addhomeOwner.data.solarUnit
                                                                        ? addhomeOwner.data.solarUnit.systemPrice
                                                                        : "" }
                                                            >
                                                                <NumberFormat
                                                                    mask=" "
                                                                    type="tel"
                                                                    className="form-control ant-input"
                                                                    onChange={(e) => {
                                                                        this.checkNumberRange(Number(e.target.value), 999999999, 'systemPrice')
                                                                    }}
                                                                />
                                                            </FormItem>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="row">
                                                    <div className="col-md-4 col-12">

                                                        <div className="form-group">
                                                            <label>Historical Annual Consumption in kWh</label>
                                                            <FormItem
                                                                hasFeedback
                                                                validateStatus={this.state.errorObj.annualConsumption || ''}
                                                                name="annualConsumption"
                                                                rules = { [
                                                                        {
                                                                            required: false
                                                                        }
                                                                    ] }
                                                                initialValue = { addhomeOwner && addhomeOwner.data && addhomeOwner.data.solarUnit
                                                                        ? addhomeOwner.data.solarUnit.annualConsumption
                                                                        : "" }
                                                            >
                                                                <NumberFormat
                                                                    mask=" "
                                                                    type="tel"
                                                                    className="form-control ant-input"
                                                                    onChange={(e) => {
                                                                        this.checkNumberRange(Number(e.target.value), 999999999, 'annualConsumption')
                                                                    }}
                                                                />
                                                            </FormItem>
                                                        </div>
                                                    </div>
                                                    <div className="col-md-4 col-12">

                                                        <div className="form-group">
                                                            <label>Projected Annual Production in kWh</label>
                                                            <FormItem
                                                                hasFeedback
                                                                validateStatus={this.state.errorObj.annualProduction || ''}
                                                                name="annualProduction"
                                                                rules = { [
                                                                        {
                                                                            required: false
                                                                        }
                                                                    ] }
                                                                initialValue = { addhomeOwner && addhomeOwner.data && addhomeOwner.data.solarUnit
                                                                        ? addhomeOwner.data.solarUnit.annualProduction
                                                                        : "" }
                                                            >
                                                                <NumberFormat
                                                                    mask=" "
                                                                    type="tel"
                                                                    className="form-control ant-input"
                                                                    onChange={(e) => {
                                                                        this.checkNumberRange(Number(e.target.value), 999999999, 'annualProduction')
                                                                    }}
                                                                />
                                                            </FormItem>
                                                        </div>
                                                    </div>
                                                    <div className="col-md-4 col-12">
                                                        <div className="form-group">
                                                            <label>Projected Annual Savings in $</label>
                                                            <FormItem
                                                                hasFeedback
                                                                validateStatus={this.state.errorObj.projectAnnualSavings || ''}
                                                                name="projectAnnualSavings"
                                                                rules = { [
                                                                        {
                                                                            required: false
                                                                        }
                                                                    ] }
                                                                initialValue = { addhomeOwner && addhomeOwner.data && addhomeOwner.data.solarUnit
                                                                        ? addhomeOwner.data.solarUnit.saving
                                                                        : "" }
                                                            >
                                                                <NumberFormat
                                                                    mask=" "
                                                                    type="tel"
                                                                    className="form-control ant-input"
                                                                    onChange={(e) => {
                                                                        this.checkNumberRange(Number(e.target.value), 999999999, 'projectAnnualSavings')
                                                                    }}
                                                                />
                                                            </FormItem>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="row">
                                                    <div className="col-md-4 col-12">

                                                        <div className="form-group">
                                                            <label className="mb-0 ">Electric Utility</label>
                                                            <FormItem
                                                                hasFeedback
                                                                name="electricityUtility"
                                                                rules = { [
                                                                        {
                                                                            required: true
                                                                        }
                                                                    ] }
                                                                initialValue = { this.state.electricutilityList
                                                                        && this.state.electricutilityList[0]
                                                                        && this.state.electricutilityList[0].electricUtilityId }
                                                            >
                                                                <Select suffixIcon = {<CaretIcon/>}
                                                                    showSearch
                                                                    optionFilterProp="children"
                                                                    filterOption={(input, option) => {
                                                                        return option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                                                    }}
                                                                    className="form-control addHomeowner"
                                                                    onChange={this.handleChangeUtility}
                                                                >
                                                                    {this.renderOptions(companyId.data ? companyId.data.electricutility : [], "name", "electricUtilityId")}
                                                                </Select>
                                                            </FormItem>
                                                        </div>
                                                    </div>
                                                    <div className="col-md-4 col-12">
                                                        <div className="form-group">
                                                            <label className="mb-0 ">Rate Plan</label>
                                                            <FormItem
                                                                hasFeedback
                                                                name="ratePlan"
                                                                rules = { [
                                                                        {
                                                                            required: true
                                                                        }
                                                                    ] }
                                                                initialValue = {
                                                                    this.state.selectedRatePlan
                                                                        && this.state.selectedRatePlan[0]
                                                                        && this.state.selectedRatePlan[0].rateId ?
                                                                        this.state.selectedRatePlan[0].rateId
                                                                        : this.state.newRatePlanList && this.state.newRatePlanList[0].rateId
                                                                }
                                                            >
                                                                <Select suffixIcon = {<CaretIcon/>} className="form-control captalize-text">
                                                                    {this.renderOptions(this.state.newRatePlanList || this.state.ratePlanList, "display", "rateId")}
                                                                </Select>
                                                            </FormItem>
                                                        </div>
                                                    </div>
                                                    <div className="col-md-4 col-12">
                                                        <div className="form-group">
                                                            <label className="mb-0 form-group">AHJ</label>
                                                            <FormItem
                                                                hasFeedback
                                                                name="ahj"
                                                                rules = { [
                                                                        {
                                                                            required: false
                                                                        }
                                                                    ] }
                                                                initialValue = { addhomeOwner && addhomeOwner.data && addhomeOwner.data.solarUnit
                                                                        ? addhomeOwner.data.solarUnit.ahj
                                                                        : "" }
                                                            >
                                                                <Input
                                                                    type="text"
                                                                    className="form-control"
                                                                    id="exampleInputPassword1"
                                                                />
                                                            </FormItem>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="section-from-box">
                                                <h3>Assign project manager</h3>
                                                <div className="form-group">
                                                    <label className="">Project Manager</label>
                                                    <FormItem
                                                        hasFeedback
                                                        name="projectManager"
                                                        rules = { [{ required: true }] }
                                                        initialValue = { this.state.defaultInstaller &&
                                                                +this.state.defaultInstaller.id }
                                                    >
                                                        <Select suffixIcon = {<CaretIcon/>}
                                                            className="form-control addHomeowner"
                                                            showSearch
                                                            optionFilterProp="children"
                                                            filterOption={(input, option) => {
                                                                return option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                                            }}
                                                        >
                                                            {
                                                                this.state.installerList && this.state.installerList.map(installer => (
                                                                    <Option value={installer.id} key={installer.id}>
                                                                        {`${installer.name ? installer.name + " - " : ''}  ${installer.email}`}
                                                                    </Option>
                                                                ))
                                                            }
                                                        </Select>
                                                    </FormItem>
                                                </div>
                                            </div>
                                            <div className="text-center margt40">
                                                <button
                                                    className="btn btn-secondary"
                                                    onClick={() =>
                                                        this.props.router.navigate(
                                                            "/" + this.slug + "/" + this.companyId + "/addhomeowner"
                                                        )
                                                    }
                                                >
                                                    Cancel
                                                </button>
                                                
                                                <button className="btn btn-primary" 
                                                    style={{marginLeft:'13px'}}
                                                    onClick={() => this.setState({loader:true})} 
                                                    disabled={this.state.disabled}
                                                >
                                                    Create account
                                                </button>
                                            </div>
                                        </Form>

                                    </div>
                                </div>
                            </div>
                        </section>
                    </div>
                </Spin>
            </React.Fragment>
        );
    };

    render() {
        if (getToken()) {
            if (this.state.isSuccess)
                return <Success {...this.props} name={`${this.state.fName} ${this.state.lName}`} email={this.state.email} goToDashboard={() => { this.goToDashboard() }} />
            return this.renderExistingInstall();
        } else {
            this.props.router.navigate("/");
            window.location.reload();
            return null;
        }
    }
}

const mapStatetoProps = state => {
    return {
        auth: state.auth,
        addhomeOwner: state.addhomeOwner,
        stateData: state.addhomeOwner.state,
        installerData: state.header.getInstaller,
        powerMeter: state.addhomeOwner.powerMeter,
        companyId: state.addhomeOwner.companyId,
        meterConnectionStatus: state.installerMeterConnection.installerMeterConnection,
        companyName : state.header && state.header.getInstaller &&  state.header.getInstaller.data && state.header.getInstaller.data.name
    };
};

const mapDispatchToProps = dispatch =>
    bindActionCreators(
        {
            requestApiData,
            requestAddHomeOwner,
            requestCompanyIdData,
            requestInstallerData,
            receiveAddHomeOwner,
            setMetricsType,
            requestEgaugePowerMeter
        },
        dispatch
    );

export default connect(
    mapStatetoProps,
    mapDispatchToProps
)(ExistingInstallForm);
