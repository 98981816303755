// import npm packages
import React from "react";
import { Modal, Form, Slider, Input, Spin }   from 'antd';
import { LoadingOutlined }              from '@ant-design/icons';
import { bindActionCreators }           from "redux";
import NumberFormat                     from "react-number-format";
import { connect }                      from "react-redux";
import { FacebookShareButton, 
        TwitterShareButton }            from 'react-share';
import UserAvatar                       from "react-user-avatar";
import moment                           from "moment";
// import local files
import {requestCompanyJourney}          from  "../../../Installer/actions/companyJourney";
import { requestPostReferralDetails, 
    receivePostReferralDetails }        from "../../actions/referralDetails";
import Header           from '../header';
import SurveyPage       from "./survey"
import LandingPage      from '../../../Common/LandingPage';
import withRouter       from '../../../../shared/withRouter';
import { getToken }     from "../../../../shared/util";
import GoogleDriveSync  from "../../../../shared/GoogleDriveSync";
import { jwtDecode }    from "../../../../shared/jwt";
import { checkIsVideo } from '../../../../shared/checkIsMobile';
import { capitalize }   from '../../../../shared/capitalize';
import surveyType       from '../shared/survey.json';
import {statusButtonLink}           from "../shared/statusFeedButton";
import {openNotificationWithIcon}   from '../../../../shared/notification';
import { PostShareDetails }         from '../../api/promiseApi';
import {replaceStatusFeedText}      from "./replaceText";
import { requestHomeownerSystemGlance } from '../../actions/dashboard';
import {requestLoggedInHomeownerAction} from '../../actions/homeOwner';
import {requestHomeownerCompanyDetail}  from '../../actions/resources';
import { requestStatusFeedPhaseSurvey, 
    receiveStatusFeedPhaseSurvey }      from '../../actions/statusFeed';
import { requestPostContactData,
     receivePostContactData }           from "../../actions/contact";

import ShowImageFromDrive           from '../../../Common/ShowImageFromDrive';
import { setLoader }                from '../../../Common/Loader/action';
import { setImages }                from '../../../Common/ShowImageFromDrive/action';
import {trackCampaignClick}         from "../../../campaign/api/campaign"
import {handleSubmitgeneric}        from "../../components/shared/solarShare"
import { requestGetEversignData }   from "../../actions/eversign";
import {
    requestHomeownerSpecialCardData,
    receiveHomeownerSpecialCardData,
    requestUpdateHomeOwner  }       from '../../actions/homeOwner'

import { requestHomeownersList, 
        receiveHomeownersList }     from '../../actions/solar';

import {ReactComponent as Share_Icon}   from "../../../../Assets/Images/outline-share-24-px.svg";
import {ReactComponent 
    as Group_of_people_Icon}            from "../../../../Assets/Images/group_of_people_yellow.svg";

const FormItem = Form.Item;
const { TextArea } = Input;

class StatusFeedForm extends React.Component {
    constructor(props) {
        super(props);
        this.GoogleDriveSync = new GoogleDriveSync();
        this.state = {
            documentId: {},
            documentSync : false,
            isOpen: false,
            phase: null,
            touchData: null,
            count: 0,
            isReply: false,
            isApiResponse: false,
            hrefValue: '#',
            calculateFeed: false,
            isThankYou: false,
            errorObj: {}
        };
    }

    componentWillMount() {
        window.scrollTo(0,0);
        this.slug = localStorage.getItem("slug");
        if (localStorage.getItem("token") === null)
            localStorage.setItem("token", "");

        if (this.props.router.location.state && this.props.router.location.state.campaignData){
            this.setState({
                campaignData: this.props.router.location.state.campaignData,
                isCampaign: true
            })
        }

        if (this.props.router.location.pathname.includes('signature')) {
            this.setState({
                isDocument:true
            });
        }

        document.body.classList.add('homeowner-bg');
        document.body.classList.remove('bg-color');
    }

    componentDidMount() {
        let {loggedInUser, router} = this.props;
        if (router.params && router.params.touchName === "statusdashboardtransitionstomonitoringdashboard") {
            localStorage.setItem('ismonitoringslides', true);
        } else {
            localStorage.removeItem('ismonitoringslides');
        }
        let token = getToken();
        if (token) {
            localStorage.removeItem('isStatusFeed');
            this.homeownerData = jwtDecode(token);
            this.props.setLoader({ loader: true });
            this.homeownerData['toolSearch'] = true;

            if (JSON.stringify(loggedInUser)==='{}')            
                this.props.requestLoggedInHomeownerAction(this.homeownerData);

            if (this.homeownerData.role && this.homeownerData.role=="homeowner") {
                if(this.state.isDocument)
                this.props.requestGetEversignData(this.homeownerData);
            }

            const { state } = this.props.router.location;
            if (state && state.createdAt) {
                this.setState({
                    createdAt: state.createdAt
                })
            }
        } else {
            localStorage.setItem('isStatusFeed', this.props.router.location.pathname);
            this.props.router.navigate(`/`)
        }

    }

    hitApi(loggedInUser){
        let data = {
            homeownerId : loggedInUser.homeownerData.id,
            companyId   : this.homeownerData.companyId
        };
        if (this.props.systemGlance.solarGlance){
            return;
        }
        this.homeownerData['fileName'] = (loggedInUser.solarUnit && loggedInUser.solarUnit.activeJourney && loggedInUser.solarUnit.activeJourney.s3Key) || 'default'; 
        this.props.requestCompanyJourney(this.homeownerData);           
        this.props.requestHomeownerSystemGlance(data);
        this.props.receiveHomeownersList({});
        this.props.requestHomeownersList(data);
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.referralDetails && nextProps.referralDetails.status === 200) {
            nextProps.receivePostReferralDetails({});
            nextProps.router.navigate('/' + this.slug + '/dashboard');
            openNotificationWithIcon('success', 'Thanks for sharing the sun.', 'Success');
        }
        if (JSON.stringify(nextProps.loggedInUser) !== '{}' && JSON.stringify(nextProps.systemGlance) !== '{}' && this.props.loader) {
            this.props.setLoader({loader:false});
            this.setState({isThankYou: true});
        }

        if(JSON.stringify(nextProps.loggedInUser) !== '{}'  && !this.state.documentSync){
            this.setState({documentSync:true},()=>this.googleDriveInvoker(nextProps.loggedInUser.documentData))
        }

        if (!this.state.isCampaign && JSON.stringify(nextProps.loggedInUser) !== '{}' && JSON.stringify(nextProps.systemGlance) !== '{}' && JSON.stringify(nextProps.companyJourney) !=='{}') {
            const touchName = nextProps.router.params && nextProps.router.params.touchName;
            let companyJourney = nextProps.companyJourney;
            const whatsNextData = companyJourney && touchName && companyJourney[touchName];
            let homeownerInfo = nextProps.loggedInUser.homeownerData
            let activationDate = parseInt(nextProps.systemGlance && nextProps.systemGlance.solarGlance && nextProps.systemGlance.solarGlance.activationDate)
            if (activationDate && !this.state.calculateFeed && JSON.stringify(nextProps.homeownerSpecialCard) == '{}') {
                this.setState({ calculateFeed: true })
                let temp = this.calculateSolarSpecialCard(activationDate, homeownerInfo.id, window.location.href);
                if (temp && temp.feedType)
                    this.props.requestHomeownerSpecialCardData(temp);
            }

            const touchHistory = (
                nextProps.loggedInUser
                && nextProps.loggedInUser.solarUnit
                && nextProps.loggedInUser.solarUnit.touchHistory
                && nextProps.loggedInUser.solarUnit.touchHistory.filter(val => {
                    if (typeof val === 'string') {
                        const parseData = JSON.parse(val)
                        if (
                            parseData && touchName
                            && parseData.touchName
                            && parseData.touchName.toLowerCase().replace(/ /g, '') === touchName.toLowerCase().replace(/ /g, '')
                        )
                            return true;
                        else
                            return false;
                    } else {
                        if (
                            val
                            && val.touchName
                            && val.touchName.toLowerCase().replace(/ /g, '') === touchName.toLowerCase().replace(/ /g, '')
                        )
                            return true;
                        else
                            return false;
                    }
                })
            ) || null;
            if (
                !whatsNextData || !whatsNextData.phase || whatsNextData.phase.length === 0
            ) {
                if (whatsNextData && whatsNextData.touchName === 'Status Dashboard transitions to Monitoring Dashboard') {
                    nextProps.router.navigate(`/${this.slug}/resources/5`);
                    if (nextProps.loggedInUser
                        && nextProps.loggedInUser.solarUnit
                        && !nextProps.loggedInUser.solarUnit.transitionStatus
                    ) {
                        nextProps.requestUpdateHomeOwner(nextProps.loggedInUser.homeownerData);
                    }
                } else  {
                    if(!this.state.isDocument){
                        nextProps.router.navigate(`/${this.slug}/dashboard`);
                    }
                }

            } else if (
                (!touchHistory
                    || touchHistory.length === 0  ) && (touchName !== '1 week impact and survey'.toLowerCase().replace(/ /g, '')) && (touchName !== '1st Month Impact'.toLowerCase().replace(/ /g, '')) && (touchName !== '1 Year Impact'.toLowerCase().replace(/ /g, ''))
            ) {
                if(!this.state.isDocument)
                    nextProps.router.navigate(`/${this.slug}/dashboard`);
                else
                nextProps.router.navigate(`/${this.slug}/dashboard`);
            }
        }

        if (
            JSON.stringify(nextProps.loggedInUser) !== '{}'
            && nextProps.loggedInUser.homeownerData
            && !this.state.isApiResponse
        ) {
            this.hitApi(nextProps.loggedInUser);
            let touchData = (
                nextProps.loggedInUser.solarUnit
                && nextProps.loggedInUser.solarUnit.touchHistory
                && nextProps.loggedInUser.solarUnit.touchHistory.filter(val => {
                    let data = val;
                    if (typeof val === 'string') {
                        data = JSON.parse(val);
                    }
                    if (
                        data && data.touchName
                        && nextProps.router.params
                        && nextProps.router.params.touchName
                        && data.touchName.toLowerCase().replace(/ /g, '') === nextProps.router.params.touchName.toLowerCase().replace(/ /g, '')
                    ) {
                        return true;
                    }
                    return false;
                })) || []
            if (!touchData || !touchData.length === 0) {
                nextProps.router.navigate(`/${this.slug}/dashboard`)
            }
            this.setState({
                isApiResponse: true,
                touchData: touchData[0] && typeof touchData[0] === 'string' ? JSON.parse(touchData[0]) : {}
            });
            nextProps.setLoader({ loader: false });
        }

        if (
            nextProps.homeownerContactDetails
            && nextProps.homeownerContactDetails.data
            && nextProps.homeownerContactDetails.data.message
            && nextProps.homeownerContactDetails.data.message === 'message sent'
            && this.state.isReply
        ) {
            let installerName = (nextProps.loggedInUser.homeownerData
                && nextProps.loggedInUser.homeownerData.installerName) || 'your project manager';
            this.setState({
                isReply: false
            })
            openNotificationWithIcon('success', `Your message has been sent to ${installerName}.`, 'Success');
            nextProps.receivePostContactData({});
        }

    }

    googleDriveInvoker(documentData){
        if (documentData
            && documentData.source
            && documentData.key
            && documentData.source === 'google'
            &&  (!this.props.cloudImages || JSON.stringify(this.props.cloudImages) === '{}')
            && (!this.state.documentId
                || !this.state.documentId.key)
        ) {
            // fetching google drive files
            this.displayDriveFiles(documentData);
        }
    }

    displayDriveFiles = (documentData) => {
        let driveData = documentData.driveData;
        if (driveData && (JSON.stringify(driveData) != '{}')) {
          this.props.setLoader({ loader: false });
          this.props.setImages(driveData);
          this.setState({
            documentId: {
              key: documentData.key,
              source: documentData.source,
            },
          })
        }
    
        else if (driveData && (JSON.stringify(driveData) == '{}')) {
          this.GoogleDriveSync.editListFiles(documentData.key, documentData.filter).then(response => {
            this.props.setLoader({ loader: false });
            if (response) {
              this.props.setImages(response.fileList)
              this.setState({
                documentId: {
                  key: documentData.key,
                  source: documentData.source,
                },
              })
            }
          }).catch(err => {
            this.props.setLoader({ loader: false });
          });
        }
      }

    renderStatusFeedContent = (content,customTouchData,touchData) => {
        const { companyDetail, systemGlance, loggedInUser } = this.props;
        if(!touchData || !touchData.touchName)
            return ''
        if((touchData.touchName =='1 week impact and survey')||(touchData.touchName =='1st Month Impact') || (touchData.touchName =='1 Year Impact'))
            return ''
        let text = content &&(typeof(content)==="string" ? content : content.content1) || '';
        text = replaceStatusFeedText(text,systemGlance,loggedInUser,customTouchData,this.props.router.params,touchData);
        return text.split("\n").map(function (item, idx) {
            if(item.includes('<a')){
                item = item.split('<a').join('<a className="premiumLinks" target="_blank"');
            }
            return (
                <>
                    <div key={idx} dangerouslySetInnerHTML={{ __html: `<p style="margin:0">${item}</p>` }} />
                    <br />
                </>
            )
        })
    }

    isVowel(text) {
        var vowelRegex = '^[aieouAIEOU].*';
        var matched = text.match(vowelRegex);
        if (matched) {
            return true;
        }
        else {
            return false;
        }
    }

    handleSubmitForm = values => {
        this.saveReferral(handleSubmitgeneric(values, [], this.props));
    }

    submitCloudespongeContacts(contacts, source, owner) {
        this.saveReferral(handleSubmitgeneric(null, contacts,this.props));
    }

    saveReferral(body) {
        if (!body)
            return;
            
        this.props.setLoader({loader:true});
        this.props.requestPostReferralDetails(body);
    }

    checkNumberRange(value, range, type) {
        const { errorObj } = this.state;
        switch (type) {
            case 'phone':
                if (value.length !== range) {
                    errorObj.phone = 'error'
                } else {
                    errorObj.phone = ''
                }
                this.setState({
                    errorObj
                })
                break;
            default:
                if (value > range) {
                    errorObj[type] = 'error'
                } else {
                    errorObj[type] = ''
                }
                this.setState({
                    errorObj
                })
                break;
        }
    }

    handleSubmitForSentMessage = (values, customTouchData, campaignId) => {
        let installersName = this.props
                               &&this.props.loggedInUser 
                               &&this.props.loggedInUser.homeownerData
                               &&this.props.loggedInUser.homeownerData.installerName?
                               this.props.loggedInUser.homeownerData.installerName:'the installer';
        
        let {loggedInUser:{homeownerData}} = this.props;
        let message = values.message;
        if (!message || message === '') {
            openNotificationWithIcon('error', `We’ll pass it on to ${installersName.trim()}.`, 'Please enter a message');
            return null;
        }
        this.props.setLoader({ loader: true })
        this.props.requestPostContactData({
            companyId       : homeownerData.companyId,
            homeownerId     : homeownerData.id,
            message         : values.message,
            statusFeed      : true,
            rating          : values.rating,
            homeOwnerName   : `${homeownerData.firstName} ${homeownerData.lastName}`,
            homeOwnerEmail  : homeownerData.email,
            installerId     : homeownerData.installerId,
            installerName   : homeownerData.installerName,
            installerEmail  : homeownerData.installerEmail,
            campaignName    : customTouchData && customTouchData.campaignName,
            touchName       : (customTouchData && (customTouchData.display||customTouchData.campaignName)) || (this.state.touchData &&  this.state.touchData.touchName),
            campaignId
        });
    }

    beforeClick(method) {
        const { loggedInUser } = this.props;

        const bodyData = {
            "homeownerId": loggedInUser && loggedInUser.solarUnit && loggedInUser.solarUnit.homeownerId,
            "companyId": loggedInUser && loggedInUser.homeownerData && loggedInUser.homeownerData.companyId,
            "method": method,
            "share": "glance"
        };
        return PostShareDetails(bodyData)
    }

    setMessageHref(url) {
        this.beforeClick(3).then(res => {
            this.setState({ hrefValue: url })
        }).catch(e => {
            console.log('error', e.toString())
        })
    }

    calculateSolarSpecialCard(activationDate, homeownerId, url) {
        var feedType;
        var feedTime;
        if (url.includes('week')) {
            feedType = 'weekly';
            feedTime = parseInt(activationDate) + 7 * 86400000;
        }

        else if (url.includes('month')) {
            feedType = 'monthly';
            feedTime = parseInt(activationDate) + 30 * 86400000;
        }
        else if (url.includes('year')) {
            feedType = 'yearly';
            feedTime = parseInt(activationDate) + 365 * 86400000;
        }
        return { feedType, homeownerId: homeownerId, feedTime }
    }

    displayUtilityName = (utilityName, utilityLabel) => {
        if (utilityLabel)
            return capitalize(utilityLabel);
        else
            return capitalize(utilityName);
    }

    renderScanifly = (scaniflyUrl) => {
        return (
            <div className="col-sm-7 col-12">
                <div className="scanifly">
                    <iframe width="100%" height="400" frameborder="0" allowfullscreen mozallowfullscreen="true" webkitallowfullscreen="true" src={scaniflyUrl}></iframe>
                    <div className="overlay statusFeed" onClick={((e) => {
                        e.stopPropagation();
                        window.open(scaniflyUrl, '_blank').focus();
                    })} />
                    <div className="expand" onClick={(e) => e.stopPropagation()} />
                </div>
            </div>
        )
    }

    // render status feed html
    renderStatusFeed(phase, touchName, createdAt,touchData,customTouchData) {
        const { companyDetail, systemGlance, loggedInUser } = this.props;
        let referralId = loggedInUser && loggedInUser.homeownerData && loggedInUser.homeownerData.referralId;
        let installerDetails = loggedInUser && loggedInUser.homeownerData
        let scaniflyUrl = loggedInUser.solarUnit && loggedInUser.solarUnit.scaniflyUrl;
        if (touchName == '') {
            let feedData = this.calculateSolarSpecialCard(systemGlance && systemGlance.solarGlance && systemGlance.solarGlance.activationDate, null, window.location.href);
            createdAt = feedData.feedTime;
        }

        let specialCardY = 0;
        let specialCardX = 0;
        if (this.props && this.props.homeownerSpecialCard
            && this.props.homeownerSpecialCard.totalConsumption
            && this.props.homeownerSpecialCard.totalProduction
            && this.props.homeownerSpecialCard.dailySaving) {
            specialCardX = ((this.props.homeownerSpecialCard.totalProduction / this.props.homeownerSpecialCard.totalConsumption) * 100).toFixed();

            specialCardY = this.props.homeownerSpecialCard.dailySaving.toFixed();
        } else if (this.props && this.props.homeownerSpecialCard
            && this.props.homeownerSpecialCard.totalProduction
            && this.props.homeownerSpecialCard.dailySaving) {
            specialCardX = (this.props.homeownerSpecialCard.totalProduction).toFixed();
            specialCardY = this.props.homeownerSpecialCard.dailySaving.toFixed();
        }
        if (this.state.createdAt) {
            createdAt = this.state.createdAt
        }
        return (
            <div className='row'>
                { (phase.isScanifly && this.renderScanifly(scaniflyUrl))}
                { (phase.isImages || phase.isLayout) && (this.renderCardImages(phase.isLayout)) }

                <div className={(phase.isScanifly || phase.isImages || phase.isLayout) ? "paddall25 col-sm-5 " : "paddall25 col-sm-12 col-12"}>
                    <div className='survey-media-modal'>
                        {this.renderInstallerInfo(installerDetails,companyDetail,referralId)}
                        { phase && this.renderStatusFeedContent(phase.content,customTouchData,touchData) }

                        {phase.isButton &&this.renderCardButton(phase)}
                        {this.renderSpecialCard(phase,touchName,specialCardX,specialCardY)}        

                        {this.state.isDocument && this.renderEversignDocument()}                   
                        
                        {!this.state.isDocument && <div className="date-reply-box">
                            <br/>
                            {this.renderReplyOption(createdAt,customTouchData)}
                            {phase.isShareSun && this.renderShareSun(this.props.systemGlance)}
                            {phase.survey && customTouchData &&  this.renderPhaseServe(surveyType[phase.survey],customTouchData)}
                        </div>}
                    </div>
                </div>
            </div>
        )
    }
    renderEversignDocument() {
        let {loggedInUser} = this.props;
        let mobileImage = (loggedInUser.homeownerData && loggedInUser.homeownerData.icon)? loggedInUser.homeownerData.icon : "https://prod-17terawatts.s3.amazonaws.com/public/logo/bothi-mobile-logo.png";
    
        let eversignDocument = this.props.eversignDetails && this.props.eversignDetails.filter((ele)=>{
            return ele.id == this.props.router.params.signatureId
        })[0];
       
        if(this.props.eversignDetails && (!eversignDocument ||  eversignDocument.signedAt)){
            this.props.router.navigate(`/${this.slug}/dashboard`);
            return;
        }

        return (
            <div className="iframeMobile">
                <label style={{ textTransform: "none", color: '#212529', fontWeight: 300, fontSize: '14px' }}>{
                    eversignDocument.message ? eversignDocument.message : 
                    `Please review and sign this important document ${eversignDocument.fileName} concerning your solar system. Let us know if you have any questions.`
                }</label>
                <div className="text-center">
                    <button
                        className="btn yellow-btn reviewSign premiumButtonText premiumButtonFill"
                        type='button'
                        disabled={this.props.isInstaller ? "disabled" : ""}
                        onClick={() => { this.setState({ reviewSignVisible: true }) }}
                    >
                        Review & Sign
                </button>
                </div>
                < Modal
                    title={null}
                    footer={null}
                    visible={this.state.reviewSignVisible}
                    onCancel={this.handleCancel}
                    width='1000px'
                >
                    <img className="reviewIcon" src={mobileImage} style={{ height: '48px', width: '48px' }}></img>
                    <label className="reviewText">Review & Sign</label>
                    <iframe src={eversignDocument.fileUrl} width="100%" height="800"></iframe>
                </Modal>
            </div>
        )
    }

    handleCancel = e => {
        this.props.setLoader({ loader: true });
        setTimeout(()=>{
            this.props.requestGetEversignData(this.homeownerData)
        },2500);
       
        this.setState({ reviewSignVisible: false })
    }

    renderSpecialCard(phase,touchName,specialCardX,specialCardY){
        return (
            <div>
                {
                    phase
                    && phase.content
                    && phase.content.content1
                    && this.props.homeownerSpecialCard
                    && this.props.homeownerSpecialCard.totalConsumption
                    && this.props.homeownerSpecialCard.totalProduction
                    && touchName
                    && touchName.toLowerCase().replace(/ /g, '') !== 'System design finalized'.toLowerCase().replace(/ /g, '')
                    && <p>
                        {
                            phase.content.content1
                                .replace(/#X/g, specialCardX)
                                .replace(/#Y/g, specialCardY)
                        }
                    </p>
                }
                {
                    phase
                    && phase.content
                    && phase.content.content2
                    && this.props.homeownerSpecialCard
                    && !this.props.homeownerSpecialCard.totalConsumption
                    && this.props.homeownerSpecialCard.totalProduction
                    && touchName
                    && touchName.toLowerCase().replace(/ /g, '') !== 'System design finalized'.toLowerCase().replace(/ /g, '')
                    &&
                    <p>
                        {
                            phase.content.content2
                                .replace(/#X/g, specialCardX)
                                .replace(/#Y/g, specialCardY)
                        }
                    </p>
                }
            </div>
        )
    }

    renderCardImages(isLayout,campaignPhoto){
        return(
            <div className='col-sm-7 col-12'>
                <ShowImageFromDrive
                    {...this.props}
                    isModal={true}
                    isOpen={true}
                    campaignPhoto = {campaignPhoto}
                    filterByName={isLayout ?('layout' || 'site plan'): ''}
                    style={{ marginBottom: '80px' }}
                />
            </div>
        )
    }

    renderInstallerInfo(installerDetails,companyDetail,referralId,isCampaign){
        let photo = installerDetails && installerDetails.photo;
        if(photo && photo.includes('installer-avatar')){
            photo = null;
        }
        let installerName = (installerDetails && installerDetails.installerName && capitalize(installerDetails.installerName)) || (installerDetails && (capitalize(installerDetails.companyName)))
        
        const imageSyle = {
            'backgroundImage': photo ? `url(` + photo + `)` : ''
        };
        return(
        <>
            <div className="media">
                    {photo ?
                        <div className="dashboard-profile-image" style={imageSyle}></div>
                        : <UserAvatar className="dashboard-profile-image premiumIconBackGround" size="40" name={installerName || 'Installer'} />
                    }
                {!isCampaign && <div className="share-link">
                    <a href="#"
                        data-toggle="collapse"
                        data-target="#collapseShareIcons2"
                        aria-expanded="true"
                        aria-controls="collapseShareIcons2"
                    >
                        <Share_Icon/>
                    </a>
                </div>}
                <div className="media-body" style={{"text-align":"left"}}>
                    <h5 className="mt-0">{installerDetails && installerDetails.installerName && capitalize(installerDetails.installerName)}</h5>
                    <a >{installerDetails && installerDetails.installerTitle}</a>
                </div>
            </div>
            <div id="collapseShareIcons2" className="white-box status-feed-page-share share-via-wrapper collapse">
                <div className="heading"> Share via:</div>
                {
                    !this.props.isInstaller ?
                        <ul>
                            <li>
                                <FacebookShareButton beforeOnClick={() => this.beforeClick(1)} quote="Hey, I've gone solar and thought you might be interested. This is a link to the equipment powering my home. Check it out!" url={window.location.origin + '/' + this.slug + '/referral/' + referralId + '/glance/1'}>
                                    <a href="#" className="facebook">
                                        <img
                                            src={require("../../../../Assets/Images/facebook.svg")}
                                            alt='facebook.svg'
                                            width="25"
                                        />
                                    </a>
                                </FacebookShareButton>
                            </li>
                            <li>
                                <TwitterShareButton beforeOnClick={() => this.beforeClick(2)} title="Hey, I've gone solar and thought you might be interested. This is a link to the equipment powering my home. Check it out!" url={window.location.origin + '/' + this.slug + '/referral/' + referralId + '/glance/2'}>
                                    <a href="#" className="facebook">
                                        <img
                                            src={require("../../../../Assets/Images/twitter.svg")}
                                            alt='twitter.svg'
                                            width="25"
                                        />
                                    </a>
                                </TwitterShareButton>
                            </li>
                            <li className="disabled">
                                <a onClick={() => this.setMessageHref("https://api.whatsapp.com/send?text=Hey, I've gone solar and thought you might be interested. This is a link to the equipment powering my home. Check it out!" + window.location.origin + '/' + this.slug + '/referral/' + referralId + '/glance/4', 4)}
                                    href={this.state.hrefValue}
                                    className="message">
                                    <img
                                        src={require("../../../../Assets/Images/whatsapp.png")}
                                        alt='sms.svg'
                                        width="25"
                                    />
                                </a>
                            </li>
                            <li className="disabled">
                                <a onClick={() => this.setMessageHref("sms:?&body=Hey, I've gone solar and thought you might be interested. This is a link to the equipment powering my home. Check it out!" + window.location.origin + '/' + this.slug + '/referral/' + referralId + '/glance/3', 3)}
                                    href={this.state.hrefValue}
                                    className="message">
                                    <img
                                        src={require("../../../../Assets/Images/sms.svg")}
                                        alt='sms.svg'
                                        width="25"
                                    />
                                </a>
                            </li>
                        </ul>
                        :
                        <ul>
                            <li>
                                <img
                                    src={require("../../../../Assets/Images/facebook.svg")}
                                    alt='facebook.svg'
                                    width="25"
                                    style={{ cursor: 'not-allowed' }}
                                />
                            </li>
                            <li>
                                <img
                                    src={require("../../../../Assets/Images/twitter.svg")}
                                    alt='twitter.svg'
                                    width="25"
                                    style={{ cursor: 'not-allowed' }}
                                />
                            </li>
                            <li>
                                <img
                                    src={require("../../../../Assets/Images/whatsapp.png")}
                                    alt='twitter.svg'
                                    width="25"
                                    style={{ cursor: 'not-allowed' }}
                                />
                            </li>
                            <li>
                                <img
                                    src={require("../../../../Assets/Images/sms.svg")}
                                    alt='sms.svg'
                                    width="25"
                                    style={{ cursor: 'not-allowed' }}
                                />
                            </li>
                        </ul>
                }
            </div>
        </>
        )
    }

    renderReplyOption(createdAt,customTouchData,campaignId){
        return (
            <>
                <span className="contact-wrapperdate-box">
                    { createdAt ? moment(createdAt).format('MMM DD, h:mm A'):'-' }
                </span>
                {
                    !this.state.isReply &&
                        <span className="pull-right">
                            <a
                                className='status-feed-reply premiumLinks'
                                onClick={() => {
                                    this.setState({
                                        isReply: !this.state.isReply
                                    })
                                }}
                            >Reply</a>
                        </span>
                }

                {
                    this.state.isReply
                    &&
                        <Form onFinish={values => this.handleSubmitForSentMessage(values, customTouchData, campaignId)}>
                            <span className="pull-right btn-margin-top">
                                <button
                                    className='status-feed-send premiumLinks'
                                    disabled={this.props.isInstaller ? 'disabled' : ''}
                                    type="submit"
                                >
                                    Send
                                </button>
                            </span>
                            <div className="form-group" style={{ paddingTop: '20px' }}>
                                <FormItem
                                    name="message"
                                >
                                    <TextArea
                                        disabled={this.props.isInstaller ? 'disabled' : ''}
                                        className="status-feed-message textarea"
                                        placeholder="Type message here..."
                                        rows={6}
                                    />
                                </FormItem>
                            </div>
                        </Form>
                }
            </>
        )
    }

    // render button
    renderCardButton(statusButton){
        return(
            <div className="text-center">
                <button
                    className="btn yellow-btn premiumButtonFill premiumButtonText documents-button-status-feed"
                    disabled={this.props.isInstaller ? 'disabled' : ''}
                    onClick={(e) => {
                        e.stopPropagation();
                        this.props.router.navigate(`/${this.slug}${statusButtonLink[statusButton.buttonUrl]|| '/documents'}`)
                    }}
                >
                    {statusButton.buttonName || 'Click Here!'} 
                </button><br />
            </div>
        )
    }

    // render share the sun
    renderShareSun(systemGlance) {
        let {loggedInUser:{homeownerData}} = this.props;
        return(
            <div>
                <div className="form-boxx">
                    <div className="community-icon" style={{ height: '28px',float: 'left'}}>
                        <Group_of_people_Icon style={{ height: '100%' }} />
                    </div>
                    <span className="share-the-sun premiumIcon">SHARE THE SUN</span>
                </div>
                <p>Did you know 5.8 days are added to earth's life for every 100 solar powered homes?{" "+systemGlance.phaseGlance && systemGlance.phaseGlance.ReferralMessage}</p>
                <Form onFinish={this.handleSubmitForm} className="form-detail-survey">
                    <div className="invite-box">
                        <p>Easily share specific contacts</p>
                        <a className="cloudsponge-launch" data-cloudsponge-source="gmail" > <img
                            src={require("../../../../Assets/Images/Gmail_Icon.svg")}
                            alt='Gmail_Icon.svg'
                            width="25"
                        /></a>
                        <a className="cloudsponge-launch" data-cloudsponge-source="outlook"> <img
                            src={require("../../../../Assets/Images/microsoft_icon.svg")}
                            alt='microsoft_icon.svg'
                            width="25"
                        /></a>
                        <a className="cloudsponge-launch" data-cloudsponge-source="yahoo"> <img
                            src={require("../../../../Assets/Images/yahoo_icon.svg")}
                            alt='yahoo_icon.svg'
                            width="25"
                        /></a>
                        <a className="cloudsponge-launch" data-cloudsponge-source="icloud"> <img
                            src={require("../../../../Assets/Images/icloud_icon.svg")}
                            alt='icloud_icon.svg'
                            width="25"
                        /></a>
                    </div>
                    <div className="form-group">
                        <label>Name</label>
                        <FormItem
                            hasFeedback
                            name="name"
                            rules = { [{
                                required: true
                            }] }
                        >
                            <Input type="text" disabled={this.props.isInstaller ? true : false} className="form-control" />
                        </FormItem>
                    </div>
                    <div className="form-group">
                        <label>Email</label>
                        <FormItem
                            hasFeedback
                            name="email"
                            rules = { [{
                                required: true
                            }] }
                        >
                            <Input type="email" disabled={this.props.isInstaller ? true : false} className="form-control" />
                        </FormItem>
                    </div>
                    <div className="form-group">
                        <label>Phone number</label>
                        <FormItem
                            hasFeedback
                            validateStatus={this.state.errorObj.phone || ''}
                            name="phone"
                        >
                            <NumberFormat
                                type="tel"
                                disabled={this.props.isInstaller ? 'disabled' : ''}
                                format= {homeownerData.formSetting && homeownerData.formSetting.phone}
                                mask=" "
                                className="ant-input"
                                placeholder="(   )  -  "
                                onChange={(e) => {
                                    this.checkNumberRange(e.target.value.replace(/[^0-9]/g, ""), homeownerData.formSetting && homeownerData.formSetting.phoneLength, 'phone')
                                }}
                            />
                        </FormItem>
                    </div>
                    <div className="button-wrap text-center">
                        <button
                            type="submit"
                            className="btn yellow-btn premiumButtonFill premiumButtonText btn-survey"
                            disabled={this.props.isInstaller ? 'disabled' : ''}
                        >{(systemGlance.solarGlance && systemGlance.solarGlance.referralType === 1)?'Give':'Invite'}</button>
                    </div>
                </Form>
            </div>
        )
    }

    // render phase surve html
    renderPhaseServe(phase,customTouchData) {
       return <SurveyPage customTouchData = {customTouchData} homeownerPhase ={phase} {...this.props} />
    }

    renderCampaignsBlock(){
        const { companyDetail, loggedInUser,activeCampaign } = this.props;
        let dyId = Math.floor(100000 + Math.random() * 900000);
        let ele = this.state.campaignData;
        let isCampaignPhoto = ele.campaignPhoto && ele.campaignPhoto.length > 0;
        return (
            <div className="row">
                    {(isCampaignPhoto && checkIsVideo(ele.campaignPhoto[0])) ?
                        this.VideoPreviewBox(ele.campaignPhoto[0]) : this.renderCardImages(null,ele.campaignPhoto)}
                <div className={`paddall25 ${isCampaignPhoto && 'col-sm-5'}  campaignPreview left-content-box`}>
                    <div className="survey-media-modal">
                        {this.renderInstallerInfo(ele, this.props.companyDetail, dyId, true)}
                        <h3>{ele.headline} </h3>
                        {ele.body && ele.body.split("\n").map((item, idx) => {
                            return (
                                <p className="homeowner" key={idx}>{item}</p>
                            )
                        })}
                        {
                            ele.button.link && <a href={ele.button.link} target="_blank"
                                onClick={() => {
                                    trackCampaignClick([{
                                        "campaignId":ele.id,
                                        "event": "app",
                                        "homeownerId": this.homeownerData.homeownerId
                                    }])
                                }}
                                className="btn yellow-btn premiumButtonFill premiumButtonText">{ele.button.label || 'Click Here'}</a>
                        }
                        <div className="date-reply-box">
                            <br />
                            {this.renderReplyOption(parseInt(ele.createdAt), {campaignName :ele.headline},ele.id)}
                        </div>
                    </div>
                </div>
            </div>
        )
    }

    ImagePreviewBox(url) {
        return (
            <div className="grid-container">
                {url && <img className="hut-img"src={url}/>}
            </div>
        )
      }
  
    VideoPreviewBox(url) {
      return(
        <div className='col-sm-7 col-12'>
          <video style={{
                  top   : '50%',
                  left  : '50%',
                  transform     : 'translate(-50%,-50%)',
                  'margin-left' : '17px'
          }} className="hut-img" controls>
            <source src={url} type="video/mp4" />
          </video>
        </div>
      )
    }

    render() {
        let {companyJourney,systemGlance} = this.props;
        const antIcon      = <LoadingOutlined style={{ fontSize: 60 }} spin />;
        const touchName    = this.props.router.params && this.props.router.params.touchName;
        let accountDetails = this.props.loggedInUser.homeownerData;
        let lowerTouchName = touchName && touchName.toLowerCase().replace(/ /g, '');
        let loadingIcon    = systemGlance && systemGlance.solarGlance && systemGlance.solarGlance.logo;
        let titleValue     = (accountDetails && (accountDetails.installerName || accountDetails.companyName));
        let phase = [];
        let customTouchData;
        if(touchName && companyJourney && companyJourney[lowerTouchName]&& companyJourney[lowerTouchName].phase){
            
            phase = companyJourney[lowerTouchName].phase.length >0 ? companyJourney[lowerTouchName].phase[0]
            : phase;
            customTouchData = companyJourney[lowerTouchName];
        }
        return (
            <React.Fragment>
                <Spin
                    spinning={this.props.loader}
                    indicator={antIcon}
                    style={{ color: "#04bfff" }}
                >
                    <header className='desktop-view-only'>
                        <Header {...this.props} />
                    </header>
                    <title>My Dashboard - {titleValue}</title>
                    <div>

                        {
                            JSON.stringify(this.props.companyJourney) === '{}' ?
                            <LandingPage loadingIcon={loadingIcon} role={this.homeownerData && this.homeownerData.role}/> :
                                    (
                                        <section className="two-box-wrapper marg-two-box portal-intro-wrapp">
                                            <div>
                                                <div className=" margin-t-73">
                                                    <div className="create-new-account-wrapper status-feed">
                                                        <div
                                                            className='pull-right oval-2'
                                                            onClick={() => {
                                                                this.props.router.navigate(`/${this.slug}/dashboard`)
                                                            }}
                                                        >
                                                            <span className='line-3'>&times;</span>
                                                        </div>
                                                        <div
                                                            className="white-box-secondary survey-modal"
                                                        >
                                                            {
                                                                this.state.isCampaign ? this.renderCampaignsBlock() :
                                                                this.renderStatusFeed(
                                                                    phase || {},
                                                                    touchName || '',
                                                                    (this.state.touchData && this.state.touchData.createdAt &&
                                                                        Number(this.state.touchData.createdAt)) ||
                                                                    new Date().getTime(),
                                                                    this.state.touchData,
                                                                    customTouchData
                                                                )
                                                            }
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </section>
                                    )
                        }

                    </div>
                </Spin>
            </React.Fragment>
        )
    }
}

const mapStatetoProps = state => {
    return {
        companyJourney: state.companyBodhiJourney && state.companyBodhiJourney.bodhiJourneyReducer
        && state.companyBodhiJourney.bodhiJourneyReducer.data&&
        state.companyBodhiJourney.bodhiJourneyReducer.data[0]||{},
        systemGlance: state.homeownerDashboard.homeownerSystemGlance.data || {},
        loggedInUser: state.homeownerLoggedInUser.homeownerLoggedInUserReducer.data || {},
        loader: state.loader.loader.loader,
        companyDetail: state.homeownerResources.homeownerResoucesCompanyDetail
            && state.homeownerResources.homeownerResoucesCompanyDetail.data
            && state.homeownerResources.homeownerResoucesCompanyDetail.data.companyDetail ?
            state.homeownerResources.homeownerResoucesCompanyDetail.data.companyDetail
            : {},

        homeownersList: state.homeownersList.homeownersList.data || {},
        installerDetails: state.homeownerContact.homeownerInstallerDetails.data,
        cloudImages: JSON.stringify(state.cloudImages.cloudImages) !== '{}' ? state.cloudImages.cloudImages : {},
        homeownerContactDetails: state.homeownerContact.homeownerContactDetails,
        statusFeedPhaseSurvey: (state.statusFeedPhaseSurvey.statusFeedPhaseSurvey && JSON.stringify(state.statusFeedPhaseSurvey.statusFeedPhaseSurvey) !== '{}' && state.statusFeedPhaseSurvey.statusFeedPhaseSurvey) || {},
        homeownerSpecialCard: (state.homeownerLoggedInUser && state.homeownerLoggedInUser.homeownerSpecialCardDataReducer && state.homeownerLoggedInUser.homeownerSpecialCardDataReducer.data) || {},
        referralDetails: state.homeownerReferralDetails.postHomeownerReferrals.data,
        eversignDetails: state.homeownerGetEversign.homeownerGetEversignDocument.data || []
    };
};

const mapDispatchToProps = dispatch =>
    bindActionCreators(
        {
            requestHomeownerSystemGlance,
            requestLoggedInHomeownerAction,
            requestHomeownerCompanyDetail,
            requestStatusFeedPhaseSurvey,
            receiveStatusFeedPhaseSurvey,
            requestPostContactData,
            receivePostContactData,
            setImages,
            setLoader,
            requestGetEversignData,
            receivePostReferralDetails,
            requestPostReferralDetails,
            requestHomeownerSpecialCardData,
            receiveHomeownerSpecialCardData,
            receiveHomeownersList,
            requestHomeownersList,
            requestUpdateHomeOwner,
            requestCompanyJourney
        },
        dispatch
    );

export default withRouter(
    connect(
        mapStatetoProps,
        mapDispatchToProps
    )(StatusFeedForm)
);