// import npm packages
import React, { Component }     from 'react';
import { Form, Input, Slider }  from 'antd';
import {bindActionCreators}     from "redux";
import { connect }              from 'react-redux';
import {
    Map, Marker,
    GoogleApiWrapper
}                                   from 'google-maps-react';
import withRouter                   from '../../../../../shared/withRouter';
import { getLoadingIcon }           from "../../../../User/components/setUserBackgroud";
import { capitalize }               from '../../../../../shared/capitalize';
import { checkIsMobile }            from '../../../../../shared/checkIsMobile';
import googleMap                    from '../../../../../environments/index';
import ErrorPage                    from '../../../../Common/ErrorPage';
import LandingPage                  from '../../../../Common/LandingPage';
import { openNotificationWithIcon } from '../../../../../../src/shared/notification';
import {receiveReferralDetails,
    receiveReferralNeighbour}       from "../../../actions/referralDetails";
const FormItem = Form.Item;

class ReferralLandingForm extends Component {
    constructor(props) {
        super(props);
        this.state = {
            errorObj: {},
            lat: null,
            lng: null,
            address: '',
            sliderStatus: 3000,
            utilityRate: null,
            isInvalid:false

        };
        this.marks = {
            500: '500 sqft',
            1800: '1800',
            3000: '3000 sqft',
            4000: '4000',
            5000: {
                label: <strong className="premiumLinks">5000+</strong>,
            },
        };
    }

    
    shouldComponentUpdate() {
        if (!this.state.lat) {
            return true;
        } else {
            return false;
        }
    }


    componentWillReceiveProps(nextProps) {
        let temp = nextProps.referralDetails
            && nextProps.referralDetails.postHomeownerAddress
            && nextProps.referralDetails.postHomeownerAddress.data
            && nextProps.referralDetails.postHomeownerAddress.data.data;
        if (temp && (!temp.lat || !temp.lng) && !this.state.isInvalid) {
            nextProps.setLoader({ loader: false });
            openNotificationWithIcon('error', 'Please enter valid address', 'Failed');
            this.setState({ isInvalid: true })
        } else if (temp && temp.utilityRate && temp.lat) {
            this.props.receiveReferralNeighbour({});
            let urlParam = this.props.router.params;
            this.props.router.navigate(`/${urlParam.slug}/referral/${urlParam.referralId}/${urlParam.share}/${urlParam.method}/calculate`);
        }

        let referralIdData = nextProps.loggedInUser.homeownerData;
        let savingForm = nextProps.referralDetails && nextProps.referralDetails.postHomeownerAddressForm
        let address = referralIdData && referralIdData.address;
        if (address) {
            address = typeof (address) == 'string' ? JSON.parse(address) : address;
            this.setState({
                lng: Number(referralIdData.lng),
                add: savingForm && savingForm.add,
                address: `${address.address1} ${address.address2} ${address.city} ${address.state} ${address.zipCode}`,
                companyName: referralIdData.companyName,
                companyUrl: referralIdData.companyUrl,
                referralAmount: referralIdData.referralAmount,
                referralType: referralIdData.referralType
            });
        }
    }

    handleSubmitCalculate = () => {
        if (this.state.add) {
            let bodydata = {
                "add": this.state.add,
                "slider": this.state.sliderStatus
            }
            this.props.receivePostAddressFormData(bodydata);
            this.setState({lat:null},()=>{this.setState({lng:null})})

            let data = {
                "path": this.state.add
            };
            
            this.props.requestPostAddressDetails(data);
            this.setState({ isInvalid: false });
        }
        else {
            openNotificationWithIcon('error', 'Address is needed to share the sun', 'Please try again');
        }
    };
    handleSlider = (val) => {
        this.setState({
            sliderStatus: val
        })
    }

    render1Form(postHomeownerAddressForm) {
        return (
            <>
                <div className="enter-your-ad rating glance">What is the size of your home?</div>
                <FormItem
                    hasFeedback
                    name="rating"
                    rules = { [
                            {
                                required: true
                            }
                        ] }
                >
                    <div>
                        <Slider marks={this.marks}
                            defaultValue={postHomeownerAddressForm.slider ? postHomeownerAddressForm.slider : this.state.sliderStatus}
                            onChange={val => { this.handleSlider(val) }}
                            min={500} max={5000}
                            className="slider-mark premiumLinks" />

                    </div>
                </FormItem>
            </>
        )
    }

    render2Form() {
        return (
            <>
                <div className="form-group">
                    <div className="enter-your-ad glance">Please enter your address</div>
                    <Input
                        type="text"
                        onChange={e => { this.setState({ add: e.target.value }) }}
                        className="form-control input-color address"
                        placeholder="Street Address, City, State, Zip Code"
                    />
                </div>
            </>
        )
    }


    renderCalculateForm() {
        const {
            referralDetails: { postHomeownerAddressForm }
        } = this.props;
        return (
            <React.Fragment>
                <div className="col-md-5 calc-gap">
                    <h6 className="explore-savings">Explore Your Savings</h6>
                    <Form >
                        {!this.props.loggedInUser.homeownerData.calculatorUrl && this.render2Form(postHomeownerAddressForm)}
                        <div className="form-group">
                            {!this.props.loggedInUser.homeownerData.calculatorUrl && this.render1Form(postHomeownerAddressForm)}
                            <div className="text-center">
                                {this.props.loggedInUser.homeownerData.calculatorUrl ?
                                    <a href={this.props.loggedInUser.homeownerData.calculatorUrl} target="_blank">
                                        <input type="button" className="btn yellow-btn premiumButtonFill premiumButtonText rating-slider-btn" value={`Calculate with ` + (this.state.companyName)} />
                                    </a>
                                    :
                                    <button type="submit " className="btn yellow-btn premiumButtonFill premiumButtonText rating-slider-btn" onClick={(e) => {
                                        this.handleSubmitCalculate();
                                    }}
                                    >{"Calculate"}</button>}
                            </div>
                        </div>
                    </Form>
                </div>
            </React.Fragment>
        )
    }

    calculateGlacier(value) {
        value = value * 1;
        let glacier = (value * 1.6 * 32) / 2000;
        let calc = glacier.toFixed(1);
        if (calc > 0.9) {
            return ` has preserved ${calc} sq ft of glaciers,`
        }
        else {
            return ''
        }
    }


    renderLanding() {
        let details = this.props.referralDetails.homeownerReferrals.data;
        let yearlyProduction = details && details.yearlyProduction;

        return (
            <React.Fragment>
                <h1 className={`referral-heading ${!checkIsMobile() ? 'premiumButtonFill premiumButtonText notHover' : ''}`}>
                    Your solar community is waiting
                </h1>
                <div className="row">
                    <div className="col-md-5 offset-md-1 padding-offset">
                        {details && (
                            <p className="referral-homeowner">
                                Did you know {details.firstName} {yearlyProduction ? this.calculateGlacier(yearlyProduction):''}{' '}
                                {details.totalSaving ? `saves $${details.totalSaving} on electricity every year, and `:''}
                                    is one of many {details.totalNeighbour} solar families in {details.firstName && details.firstName.trim()}'s neighborhood? Most people are amazed that they can go solar with $0 down while also saving on their electricity bill.
                            </p>
                        )}
                        <p className="join_solar_header">JOIN {details.firstName.toUpperCase().trim()}'S SOLAR COMMUNITY</p>
                        <p className="referral-homeowner companyInfo">
                            Find out from{' '}
                            <a className="premiumLinks" target='_blank' href={this.state.companyUrl}>  {
                                this.state.companyName && capitalize(this.state.companyName)}{' '}
                            </a>
                                how your home can also be powered by the sun{
                                this.state.referralAmount ? (this.state.referralType === 1 ?
                                    ` and receive a $${this.state.referralAmount} discount to go solar.` : '.')
                                    : '.'
                            }
                        </p>
                    </div>
                    {this.renderCalculateForm()}
                </div>
            </React.Fragment>
        );
    }

    renderMap() {
        let homeownersList = this.props.neighbourAddress;
        let {loggedInUser:{homeownerData}} = this.props
        const element = document.getElementsByClassName('premiumLinks');
        const style = element[0] && window.getComputedStyle(element[0]);
        const premumLink = style && style.getPropertyValue('color');
        if (homeownersList && homeownersList.lat && !this.state.lat){
            this.setState({lat:homeownersList.lat})
        }
            return (
                <div className="map-wrapper">
                    <Map
                        google={this.props.google}
                        onReady={this.onMapReady}
                        zoom={16}
                        mapTypeControl={false}
                        fullscreenControl={false}
                        zoomControl={false}
                        streetViewControl={false}
                        initialCenter={{
                            lat: homeownerData.lat,
                            lng: homeownerData.lng
                        }}
                    >
                        <Marker
                            title={this.state.address}
                            position={{
                                lat: homeownerData.lat,
                                lng: homeownerData.lng
                            }}
                            icon={{
                                path:
                                    `M 0,-1
                                  C 0.5523, -1   1, -0.5523    1,0
                                  C 1, 0.5523    0.5523, 1     0,1
                                  C -0.5523, 1   -1, 0.5523    -1,0
                                  C -1, -0.5523  -0.5523, -1   0,-1`,
                                fillColor: premumLink || "#FF6F47",
                                fillOpacity: 1,
                                strokeWeight: 0,
                                rotation: 0,
                                scale: 5,
                            }}
                        />

                        {homeownersList &&
                            homeownersList.homesByAdd &&
                            homeownersList.homesByAdd.length !== 0 &&
                            homeownersList.homesByAdd.map((homeowner, index) => {
                                if (homeowner.lat && homeowner.lng && homeowner.nearaddress) {
                                    return (
                                        <Marker
                                            key={index}
                                            title={homeowner.nearaddress}
                                            position={{
                                                lat: Number(homeowner.lat),
                                                lng: Number(homeowner.lng)
                                            }}
                                            icon={{
                                                url: require('../../../../../Assets/Images/location.svg')
                                            }}
                                        />
                                    );
                                }
                                return '';
                            })}
                    </Map>
                </div>
            )
    }

    render() {
        let { loggedInUser: { homeownerData } } = this.props;
        let homeownersList = homeownerData;
        let mobileImage = homeownersList && homeownersList.icon;
        let image = homeownersList && homeownersList.logo;
        let sectionStyle = checkIsMobile() ? {
            backgroundImage: `url(${mobileImage})`,
            height: '33px',
            width: '33px',
            'background-size': '33px'
        } :
            {
                backgroundImage: `url(${image})`,
                height: '44px',
                width: '350px',
                'background-size': 'auto 44px',
                'background-repeat': 'no-repeat'
            };
        if (this.state.lng) {
            return (
                <>
                    <header>
                        <div className="nav-header-wrapper dashboard-nav-wrapper">
                            <nav className="navbar navbar-expand-lg navbar-light bg-light bg-light-homeowner pt-0">
                                <a style={image && sectionStyle} className="navbar-brand publicReferral" />
                            </nav>
                        </div>
                    </header>

                    <div className="referral-wrap mapMargin">
                        <title>Join {homeownersList && homeownersList.firstName && homeownersList.firstName.trim()}'s Expanding Solar Community!</title>
                        {homeownersList.lat && homeownersList.lng && homeownersList.address && this.renderMap()}
                        <div className={`referral-content`}>{this.renderLanding()}</div>
                    </div>
                </>
            );
        } else {
            return <LandingPage loadingIcon={getLoadingIcon(this.props.router.params.slug)} role={'homeowner123'} />
        }
    }
}

const mapStatetoProps = state => {
    let loggedInUser = {};
    loggedInUser['homeownerData'] =
        state.homeownerReferralDetails &&
        state.homeownerReferralDetails.homeownerReferrals &&
        state.homeownerReferralDetails.homeownerReferrals.data;
    return {
        auth: state.auth,
        loggedInUser: loggedInUser,
        neighbourAddress: state.homeownerReferralDetails.homeownerReferralNeighbour.data
    };
};

const mapDispatchToProps = dispatch =>
	bindActionCreators(
		{
            receiveReferralNeighbour,
			receiveReferralDetails
		},
		dispatch
	);

export default withRouter(
    connect(
        mapStatetoProps,
        mapDispatchToProps
    )(
        GoogleApiWrapper({
            apiKey: googleMap.envConfig.googleMap.API_KEY
        })(ReferralLandingForm)
    )
);
