// import npm packages
import React                    from "react";
import { Form, Input, Modal }   from 'antd';
import { bindActionCreators }   from "redux";
import { connect }              from "react-redux";
import { FacebookShareButton, 
        TwitterShareButton }    from 'react-share';
import moment                   from "moment";
import UserAvatar               from "react-user-avatar";

import withRouter                   from '../../../../shared/withRouter';
import { getToken }                 from "../../../../shared/util";
import { jwtDecode }                from "../../../../shared/jwt";
import { capitalize }               from '../../../../shared/capitalize';
import { checkIsVideo }             from '../../../../shared/checkIsMobile';
import { requestStatusFeedPhaseSurvey, 
    receiveStatusFeedPhaseSurvey }  from '../../actions/statusFeed';
import { requestPostContactData, 
    receivePostContactData }        from "../../actions/contact";
import ShowImageFromDrive           from '../../../Common/ShowImageFromDrive';
import { setImages }                from '../../../Common/ShowImageFromDrive/action';
import {openNotificationWithIcon}   from '../../../../shared/notification';
import { PostShareDetails }         from '../../api/promiseApi';
import { calcTime }                 from '../../../../shared/calcTime';
import {replaceStatusFeedText}      from "./replaceText";
import SurveyPage                   from "./survey"
import {statusButtonLink}           from "../shared/statusFeedButton";
import {trackCampaignClick}         from "../../../campaign/api/campaign"
import { requestHomeownerSpecialCardData,
     receiveHomeownerSpecialCardData } from '../../actions/homeOwner';
import {ReactComponent as Share_Icon}  from "../../../../Assets/Images/outline-share-24-px.svg";

const FormItem = Form.Item;
const { TextArea } = Input;

class StatusFeedForm extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isOpen: false,
            phase: null,
            touchData: null,
            isReply: false,
            hrefValue: '#',
            reviewSignVisible: false,
            isApiResponse: false
        };
    }

    componentWillReceiveProps(nextProps) {
        if (
            nextProps.homeownerContactDetails
            && nextProps.homeownerContactDetails.data
            && nextProps.homeownerContactDetails.data.message
            && nextProps.homeownerContactDetails.data.message === 'message sent'
            && this.state.isReply
        ) {
            let installerName = (nextProps.loggedInUser.homeownerData
             && nextProps.loggedInUser.homeownerData.installerName) || 'your project manager';
            
            this.setState({
                isReply: false
            })
            openNotificationWithIcon('success', `Your message has been sent to ${installerName}.`, 'Success');
            nextProps.receivePostContactData({});
        }
        if (nextProps.feedType && !this.state.isApiResponse) {
            this.props.requestHomeownerSpecialCardData({ feedType: nextProps.feedType, homeownerId: nextProps.homeOwnerId, feedTime: nextProps.feedTime });
            this.setState({ isApiResponse: true });
        }
    }

    componentDidMount(){
        let token = getToken();
        if (token) {
            this.homeownerData = jwtDecode(token);
        }
    }

    beforeClick(method) {
        const { loggedInUser } = this.props;

        const bodyData = {
            "homeownerId": loggedInUser && loggedInUser.solarUnit && loggedInUser.solarUnit.homeownerId,
            "companyId": loggedInUser && loggedInUser.homeownerData && loggedInUser.homeownerData.companyId,
            "method": method,
            "share": "glance"
        };
        return PostShareDetails(bodyData)
    }

    setMessageHref(url, method) {
        this.beforeClick(method).then(res => {
            this.setState({ hrefValue: url })
        }).catch(e => {
            console.log('error', e.toString())
        });
    }

    isVowel(text) {
        var vowelRegex = '^[aieouAIEOU].*';
        var matched = text.match(vowelRegex);
        if (matched) {
           return true;
        }
        else {
            return false;
        }

    }

    // subit function for message
    handleSubmitForSentMessage = (customTouchData, campaignId) => {
        let installersName = this.props
                               && this.props.loggedInUser 
                               && this.props.loggedInUser.homeownerData
                               && this.props.loggedInUser.homeownerData.installerName ?
                                this.props.loggedInUser.homeownerData.installerName :
                                    'the installer';

        return values => {
            let message = values.message;
            if (!message || message === '') {
                openNotificationWithIcon('error', `We’ll pass it on to ${installersName.trim()}.`, 'Please enter a message');
                return null;
            }
            let {loggedInUser:{homeownerData}} = this.props;
            this.props.setLoader({ loader: true })
            this.props.requestPostContactData({
                companyId       : homeownerData.companyId,
                homeownerId     : homeownerData.id,
                message         : values.message,
                statusFeed      : true,
                rating          : values.rating,
                homeOwnerName   : `${homeownerData.firstName} ${homeownerData.lastName}`,
                homeOwnerEmail  : homeownerData.email,
                installerId     : homeownerData.installerId,
                installerName   : homeownerData.installerName,
                installerEmail  : homeownerData.installerEmail,
                campaignName    : customTouchData && customTouchData.campaignName,
                touchName       : (customTouchData && (customTouchData.display||customTouchData.campaignName)) || (this.state.touchData &&  this.state.touchData.touchName),
                campaignId
            });
        }
    }

    handleCancel = e => {
        setTimeout(()=>{
            this.props.requestGetEversignData(this.homeownerData)
        },2500);
        this.setState({ reviewSignVisible: false })
    }



    // render phase card content
    renderPhaseContent = (content,customTouchData,touchData) => {
        if(touchData && ((touchData.touchName =='1 week impact and survey')||(touchData.touchName =='1st Month Impact') || (touchData.touchName =='1 Year Impact')))
            return ''
        const { systemGlance, loggedInUser } = this.props;
        let text = typeof(content)==="string" ? content : content.content1;
        text = replaceStatusFeedText(text,systemGlance,loggedInUser,customTouchData,this.props.router.params,touchData);
        return text.split("\n").map(function (item, idx) {
            if(item.includes('<a')){
                item = item.split('<a').join('<a className="premiumLinks" target="_blank"');
            }
            return (
                <>
                    <div key={idx} dangerouslySetInnerHTML={{ __html: `<p style="margin:0">${item}</p>` }} />
                    <br />
                </>
            )
        })
    }


    displayUtilityName = (utilityName,utilityLabel) => {
        if(utilityLabel)
            return capitalize(utilityLabel);
        else
            return capitalize(utilityName);
    }

    // render status feed html
    renderStatusFeed() {
        const {docCreatedAt, loggedInUser,companyJourney } = this.props;
        const touchData = this.props.touchNameData || {};
        let phase = [];

        if(this.props.monitoringContent){
            phase = this.props.monitoringContent.phase && this.props.monitoringContent.phase[0];
        }
        let scaniflyUrl = loggedInUser.solarUnit && loggedInUser.solarUnit.scaniflyUrl;
        let offset = loggedInUser && loggedInUser.homeownerData && loggedInUser.homeownerData.offset
        let docCreatedDate = (docCreatedAt && calcTime(new Date(Number(docCreatedAt * 1000)), offset)) || new Date().getTime();
        
        let feedCreatedDate = (touchData.createdAt && calcTime(new Date(Number(touchData.createdAt)), offset)) || new Date().getTime();
        let createdAt = !this.props.isDocument ? feedCreatedDate : (docCreatedDate);

        let lowerTouchName =  (touchData && touchData.touchName && touchData.touchName.toLowerCase().replace(/ /g, '')) || '';

        if(lowerTouchName && companyJourney && companyJourney[lowerTouchName]&& companyJourney[lowerTouchName].phase){
            phase = companyJourney[lowerTouchName].phase.length >0 ? companyJourney[lowerTouchName].phase[0]
            : phase;
            var customTouchData = companyJourney[lowerTouchName];
        }
        let dyId = Math.floor(100000 + Math.random() * 900000);
        return (
            <div
                className="white-box-secondary margb15 spacingMobile"
                key={this.props.key}
                onClick={() => {
                    if (!this.props.isInstaller)
                        this.setState({ phase: phase || [], touchData, isOpen: true})
                }}
            >
                <div>
                    <div className='paddall25 pdding-left-zero pdding-right-zero'>
                        <div>
                            {this.renderInstallerInfo(loggedInUser && loggedInUser.homeownerData,this.props.companyDetail, dyId)}
                            {this.renderShareOption(dyId)}

                            {
                                this.props.isDocument ? this.renderEversignDocument(this.props.eversignDocument) :
                                    <div
                                        onClick={() => {
                                            if (!this.props.isInstaller)
                                                this.props.router.navigate(`/${localStorage.getItem('slug')}/statusFeed/${lowerTouchName}`,
                                                    {
                                                        dateParam: this.props.dateParam,
                                                        homeownerId: this.props.homeOwnerId,
                                                        companyJourney: this.props.companyJourney,
                                                        createdAt
                                                    })
                                        }}
                                    >
                                        <p>
                                            {phase && scaniflyUrl && phase.isScanifly && this.renderScanifly(scaniflyUrl)}
                                            {phase && (phase.isImages || phase.isLayout) && this.renderCardImages(phase.isLayout)}
                                            {phase && phase.content && this.renderPhaseContent(phase.content, customTouchData, touchData)}
                                            {phase && phase.isButton && this.renderCardButton(phase)}
                                        </p>
                                        {this.renderSpecialCard(phase, lowerTouchName)}
                                    </div>
                            }
                            <br />
                            {this.renderReplyOption(createdAt, customTouchData)}
                            {/* {phase.survey &&  this.renderPhaseServe(surveyType[phase.survey], this.props.systemGlance)} */}
                        </div>
                    </div>
                </div>
            </div>
        )
    }

    renderScanifly=(scaniflyUrl)=>{
        return(
            <div className="scanifly">
                <iframe  width="100%" height="360" frameborder="0" allowfullscreen mozallowfullscreen="true" webkitallowfullscreen="true" src={scaniflyUrl}></iframe>
                <div className="overlay" onClick={((e)=>{
                    e.stopPropagation();
                    window.open(scaniflyUrl, '_blank').focus();
                })}/>   
                <div className="expand" onClick={(e)=>e.stopPropagation()}/>
            </div>
        )
    }

    renderPhaseServe(phase) {
        return <SurveyPage homeownerPhase={phase} {...this.props} />
    }

    renderMagicDataBlock(){
        const {touchNameData,loggedInUser} = this.props;
        let imageUrl  = [];
        let dyId = Math.floor(100000 + Math.random() * 900000);

        touchNameData.Pictures_For_Customer_Update && touchNameData.Pictures_For_Customer_Update.values.map(ele=>{
            if(ele.Customer_Picture){
                imageUrl.push(ele.Customer_Picture.value);
            }
        });

        let msg = touchNameData.appMsg;

        return (
            <div className="white-box-secondary margb15 spacingMobile">
                <div>
                    <div className='paddall25 pdding-left-zero pdding-right-zero'>
                        {
                            <div onClick={() => {
                            }}>
                                {this.renderInstallerInfo(loggedInUser && loggedInUser.homeownerData, this.props.companyDetail, dyId, true)}
                                {this.renderCardImages(null, imageUrl)}
                                <div className="campaignPreview left-content-box">
                                    {msg && msg.split("\n").map((item, idx) => {
                                        return (
                                            <p className="homeowner" key={idx}>{item}</p>
                                        )
                                    })}
                                </div>
                            </div>
                        }
                        <br />
                        {this.renderReplyOption(parseInt(touchNameData.createdAt))}
                    </div>
                </div>
            </div>

        )
    }

    renderCampaignsBlock(){
        const { loggedInUser } = this.props;
        let dyId = Math.floor(100000 + Math.random() * 900000);
        let ele = this.props.campaignData;
        return (
            <div className="white-box-secondary margb15 spacingMobile">
                <div>
                    <div className='paddall25 pdding-left-zero pdding-right-zero'>
                        <div>
                            {this.renderInstallerInfo(ele,this.props.companyDetail, dyId,true)}
                            {
                                <div onClick={() => {
                                    if (!this.props.isInstaller)
                                        this.props.router.navigate(`/${localStorage.getItem('slug')}/campaigns/${ele.id}`,
                                            { campaignData: ele })
                                }}>
                                    {(ele.campaignPhoto && ele.campaignPhoto.length> 0 &&
                                    checkIsVideo(ele.campaignPhoto[0]) )?
                                            this.VideoPreviewBox(ele.campaignPhoto[0]) : this.renderCardImages(null,ele.campaignPhoto)}
                                    <div className="campaignPreview left-content-box">
                                        <h3>{ele.headline} </h3>
                                        {ele.body && ele.body.split("\n").map((item, idx) => {
                                            return (
                                                <p className="homeowner" key={idx}>{item}</p>
                                            )
                                        })}
                                        {
                                            ele.button.link && <a href={ele.button.link} target="_blank"
                                            onClick={()=>{
                                                trackCampaignClick([{
                                                    "campaignId" : ele.id,
                                                    "event" : "app",
                                                    "homeownerId" : this.homeownerData.homeownerId
                                                }])
                                            }}
                                            className="btn yellow-btn premiumButtonFill premiumButtonText">{ele.button.label || 'Click Here'}</a>
                                        }
                                    </div>
                                </div>

                            }
                            <br />
                            {this.renderReplyOption(parseInt(ele.createdAt),{campaignName :ele.headline},ele.id)}
                        </div>
                    </div>
                </div>
            </div>
            
        )
    }

    ImagePreviewBox(url){
        return (
            url && <img className="hut-img"
            src={url}
          />
        )
      }
  
    VideoPreviewBox(url){
      return(
        <>
          <video className="hut-img" controls>
            <source src={url} type="video/mp4" />
          </video>
        </>
      )
    }
    
    renderSpecialCard(phase,touchName){
        let specialCardY = 0;
        let specialCardX = 0;
        if (this.props && this.props.homeownerSpecialCard
            && this.props.homeownerSpecialCard.totalConsumption
            && this.props.homeownerSpecialCard.totalProduction
            && this.props.homeownerSpecialCard.dailySaving) {
            specialCardX = ((this.props.homeownerSpecialCard.totalProduction / this.props.homeownerSpecialCard.totalConsumption) * 100).toFixed();

            specialCardY = this.props.homeownerSpecialCard.dailySaving.toFixed();
        } else if (this.props && this.props.homeownerSpecialCard
            && this.props.homeownerSpecialCard.totalProduction 
            && this.props.homeownerSpecialCard.dailySaving) {
            specialCardX = (this.props.homeownerSpecialCard.totalProduction).toFixed();
            specialCardY = this.props.homeownerSpecialCard.dailySaving.toFixed();
        }
        return (
            <div>
                {
                    phase
                    && phase.content
                    && phase.content.content1
                    && this.props.homeownerSpecialCard
                    && this.props.homeownerSpecialCard.totalConsumption
                    && this.props.homeownerSpecialCard.totalProduction
                    && touchName.toLowerCase().replace(/ /g, '') !== 'System design finalized'.toLowerCase().replace(/ /g, '')
                    ? <p>
                        {
                            phase.content.content1
                                .replace(/#X/g, specialCardX)
                                .replace(/#Y/g, specialCardY)
                        }
                    </p>
                    : ''
                }
                {
                    phase
                    && phase.content
                    && phase.content.content2
                    && this.props.homeownerSpecialCard
                    && !this.props.homeownerSpecialCard.totalConsumption
                    && this.props.homeownerSpecialCard.totalProduction
                    && touchName.toLowerCase().replace(/ /g, '') !== 'System design finalized'.toLowerCase().replace(/ /g, '')
                    ?
                    <p>
                        {
                            phase.content.content2
                                .replace(/#X/g, specialCardX)
                                .replace(/#Y/g, specialCardY)
                        }
                    </p>
                    : ''
                }
            </div>
        )
    }

    renderInstallerInfo(installerDetails,companyDetail,dyId,isCampaign){
        let photo = installerDetails && installerDetails.photo;
        if(photo && photo.includes('installer-avatar')){
            photo = null;
        }
        let installerName = (installerDetails && installerDetails.installerName && capitalize(installerDetails.installerName)) || (installerDetails && (capitalize(installerDetails.companyName)));
        
        const imageSyle = {
            'backgroundImage': photo ? `url(` + photo + `)` : ''
        };
        return(
            <div className="media share-comments">
                {photo ?
                    <div className="dashboard-profile-image" style={imageSyle}></div>
                    : <UserAvatar className="dashboard-profile-image premiumIconBackGround" size="40" name={installerName || 'Installer'} />
                }
                {!isCampaign && <div className="share-link status-feed-share">
                    <a href="#"
                        data-toggle="collapse"
                        data-target={`#collapseShareIcons-status-feed-${dyId}`}
                        aria-expanded="true"
                        aria-controls={`collapseShareIcons-status-feed-${dyId}`}
                    >
                        <Share_Icon/>

                    </a>
                </div>}
                <div className="media-body">
                    <h5 className="mt-0">{installerDetails && installerDetails.installerName && capitalize(installerDetails.installerName)}</h5>
                    <a>{installerDetails && installerDetails.installerTitle}</a>
                </div>
            </div>
        )
    }

    renderReplyOption(createdAt,customTouchData,campaignId) {
        return (
            <div className="date-reply-box">
                <span className="contact-wrapperdate-box">
                    { createdAt ? moment(createdAt).format('MMM DD, h:mm A'):'-' }
                </span>
                {
                    !this.state.isReply &&
                        <span className="pull-right">
                            <a
                                className='status-feed-reply premiumLinks'
                                onClick={() => {
                                    this.setState({
                                        isReply: !this.state.isReply
                                    })
                                }}
                            >Reply</a>
                        </span>
                }

                {
                    this.state.isReply
                    &&
                        <Form
                            onFinish={ this.handleSubmitForSentMessage(customTouchData, campaignId) }
                        >
                            <span className="pull-right btn-margin-top">
                                <button
                                    className='status-feed-send premiumLinks'
                                    disabled={this.props.isInstaller ? 'disabled' : ''}
                                    type="submit"
                                >
                                    Send
                                </button>
                            </span>
                            <div className="form-group" style={{ paddingTop: '20px' }}>
                                <FormItem
                                    name="message"
                                >
                                    <TextArea
                                        disabled={this.props.isInstaller ? 'disabled' : ''}
                                        className="status-feed-message textarea"
                                        placeholder="Type message here..."
                                        rows={6}
                                    />
                                </FormItem>
                            </div>
                        </Form>
                }
            </div>
        )
    }

    renderShareOption(dyId){
        let {loggedInUser} = this.props;
        let referralId = loggedInUser && loggedInUser.homeownerData && loggedInUser.homeownerData.referralId;
        return (
            <div id={`collapseShareIcons-status-feed-${dyId}`} className="white-box share-via-wrapper collapse">
                <div className="heading"> Share via:</div>
                {
                    !this.props.isInstaller ?
                        <ul>
                            <li>
                                <FacebookShareButton beforeOnClick={() => this.beforeClick(1)} quote="Hey, I've gone solar and thought you might be interested. This is a link to the equipment powering my home. Check it out!" url={window.location.origin + '/' + this.slug + '/referral/' + referralId + '/glance/1'}>
                                    <a href="#" className="facebook">
                                        <img
                                            src={require("../../../../Assets/Images/facebook.svg")}
                                            alt='facebook.svg'
                                            width="25"
                                        />
                                    </a>
                                </FacebookShareButton>
                            </li>
                            <li>
                                <TwitterShareButton beforeOnClick={() => this.beforeClick(2)} title="Hey, I've gone solar and thought you might be interested. This is a link to the equipment powering my home. Check it out!" url={window.location.origin + '/' + this.slug + '/referral/' + referralId + '/glance/2'}>
                                    <a href="#" className="facebook">
                                        <img
                                            src={require("../../../../Assets/Images/twitter.svg")}
                                            alt='twitter.svg'
                                            width="25"
                                        />
                                    </a>
                                </TwitterShareButton>
                            </li>
                            <li className="disabled">
                                <a onClick={() => this.setMessageHref("https://api.whatsapp.com/send?text=Hey, I've gone solar and thought you might be interested. This is a link to the equipment powering my home. Check it out!" + window.location.origin + '/' + this.slug + '/referral/' + referralId + '/glance/4', 4)}
                                    href={this.state.hrefValue}
                                    className="message">
                                    <img
                                        src={require("../../../../Assets/Images/whatsapp.png")}
                                        alt='sms.svg'
                                        width="25"
                                    />
                                </a>
                            </li>
                            <li className="disabled">
                                <a onClick={() => this.setMessageHref("sms:?&body=Hey, I've gone solar and thought you might be interested. This is a link to the equipment powering my home. Check it out!" + window.location.origin + '/' + this.slug + '/referral/' + referralId + '/glance/3', 3)}
                                    href={this.state.hrefValue}
                                    className="message">
                                    <img
                                        src={require("../../../../Assets/Images/sms.svg")}
                                        alt='sms.svg'
                                        width="25"
                                    />
                                </a>
                            </li>
                        </ul>
                        :
                        <ul>
                            <li>
                                <img
                                    src={require("../../../../Assets/Images/facebook.svg")}
                                    alt='facebook.svg'
                                    width="25"
                                    style={{ cursor: 'not-allowed' }}
                                />
                            </li>
                            <li>
                                <img
                                    src={require("../../../../Assets/Images/twitter.svg")}
                                    alt='twitter.svg'
                                    width="25"
                                    style={{ cursor: 'not-allowed' }}
                                />
                            </li>
                            <li>
                                <img
                                    src={require("../../../../Assets/Images/whatsapp.png")}
                                    alt='twitter.svg'
                                    width="25"
                                    style={{ cursor: 'not-allowed' }}
                                />
                            </li>
                            <li>
                                <img
                                    src={require("../../../../Assets/Images/sms.svg")}
                                    alt='sms.svg'
                                    width="25"
                                    style={{ cursor: 'not-allowed' }}
                                />
                            </li>
                        </ul>
                }
            </div>
        )
    }

    renderCardImages(isLayout,campaignPhoto){
        return(
            <ShowImageFromDrive
                {...this.props}
                campaignPhoto = {campaignPhoto}
                isOpen={this.props.isInstaller || false}
                filterByName={isLayout ?('layout' || 'site plan'): ''}
                style={{ marginBottom: '80px' }}
            />
        )
    }

    renderCardButton(statusButton){
        return(
            <div className="text-center">
                <button
                    className="btn yellow-btn premiumButtonFill premiumButtonText documents-button-status-feed"
                    disabled={this.props.isInstaller ? 'disabled' : ''}
                    onClick={(e) => {
                        e.stopPropagation();
                        this.props.router.navigate(`/${localStorage.getItem('slug')}${statusButtonLink[statusButton.buttonUrl]|| '/documents'}`)
                    }}
                >
                   {statusButton.buttonName || 'Click Here!'} 
                </button><br />
            </div>
        )
    }

    renderEversignDocument(eversignDocument) {
        let {loggedInUser} = this.props;
        let mobileImage = (loggedInUser.homeownerData && loggedInUser.homeownerData.icon)? loggedInUser.homeownerData.icon : "https://prod-17terawatts.s3.amazonaws.com/public/logo/bothi-mobile-logo.png";
    
        return (
            <>
                <div className="iframeMobile">
                    <label style={{ textTransform: "none",cursor:"pointer",color: '#212529', fontWeight: 300, fontSize: '14px' }}
                        onClick={(e) => {
                            e.stopPropagation();
                            if (!this.props.isInstaller)
                                this.props.router.navigate(`/${localStorage.getItem('slug')}/signature/${eversignDocument.id}`,
                                    {
                                        dateParam: this.props.dateParam,
                                        eversignDocument,
                                        homeownerId: this.props.homeOwnerId,
                                        companyJourney: this.props.companyJourney
                                    })
                        }}
                    >{
                        eversignDocument.message ? eversignDocument.message :
                            `Please review and sign this important document ${eversignDocument.fileName} concerning your solar system. Let us know if you have any questions.`
                    }</label>
                    <div className="text-center">
                        <button
                            className="btn yellow-btn reviewSign premiumButtonText premiumButtonFill"
                            type='button'
                            disabled={this.props.isInstaller ? "disabled" : ""}
                            onClick={(e) => {
                                e.stopPropagation();
                                this.setState({ reviewSignVisible: true })
                            }}
                        >
                            Review & Sign
                    </button>
                    </div>
                    < Modal
                        title={null}
                        footer={null}
                        visible={this.state.reviewSignVisible}
                        onCancel={this.handleCancel}
                        width='1000px'
                    >
                        <img className="reviewIcon" src={mobileImage} style={{ height: '48px', width: '48px' }}></img>
                        <label className="reviewText">Review & Sign</label>
                        <iframe src={eversignDocument.fileUrl} width="100%" height="800"></iframe>
                    </Modal>
                </div>
            </>
        )
    }

    render() {
        this.slug = localStorage.getItem("slug");
        return (
            <>
                {this.props.isCampaign ? this.renderCampaignsBlock() : (this.props.isMagicFileData ? this.renderMagicDataBlock() : this.renderStatusFeed())}
            </>
        )
    }
}

const mapStatetoProps = state => {
    return {
        cloudImages: JSON.stringify(state.cloudImages.cloudImages) !== '{}' ? state.cloudImages.cloudImages : {},
        homeownerContactDetails: state.homeownerContact.homeownerContactDetails,
        statusFeedPhaseSurvey: (state.statusFeedPhaseSurvey.statusFeedPhaseSurvey && JSON.stringify(state.statusFeedPhaseSurvey.statusFeedPhaseSurvey) !== '{}' && state.statusFeedPhaseSurvey.statusFeedPhaseSurvey) || {},
        homeownerSpecialCard: (state.homeownerLoggedInUser && state.homeownerLoggedInUser.homeownerSpecialCardDataReducer && state.homeownerLoggedInUser.homeownerSpecialCardDataReducer.data) || {}
    };
};

const mapDispatchToProps = dispatch =>
    bindActionCreators(
        {
            requestStatusFeedPhaseSurvey,
            receiveStatusFeedPhaseSurvey,
            requestPostContactData,
            receivePostContactData,
            requestHomeownerSpecialCardData,
            receiveHomeownerSpecialCardData,
            setImages
        },
        dispatch
    );

export default withRouter(
    connect(
        mapStatetoProps,
        mapDispatchToProps
    )(StatusFeedForm)
);