// import npm packages
import { call, put } from "redux-saga/effects";

// import local files

import {
	getHomeownerById,
	getHomeownerDeviceMagicData,
	getHomeownerEnergyReport,
	getHomeownerSpecialCardData,
	getUpdateHomeOwner
} from "../../screens/homeowner/api/homeOwner";

import handleError from "../../shared/errorHandler";
import { setLoader } from "../../screens/Common/Loader/action";
import {
	receiveLoggedInHomeownerAction,
	receiveHomeownerEnergyData,
	receiveDeviceMagic,
	receiveHomeownerSpecialCardData
} from "../../screens/homeowner/actions/homeOwner";

function* getHomeownerByIdSaga(action) {
	try {
		const data = yield call(getHomeownerById, action.data);
		yield put(setLoader({ loader: false }));
		yield put(receiveLoggedInHomeownerAction(data));
	} catch (e) {
		yield put(receiveLoggedInHomeownerAction({data:{statusCode : 404}}));
		yield put(setLoader({ loader: false }));
		handleError(e);
	}
}

function* getHomeownerEnergyReportSaga(action) {
	try {
		const data = yield call(getHomeownerEnergyReport, action.data);
		yield put(setLoader({ loader: false }));
		yield put(receiveHomeownerEnergyData(data));
	} catch (e) {
		yield put(setLoader({ loader: false }));
		handleError(e);
	}
}

	function* getHomeownerSpecialCardDataSaga(action) {
	try {
		const data = yield call(getHomeownerSpecialCardData, action.data);
		yield put(setLoader({ loader: false }));
		yield put(receiveHomeownerSpecialCardData(data));
	} catch (e) {
		yield put(setLoader({ loader: false }));
		handleError(e);
	}
}

function* getUpdateHomeOwnerSaga(action) {
	try {
		const data = yield call(getUpdateHomeOwner, action.data);
		yield put(setLoader({ loader: false }));
		// yield put(receiveUpdateHomeOwner(data));
	} catch (e) {
		yield put(setLoader({ loader: false }));
		handleError(e);
	}
}

function* getHomeownerDeviceMagic(action){
	try {
		const data = yield call(getHomeownerDeviceMagicData, action.data);
		yield put(receiveDeviceMagic(data));
	} catch (e) {
		handleError(e);
	}
}

export const homeownerUser = {
	getHomeownerByIdSaga,
	getHomeownerEnergyReportSaga,
	getHomeownerSpecialCardDataSaga,
	getUpdateHomeOwnerSaga,
	getHomeownerDeviceMagic
};