// import npm packages
import React, { Component } from 'react';
import { Form,Input }       from 'antd';
import { Map, Marker, 
    GoogleApiWrapper }      from 'google-maps-react';
import { connect }          from 'react-redux';
import {bindActionCreators} from "redux";
// import local files
import googleMap                    from '../../../../environments/index';
import withRouter                   from '../../../../shared/withRouter';
import {openNotificationWithIcon}   from '../../../../shared/notification';
import {checkIsMobile}              from '../../../../shared/checkIsMobile';
import {requestPostAddressDetails,
        receivePostAddressDetails}  from "../../../homeowner/actions/referralDetails"

class AdminMapForm extends Component {
    constructor(props) {
        super(props);
        this.state = {
            errorObj: {},
            lat: 30.2527036,
            lng: -97.7812972,
            address:"2400 Homedale Cir Austin TX 78704"
        };
        this.onboardZoom =checkIsMobile() ? 12 : 13;
    }

    shouldComponentUpdate() {
        if (!this.state.lat) {
            return true;
        } else {
            return false;
        }
    }

    componentWillReceiveProps(nextProps) {
          let temp = nextProps.referralDetails
            && nextProps.referralDetails.postHomeownerAddress
            && nextProps.referralDetails.postHomeownerAddress.data
            && nextProps.referralDetails.postHomeownerAddress.data.data;

        if (temp && (!temp.lat || !temp.lng) && !this.state.isInvalid) {
            nextProps.receivePostAddressDetails({});
            openNotificationWithIcon('error', 'Please enter valid address', 'Failed');
            this.setState({isInvalid:true,isload:false});
        } else if (temp  && temp.lat) {
            nextProps.receivePostAddressDetails({});
            this.setState({
                isload  : false,
                lat     : Number(temp.lat),
                lng     : Number(temp.lng),
                address : this.state.add,
                homesByAdd : temp.homesByAdd
            });
        }
    }

    handleSubmitCalculate = () => { 
  
        if (this.state.add) {
            let data = {
                "path": this.state.add
            };
            this.props.requestPostAddressDetails(data);
            this.setState({ isInvalid: false,lat:null},()=>{
                this.setState({isload:true})
            });
        }
        else {
            openNotificationWithIcon('error','Please enter an address to populate solar addresses.', 'Please try again');
        }
    };
   
   


    renderMap(){
        let {homesByAdd} = this.state;
        let current_Icon;
        if(!homesByAdd && this.props.onBoardAdd){
            homesByAdd = this.props.onBoardAdd
        }

        if (this.props.completeMap && this.props.zoom && this.onboardZoom) {
            
            if(this.props.zoomOut && !this.zoomOut){
                this.onboardZoom =20;
                this.zoomOut = true;
            }
            
            setTimeout(() => {
                var refreshId =  setInterval(() => {
                    if (!this.props.zoomOut && document.querySelector('button[title="Zoom in"]') && this.onboardZoom <= this.props.zoom) {
                        this.onboardZoom = this.onboardZoom + 1;
                        document.querySelector('button[title="Zoom in"]').style.opacity = 0;
                        document.querySelector('button[title="Zoom out"]').style.opacity = 0;
                        document.querySelector('button[title="Zoom in"]').click();
                    }
                    if(this.props.zoomOut && document.querySelector('button[title="Zoom out"]') &&  this.onboardZoom >= this.props.zoom){
                        this.onboardZoom = this.onboardZoom - 1;
                        document.querySelector('button[title="Zoom out"]').click();
                    }
                    if (this.props.zoomOut  && (this.onboardZoom < this.props.zoom)) {
                        clearInterval(refreshId)
                    }
                    if (!this.props.zoomOut && this.props.updateState && (this.onboardZoom > this.props.zoom)) {
                        setTimeout(() => {
                            this.props.updateState();
                        }, 2000)
                        clearInterval(refreshId)
                    }
                }, checkIsMobile() ? 100 : 80);
            }, 1500)
        }

        if(this.props.onBoarding){
            const element       = document.getElementsByClassName('premiumLinks');
            const style         = element[0] && window.getComputedStyle(element[0]);
            const premumLink    = style && style.getPropertyValue('color');
            current_Icon = {
                path:
                    `M 0,-1
                      C 0.5523, -1   1, -0.5523    1,0
                      C 1, 0.5523    0.5523, 1     0,1
                      C -0.5523, 1   -1, 0.5523    -1,0
                      C -1, -0.5523  -0.5523, -1   0,-1`,
                fillColor: premumLink || "#FF6F47",
                fillOpacity: 1,
                strokeWeight: 0,
                rotation: 0,
                scale: 12,
            }
        } else {
            current_Icon = {
                url: require(`../../../../Assets/Images/current-location.png`)
            }
        }
                              
        return(
            <div className={`map-wrapper ${this.props.overlay ? 'overlay' : ''}`}>
                <Map
                    google={this.props.google}
                    onReady={this.onMapReady}
                    zoom={(this.props.completeMap ?  this.onboardZoom : (this.props.zoom || 16))}
                    mapTypeControl={false}
                    fullscreenControl={false}
                    zoomControl={this.props.completeMap ? true : false}
                    scaleControl= {false}
                    scrollwheel= {this.props.onBoarding ? false : true}
                    showRoadLabels= {false}
                    gestureHandling = {'none'}
                    options={{streetViewControl: false}}
                    streetViewControl={false}
                    initialCenter={{
                        lat:  this.props.onboardLat || this.state.lat, 
                        lng:  this.props.onboardLng || this.state.lng
                    }}
                    center={{
                        lat:  this.props.onboardLat || this.state.lat ,
                        lng:  this.props.onboardLng || this.state.lng
                    }}
                >
                    <Marker
                        title={this.props.onboardAddress || this.state.address}
                        position={{
                            lat:  this.props.onboardlat || this.state.lat ,
                            lng:  this.props.onboardlng || this.state.lng
                        }}
                        animation={this.props.bounce ? window.google.maps.Animation.BOUNCE : ''}
                        label={{
                            color: 'black',
                            fontWeight: 'bold',
                            'marginTop': '45px',
                            text: this.props.onboardAddress || this.state.address,
                        }}
                        icon={current_Icon}
                    />

                    {homesByAdd &&
                        homesByAdd.length !== 0 &&
                        homesByAdd.map((homeowner, index) => {
                            if (homeowner.lat && homeowner.lng && homeowner.nearaddress) {
                                return (
                                    <Marker
                                        key={index}
                                        title={homeowner.nearaddress}
                                        position={{
                                            lat: Number(homeowner.lat),
                                            lng: Number(homeowner.lng)
                                        }}
                                        icon={{
                                            url: require('../../../../Assets/Images/location.svg')
                                        }}
                                    />
                                );
                            }
                            return '';
                        })}
                </Map>
            </div>
        )
    }

    render() {
            return (
                <>
                    {!this.props.onBoarding &&
                        <div className="map-address-box">
                            <Input
                                type="text"
                                className="form-control"
                                placeholder="Enter an address to populate solar addresses"
                                onChange={(e) => this.setState({ 'add': e.target.value })}
                                onKeyDown={(e) => {
                                    if (e.key === 'Enter') {
                                        this.handleSubmitCalculate()
                                    }
                                }}
                            />
                            {
                                this.state.isload ?
                                    <i className={`fa fa-refresh fa-spin`} style={{
                                        "color": "rgb(106, 106, 106)",
                                        "cursor": "pointer"
                                    }}></i> :
                                    <i className="fa fa-search" onClick={() => this.handleSubmitCalculate()} aria-hidden="true"></i>
                            }

                        </div>
                    }
                    {this.renderMap()}
                </>
            );
        } 
}

const mapStatetoProps = state => {
    let loggedInUser = {};
    loggedInUser['homeownerData'] =
        state.homeownerReferralDetails &&
        state.homeownerReferralDetails.homeownerReferrals &&
        state.homeownerReferralDetails.homeownerReferrals.data;
    return {
        loggedInUser: loggedInUser,
        referralDetails: state.homeownerReferralDetails,
    };
};

const mapDispatchToProps = dispatch =>
	bindActionCreators({
        receivePostAddressDetails,
        requestPostAddressDetails 
    },dispatch
)

export default withRouter(
    connect(
        mapStatetoProps,
        mapDispatchToProps
    )(
        GoogleApiWrapper({
            apiKey: googleMap.envConfig.googleMap.API_KEY
        })(AdminMapForm)
    )
);
