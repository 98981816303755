import { installerSettingKey, appMetaEndpoint } from './routes'
/**
 * A key to manage application vintage/versioning
 */
const bodhiVersionTimestampKey = '_mybvts'

export {
    bodhiVersionTimestampKey,
    installerSettingKey,
    appMetaEndpoint
}