// import npm packages
import React, { Component } from "react";
import { connect } from "react-redux";
// import local files
import withRouter from '../../../../shared/withRouter';
import GoogleDriveSync from '../../../../shared/GoogleDriveSync';
import DropBoxSync from '../../../../shared/DropBoxSync';
import { openNotificationWithIcon } from '../../../../shared/notification';
import { setHeight } from '../../../../shared/setHeight';

class Documents extends Component {
  constructor(props) {
    super(props);
    this.GoogleDriveSync = new GoogleDriveSync();
    this.DropBoxSync = new DropBoxSync();
    this.state = {
      select: '',
      classFolderList: [],
      files: null,
      errorMessage: {},
      filter: '',
      isSyncCalled: false,
      docValues: {},
      isApiResponse: false,
      embedded_claim_url: ''

    }
  }

  componentWillMount(){
    this.fetchFiles(this.props);
  }

  componentWillReceiveProps(nextProps) {
    this.fetchFiles(nextProps);
  }

  fetchFiles(propsData){
    let {loggedInUser:{documentData}} = propsData;
    if (documentData && documentData.key) {
      this.setState({filter : documentData.filter});
      documentData.source ==="dropbox" && this.initializeDropbox(propsData);
      this.getFilesFromGoogle(propsData);
    }
  }

  // get google files
  getFilesFromGoogle(nextProps) {
    const { documentData } = nextProps.loggedInUser;
    if (
      documentData
      && documentData.source
      && !this.state.isSyncCalled
      && (!this.state.documentId || !this.state.documentId.key)
    ) {
      if (documentData.source === 'google' && (!this.props.cloudImages || JSON.stringify(this.props.cloudImages) === '{}')) {
        if (!this.state.files) {
          this.setState({
            isSyncCalled: true
          }, () => {
            this.props.setLoader({ loader: true });
            this.displayDriveFiles(documentData);
          });
        }
      }
      else if (documentData.driveData && (JSON.stringify(documentData.driveData) != '{}' || JSON.stringify(this.props.cloudImages) != '{}')) {
        let files = JSON.stringify(documentData.driveData) != '{}' ? documentData.driveData : this.props.cloudImages;
        let files2 = [];
        this.props.setLoader({ loader: false });
        if (documentData.source === "solarNexus") {
          documentData.driveData[documentData.key] && documentData.driveData[documentData.key].map(ele => {
            files2.push({
              name: ele['document']['file_name'],
              public_url: ele['document']['url']
            })
          })
        }

        this.props.setImages(files2.length > 0 ? { [documentData.key]: files2 } :files);
        this.setState({
          files: this.arrangeFiles(files2.length > 0 ? { [documentData.key]: files2 } :files, documentData.source),
          documentId: {
            key: documentData.key,
            source: documentData.source,
          },
        })
      }
    }
  }

  displayDriveFiles = (documentData) => {
    let driveData = documentData.driveData
    if (driveData && (JSON.stringify(driveData) != '{}'|| JSON.stringify(this.props.cloudImages) != '{}')) {
      let files = JSON.stringify(driveData) != '{}' ? driveData : this.props.cloudImages;
      this.props.setLoader({ loader: false });
      this.props.setImages(files);
      this.setState({
        files: this.arrangeFiles(files,documentData.source),
        documentId: {
          key: documentData.key,
          source: documentData.source,
        },
      })
    }
    else if(this.props.documentTab){
      this.props.setLoader({ loader: false });
      this.setState({
        files: this.arrangeFiles(this.props.cloudImages,documentData.source),
        documentId: {
          key: documentData.key,
          source: documentData.source,
        },
        loader: false
      })
    }
    else if (driveData && (JSON.stringify(driveData) == '{}') && documentData.source==='google' && JSON.stringify(this.props.cloudImages) === '{}') {
      this.GoogleDriveSync.editListFiles(documentData.key, documentData.filter).then(response => {
        if (
          response
          && response.missingFile
          && response.missingFile.length
          && response.missingFile.length !== 0
        ) {
          openNotificationWithIcon('error', `${response.missingFile.join()} files is missing.Please add them.`, 'Error')
        }
        this.props.setLoader({ loader: false });
        if (response) {
          this.setState({
            files: this.arrangeFiles(response.fileList,documentData.source),
            documentId: {
              key: documentData.key,
              source: documentData.source,
            },
            loader: false
          })
        }
      }).catch(err => {
        console.log('file error:', err);
      });
    }
  }

  // sort folder by name
  sortFolder(files) {
    const temp = {}
    const tempArray = [];
    Object.keys(files).forEach(file => {
      if (files[file][0] && !files[file][0].id) {
        tempArray.push(files[file][0]);
      } else {
        temp[file] = files[file];
      }
    });
    tempArray.sort((val1, val2) => {
      return (val1 && val2 && val1.toLowerCase() < val2.toLowerCase()) ? -1 : (val1 && val2 && val1.toLowerCase() > val2.toLowerCase()) ? 1 : 0;
    }).forEach(val => {
      Object.keys(files).forEach(file => {
        if (files[file][0] && !files[file][0].id && files[file][0] === val) {
          temp[file] = files[file];
        }
      });
    });

    return temp;
  }
  // arrange file in proper way
  arrangeFiles(files,documentSource) {
    const temp = {
      collapsible: {},
      nonCollapsible: {}
    }
    const tempArray = [];
    Object.keys(files).forEach(file => {
      if((files[file][0]&& files[file][0].file_location)|| documentSource ==="teamwork" || documentSource ==="zoho" || documentSource ==="monday" || documentSource==="solarNexus" ||documentSource=='salesforce' || documentSource=='sharepoint'){
        temp.nonCollapsible[file] = files[file];
      }

      else if (files[file][0] && !files[file][0].id) {
        tempArray.push(files[file][0])
        // temp.collapsible[file] = files[file];
      } else {
        temp.nonCollapsible[file] = files[file];
      }
    });
    tempArray.sort((val1, val2) => {
      return (val1 && val2 && val1.toLowerCase() < val2.toLowerCase()) ? -1 : (val1 && val2 && val1.toLowerCase() > val2.toLowerCase()) ? 1 : 0;
    }).forEach(val => {
      Object.keys(files).forEach(file => {
        if (files[file][0] && !files[file][0].id && files[file][0] === val) {
          temp.collapsible[file] = files[file];
        }
      });
    });
    return temp
  }

  // initialize dropbox and fetch files if add
  initializeDropbox() {
    let { loggedInUser: { homeownerData, documentData } } = this.props;
    if (homeownerData && documentData.source === "dropbox" && homeownerData.dropboxToken && !this.state.isSyncCalled) {
      this.setState({ isSyncCalled: true }, () => {
        this.props.setLoader({ loader: true });
        this.DropBoxSync.initializeDropbox(homeownerData.dropboxToken).then(async res => {
          if (res && documentData && documentData.key) {
            this.props.setLoader({ loader: false });
            this.DropBoxSync.getDropBoxFilesList(documentData.key, documentData.filter).then(response => {
              response && this.setState({
                dropboxFile: response.previewUrls,
                files: this.arrangeFiles(response.files)
              });
            })
          }
        });
      })
    }
  }

  // render files
  nonCollapsible(files) {
    let {loggedInUser:{documentData}} = this.props;
    let f = documentData && documentData.filter;
    return Object.keys(files).map(file => {
      return files[file].map((val, index) => {
        if(documentData && documentData.source ==="serviceFusion" ){
          let rexpression = new RegExp(f, "i");
          if (f && !rexpression.test(val.name)) {
            return;
          }

          if (val.doc_type === 'IMG')
            val.url = `https://servicefusion.s3.amazonaws.com/images/estimates/${val.file_location}`
          else
            val.url = `https://servicefusion.s3.amazonaws.com/userdocs/6794/${val.file_location}`
          }
        else if (documentData && documentData.source === "salesforce") {
          val.name = val.fileName;
          let rexpression = new RegExp(f, "i");
          if (f && !rexpression.test(val.name)) {
            return;
          }
          val.url = val.publicURL;
        } 
        else if (documentData && documentData.source === "solarNexus") {
          let rexpression = new RegExp(f, "i");
          if (f && !rexpression.test(val.name)) {
            return;
          }
          val.url = val.public_url;
        }
        else if (documentData && documentData.source === "monday") {
          let f = this.props.loggedInUser.documentData.filter;
          let rexpression = new RegExp(f, "i");
          if (f && !rexpression.test(val.name)) {
            return;
          }
          val.url = val.public_url;
        } else if (documentData&& documentData.source === "zoho") {
          let f = this.props.loggedInUser.documentData.filter;
          let rexpression = new RegExp(f, "i");
          if (f && !rexpression.test(val.File_Name)) {
            return;
          }
          val.name = val.File_Name;
          val.url = val.$link_url;
        }
        else if (documentData && documentData.source === "sharepoint") {
          val.name = val.fileName;
          val.url = val.publicURL;
        }
        else if (documentData && documentData.source === "teamwork") {
          val.url = val.previewUrl;
        }
        else if (index === 0 && !files[file][0].id)
          return '';

        return (
          <li key={val.id}>
            <a style={{ marginLeft: '10px' }} href={val.url ? val.url : ''} target='_blank'>
              {val && val.name}
            </a>
          </li>
        )
      })
    })
  }

  // render sub folder
  collapsible(files) {
    const { dropboxFile } = this.state;
    const { loggedInUser:{documentData} }= this.props;
    return Object.keys(this.sortFolder(files)).map(file => {

      if (
        files
        && files[file]
        && files[file].length < 2
        && files[file][0]
        && !files[file][0].id
      ) {
        return (
          <li key={file} className='file-error'>
            {files[file][0]}
          </li>
        )
      }
      return (
        <li key={file}>

          <div
            href="#"
            className={this.state.select === file.replace(/ /g, '') ? "row collapsed up-arra active" : "row collapsed up-arra"}
            data-toggle="collapse"
            data-target={`#collapse-${file.replace(/ /g, '')}`}
            aria-expanded="false"
            onClick={() => {
              const { select } = this.state;
              if (select === file.replace(/ /g, '')) {
                this.setState({
                  select: ''
                })
              } else {
                this.setState({
                  select: file.replace(/ /g, '')
                });
              }
            }}
          >
            <span className='col-sm-10 col-10'>{files[file][0] && !files[file][0].id ? files[file][0] : ''}</span>
            {
              this.state.select === file.replace(/ /g, '') ?
                <i className="fa fa-angle-up col-sm-2 col-2" data-unicode="f107" />
                :
                <i className="fa fa-angle-down col-sm-2 col-2" data-unicode="f107" />
            }

          </div>
          <div
            id={`collapse-${file.replace(/ /g, '')}`}
            className={`collapse ${this.state.select === file.replace(/ /g, '') ? 'show' : ''}`}
            aria-labelledby="headingOne" data-parent="#accordionExample"
          >
            <ul>
              {
                files[file].sort((file1, file2) => {
                  const name1 = file1 && file1.name && (file1.name.toLowerCase() || null)
                  const name2 = file2 && file2.name && (file2.name.toLowerCase() || null)
                  return (name1 && name2 && name1 < name2) ? -1 : (name1 && name2 && name1 > name2) ? 1 : 0;
                }).map((val, index) => {
                  if (index === 0 && !files[file][0].id)
                    return '';

                    if (dropboxFile && documentData && documentData.source === "dropbox"){
                      val.url = dropboxFile && dropboxFile.find((ele)=>{
                        return val.path_display === ele.filePath
                      })['previewUrl'];
                    }

                  return (
                    <li key={val.id}>
                      <a style={{ marginLeft: '10px' }} href={val.url ? val.url : ''} target='_blank'>
                        {val && val.name}
                      </a>
                    </li>
                  )
                })
              }
            </ul>
          </div>
        </li>
      )
    })
  }


  // render Google drive files
  renderFiles = (files) => {
    return <React.Fragment>
      {this.nonCollapsible(files.nonCollapsible)}
      {this.collapsible(files.collapsible)}
    </React.Fragment>
  }

  isURL(str) {
    return /^(?:\w+:)?\/\/([^\s\.]+\.\S{2}|localhost[\:?\d]*)\S*$/.test(str); 
  }

  renderNetSuite = (files)=>{
    if(files && files.driveData && files.key && Array.isArray(files.driveData[files.key])){     
      return files.driveData[files.key].map((ele,index)=>{
        if(!this.isURL(ele.Value))
          return;
        let id = 'frame'+index; 
        return(
          <iframe
            id={id}
            src={`${ele.Value}`}
            scrolling="yes"
            frameBorder="0"
          ></iframe>
        )
      })
    } 
  }


  render() {
    let {loggedInUser} = this.props;
    let titleValue = loggedInUser.homeownerData &&  loggedInUser.homeownerData.companyName;

    return (
      <React.Fragment>
        <title>System Documents - {titleValue}</title>
        <div className="white-box w-100 padd15 paddl0 paddr0">
          <p className="page-title documents-title">documents</p>

          <div className="document-collapse accordion" id="accordionExample">
            {
              this.state.files
              && loggedInUser.documentData && loggedInUser.documentData.source!=='netsuite'
              && JSON.stringify(this.state.files) !== '{}'
              && (
                <ul>
                  {this.renderFiles(this.state.files)}
                </ul>
              )
            }
            {
              loggedInUser.documentData && loggedInUser.documentData.source==='netsuite'
              && this.renderNetSuite(loggedInUser.documentData && loggedInUser.documentData)
            }
          </div>
        </div>
      </React.Fragment>
    )
  }
}


export default withRouter(
  connect(
  )(Documents)
);