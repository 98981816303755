import React from 'react';

import withRouter from '../../../../shared/withRouter'
import {getCode} from '../../../../shared/getAccessToken';
import {openNotificationWithIcon} from '../../../../shared/notification';

class Auth extends React.Component {

  componentWillMount() {
    if(this.props.router.location) {
      const code = this.props.router.location.hash || this.props.router.location.search
      const obj = this.props.router.location.hash.includes('error')?false:getCode(code)
      if(obj && obj.access_token) {
        localStorage.setItem('dropboxAccessToken',obj.access_token);
      } else if(obj.code) {
        localStorage.setItem('salesforceToken' ,obj.code)
        localStorage.setItem('isSalesforce', true)
      }
      else{
        openNotificationWithIcon('error', 'Not Authorized To Access Dropbox Account.', 'Error'); 
      }
      if(localStorage.getItem('pathname'))
          this.props.router.navigate(localStorage.getItem('pathname'),
            {
              isHomeOwner: localStorage.getItem('isHomeOwner') && localStorage.getItem('isHomeOwner') === 'true' ? true : false,
              email: localStorage.getItem('homeOwnerEmail')
            });
    }
  }

  render() {
    return null
  }
}

export default withRouter(Auth)
