import React, { Component }     from "react";
import { Map, Marker, 
    GoogleApiWrapper }          from 'google-maps-react';
import { FacebookShareButton, 
    TwitterShareButton }        from 'react-share';
import NumberFormat             from "react-number-format";
import { Form,Input }           from "antd";

// import local files
import googleMap             from '../../../../environments/index';
import { PostShareDetails }  from '../../api/promiseApi';
import withRouter            from '../../../../shared/withRouter'
import { setHeight }         from '../../../../shared/setHeight';
import {checkIsMobile}       from "../../../../shared/checkIsMobile";
import {handleSubmitgeneric} from "../../components/shared/solarShare"
import {ReactComponent as 
    Group_of_people_Icon}     from "../../../../Assets/Images/group_of_people_yellow.svg";

class SolarCommunityForm extends Component {
    constructor(props) {
        super(props);
        this.state = {
            lat: null,
            lng: null,
            address: null,
            places: [],
            hrefValue: '#',
            isThankYou: false,
            isErrorMessage: false,
            socialClick : false
        };
    }
    componentWillMount() {
        this.updateLatLng(this.props);
    }

    componentDidMount(){
        window.cloudsponge && window.cloudsponge.init({
            sources: ['gmail','yahoo','icloud',,"outlook"],
            afterSubmitContacts: this.submitCloudespongeContacts.bind(this),

        });
    }

    componentWillReceiveProps(nextProps) {
        setHeight('homeOwnerWrapper', 'menuMobile');
        let data = nextProps.referralDetails.postHomeownerReferrals;
        if (data.data && this.state.isSolarPage) {
            this.props.setLoader({loader:false});
            this.props.receivePostReferralDetails('');
            this.setState({isSolarPage:false,lat:null,name:"",email:""},()=>{
                this.setState({ isThankYou: true, isErrorMessage: false });

                const hamburger = nextProps.history;
                if (hamburger.location && hamburger.location.state && hamburger.location.state.isHamburger) {
                    const state = { ...hamburger.location.state };
                    delete state.isHamburger;
                    nextProps.history.replace({ ...hamburger.location, state });
                }
            })
        }
        else if (data === 401 || data === 500 || data === 501 || data === 400 || data === 404) {
            this.props.receivePostReferralDetails('');
            this.setState({ isErrorMessage: true, isThankYou: false });
        }
        if(!this.state.lat)
            this.updateLatLng(nextProps);
    }

    componentDidUpdate(){
        if(!this.state.lat)
        this.updateLatLng(this.props);
    }

    shouldComponentUpdate() {
        if (!this.state.lat || this.state.socialClick) {
            return true;
        } else {
            return false;
        }
    }

    updateLatLng(nextProps) {
        if (
            nextProps.loggedInUser
            && nextProps.loggedInUser.homeownerData
            && nextProps.loggedInUser.homeownerData.lat
            && nextProps.loggedInUser.homeownerData.lng
            && nextProps.loggedInUser.homeownerData.address
            && nextProps.homeownersList
            && nextProps.homeownersList.ReferralMessage
        ) {
            this.setState({
                lat: Number(nextProps.loggedInUser.homeownerData.lat),
                lng: Number(nextProps.loggedInUser.homeownerData.lng),
                address: `${nextProps.loggedInUser.homeownerData.address.address1} ${nextProps.loggedInUser.homeownerData.address.address2} ${nextProps.loggedInUser.homeownerData.address.city} ${nextProps.loggedInUser.homeownerData.address.state} ${nextProps.loggedInUser.homeownerData.address.zipCode}`
            })
        }
    }

    beforeClick(method) {
        const bodyData = {
            "homeownerId": this.props.loggedInUser && this.props.loggedInUser.solarUnit && this.props.loggedInUser.solarUnit.homeownerId,
            "companyId": this.props.loggedInUser && this.props.loggedInUser.homeownerData && this.props.loggedInUser.homeownerData.companyId,
            "method": method,
            "share": "referral"
        };
        return PostShareDetails(bodyData)
    }


    onMapReady = (mapProps, map) => this.searchNearby(map, map.center);

    searchNearby = (map, center) => {
        const { google } = this.props;
        const service = new google.maps.places.PlacesService(map);

        // Specify location, radius and place types for your Places API search.
        const request = {
            location: center,
            radius: '500',
            type: ['food']
        };

        service.nearbySearch(request, (results, status) => {
            if (status === google.maps.places.PlacesServiceStatus.OK)
                this.setState({ places: results });
        });
    };


    handleSubmit = (e) => {
        e.preventDefault();
        this.saveReferral(handleSubmitgeneric(this.state, [],this.props));
    }

    submitCloudespongeContacts(contacts, source, owner) {
        this.saveReferral(handleSubmitgeneric(null, contacts,this.props));
    }
    saveReferral(body){
        if(!body)
            return;

        this.props.setLoader({loader:true});
        this.setState({
            lat : null,
            name : '',
            email : '',
            isSolarPage: true
        }, () => {
            if(this.nameTextElem){
                this.nameTextElem.value  = '';
                this.emailTextElem.value = '';
            }
            this.setState({phone:''});
            this.props.requestPostReferralDetails(body);
        })
    }

    setMessageHref(url, method) {
        this.beforeClick(method).then(res => {
            this.setState({socialClick: true}, () => {
                this.setState({ hrefValue: url })
            })
        }).catch(e => {
            console.log('error', e.toString())
        });
    }
    renderThankYou(){
        {checkIsMobile() &&  window.scroll({top: 0})}
        return(
                 <div className="solar-community-box">
                                    <center>
                                        <div>
                                            <i>THANK YOU FOR SHARING!</i>
                                            <p>We will notify you when your neighbor goes solar.</p>
                                        </div>
                                        <button
                                            type="button"
                                            className="btn yellow-btn premiumButtonFill premiumButtonText"
                                            onClick={() => this.setState({ isThankYou: false, isErrorMessage: false,lat:null},
                                                ()=>{
                                                    this.updateLatLng(this.props);
                                                })}
                                        >
                                            Continue
                                            </button>
                                    </center>
                 </div>
        )
    }

    referralMessageType = (systemGlance) => {
        if (systemGlance.solarGlance && systemGlance.solarGlance.referralType === 1) {
            return <h6>SHARE THE SUN <br />
            {(systemGlance.phaseGlance && systemGlance.phaseGlance.referralAmount) ? 'GIVE YOUR FRIEND $'+systemGlance.phaseGlance.referralAmount : ''}</h6>
        }
        else if (systemGlance.solarGlance && systemGlance.solarGlance.referralType === 2) {
            return <h6>REFER A FRIEND. <br />
            {(systemGlance.phaseGlance && systemGlance.phaseGlance.referralAmount) ? 'RECEIVE $'+systemGlance.phaseGlance.referralAmount : ''}</h6>
        }
    }

    render() {
        const { 
            homeownersList, 
            systemGlance,
            loggedInUser:{homeownerData}
        } = this.props;
        let hamburger = this.props.router.location && this.props.router.location.state && this.props.router.location.state.isHamburger;
        let referralId = this.props.loggedInUser && this.props.loggedInUser.homeownerData && this.props.loggedInUser.homeownerData.referralId;
        this.slug = localStorage.getItem("slug");

        const formSetting   = homeownerData.formSetting;
        const element       = document.getElementsByClassName('premiumLinks');
        const style         = element[0] && window.getComputedStyle(element[0]);
        const premumLink    = style && style.getPropertyValue('color');
      return (
            <React.Fragment>
                <div className='card-body map-bg'>
                    <div className='solar-community-drop-down'>
                        <a
                            href="#"

                            className="collapsed up-arra map-drop-down premiumButtonFill premiumButtonText notHover"
                            data-toggle="collapse"
                            data-target="#collapseThree"
                            aria-expanded="false"
                            aria-controls="collapseTwo"
                        >
                            Grow your solar community{" "}
                            <span className="pull-right">
                                {" "}
                                <i
                                    className="fa fa-angle-down"
                                    aria-hidden="true"
                                />
                            </span>
                        </a>
                        <div id="collapseThree" className={'collapse mobile3 ' + (this.props.isReferralCommunity || hamburger ? 'show' : '')}>
                            {(this.state.isThankYou && !this.state.isErrorMessage) && this.renderThankYou() }
                            {(this.state.isErrorMessage && (!this.state.isThankYou || hamburger)) &&
                                <div className="solar-community-box">
                                    <center>
                                        <div>
                                            <i>OOPS!</i>
                                            <p>Something went terribly wrong.</p>
                                        </div>
                                        <button
                                            type="button"
                                            className="btn yellow-btn premiumButtonFill premiumButtonText"
                                            onClick={() => this.setState({ isThankYou: false, isErrorMessage: false,lat:null },
                                                ()=>{
                                                    this.updateLatLng(this.props);
                                                })}
                                        >
                                            TRY AGAIN
                                            </button>
                                    </center></div>}
                            {/* Redner option to fill the form for referral */}
                            <div className="solar-community-box">

                                {((!this.state.isThankYou || hamburger) && !this.state.isErrorMessage) &&
                                    <React.Fragment>
                                  <div className="community-icon">
                                      <Group_of_people_Icon style={{height: '100%'}}/>
                                  </div>

                                        {this.referralMessageType(systemGlance)}
                                        <p>
                                            17 trillion watts is needed to power our global energy
                                        consumption. Make sure it's 17 terawatts of clean energy. {homeownersList
                                                && homeownersList.ReferralMessage}
                                        </p>
                                    </React.Fragment>}
                                {/* {(!this.state.isThankYou && !this.state.isErrorMessage) && */}
                              <form id="create-share-form" onSubmit={this.handleSubmit} style={{ display: !this.state.isThankYou && !this.state.isErrorMessage ? 'block' : 'none' }}>
                                  <div className="invite-box">
                                      <p>Easily share specific contacts</p>
                                      <a className="cloudsponge-launch" data-cloudsponge-source="gmail" > <img
                                          src={require("../../../../Assets/Images/Gmail_Icon.svg")}
                                          alt='Gmail_Icon.svg'
                                          width="25"
                                      /></a>
                                      <a className="cloudsponge-launch" data-cloudsponge-source="outlook"> <img
                                          src={require("../../../../Assets/Images/microsoft_icon.svg")}
                                          alt='microsoft_icon.svg'
                                          width="25"
                                      /></a>
                                      <a className="cloudsponge-launch" data-cloudsponge-source="yahoo"> <img
                                          src={require("../../../../Assets/Images/yahoo_icon.svg")}
                                          alt='yahoo_icon.svg'
                                          width="25"
                                      /></a>
                                      <a className="cloudsponge-launch" data-cloudsponge-source="icloud"> <img
                                          src={require("../../../../Assets/Images/icloud_icon.svg")}
                                          alt='icloud_icon.svg'
                                          width="25"
                                      /></a>
                                  </div>
                                  <div className="form-group">
                                      <Input
                                          type="text"
                                          ref= {el => this.nameTextElem = el} 
                                          placeholder="Name"
                                          className="form-control"
                                          onChange={e => { this.setState({ name: e.target.value }) }}
                                      />
                                  </div>
                                  <div className="form-group">
                                      <Input
                                          type="email"
                                          placeholder="Email"
                                          ref= {el => this.emailTextElem = el} 
                                          className="form-control"
                                          onChange={e => { this.setState({ email: e.target.value }) }}
                                      />
                                  </div>
                                  <div className="form-group">
                                      <NumberFormat
                                          type="tel"
                                          format={(formSetting && formSetting.phone)}
                                          placeholder="Phone Number"
                                          value= {this.state.phone}
                                          mask=" "
                                          className="form-control"
                                          onChange={(e) => this.setState({ phone: e.target.value })}
                                      />
                                  </div>
                                  <div className="button-wrap text-center">
                                      <button
                                          type="submit"
                                          className="btn yellow-btn premiumButtonFill premiumButtonText"
                                          disabled={this.props.isInstaller ? 'disabled' : ''}
                                      >
                                          {(systemGlance.solarGlance && systemGlance.solarGlance.referralType === 1) ? 'Give' : 'Invite'}
                                      </button>
                                  </div>
                              </form>
                                {/* } */}
                                {/* Render the option to share */}
                                {!this.state.isThankYou &&
                                    <div className="share-via-wrapper border-zero">
                                        <div className="heading-share premiumLinks" data-toggle="collapse" data-target="#share-media-box">
                                            {" "}
                                            Share on social media {" "}
                                        </div>
                                        {!this.props.isInstaller ?
                                            <ul id="share-media-box" className="white-box share-via-wrapper collapse">
                                                <li>
                                                    <FacebookShareButton beforeOnClick={() => this.beforeClick(1)} quote="Hey, I've gone solar and thought you might be interested. This is a link to my solar community. Check it out!" url={window.location.origin + '/' + this.slug + '/referral/' + referralId + '/referral/1'}>
                                                        <a
                                                            href="#"
                                                            className="facebook"
                                                        >
                                                            <img
                                                                src={require("../../../../Assets/Images/facebook.svg")}
                                                                alt='facebook.svg'
                                                                width="25"
                                                            />
                                                        </a>
                                                    </FacebookShareButton>
                                                </li>
                                                <li>
                                                    <TwitterShareButton beforeOnClick={() => this.beforeClick(2)} title="Hey, I've gone solar and thought you might be interested. This is a link to my solar community. Check it out!" url={window.location.origin + '/' + this.slug + '/referral/' + referralId + '/referral/2'}>
                                                        <a
                                                            href="#"
                                                            className="facebook"
                                                        >
                                                            <img
                                                                src={require("../../../../Assets/Images/twitter.svg")}
                                                                alt='twitter.svg'
                                                                width="25"
                                                            />
                                                        </a>
                                                    </TwitterShareButton>
                                                </li>
                                                <li className="disabled">
                                                    <a onClick={() => this.setMessageHref("https://api.whatsapp.com/send?text=Hey, I've gone solar and thought you might be interested. This is a link to my solar community. Check it out!" + window.location.origin + '/' + this.slug + '/referral/' + referralId + '/referral/4', 4)}
                                                        href={this.state.hrefValue}
                                                        className="message">
                                                        <img
                                                            src={require("../../../../Assets/Images/whatsapp.png")}
                                                            alt='sms.svg'
                                                            width="25"
                                                        />
                                                    </a>
                                                </li>
                                                <li className="disabled">
                                                    <a onClick={() => this.setMessageHref("sms:?&body=Hey, I've gone solar and thought you might be interested. This is a link to my solar community. Check it out!" + window.location.origin + '/' + this.slug + '/referral/' + referralId + '/referral/3', 3)}
                                                        href={this.state.hrefValue}
                                                        className="message"
                                                    >
                                                        <img
                                                            src={require("../../../../Assets/Images/sms.svg")}
                                                            alt='sms.svg'
                                                            width="25"
                                                        />
                                                    </a>
                                                </li>
                                            </ul> :
                                            <ul>
                                                <li>
                                                    <img
                                                        src={require("../../../../Assets/Images/facebook.svg")}
                                                        alt='facebook.svg'
                                                        width="25"
                                                        style={{ cursor: 'not-allowed' }}
                                                    />
                                                </li>
                                                <li>
                                                    <img
                                                        src={require("../../../../Assets/Images/twitter.svg")}
                                                        alt='twitter.svg'
                                                        width="25"
                                                        style={{ cursor: 'not-allowed' }}
                                                    />
                                                </li>
                                                <li>
                                                    <img
                                                        src={require("../../../../Assets/Images/whatsapp.png")}
                                                        alt='twitter.svg'
                                                        width="25"
                                                        style={{ cursor: 'not-allowed' }}
                                                    />
                                                </li>
                                                <li>
                                                    <img
                                                        src={require("../../../../Assets/Images/sms.svg")}
                                                        alt='sms.svg'
                                                        width="25"
                                                        style={{ cursor: 'not-allowed' }}
                                                    />
                                                </li>
                                            </ul>}

                                    </div>
                                }
                            </div>
                        </div>
                    </div>
                    {/* Render the location in google map */}
                    {
                            <Map
                                google={this.props.google}
                                onReady={this.onMapReady}
                                zoom={16}
                                mapTypeControl={false}
                                fullscreenControl={false}
                                zoomControl={false}
                                streetViewControl={false}
                                initialCenter={{
                                    lat: this.state.lat || (homeownerData && homeownerData.lat),
                                    lng: this.state.lng || (homeownerData && homeownerData.lng)
                                }}
                            >

                              {
                                  homeownersList
                                  && homeownersList.homesByAdd
                                  && homeownersList.homesByAdd.length !== 0
                                  && homeownersList.homesByAdd.map((homeowner, index) => {
                                      if (
                                          homeowner.lat
                                          && homeowner.lng
                                          && homeowner.nearaddress
                                      ) {
                                          return (
                                              <Marker
                                                  key={index}
                                                  title={homeowner.nearaddress}
                                                  position={{
                                                      lat: Number(homeowner.lat),
                                                      lng: Number(homeowner.lng)
                                                  }}
                                                  icon={{
                                                      url: require("../../../../Assets/Images/location.svg"),
                                                  }}
                                              />
                                          )
                                      }
                                      return '';
                                  })
                              }

                              <Marker
                                  title={this.state.address}
                                  position={{
                                    lat: this.state.lat || (homeownerData && homeownerData.lat),
                                    lng: this.state.lng || (homeownerData && homeownerData.lng)
                                  }}
                                  icon={{
                                    path:
                                      `M 0,-1
                                          C 0.5523, -1   1, -0.5523    1,0
                                          C 1, 0.5523    0.5523, 1     0,1
                                          C -0.5523, 1   -1, 0.5523    -1,0
                                          C -1, -0.5523  -0.5523, -1   0,-1`,
                                    fillColor: premumLink || "#FF6F47",
                                    fillOpacity: 1,
                                    strokeWeight: 0,
                                    rotation: 0,
                                    scale: 5,
                                  }}

                              />

                            </Map>
                    }

                </div>
            </React.Fragment>
        );
    }
}

export default GoogleApiWrapper({
    apiKey: googleMap.envConfig.googleMap.API_KEY
})(withRouter(SolarCommunityForm))
