// import npm packages
import React, { Component }   from 'react';
import {
  Route,
  Routes,
  Navigate
}                             from 'react-router-dom';
import SignIn                 from './screens/User/components/Login';
import InstallerLayout        from './screens/Layout/Installer';
import HomeownerLayout        from './screens/Layout/Homeowner';
import SalesforceGuide        from "./screens/User/components/salesforceGuide"
import ResetPassword          from './screens/User/components/ResetPassword';
import ForgotPassword         from './screens/User/components/ForgotPassword';
import TermsAndConditions     from './screens/User/components/TermsAndConditions';
import PrivacyAndPolicy       from './screens/User/components/PrivacyAndPolicy';
import Homepage               from './screens/Homepage/components/Homepage'
import ForceChangePassword    from './screens/User/components/ForceChangePassword';
import Auth                   from './shared/Auth';
import withRouter             from './shared/withRouter';
import DashboardCommon        from './screens/Common/DashboardCommon';
import HomeOwner              from './screens/Installer/components/homeOwner/homeOwner';
import AddHomeOwner           from './screens/Installer/components/addHomeOwner/AddHomeOwner';
import NewInstall             from './screens/Installer/components/addHomeOwner/NewInstall';
import ExistingInstall        from './screens/Installer/components/addHomeOwner/ExistingInstall';
import ExistingInstallSuccess from './screens/Installer/components/addHomeOwner/ExistingInstallSuccess';
import Setting                from './screens/Installer/container/settings';
import CompanySetting         from './screens/Installer/container/companySettings/CompanySetting';
import Search                 from './screens/Installer/components/Search';
import DropBoxAuth            from './screens/Installer/components/auth';
import SalesforceAuth         from './screens/Installer/components/auth';
import { jwtDecode }          from './shared/jwt';

import AdminLayout        from './screens/Layout/admin';
import Report             from "./screens/Admin/components/Report/Report";
import AdminMap           from "./screens/Admin/components/Map/map";
import AddCompany         from "./screens/Admin/components/dashboard/AddCompany";
import AdminDashboard     from "./screens/Admin/components/dashboard/dashboard";
import AdminUtility       from "./screens/Admin/components/dashboard/Utility";

import './styles/style.scss';
import ShareCommon        from './screens/homeowner/components/shared/shareCommon';
import CalculatorLanding  from'./screens/homeowner/components/shared/sharedPage/calculatorComponent';
import FAQ                    from './screens/homeowner/container/faq';
import Settings               from './screens/homeowner/container/settings';
import Resources              from './screens/homeowner/container/resources/resources';
import Contact                from './screens/homeowner/container/contact/contact';
import SolarCommunity         from './screens/homeowner/components/shared/solarCommunity';
import SystemAtGlance         from './screens/homeowner/components/shared/systemAtGlance';
import Documents              from './screens/homeowner/container/documents';
import StatusFeedPage         from './screens/homeowner/container/statusFeed/statusFeedPage';

import CampaignDashboard  from './screens/campaign/components/dashboard/dashboard';
import CreateCampaign     from './screens/campaign/components/new-campaign/create-campaign';
import ExistingCampaign   from './screens/campaign/components/ExistingCampaign/ExistingCampaign';
import Hoc                from './HocApp';

let slug = '';

const getUser = () => {
  const token = localStorage.getItem('token');
  return token ? jwtDecode(token) : null;  
}

const ValidateAdminUser = ({ children }) => {
  return !getUser() ? (<Navigate to={{ pathname: '/' }} />) : children
};

class App extends Component {
  constructor(props) {
    super(props);
  }

  componentWillMount() {
    window.scroll({top: 0});
    let url = window.location.pathname;
    slug = url.split('/')[1];
    const array = ['dropboxAuth','forgotpassword','resetpassword','oauth','terms',
    'privacy-policy','login','salesforce-configuration','salesforce-user-guide','admin'];
    // if (localStorage.getItem("isLogout") == "true") {
      if (array.indexOf(slug) === -1)
        localStorage.setItem('slug', slug);
    // }
  }

  componentDidMount() {
    this.addRemoveColor();
  }

  componentDidUpdate() {
    this.addRemoveColor();
  }

  addRemoveColor() {
    if (
      (window.location.pathname.includes('/forgotpassword') ||
        window.location.pathname.includes('/resetpassword') ||
        window.location.pathname.includes('/terms') ||
        window.location.pathname.includes('/privacy-policy') ||
        window.location.pathname.includes('/forcechangepassword') ||
        window.location.pathname.includes('/login') ||
        window.location.pathname === `/${slug}` ||
        window.location.pathname === `/${slug}/`) && (window.location.pathname !== '/')
    ) {
      document.body.classList.add('bg-color');
      document.body.classList.remove('homeowner-bg');
    } 
  }

  get getSlug() {
    return localStorage.getItem('slug') || localStorage.getItem('token');
  }

  render() {
    window.scroll({top: 0});
    if (this.getSlug) {
      const user = getUser()
      const Frame = user && user.role && user.role.includes('homeowner') ? HomeownerLayout : InstallerLayout
      return (
        <div>
          <Auth />
          <Routes>
            <Route path="/admin/map" element={
              <ValidateAdminUser>
                <AdminLayout component={AdminMap} />
              </ValidateAdminUser>
            } />
            <Route path="/admin/report" element={
              <ValidateAdminUser>
                <AdminLayout component={Report} />
              </ValidateAdminUser>
            } />
            <Route path="/admin/addcompany" element={
              <ValidateAdminUser>
                <AdminLayout component={AddCompany} />
              </ValidateAdminUser>
            } />
            <Route path="admin/utility" element={
              <ValidateAdminUser>
                <AdminLayout component={AdminUtility} />
              </ValidateAdminUser>
            } />
            <Route path="/admin/dashboard" element={
              <ValidateAdminUser>
                <AdminLayout component={AdminDashboard} />
              </ValidateAdminUser>
            } />
            <Route path="/admin/:companyId/companysetting" element={
              <ValidateAdminUser>
                <AdminLayout component={CompanySetting} />
              </ValidateAdminUser>
            } />

            <Route path="/:slug/login" element={<SignIn />} />
            <Route path="/:slug/terms" element={<TermsAndConditions />} />

            <Route path="/dropboxAuth" element={
              <Frame component={DropBoxAuth} />
            } />

            <Route path="/salesforce-user-guide" element= {<SalesforceGuide />}/>
            <Route path="/salesforce-configuration" element= {<SalesforceGuide />}/>

            <Route path="/oauth/callback" element={
              <Frame component={SalesforceAuth} />
            } />

            <Route path="/:slug/privacy-policy" element={<PrivacyAndPolicy />} />
            <Route path="/:slug/forcechangepassword" element={<ForceChangePassword />}/>
            <Route path="/:slug/forgotpassword" element={<ForgotPassword />} />
            <Route path="/:slug/resetpassword/:email" element={<ResetPassword />}/>

            <Route path="/:slug/:companyId/newinstall" element={
              <Frame component={NewInstall} />
            } />
            <Route path="/:slug/addhomeowner/success" element={
              <Frame component={ExistingInstallSuccess} />
            } />
            <Route path="/:slug/:companyId/addhomeowner" element={
              <Frame component={AddHomeOwner} />
            } />
            <Route path="/:slug/:companyId/existinginstall" element={
              <Frame component={ExistingInstall} />
            } />
            <Route path="/:slug/:companyId/dashboard/:id/homeowner" element={
              <Frame component={HomeOwner} />
            } />
            <Route path="/:slug/:companyId/dashboard" element={
              <Frame component={DashboardCommon} />
            } />
            <Route path="/:slug/:companyId/search" element={
              <Frame component={Search} />
            } />
            <Route path="/:slug/:companyId/companysetting" element={
              <Frame component={CompanySetting} />
            } />
            
            {/* home owner routes start */}
            <Route path="/:slug/dashboard" element={
              <Frame component={DashboardCommon} />
            } />
            <Route path="/:slug/faq" element={
              <Frame component={FAQ} />
            } />
            <Route path="/:slug/settings" element={
              <Frame component={Settings} />
            } />
            <Route path="/:slug/resources/:slide/:slideNumber" element={
              <Frame component={Resources} />
            } />
            <Route path="/:slug/resources/:slide" element={
              <Frame component={Resources} />
            } />
            <Route path="/:slug/resources" element={
              <Frame component={Resources} />
            } />
            <Route path="/:slug/contact" element={
              <Frame component={Contact} />
            } />
            <Route path="/:slug/systemGlance" element={
              <Frame component={SystemAtGlance} />
            } />
            <Route path="/:slug/solarCommunity" element={
              <Frame component={SolarCommunity} />
            } />
            <Route path="/:slug/documents" element={
              <Frame component={Documents} />
            } />

            <Route
              path="/:slug/statusFeed/:touchName"
              element={<StatusFeedPage />}
            />
             <Route
              path="/:slug/signature/:signatureId"
              element={<StatusFeedPage />}
            />
            <Route
              path="/:slug/campaigns/:campaignId"
              element={<StatusFeedPage />}
            />
            <Route
              path="/:slug/referral/:referralId/:share/:method/:date/:offset"
              element={<ShareCommon />}
            />

            <Route
              path="/:slug/referral/:referralId/:share/:method/calculate"
              element={<CalculatorLanding />}
            />

            <Route
              path="/:slug/referral/:referralId/:share/:method"
              element={<ShareCommon />}
            />

            {/* home owner routes end */}

            <Route path="/:slug/:companyId/dashboard" element={
              <Frame component={DashboardCommon} />
            } />
            <Route path="/:slug/:companyId/setting" element={
              <Frame component={Setting} />
            } />
            <Route path="/:slug/:companyId/campaign/create" element={
              <Frame component={CreateCampaign} />
            } />
            <Route path="/:slug/:companyId/campaign/dashboard" element={
              <Frame component={CampaignDashboard} />
            } />

            <Route path="/:slug/:companyId/campaign/:id" element={
              <Frame component={ExistingCampaign} />
            } />

            <Route path="*" element={<SignIn />} />
          </Routes>
        </div>
      );
    } else {
      return (
        <div>
          <Auth />
          <Routes>
            <Route path="/admin/dashboard" element={
              <ValidateAdminUser>
                <AdminLayout component={AdminDashboard} />
              </ValidateAdminUser>
            } />

            <Route path="/forgotpassword" element={<ForgotPassword />} />
            <Route path="/salesforce-user-guide" element= {<SalesforceGuide />}/>
            <Route path="/salesforce-configuration" element= {<SalesforceGuide />}/>
            <Route path="/resetpassword/:email" element={<ResetPassword />}/>
            <Route path="/forcechangepassword" element={<ForceChangePassword />}/>
            <Route path="/privacy-policy" element={<PrivacyAndPolicy />} />
            <Route path="/terms" element={<TermsAndConditions />} />
            <Route path="/login" element={<SignIn />} />
            <Route path="/:slug/referral/:referralId/:share/:method/:date/:offset" element={<ShareCommon />}/>
            <Route path="/:slug/referral/:referralId/:share/:method/calculate"element={<CalculatorLanding />}/>
            <Route path="/:slug/referral/:referralId/:share/:method" element={<ShareCommon />}/>
            <Route path="/:slug" element={<SignIn />} />
            <Route path="/" element={<Homepage />} />
          </Routes>
        </div>
      );
    }
  }
}

export default Hoc(withRouter(App));
