import React                        from "react";
import { bindActionCreators }       from "redux";
import { connect }                  from 'react-redux';
import { LoadingOutlined }          from '@ant-design/icons';
import RedoOutlined                 from '@ant-design/icons';
import { Button, Form, Input, Modal, 
        Select, Spin, Tooltip }     from 'antd';
import {requestGetUtilityRatePlanData,
    requestSetElectricUtility,
    receiveSetElectricUtility,
    receiveDeleteElectricUtility,    
    requestDeleteElectricUtility}   from "../../Actions/setting";
import withRouter                   from '../../../../shared/withRouter';
import {setLoader}                  from '../../../Common/Loader/action';
import addHardwareIcon              from "../../../../Assets/Images/add-hardware.svg"
import deleteIcon                   from "../../../../Assets/Images/delete-icon.png"
import editDetailsIcon              from "../../../../Assets/Images/edit-details-icon.png"
import {ReactComponent as CaretIcon}from "../../../../Assets/Images/path-2.svg";

const Option = Select.Option;
const antIcon = (
    <LoadingOutlined
        className="dashboard-loading"
        style={{ fontSize: 60 }}
        spin
    />
);

const UtilityForm = props => {

    const {
        handleEdit,
        electricUtilityListdisplay
    } = props;

    const [form] = Form.useForm();

    const onFinish = values => {
        // hacky, just for now because looks like 2 separate use cases same time
        if (form) {
            form.resetFields();
        }
        handleEdit(values);
    };

    return (
        <Form
            form={form}
            onFinish={onFinish}
        >
            <table className="metrics-table">
                <thead>
                    <tr>
                    
                        <th className="table-header seprator-right">Name</th>
                        <th className="table-header seprator-right">ABBR</th>
                        <th className="table-header seprator-right">Rate Plan</th>
                        <th className="table-header seprator-right">Key</th>
                    </tr>
                </thead>
                <tbody>
                {
                    electricUtilityListdisplay()
                }
                </tbody>
            </table>
        </Form>
    )
}

class AdminUtilityForm extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            addUtility: false
        };
    }

    componentDidMount(){
        this.props.requestGetUtilityRatePlanData();
    }

    electricUtilityDeleteStore = (electricutilityList) => {
        let data = {
          electricUtilityId: electricutilityList.id,
          isDisabled: !electricutilityList.isDisable
        }
       
        this.props.requestDeleteElectricUtility(data)
        this.props.setLoader({loader:true});
        this.setState({isEditable : null});
    }

    renderAddUtility() {
        return(
            <Modal
                visible={this.state.addUtility}
                className="add-utility popUp"
                width={920}
                footer={null}
                onCancel={() => {
                    this.setState({
                      addUtility: false
                    });
                  }}
            >
                <div className="modal-header cmn-modal-header">
                    <h4 className="modal-title cmn-modal-title">
                    <img src={addHardwareIcon} alt="" className="modal-title-logo" />
                        Add utility
                     </h4>
                </div>
                <Form
                    id="myForm"
                    className="modal-body cmn-modal-body"
                    onFinish={this.handleEdit}
                >
                    <div className="row justify-center-content space-bottom">
                        <div className="col-md-2">
                            <Form.Item hasFeedback name="names[0]" rules={[{ required: true }]}>
                                <Input placeholder="Name" />
                            </Form.Item>
                        </div>
                        <div className="col-md-3">
                            <Form.Item name="abbr[0]" hasFeedback rules={[{ required: true }]}>
                                <Input placeholder="Abbreviation" />
                            </Form.Item>
                        </div>
                        <div className="col-md-3">
                            <Form.Item name="rateplan[0]" hasFeedback rules={[{ required: true }]}>
                                <Select suffixIcon = {<CaretIcon/>}
                                    className="form-control"
                                    mode="multiple"
                                    placeholder="Rate Plan"
                                >
                                    {
                                      this.props.utilityAndRatePlan && this.props.utilityAndRatePlan.data && this.props.utilityAndRatePlan.data.ratePlanList && this.props.utilityAndRatePlan.data.ratePlanList.map(
                                        (utilityAndRatePlan, i) => (
                                          <Option
                                            value={utilityAndRatePlan.id}
                                            key={utilityAndRatePlan.id}
                                          >
                                            {utilityAndRatePlan.name}
                                          </Option>
                                        )
                                      )}
                                  </Select>
                            </Form.Item>
                        </div>
                        <div className="col-md-2">
                            <Form.Item hasFeedback name="key[0]" rules={[{ required: true }]}>
                                <Input placeholder="Key" />
                            </Form.Item>
                        </div>
                    </div>

                    <div className="form-submitted-btn">
                        <button
                            className="btn btn-secondary"
                            onClick={() => this.setState({addUtility: false})}
                            type="button"
                        >
                            Cancel
                        </button>
                        <button
                            className="btn btn-primary"
                            type="submit"
                        >
                            Save
                        </button>
                    </div>

                </Form>               
            </Modal>
        )
    }

    componentWillReceiveProps(nextProps, nextstate) {
        if (nextProps.deleteUtilityProp  && nextProps.deleteUtilityProp.status === 200) {
            nextProps.requestGetUtilityRatePlanData();
            nextProps.receiveDeleteElectricUtility({})
            nextProps.setLoader({ loader: false });
        }

        if (nextProps.addUtilityProp && nextProps.addUtilityProp.status === 200) {
            nextProps.receiveSetElectricUtility({})
            nextProps.setLoader({ loader: false });
        }
    }

    handleInlineEdit = electricutilityList => {
        const requestSetElectricUtility = this.props.requestSetElectricUtility
        const stateSetter = this.setState.bind(this)
        return e => {
            requestSetElectricUtility(electricutilityList)
            stateSetter({
                isEditable: false,
                addUtility: false
            });
        }
    }

    handleEdit = values => {
        if (JSON.stringify(values) !== '{}') {
            let list = this.props.utilityAndRatePlan.data.electricutilityList;
            let prevValue = list && list.filter(ele => {
                return ele.id === this.state.isEditable;
            })[0];

            if (prevValue && !this.state.addUtility) {
                values['key[0]'] = [prevValue['key']];
            }

            if (values['key[0]']) {
                let data = { values }
                this.setState({
                    isEditable: false,
                    addUtility: false
                });
                this.props.setLoader({
                    loader: true
                });
                this.props.requestSetElectricUtility(data)
            }
        }
    }

    electricUtilityListdisplay() {
        const ElectricUtilityList = this.props.utilityAndRatePlan && this.props.utilityAndRatePlan.data && this.props.utilityAndRatePlan.data.electricutilityList ? this.props.utilityAndRatePlan.data.electricutilityList : [];

        return ElectricUtilityList.map((electricutilityList, index) => {
            return (
                <tr key={index} className={`metrics-row ${electricutilityList.isDisable ? "deletedelectricutility" : ""}`}>
                    <td key={index} className="metrics-data">
                        {
                            this.state.isEditable && this.state.isEditable === electricutilityList.id
                            ?
                            <Form.Item
                                name="names[0]"
                                rules={ [{ message: "Please input passenger's name or delete this field." }] }
                                initialValue={ electricutilityList.name }
                            >
                                <Input className="edit-box" type="text"  />
                            </Form.Item>
                            : <span>{
                                electricutilityList.name
                            }</span>
                        }
                    </td>
                    <td className="metrics-data">
                        {
                            this.state.isEditable && this.state.isEditable === electricutilityList.id
                            ?
                            <Form.Item
                                name="abbr[0]"
                                rules={ [{ message: "Please input passenger's name or delete this field." }] }
                                initialValue = { electricutilityList.abbr }
                            >
                                <Input className="edit-box" type="text"  />
                            </Form.Item>
                            : <span> {
                                electricutilityList.abbr
                            }</span>
                        }
                    </td>
                    <td className="metrics-data">
                        {this.state.isEditable
                            && this.state.isEditable === electricutilityList.id
                            ?
                            <Form.Item
                                name="rateplan[0]"
                                rules = { [{ required: true }] }
                                initialValue = { electricutilityList.rateplan ?
                                        electricutilityList.rateplan.map(val => (val.rateId && Number(val.rateId)))
                                        : [] }
                            >
                                <Select
                                    mode="multiple"
                                    placeholder="Select"
                                >
                                    {
                                        this.props.utilityAndRatePlan.data.ratePlanList && this.props.utilityAndRatePlan.data.ratePlanList.map(
                                            (utilityAndRatePlan, i) => (
                                                <Option
                                                    value={utilityAndRatePlan.id}
                                                    key={utilityAndRatePlan.id}
                                                >
                                                    {utilityAndRatePlan.name}
                                                </Option>
                                            )
                                        )}
                                </Select>
                            </Form.Item>

                            :
                            electricutilityList['rateplan'] ? electricutilityList['rateplan'].map((rate, index) =>
                                <span key={index}>{electricutilityList['rateplan'].length === (index + 1) ? ` ${rate['display']}` : ` ${rate['display']},`}</span>)
                                : 'NA'
                        }
                    </td>

                    <td className="metrics-data">{electricutilityList.key}
                        <span className="edit-option">
                            {
                                electricutilityList.isDisable ?
                                <Tooltip overlayClassName="black-tooltip" title="Restore">
                                    <RedoOutlined
                                        className="dynamic-button"
                                        onClick={() => this.electricUtilityDeleteStore(electricutilityList)}
                                    />
                                </Tooltip>

                                    :
                                    <img src={deleteIcon}
                                        className="dynamic-button"
                                        type="delete"
                                        onClick={
                                            () => this.electricUtilityDeleteStore(electricutilityList)
                                        }
                                    />
                            }

                            {
                                this.state.isEditable && this.state.isEditable === electricutilityList.id ?
                                    <Button
                                        className="dynamic-button"
                                        type="submit"
                                        onClick={this.handleInlineEdit(electricutilityList)}
                                    >
                                        Save
                                    </Button>
                                    :

                                    <img src={editDetailsIcon}
                                        className="dynamic-button"
                                        type="edit"
                                        onClick={form => this.editModule(electricutilityList.id, form)}
                                    />
                            }
                        </span>
                    </td>
                </tr>
            )
        })
    } 

    editModule = (key, form) => {
        this.setState({
            isEditable: key
        });
        if (form && form.resetFields) {
            form.resetFields();
        }
    }

    renderUtility() {
        this.electricUtilityListdisplay = this.electricUtilityListdisplay.bind(this)
        return (
            <div className="metrics-table-box" style={{ 'marginTop': '185px' }}>
                <div className="table-box-header dashboard-nav-wrapper">
                    <h2 className="table-type"> Utilities </h2>
                    <div className="nav-item nav-add-link"
                        style={{
                            'float': 'right',
                            'marginRight': '5px',
                            'marginTop': '10px'
                        }}
                        onClick={() => {
                            this.setState({
                                addUtility: true
                            })
                        }}
                    >
                        <a className={`nav-link addhomeowner`}>+{" "}
                            <span>Add Utility</span>
                        </a>
                    </div>
                </div>
                <div className="table-responsive box-Shadow">
                    <UtilityForm
                        handleEdit={this.handleEdit}
                        electricUtilityListdisplay={this.electricUtilityListdisplay}
                    />
                </div>
            </div>        
        )
    }

    render() {
        if (localStorage.getItem("token")) {
            return (
                <Spin
                    spinning={this.props.loader}
                    indicator={antIcon}
                    style={{ color: "#04bfff" }}
                >
                    {
                        this.renderUtility()
                    }
                    {
                        this.state.addUtility && this.renderAddUtility()
                    }
                </Spin>
            )
           
        } else {
            this.props.router.navigate("/");
            return null;
        }
    }

}

const mapStatetoProps = state => {
    return {
        loader: state.loader.loader.loader,
        deleteUtilityProp   : state.adminSetting.deleteElectricUtility,
        addUtilityProp      : state.adminSetting.setElectricUtility,
        utilityAndRatePlan  : state.adminSetting.getUtilityAndRatePlan
    }
}

const mapDispatchToProps = dispatch =>
    bindActionCreators({
        requestGetUtilityRatePlanData,
        requestDeleteElectricUtility,
        receiveDeleteElectricUtility,
        requestSetElectricUtility,
        receiveSetElectricUtility,
        setLoader
    },
        dispatch
    )

export default withRouter(
    connect(
        mapStatetoProps,
        mapDispatchToProps
    )(AdminUtilityForm)
);
