// import node modules
import {momentLocalizer,
      Calendar }            from 'react-big-calendar'
import ReactDOM             from "react-dom";
import {Overlay,Tooltip}    from "react-bootstrap";
import moment               from "moment";
import { connect }          from "react-redux";
import {bindActionCreators} from "redux";
import "react-big-calendar/lib/css/react-big-calendar.css";
import React, { Component,useState, useRef } from 'react';
import withRouter       from '../../../../shared/withRouter';
import { getToken }     from '../../../../shared/util';
import {checkIsMobile}  from "../../../../shared/checkIsMobile"
import EnergyTipsData   from './EnergyTipsData';
import { convertDate }  from '../../../../shared/convertDate';
import MobileEnergyTips from './mobileEnergyTips';
import EnergyFlow       from '../shared/energyFlow';
import { Carousel }     from 'react-responsive-carousel';
import StatusFeed       from '../../container/statusFeed';
import {getUpdateHomeOwner}     from "../../api/homeOwner"
import {filterHomeownerTouches} from '../../../Common/FilterTouchHistory'
import {
  requestLoggedInHomeownerAction,
}                       from '../../../homeowner/actions/homeOwner';
let mobileWidth = document.body.offsetWidth;
let mobile2 = 767;

const localizer = momentLocalizer(moment);

class EnergyTips extends Component {
  constructor(props) {
    super(props);
    this.monthHistory = {};
    this.events = [];
    this.historyMonth = moment().format('YYYYMM');
    this.state = {
      peakData:{},
      date: null,
      count: 0,
      offset: null,
      isInfiniteLoop: false,
      centerMode: false
    };
  }



  Event = (event)=> {
    const [showTooltip, setShowTooltip] = useState(false);  
    let {energyReport} = this.props;
    const closeTooltip = () => {
      setShowTooltip(false);
    };
  
    const openTooltip = () => {
      setShowTooltip(true);
    }
    const ref = useRef(null);
  
    const getTarget = () => {
      return ReactDOM.findDOMNode(ref.current);
    };

    if(ref.current && ref.current.textContent && !this.monthHistory[ref.current.textContent]){
      return(<></>)
    }else  
    return (
      <div ref={ref}>
        <span onMouseOver={openTooltip} onMouseLeave={closeTooltip}>{event.title}</span>
        <Overlay
          rootClose
          target={getTarget}
          show={showTooltip}
          placement="top"
          onHide={closeTooltip}
        >
          <Tooltip  className="energyToolTip">
            <div>
              <strong>Produced:{" "}</strong>{(event.event.production/energyReport.interval).toFixed(1)} kWh
            </div>
            {
              this.isConsumption && <div>
                <strong>Consumed:{" "}</strong>{(event.event.consumption/energyReport.consumptionInterval).toFixed(1)} kWh
               </div>
            }
          </Tooltip>
        </Overlay>
      </div>
    );
  }

  getCustomToolbar = (toolbar) => {
    this.toolbarDate = toolbar.date;
    
    const goToBack = () => {
      let mDate = toolbar.date;
      let newDate = moment(mDate).subtract(1, 'month').endOf('month').toDate();
      toolbar.onNavigate('prev', newDate);
    }
    const goToNext = () => {
      let mDate = toolbar.date;
      let newDate = new Date(
        mDate.getFullYear(),
        mDate.getMonth() + 1,
        1);
      toolbar.onNavigate('next', newDate);
    }
    if(this.state.date){
      var monthName = moment(this.state.date).format('MMMM YYYY');
    }else{
      var monthName = moment(new Date()).format('MMMM YYYY');
    }

    return (
      <div className="toolbar-container">
        <div className="navigation-buttons premiumLinks">
          <i className="navigation fa fa-chevron-left fa-lg" onClick={goToBack} aria-hidden="true"></i>
          <label className='label-date'>{monthName}</label>
          <i className="navigation fa fa-chevron-right fa-lg" onClick={goToNext} aria-hidden="true"></i>
        </div>
      </div >
    )
  }


  setDate(date) {
    this.setState({
      date
    });
  }
  componentWillMount() {
    if (mobileWidth <= mobile2 && !this.state.centerMode) {
      this.setState({
        centerMode: true
      });
    } else if (this.state.centerMode) {
      this.setState({
        centerMode: false
      });
    }
  }
  componentWillReceiveProps(nextProps) {
    this.slug = localStorage.getItem("slug");
    if (nextProps.loggedInUser
      && nextProps.loggedInUser.solarUnit
      && nextProps.loggedInUser.solarUnit.phase == 6
      && !nextProps.loggedInUser.solarUnit.transitionStatus
      && !nextProps.isInstaller
    ) {
      let param = {
        companyId: nextProps.loggedInUser.homeownerData.companyId,
        homeownerId: nextProps.loggedInUser.homeownerData.id
      }
      this.props.requestLoggedInHomeownerAction(param);
      getUpdateHomeOwner(param);
      nextProps.requestUpdateHomeOwner(param);
      localStorage.setItem('ismonitoringslides', true);
      nextProps.router.navigate(`/${localStorage.getItem('slug')}/resources/5`);
    }
  }

   DateCell = ({range,value,children}) => {
    let {energyReport} = this.props;
    let custom_class_name = '';
    let activeDate =''
    if(moment(this.state.date).format('YYYYMMDD')===moment(value).format('YYYYMMDD')){
      activeDate = 'premiumLinks bgColor'
    }

    if(this.monthHistory[moment(value).format("YYYY-MM-DD")]){
      let t= moment(value).format("YYYY-MM-DD");
      let temp = this.isConsumption ? this.monthHistory[t]['totalConsumption']/energyReport.consumptionInterval : this.maxProd/energyReport.interval;
      let prod = this.monthHistory[t]['totalProduction']/energyReport.interval;
      custom_class_name = `${activeDate} stylecalender _${parseInt((prod/temp)*10)}`
    }
   
    return (
     <div className={ "date-in-past "+custom_class_name }>
      { children }
     </div>
    )
   }

   calcMaxPeakMobile = (data) => {
    if (data && (this.state.peakData.peakTime !== data.peakTime)) {
      this.setState({ peakData: data })
    }
  }

  renderDashboard = () => {
    let { eversignDetails,loggedInUser,systemGlance,activeCampaign,energyReport,companyJourney,magicData } = this.props;
    let data = energyReport && energyReport.monthlyHistory;
    this.isConsumption = true;
    if(!energyReport.totalConsumption){
      this.isConsumption = false
    }
    if(data && data.length>0 && JSON.stringify(this.monthHistory)==='{}'){
      this.maxProd =1;
      data.forEach((ele,index)=>{
        if(ele.date !== moment().format('YYYY-MM-DD')){
          this.events.push({
            id          : index,
            title       : ele.date,
            allDay      : true,
            start       : moment(ele.date).toDate(),
            end         : moment(ele.date).toDate(),
            production  : ele.totalProduction,
            consumption : ele.totalConsumption
          })
          this.monthHistory[ele.date] = {};
          if((ele.totalProduction> this.maxProd)&&!this.isConsumption)
            this.maxProd = ele.totalProduction;
          Object.assign(this.monthHistory[ele.date],ele);
        }
      })
    }

    let currentDate = new Date().getTime();
    let {peakData} = this.state;
    let activationDate = systemGlance &&
      systemGlance.solarGlance &&
      systemGlance.solarGlance.dailySyncTime &&
      systemGlance.solarGlance.activationDate &&
      moment(systemGlance.solarGlance.activationDate * 1).startOf('day').valueOf();
      let titleValue = loggedInUser && loggedInUser.homeownerData &&  loggedInUser.homeownerData.companyName;
    return (
      <>
      <title>My Dashboard - {titleValue}</title>
      <div className="next-level-wrapp w-100">
        <div className="mobile-view">
          {checkIsMobile() &&<MobileEnergyTips
            homeOwnerId={this.props.homeOwnerId}
            receiveHomeownerEnergyData={this.props.receiveHomeownerEnergyData}
            requestHomeownerEnergyData={this.props.requestHomeownerEnergyData}
            setLoader={this.props.setLoader}
            peakData ={peakData}
            energyReport={this.props.energyReport}
            loggedInUser={this.props.loggedInUser}
            monthHistory = {this.monthHistory}
            maxProd = {this.maxProd}
            setDate={date => {
              this.setDate(date);
            }}
          />}
          <div className="white-box-secondary margb30 border0 padd0">
            <EnergyFlow
              energyReport={this.props.energyReport}
              loggedInUser={this.props.loggedInUser}
              isInstaller={this.props.isInstaller}
              systemGlance={this.props.systemGlance}
              monthHistory = {this.monthHistory}
              maxProd = {this.maxProd}
              calcMaxPeakMobile= {this.calcMaxPeakMobile}
              setDate={date => {
                this.setDate(date);
              }}
              {...this.props}
            />
          </div>
        </div>
        <div className="white-box-secondary margb35 desktop-view">
          <div className="heading" style={{ fontWeight: '400','margin-top': '13px' }}>
            USAGE HISTORY
          </div>
          <div className="calendar-box">
          <Calendar
            components={{ 
              dateCellWrapper: this.DateCell,
              event: this.Event ,
              toolbar: this.getCustomToolbar }}
            events={this.events}
            startAccessor="start"
            endAccessor="end"
            defaultDate={moment().toDate()}
            localizer={localizer}
            maxDate={new Date()}
            onNavigate = {(date)=>{
              if(date<=(new Date))
              this.getDateEnergyReport(date);
            }}
            views = {['month']}
          />
          </div>
        </div>

        {eversignDetails &&
          eversignDetails.map(detail => (
            !detail.signerSigned && 
            <StatusFeed
              {...this.props}
              docCreatedAt={detail.createdAt}
              eversignDocument = {detail}
              isDocument={true}
              isInstaller={this.props.isInstaller}
            />
          ))}



        {/* {first one for weekly} */}
        {this.props.loggedInUser &&
          this.props.loggedInUser.homeownerData &&
          !this.props.loggedInUser.homeownerData.isExisting &&
          (currentDate - activationDate) / 86400000 >= 7 &&
          (currentDate - activationDate) / 86400000 < 30 && (
            <StatusFeed
              feedType = "weekly"
              feedTime = {parseInt(this.props.systemGlance.solarGlance.activationDate ) + 7 * 86400000}
              touchNameData={{
                touchName: '1 week impact and survey',
                createdAt: parseInt(this.props.systemGlance.solarGlance.activationDate ) + 7 * 86400000
              }}
              {...this.props}
              showCard={true}
            />
          )}
        {/* {second one for month} */}
        {this.props.loggedInUser &&
          this.props.loggedInUser.homeownerData &&
          !this.props.loggedInUser.homeownerData.isExisting &&
          (currentDate - activationDate) / 86400000 >= 30 &&
          (currentDate - activationDate) / 86400000 < 60 && (
            <StatusFeed
              feedType= "monthly"
              feedTime = {parseInt(this.props.systemGlance.solarGlance.activationDate )  + 30 * 86400000}
              touchNameData={{
                touchName: '1st Month Impact',
                createdAt: parseInt(this.props.systemGlance.solarGlance.activationDate )  + 30 * 86400000
              }}
              {...this.props}
              showCard={true}
            />
          )}
        {/* {third one for year} */}
        {this.props.loggedInUser &&
          this.props.loggedInUser.homeownerData &&
          !this.props.loggedInUser.homeownerData.isExisting &&
          (currentDate - activationDate) / 86400000 >= 365 &&
          (currentDate - activationDate) / 86400000 < 395 && (
            <StatusFeed
            feedType= "yearly"
            feedTime= {parseInt(this.props.systemGlance.solarGlance.activationDate )  + 365 * 86400000}
              touchNameData={{
                touchName: '1 Year Impact',
                createdAt: parseInt(this.props.systemGlance.solarGlance.activationDate )  + 365 * 86400000
              }}
              {...this.props}
              showCard={true}
            />
          )}

        {/* Render Status feed */}
        {this.props.loggedInUser &&
          this.props.loggedInUser.homeownerData &&
          this.props.loggedInUser.solarUnit &&
          this.props.loggedInUser.solarUnit.touchHistory &&
          this.props.loggedInUser.solarUnit.touchHistory.length !== 0 &&
          this.props.loggedInUser.solarUnit.phase &&
          filterHomeownerTouches(
            this.props.loggedInUser.solarUnit.touchHistory,
            activeCampaign, 'whatsNext',this.props.companyJourney,magicData
          ).map((touchNameData, index) => {
            const whatsNextData = (touchNameData &&touchNameData.touchName) && (companyJourney &&companyJourney[touchNameData.touchName.toLowerCase().replace(/ /g, '')]);
            if ((whatsNextData && whatsNextData.phase && 
              !this.props.loggedInUser.homeownerData.isExisting)|| (touchNameData && touchNameData.isCampaign) || (touchNameData && touchNameData.Customer_Email)){
              return (
                <StatusFeed
                  {...this.props}
                  isCampaign = {touchNameData.isCampaign}
                  touchNameData={touchNameData}
                  key={index}
                  campaignData={touchNameData.isCampaign && touchNameData}
                  isMagicFileData = {(touchNameData && touchNameData.magicData) ? true : false}
                />
              );
            }
            return '';
          })}
      </div>
      </>
    );
  };

  getDateEnergyReport = (date) =>{
    this.setState({
      date
    });
    let requestMonthHistory = false;
    let requestMonth = moment(date).format('YYYYMM');
    if(this.historyMonth!=requestMonth){
      this.historyMonth = requestMonth;
      this.monthHistory = {};
      requestMonthHistory = true;
    }
    this.props.receiveHomeownerEnergyData({data:{monthlyHistory:[]}});
    this.props.setLoader({ loader: true });
    this.props.requestHomeownerEnergyData({
      sync: false,
      date: convertDate(date),
      homeownerId: this.props.homeOwnerId,
      offset: this.state.offset,
      requestMonthHistory
    });
    localStorage.setItem('energyReportDate', date.toDateString());
  }

  render() {
    if (getToken()) {
      return this.renderDashboard();
    } else {
      this.props.router.navigate('/');
      return null;
    }
  }
}

const mapStatetoProps = state => {
  return {
      loggedInUser: state.homeownerLoggedInUser.homeownerLoggedInUserReducer.data || {},
  }
}

const mapDispatchToProps = dispatch =>
    bindActionCreators(
        {
            requestLoggedInHomeownerAction,
        },
        dispatch
    );

export default withRouter(
    connect(
        mapStatetoProps,
        mapDispatchToProps
    )(EnergyTips)
);
