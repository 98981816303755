export const REQUEST_API_DATA = "REQUEST_API_DATA";
export const RECEIVE_API_DATA = "RECEIVE_API_DATA";

export const REQUEST_TIME_ZONE_DATA = "/screens/AddHomeOwner/data/TimeZone/REQUEST_API_DATA";
export const RECEIVE_TIME_ZONE_DATA = "/screens/AddHomeOwner/data/TimeZone/RECEIVE_API_DATA";

export const REQUEST_STATE_DATA = "/screens/AddHomeOwner/data/GET_STATE/REQUEST_STATE_DATA";
export const RECEIVE_STATE_DATA = "/screens/AddHomeOwner/data/GET_STATE/RECIVE_STATE_DATA";

export const RECEIVE_COMPANY_ID_DATA = "/screens/AddHomeOwner/data/Company_Id/RECEIVE_COMPANY_ID_DATA";
export const REQUEST_COMPANY_ID_DATA = "/screens/AddHomeOwner/data/Company_Id/REQUEST_COMPANY_ID_DATA";

export const RECEIVE_INSTALLER_DATA = "/screens/AddHomeOwner/data/Installer/RECEIVE_INSTALLER_DATA";
export const REQUEST_INSTALLER_DATA = "/screens/AddHomeOwner/data/Installer/REQUEST_INSTALLER_DATA";

export const RECEIVE_POWER_METER_DATA = "/screens/AddHomeOwner/data/Installer/RECEIVE_POWER_METER_DATA";
export const REQUEST_POWER_METER_DATA = "/screens/AddHomeOwner/data/Installer/REQUEST_POWER_METER_DATA";

export const RECEIVE_HOME_OWNER_DATA = "/screens/HomeOwner/data/RECEIVE_HOME_OWNER_DATA";
export const REQUEST_HOME_OWNER_DATA = "/screens/HomeOwner/data/REQUEST_HOME_OWNER_DATA";

export const RECEIVE_UPDATE_HOME_OWNER_DATA = "/screens/HomeOwner/data/RECEIVE_UPDATE_HOME_OWNER_DATA";
export const REQUEST_UPDATE_HOME_OWNER_DATA = "/screens/HomeOwner/data/REQUEST_UPDATE_HOME_OWNER_DATA";

export const REQUEST_DELETE_HOME_OWNER_DATA = "/screens/HomeOwner/data/REQUEST_DELETE_HOME_OWNER_DATA";

export const RECEIVE_HOME_OWNER_REFERRALS_DATA = 'RECEIVE_HOME_OWNER_REFERRALS_DATA';
export const REQUEST_HOME_OWNER_REFERRALS_DATA = 'REQUEST_HOME_OWNER_REFERRALS_DATA';

export const REQUEST_HOME_OWNER_SURVEY_RESULT = 'REQUEST_HOME_OWNER_SURVEY_RESULT';
export const RECEIVE_HOME_OWNER_SURVEY_RESULT = 'RECEIVE_HOME_OWNER_SURVEY_RESULT';

export const REQUEST_HOME_OWNER_SOCIAL_SHARES = 'REQUEST_HOME_OWNER_SOCIAL_SHARES';
export const RECEIVE_HOME_OWNER_SOCIAL_SHARES = 'RECEIVE_HOME_OWNER_SOCIAL_SHARES';

export const REQUEST_HOME_OWNER_ENGAGEMENT_SCORE = 'REQUEST_HOME_OWNER_ENGAGEMENT_SCORE';
export const RECEIVE_HOME_OWNER_ENGAGEMENT_SCORE = 'RECEIVE_HOME_OWNER_ENGAGEMENT_SCORE';

export const REQUEST_HOME_OWNER_ADD_USER = "REQUEST_HOME_OWNER_ADD_USER";
export const RECEIVE_HOME_OWNER_ADD_USER = "RECEIVE_HOME_OWNER_ADD_USER";

export const requestApiData = data => ({ type: REQUEST_API_DATA, data });
export const receiveApiData = data => ({ type: RECEIVE_API_DATA, data });
export const requestStateData = data => ({ type: REQUEST_STATE_DATA, data });
export const receiveStateData = data => ({ type: RECEIVE_STATE_DATA, data });
export const requestTimeZoneData = () => ({ type: REQUEST_TIME_ZONE_DATA });
export const receiveTimezoneData = data => ({ type: RECEIVE_TIME_ZONE_DATA, data });
export const receiveCompanyIdData = data => ({ type: RECEIVE_COMPANY_ID_DATA, data });
export const requestCompanyIdData = data => ({ type: REQUEST_COMPANY_ID_DATA, data });
export const receiveInstallerData = data => ({ type: RECEIVE_INSTALLER_DATA, data });
export const requestInstallerData = data => ({ type: REQUEST_INSTALLER_DATA, data });
export const receivePowerMeter = data => ({ type: RECEIVE_POWER_METER_DATA, data });
export const requestPowerMeter = data => ({ type: REQUEST_POWER_METER_DATA, data });

export const receiveHomeOwner = data => ({ type: RECEIVE_HOME_OWNER_DATA, data });
export const requestHomeOwner = data => ({ type: REQUEST_HOME_OWNER_DATA, data });

export const receiveUpdateHomeOwner = data => ({ type: RECEIVE_UPDATE_HOME_OWNER_DATA, data });
export const requestUpdateHomeOwner = data => ({ type: REQUEST_UPDATE_HOME_OWNER_DATA, data });

export const requestDeleteHomeOwner = data => ({ type: REQUEST_DELETE_HOME_OWNER_DATA, data });

export const receiveReferralData = data => ({
    type:  RECEIVE_HOME_OWNER_REFERRALS_DATA, data
});
export const requestReferralData = data => ({
    type:  REQUEST_HOME_OWNER_REFERRALS_DATA, data
});

export const receiveSurveyData = data => ({
    type:  RECEIVE_HOME_OWNER_SURVEY_RESULT, data
});
export const requestSurveyData = data => ({
    type:  REQUEST_HOME_OWNER_SURVEY_RESULT, data
});

export const receiveSocialShareData = data => ({
    type:  RECEIVE_HOME_OWNER_SOCIAL_SHARES, data
});
export const requestSocialShareData = data => ({
    type:  REQUEST_HOME_OWNER_SOCIAL_SHARES, data
});

export const receiveEngagementScore = data => ({
    type:  RECEIVE_HOME_OWNER_ENGAGEMENT_SCORE, data
});
export const requestEngagementScore = data => ({
    type:  REQUEST_HOME_OWNER_ENGAGEMENT_SCORE, data
});

export const receiveHomeownerAddUser = data => ({
    type:  RECEIVE_HOME_OWNER_ADD_USER, data
});
export const requestHomeownerAddUser = data => ({
    type:  REQUEST_HOME_OWNER_ADD_USER, data
});
