// import npm packages
import React, { Component } from 'react';
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Spin, Modal } from "antd";
import { LoadingOutlined } from '@ant-design/icons';
import {Helmet} from "react-helmet";
import {meterStatusNotification} from "../../Installer/api/dashboard"

// import local files
import Header from '../../homeowner/container/header';
import withRouter    from '../../../shared/withRouter';
import { jwtDecode } from '../../../shared/jwt';
import GoogleDriveSync from "../../../shared/GoogleDriveSync"
import SystemAtGlance from '../../homeowner/components/shared/systemAtGlance';
import SolarCommunity from '../../homeowner/components/shared/solarCommunity';
import { getToken } from '../../../shared/util';
import { requestHomeownerSystemGlance,requestHomeownerCampaignData } from '../../homeowner/actions/dashboard';
import {
    requestLoggedInHomeownerAction,
    requestHomeownerEnergyData,
    receiveHomeownerEnergyData,
    requestHomeownerInstallerNotify,
    requestUpdateHomeOwner,
    requestDeviceMagic,
    receiveUpdateHomeOwner
} from '../../homeowner/actions/homeOwner';
import {requestCompanyJourney} from  "../../Installer/actions/companyJourney"
import {
    requestHomeownersList
} from '../../homeowner/actions/solar';
import { requestHomeownerCompanyDetail } from '../../homeowner/actions/resources';
import { setLoader }                     from '../../Common/Loader/action';
import BodhiJourney                      from '../../homeowner/components/shared/bodhiJourney';
import {openNotificationWithIcon}        from "../../../shared/notification"
import { setImages }                     from '../../Common/ShowImageFromDrive/action';
import { requestPostReferralDetails, 
        receivePostReferralDetails }     from "../../homeowner/actions/referralDetails";
import { requestGetEversignData, 
        receiveGetEversignData }         from "../../homeowner/actions/eversign";
import {getLoadingIcon,envAppIcon}       from "../../User/components/setUserBackgroud"
import { REPORT_SYNC }                   from '../../../shared/config';
import LandingPage                       from '../../Common/LandingPage';
import OnBoarding                        from "../../homeowner/components/onBoarding/onBoarding"

class HomeownerLayout extends Component {
    constructor(props) {
        super(props);
        this.slug = "";
        this.GoogleDriveSync = new GoogleDriveSync();
        this.state = {
            documentId: {},
            isSyncCalled: false,
            meterStatusNotification : false,
            isPopUpVisible : false,
            isReferralCommunity: false,
            isEnergyFlow: false,
            isApiResponse: false,
            width : window.innerWidth,
            isNewUser: localStorage.getItem('isNewUser') && localStorage.getItem('isNewUser') === 'true' ? true : false,
        };
    }
    componentWillMount() {
        document.body.classList.add('homeowner-bg');
        document.body.classList.remove('bg-color');
        this.slug = localStorage.getItem("slug");
    }

    hitApi(homeownerId){
        let companyId = this.homeownerData.companyId;
        let {loggedInUser,systemGlance:{solarGlance}} = this.props;
        if(solarGlance){
            return;
        }
        this.props.requestHomeownerSystemGlance({
            companyId,
            homeownerId
        });

        if(loggedInUser.solarUnit && loggedInUser.solarUnit.activeCampaign && JSON.stringify(this.props.activeCampaign)==='[]')
        this.props.requestHomeownerCampaignData({
            companyId,
            homeownerId
        });

        this.props.requestHomeownersList({
            companyId,
            homeownerId
        });
        this.props.requestHomeownerEnergyData({
            homeownerId,
            sync: REPORT_SYNC,
            requestMonthHistory : true
        });
        if(loggedInUser && loggedInUser.solarUnit && loggedInUser.solarUnit.dailySyncTime){
    
            setTimeout(()=>{
                this.props.requestHomeownerEnergyData({
                homeownerId,
                sync: false,
                requestMonthHistory : false
            })
            },15000);
        }
        
    }

    componentDidMount() {
        let token = getToken();
        if (token) {
            this.homeownerData = jwtDecode(token);
           
            this.props.setLoader({ loader: true });


            this.props.requestLoggedInHomeownerAction(this.homeownerData);

            if(this.homeownerData.role==="homeowner"){
                this.props.requestGetEversignData(this.homeownerData);
            }
        } else {
            this.props.router.navigate('/')
        }
        window.addEventListener('resize', this.updateWindowDimensions.bind(this));
    }
    componentWillUnmount() {
        window.removeEventListener('resize', this.updateWindowDimensions.bind(this));
    }


    googleDriveInvoker(documentData){
        let driveData = documentData.driveData;
        if (driveData && (JSON.stringify(driveData) != '{}')) {
            let files = [];
            if (documentData.source === "solarNexus") {

                driveData[documentData.key] && driveData[documentData.key].map(ele => {
                    files.push({
                        name: ele['document']['file_name'],
                        public_url: ele['document']['url']
                    })
                })
            }
            else if (documentData.source === "zoho") {
                driveData[documentData.key] && driveData[documentData.key].map(ele => {
                    if(ele['$link_url'])
                    files.push({
                        name: ele['File_Name'],
                        public_url: ele['$link_url']
                    })
                })
            }

            else if (documentData.source === "teamwork") {
                driveData[documentData.key] && driveData[documentData.key].map(ele => {
                    if(ele['previewUrl'])
                    files.push({
                        name: ele['name'],
                        public_url: ele['previewUrl']
                    })
                })
            }

            this.props.setLoader({ loader: false });
            this.props.setImages(files.length > 0 ? { [documentData.key]: files } : driveData);
            this.setState({
                documentId: {
                    key: documentData.key,
                    source: documentData.source,
                },
            })
        }
    
        else if (documentData
            && !this.state.isSyncCalled
            && documentData.source
            && documentData.key
            && documentData.source === 'google'
            &&  (!this.props.cloudImages || JSON.stringify(this.props.cloudImages) === '{}')
            && (!this.state.documentId
                || !this.state.documentId.key)
        ) {
            // fetching google drive files
            this.props.setLoader({ loader: true });
            this.setState({ isSyncCalled: true }, () => {
                this.displayDriveFiles(documentData);
            });
        }
    }

    displayDriveFiles = (documentData) => {
        let driveData = documentData.driveData;
        if (driveData && (JSON.stringify(driveData) != '{}')) {
          this.props.setLoader({ loader: false });
          this.props.setImages(driveData);
          this.setState({
            documentId: {
              key: documentData.key,
              source: documentData.source,
            },
          })
        }
    
        else if (driveData && (JSON.stringify(driveData) == '{}')) {
          this.GoogleDriveSync.editListFiles(documentData.key, documentData.filter).then(response => {
            this.props.setLoader({ loader: false });
            if (response) {
              this.props.setImages(response.fileList)
              this.setState({
                documentId: {
                  key: documentData.key,
                  source: documentData.source,
                },
              })
            }
          }).catch(err => {
            this.props.setLoader({ loader: false });
          });
        }
      }

    addScript() {

        let iconUrl = this.state.headTag;
        !this.state.headTag && this.setState({ headTag: true },()=>{
          
            envAppIcon(this.slug).then(headTag => {
              this.setState({ headTag });
            });
        });
        
        if(iconUrl && iconUrl !=true) {
          return (
              <Helmet>
                  <link rel="shortcut icon" href={iconUrl} />
                  <script src="https://api.cloudsponge.com/widget/fj1g7kuwAfk01wOrYttfBw.js"></script>
                  <meta name="apple-mobile-web-app-title" content={this.companyName || 'Bodhi'} />
                  <link rel="apple-touch-icon" href={iconUrl} sizes="72x72" />
              </Helmet>
          )
        }
    }


    componentWillReceiveProps(nextProps) {
        if(nextProps.premiumSetting && Object.keys(nextProps.premiumSetting).length>1){
            document.body.classList.add(nextProps.premiumSetting.font);
        }

        if(this.homeownerData && !this.homeownerData.role.includes('secondary') && nextProps.loggedInUser.homeownerData && nextProps.loggedInUser.homeownerData.id && !this.state.homeownerApiResponse){
            this.setState({homeownerApiResponse:true},()=>this.hitApi(this.homeownerData.homeownerId))
        }

        if (
            nextProps.loggedInUser.homeownerData
            && nextProps.loggedInUser.homeownerData.id
            && !this.state.isApiResponse
        ) {
            this.googleDriveInvoker(nextProps.loggedInUser.documentData);
            if(nextProps.loggedInUser.homeownerData.magicFile){
                this.props.requestDeviceMagic(nextProps.loggedInUser.homeownerData.magicFile);
            }
            
            if(this.homeownerData && this.homeownerData.role.includes('secondary')){
                this.hitApi(nextProps.loggedInUser.homeownerData.id)
            }

            this.homeownerData['toolSearch'] = true;
            this.homeownerData['fileName'] = (nextProps.loggedInUser.solarUnit.activeJourney && nextProps.loggedInUser.solarUnit.activeJourney.s3Key) || 'default';
            this.props.requestCompanyJourney(this.homeownerData);

            this.setState({
                isApiResponse: true,
                isPopUpVisible : true
            });
            nextProps.setLoader({ loader: false });
        }
    }

    updateWindowDimensions() {
        
        this.setState({ width: window.innerWidth });
    }

    updateLocalStorage = (status,onboardMessage,isLast) => {
        let {loggedInUser:{homeownerData}} = this.props; 
       if(!status){
            if (!onboardMessage || onboardMessage === '') {
                openNotificationWithIcon('error', `We’ll pass it on to your project manager.`, 'Please enter a message');
                return null;
            }         
            this.homeownerData['onboardMessage'] = onboardMessage;
            this.homeownerData['isProjectTrack'] = homeownerData.isProjectTrack;
            
            let body = {
                onboardMessage : onboardMessage,
                isProjectTrack : homeownerData.isProjectTrack,
                homeownerName  : `${homeownerData.firstName} ${homeownerData.lastName}`,
                name           : homeownerData.installerName && homeownerData.installerName.split(' ')[0],
                slug           : this.slug,
                email          : homeownerData.installerEmail,
                homeowner_email: homeownerData.email,
                homeownerId    : homeownerData.id
            };

            this.props.requestHomeownerInstallerNotify(body);       
       }
       if(!isLast){
        localStorage.removeItem('isNewUser');
        this.setState({ isNewUser: false });
       }
    }

    renderHomeownerDashboard = () => {
        const MyComponent = this.props.component;
        const slideNumber = this.props.router.params.slideNumber;
        const { systemGlance, loggedInUser } = this.props;
        let installerName = (loggedInUser.homeownerData &&
        loggedInUser.homeownerData.installerName) || 'your installer';
        return (
            <>
            {this.state.isPopUpVisible && this.renderPopUp(installerName)}
            
            <section className="two-box-wrapper marg-two-box portal-intro-wrapp">
                <div>
                    <div className={"container margin-t-73 "+(slideNumber ? 'margin-t-73-new' : '')}>
                        <div className={"create-new-account-wrapper "+(slideNumber ? 'create-new-account-wrapper-new' : '')}>
                            <div className="row">
                                <div className="col-md-6 desktop-view-only">
                                    <div className="progress-wrapp">
                                        <BodhiJourney {...this.props} isInstaller={false} />
    
                                        <div className="whtie-heading-accordion">
                                            <div id="accordion">
    
                                            <div className="card">
                                                    <div className="card-header" id="headingTwo">
                                                        <h5 className="mb-0">
                                                            <a
                                                                href="#"
                                                                className="up-arra premiumBackgroundText"
                                                                data-toggle="collapse"
                                                                data-target="#collapseTwo"
                                                                aria-expanded="true"
                                                                aria-controls="collapseTwo"
                                                            >
                                                                <span>SHARE THE SUN</span>
                                                                <span className="arrow-wrap pull-right">
                                                                    <span className="up-arrow">
                                                                        <i
                                                                            className="fa fa-angle-up"
                                                                            data-unicode="f106"
                                                                        />
                                                                    </span>
                                                                    <span className="down-arrow">
                                                                        <i
                                                                            className="fa fa-angle-down"
                                                                            data-unicode="f107"
                                                                        />
                                                                    </span>
                                                                </span>
                                                            </a>
                                                        </h5>
                                                    </div>
                                                    <div
                                                        id="collapseTwo"
                                                        className="collapse show"
                                                        aria-labelledby="headingTwo"
                                                        data-parent="#accordion"
                                                    >
                                                        <SolarCommunity {...this.props} />
                                                    </div>
                                                </div>
                                                <div className="card margb30">
                                                    <div className="card-header" id="headingOne">
                                                        <h5 className="mb-0">
                                                            <a
                                                                href="#"
                                                                className="up-arra collapsed"
                                                                data-toggle="collapse"
                                                                data-target="#collapseOne"
                                                                aria-expanded="true"
                                                                aria-controls="collapseOne"
                                                            >
                                                                <span className="ho-setting-acc-heading premiumBackgroundText">YOUR SYSTEM AT A GLANCE</span>
                                                                <span className="arrow-wrap pull-right">
                                                                    <span className="up-arrow premiumBackgroundText">
                                                                        <i
                                                                            className="fa fa-angle-up"
                                                                            data-unicode="f106"
                                                                        ></i>
                                                                    </span>
                                                                    <span className="down-arrow premiumBackgroundText">
                                                                        <i
                                                                            className="fa fa-angle-down"
                                                                            data-unicode="f107"
                                                                        ></i>
                                                                    </span>
                                                                </span>
                                                            </a>
                                                        </h5>
                                                    </div>
                                                    <div id="collapseOne" className="card-body collapse paddt0 paddb0" aria-labelledby="headingOne"
                                                        data-parent="#accordion">
                                                        <SystemAtGlance
                                                            systemGlance={systemGlance}
                                                            loggedInUser={loggedInUser}
                                                            {...this.props}
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                {/* <div className="col-md-6" style={{paddingLeft: '0', paddingRight: '0'}}> */}
                                <div className={"col-md-6 " + (slideNumber ? 'resources-wrapp' : '')}>
                                    <MyComponent {...this.props} {...this.params} homeOwnerId={loggedInUser.homeownerData && loggedInUser.homeownerData.id} />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            </>
        )
    }
    
    renderPopUp = (installerName) => {
        let {loggedInUser} = this.props;
        let location = window.location;
        let url = new URL(location);
        let action = url.searchParams.get("action");
        if(!action || !loggedInUser.homeownerData || !loggedInUser.homeownerData.id)
          return '';
        else if(!this.state.meterStatusNotification){
            this.setState({
                meterStatusNotification : true
            },()=>{
                meterStatusNotification({
                    homeownerId : loggedInUser.homeownerData.id,
                    action      : action,
                    companyId   : loggedInUser.homeownerData.companyId
                })
            })
        }  

        return (
            <div>
                <Modal
                    visible={true}
                    className="thank-you popUp"
                    footer={null}
                >
                    <h2>Thanks for attempting to debug and isolate the performance issue
                        with your system.
                    </h2>
                    <p>We're letting {installerName} know you've taken these 
                    steps and to follow up with you</p>
                    <button className="btn thanks-btn" onClick={()=>{
                        let base_url =window.location.href;
                        window.history.pushState({}, "", base_url.split("?")[0]);
                        this.setState({
                            isPopUpVisible : false
                        })
                    }}>DASHBOARD</button>
                </Modal>
            </div>
          );
    }

    renderHeader = () => {
        if (localStorage.getItem('isNewUser') != 'true') {
            return <header>
                <Header {...this.props} />
            </header>
        }
    }

    render() {
        let slideNumber = this.props.router.params.slideNumber;
        const antIcon = <LoadingOutlined style={{ fontSize: 60 }} spin />;
        const { systemGlance} = this.props;
        let loadingIcon = (this.homeownerData && this.homeownerData.role.includes('secondary')) ? getLoadingIcon(this.slug) :(systemGlance && systemGlance.solarGlance && systemGlance.solarGlance.logo);
        return (
            <React.Fragment>
                   {this.addScript()}
                <Spin
                    spinning={this.props.loader}
                    indicator={antIcon}
                    style={{ color: "#04bfff" }}
                >
                    {slideNumber && this.state.width <=767.98 ? '' : this.renderHeader()}
                    <div>
                        {
                            (JSON.stringify(this.props.loggedInUser) === '{}')
                                ?
                                <LandingPage loadingIcon={loadingIcon} role={this.homeownerData && this.homeownerData.role}/>
                                : (localStorage.getItem('isNewUser') === 'true' ? <OnBoarding slug={this.slug} updateLocalStorage={this.updateLocalStorage} {...this.props}/> : this.renderHomeownerDashboard())
                        }

                    </div>
                </Spin>
            </React.Fragment>
        )
    }
}


const mapStatetoProps = state => {
    return {
        magicData: state.homeownerLoggedInUser.getHomeownerDeviceMagicReducer.data || [],
        companyJourney: state.companyBodhiJourney && state.companyBodhiJourney.bodhiJourneyReducer
            && state.companyBodhiJourney.bodhiJourneyReducer.data&&
            state.companyBodhiJourney.bodhiJourneyReducer.data[0]||{},
        resourceData : state.companyBodhiJourney && state.companyBodhiJourney.bodhiJourneyReducer
        && state.companyBodhiJourney.bodhiJourneyReducer.data&&
        state.companyBodhiJourney.bodhiJourneyReducer.data[2],
        additionalUser : (state.homeOwner.getHomeownerAddUser && state.homeOwner.getHomeownerAddUser.data),
        activeCampaign:state.homeownerDashboard.homeownerCampaignData.data ||[],
        systemGlance: state.homeownerDashboard.homeownerSystemGlance.data || {},
        loggedInUser: state.homeownerLoggedInUser.homeownerLoggedInUserReducer.data || {},
        loader: state.loader.loader.loader,
        energyReport: state.homeownerLoggedInUser.homeownerEnergyReportReducer.data || {},
        installerDetails: state.homeownerContact.homeownerInstallerDetails.data,
        cloudImages: JSON.stringify(state.cloudImages.cloudImages) !== '{}' ? state.cloudImages.cloudImages : {},
        homeownersList: state.homeownersList.homeownersList.data || {},
        referralDetails: state.homeownerReferralDetails,
        companyDetail: state.homeownerResources.homeownerResoucesCompanyDetail
            && state.homeownerResources.homeownerResoucesCompanyDetail.data
            && state.homeownerResources.homeownerResoucesCompanyDetail.data.companyDetail ?
            state.homeownerResources.homeownerResoucesCompanyDetail.data.companyDetail
            : {},
        eversignDetails: state.homeownerGetEversign.homeownerGetEversignDocument.data
    };
};

const mapDispatchToProps = dispatch =>
    bindActionCreators(
        {
            requestHomeownerCampaignData,
            requestHomeownerSystemGlance,
            requestLoggedInHomeownerAction,
            requestHomeownerEnergyData,
            receiveHomeownerEnergyData,
            setLoader,
            setImages,
            requestPostReferralDetails,
            receivePostReferralDetails,
            requestHomeownersList,
            requestHomeownerCompanyDetail,
            requestGetEversignData,
            receiveGetEversignData,
            requestHomeownerInstallerNotify,
            requestUpdateHomeOwner,
            requestCompanyJourney,
            requestDeviceMagic       
        },
        dispatch
    );

export default withRouter(
    connect(
        mapStatetoProps,
        mapDispatchToProps
    )(HomeownerLayout)
);