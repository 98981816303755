// import npm packages
import React, { Component } from "react";
import PropTypes from "prop-types";
import { reduxForm } from "redux-form";
import { state } from "aws-cognito-redux-saga";
import { Input, Spin } from "antd";
import { LoadingOutlined }    from '@ant-design/icons';
import { EyeTwoTone }         from '@ant-design/icons';
// import local files
import { handleCognitoError } from './../../../../shared/handleCognitoError'
import withRouter from './../../../../shared/withRouter'
import * as validate from './../../../../shared/validators'
import * as config from './../../../../shared/config'
import { envImageUrl,checkImageValid,
        getLoadingIcon,convertJSON2CSS}  from "../setUserBackgroud"

const antIcon = <LoadingOutlined style={{ fontSize: 60 }} spin />;

const style = {
  error: {
    width: "80%",
    color: "rgb(200,0,0)",
    margin: "8px",
  }
};

class ChangePassword extends Component {
  static propTypes = {
    router: PropTypes.object,
    handleSubmit: PropTypes.func.isRequired,
    changePassword: PropTypes.func.isRequired,
    auth: PropTypes.object,
    init: PropTypes.func
  };
  constructor(props) {
    super(props);
    this.newPassword = "";
    this.loader = false;
    this.slug = "";
    this.image = "";
    this.authError = undefined;
    this.pwdError = undefined;
    this.confirmPassword = "";
    this.state = {
      code: '',
      password: '',
      newPassword: ''
    }
  }

  componentWillMount() {
    this.loader = false;
    this.slug = localStorage.getItem("slug");
    convertJSON2CSS(this.slug).then(r=>{
      this.companyName= r;
    });
    this.props.init();
  }

  componentWillReceiveProps(nextProps, nextState) {
    if (nextProps.image && nextProps.image.status === 200) {
      this.image = nextProps.image.data.logo;
    }
  }

  changePassword = values => {
    // =============================================
    let newPasswordError = validate.passwordValidation(this.newPassword)
    let confirmPasswordError = validate.passwordValidation(this.confirmPassword)
    let codeError = this.state.code === "" ? config.CODE_REQUIRED : undefined
    let email = this.props.router.location && this.props.router.location.state ? this.props.router.location.state.email : this.props.router.params && this.props.router.params.email ? this.props.router.params.email : '';

    if (codeError) {
      this.pwdError = { "message": codeError }
      return null;
    }

    if (this.newPassword === "" || this.confirmPassword === "") {
      this.pwdError = { "message": config.EMPTY_PASSWORD }
      return null;
    }

    if (this.error) {
      this.pwdError = { "message": this.error }
      return null;
    }

    if (newPasswordError) {
      this.pwdError = { "message": newPasswordError }
      return null;
    }

    if (confirmPasswordError) {
      this.pwdError = { "message": confirmPasswordError }
      return null;
    }

    if (email !== "") {
      this.props.changePassword(email, this.state.code, this.state.password);
    }

    this.forceUpdate();
  };
  onChangeCode = e => {
    this.codeError = undefined;
    this.setState({ code: e.target.value.trim() });
  }
  onChangePassword = e => {
    this.newPassword = e.target.value.trim();
    this.setState({ password: e.target.value.trim() });
  }

  componentWillUpdate() {
    this.error = validate.passwordMatch(this.newPassword, this.confirmPassword)
  }

  componentDidMount(){
    checkImageValid('test-bg').then((response)=>{
      if (response && !this.state.defaultLoginImage) {
        this.setState({
          defaultLoginImage: "https://prod-17terawatts.s3.amazonaws.com/public/logo/desktopLogin_BG.jpg"
        })
      }
    });
  }

  componentDidUpdate() {
    if (this.props.auth.error) {
      this.loader = false;
      // this.authError = this.props.auth.error
    }
  }
  onChangeNewPassword = e => {
    this.pwdError = undefined;    
    this.confirmPassword = e.target.value.trim()
    this.setState({ newPassword: e.target.value.trim() });
  }
  renderChangePassword() {
    const { handleSubmit, auth, image } = this.props;
    let css = {
      "backgroundImage": `url(${this.state.defaultLoginImage ||  envImageUrl(this.slug)})`
    }

    if(auth.error && auth.error.code && auth.error.code==="ExpiredCodeException"){
      if(this.slug){
        this.props.router.navigate(`/${this.slug}/forgotpassword`,{expiredCode: true});
      } else{
        this.props.router.navigate(`/forgotpassword`,{expiredCode: true});
      }
    }

    return (
      <React.Fragment>
        <Spin
          spinning={this.loader}
          indicator={antIcon}
          style={{ color: "white" }}
        >
          <title>{this.companyName || 'Bodhi'} - Reset Password</title>
          <div id="test-bg" className="bg-black login-bg body-height" style={css}>
            <div id="login-container" className="h-100">
              <div className="login-box ">
                <div className="d-inline-block justify-content-end w-100 text-right">
                  <div className="white-logo d-inline-block" />
                </div>

                <div className="box-Shadow center-white-box" id="reset-password-cont">
                  <div className="forgotpassword">
                    <div className="logo-blue" style={{ 'backgroundImage': `url(${(this.slug && getLoadingIcon(this.slug)) || ''})` }} />
                    <p className="mobile-h2">Welcome to Bodhi.<br />
                    Your partner on the solar journey
                    </p>
                    <small>We have sent a code to your email account</small>
                    <form className="mt-0" action="forgot.html">
                      <div className="form-group">
                        <Input
                          className="form-control"
                          id="exampleInputPassword1"
                          onChange={this.onChangeCode}
                          placeholder="Code"
                        />
                        {this.codeError ? (
                          <div style={style.error}>
                            {this.codeError}
                          </div>
                        ) : (
                          ""
                        )}
                      </div>
                      <div className="form-group">
                        <small style={{marginTop:"35px"}}>Please set a new password that is 12 or more alphanumeric characters.</small>
                        <Input.Password
                          className="form-control"
                          id="exampleInputPassword1"
                          iconRender={() => <EyeTwoTone /> }
                          type="password"
                          onChange={this.onChangePassword}
                          placeholder="New Password"
                        />
                      </div>
                      <div className="form-group">

                        <Input.Password
                          className="form-control"
                          id="exampleInputPassword1"
                          type="password"
                          iconRender={() => <EyeTwoTone /> }
                          onChange={this.onChangeNewPassword}
                          placeholder="Confirm Password"
                        />
                      </div>
                      {
                        auth.error
                          && !this.pwdError ?
                          <div style={style.error}>
                            {
                              JSON.stringify(auth.error) !== '{}' ?
                                handleCognitoError(auth.error.code)
                                :
                                null
                            }
                          </div>
                          :
                          ''
                      }
                      {this.pwdError ? <div style={style.error}>{this.pwdError.message}</div> : ''}
                      <div className="text-center">
                        <button
                          onClick={handleSubmit(this.changePassword)}
                          type="submit"
                          className="btn yellow-btn premiumButtonFill premiumButtonText login-text"
                        >
                          Reset password
                          </button>
                      </div>
                    </form>
                  </div>
                </div>

              </div>
            </div>
          </div>
        </Spin>
      </React.Fragment>
    );
  }

  renderSignIn() {
    this.props.setLoader({loader:true});
    this.props.signIn(this.props.router.params.email, this.state.password);
    this.props.router.navigate('/login')
    return (
      null
    );
  }

  render() {
    const { auth } = this.props;
    return auth.hasChangedPassword === state.AUTH_SUCCESS
      ? this.renderSignIn()
      : this.renderChangePassword();
  }
}

// Decorate the form component
export default reduxForm({
  form: "changePassword"
})(withRouter(ChangePassword));
