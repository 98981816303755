import { Services } from '../../../Api/services';
import * as config from '../../../shared/config';
import { openNotificationWithIcon } from '../../../shared/notification';


export const stateApi = async data => {
  const url = `${config.ADMIN_BASE_URL}admin/states`;
  let res = await Services.getCall(url, true);
  return { data: res.data, status: res.status };
};

export const timeZoneApi = async data => {
  const url = `${config.ADMIN_BASE_URL}admin/timezone`;
  let res = await Services.getCall(url, true);
  return { data: res.data, status: res.status };
};

export const companyIdApi = async id => {
  const url = `${config.ADMIN_BASE_URL}masterdata/1`;
  let res = await Services.getCall(url, true);
  return { data: res.data, status: res.status };
};

export const getInstallerApi = async id => {
  const url = `${config.INSTALLER_BASE_URL}company/${id}/installers`;
  let res = await Services.getCall(url, true);
  return { data: res.data, status: res.status };
};

export const getPowerMeterApi = async id => {
  const url = `${config.COMPANY_BASE_URL}company/${id}/powermeter`;
  let res = await Services.getCall(url, true);
  return { data: res.data, status: res.status };
};

export const getHomeOwnerApi = async data => {
  const url = `${config.HOME_OWNER_BASE_URL}company/${
    data.companyId
  }/homeowner/${data.homeOwnerId}`;
  let res = await Services.getCall(url, true);
  return { data: res.data, status: res.status };
};

export const getUpdateHomeOwnerApi = async data => {
  const url = `${config.HOME_OWNER_BASE_URL}company/${
    data.companyId
  }/homeowner/${data.homeOwnerId}?slug=${data.slug}`;

  let bodyData;

  if(data.saveMonitoringCredentials){
     bodyData = data.bodyData;
  }
  else{
    bodyData = {
      firstName: data.bodyData.firstName,
      lastName: data.bodyData.lastName,
      middleName: data.bodyData.middleName,
      phone: data.bodyData.phone,
      toolContactId: data.bodyData.toolContactId,
      timezone: data.bodyData.timezone,
      address: {
        address1: data.bodyData.address1,
        address2: data.bodyData.address2,
        city: data.bodyData.city,
        state:
          data.bodyData.state && data.bodyData.state.label
            ? data.bodyData.state.label
            : data.bodyData.state,
        zipCode: +data.bodyData.zipcode
      },
      document: data.documentId,
      solarUnit: {
        moduleCount: +data.bodyData.numberOfModules,
        activeJourney : data.bodyData.activeJourney,
        moduleModel: data.bodyData.moduleModel,
        moduleBrandId: +data.bodyData.moduleBrand,
        inverterBrandId: +data.bodyData.inverterBrand,
        saving: data.bodyData.projectAnnualSavings ? +data.bodyData.projectAnnualSavings : 0,
        meterId: data.bodyData.monitorType ? +data.bodyData.monitorType : null,
        meterUrl: data.bodyData.meterUrl ? data.bodyData.meterUrl : null,
        meterconId: data.bodyData.meterID ? data.bodyData.meterID : null,
        scaniflyUrl : data.bodyData.scaniflyUrl,
        companyId: data.companyId,
        utilityRateId: data.bodyData.ratePlan || null,
        electricUtilityId: data.bodyData.electricityUtility || null,
        systemSize: data.bodyData.systemSize,
        systemPrice: +data.bodyData.systemPrice,
        annualConsumption: +data.bodyData.annualConsumption,
        annualProduction: +data.bodyData.annualProduction,
        touchHistory: data.bodyData.touchHistory,
        toolContactId: data.bodyData.toolContactId,
        toolSolarUnitId: data.bodyData.toolSolarUnitId,
        crmMapping  : data.bodyData.crmMapping,
        installationDate : data.bodyData.installationDate,
        ahj: data.bodyData.ahj,
        documents : data.fusionDocuments ? data.fusionDocuments : null
      },
      companyId: data.companyId,
      companyS3Data : data.bodyData.companyS3Data,
      salePersonId : data.bodyData.salePersonId,
      installerId: data.bodyData.projectManager
    };
  }

  let res = await Services.putCall(url, bodyData);
  openNotificationWithIcon(
    'success',
    'Information saved successfully',
    'Success'
  );
  return { data: res.data, status: res.status };
};

export const getDeleteHomeOwnerApi = async data => {
  const url = `${config.HOME_OWNER_BASE_URL}company/${
    data.homeOwnerCompanyId
  }/homeowner/${data.homeOwnerId}?email=${encodeURIComponent(
    data.homeOwnerEmail
  )}`;

  let res = await Services.deleteCall(url, true);
  openNotificationWithIcon('success', 'Account deletion successful', 'Success');
  return { data: res.data, status: res.status };
};

export const getReferralData = async data => {
  const url = `${config.REFERRAL_BASE_URL}referral/company/${
    data.companyId
  }?count=true&homeownerId=${data.homeownerId}`;
  let res = await Services.getCall(url, true);
  return { data: res.data, status: res.status };
};

export const getSurveyData = async data => {
  const url = `${config.SURVEY_BASE_URL}survey/company/${
    data.companyId
  }?count=true&homeownerId=${data.homeownerId}`;
  let res = await Services.getCall(url, true);
  return { data: res.data, status: res.status };
};

export const getSocialShareData = async data => {
  const url = `${config.SOCIAL_SHARE_BASE_URL}company/${
    data.companyId
  }/socialshare?homeownerId=${data.homeownerId}`;
  let res = await Services.getCall(url, true);
  return { data: res.data, status: res.status };
};

export const getEngagementScoreData = async data => {
  const url = `${config.SOCIAL_SHARE_BASE_URL}company/${
    data.companyId
  }/homeowner/${data.homeownerId}/engagement`;
  let res = await Services.getCall(url, true);
  return { data: res.data, status: res.status };
};

export const getHomeownerAddUserData = async data =>{
  const url =`${config.HOME_OWNER_BASE_URL}company/${data.companyId}/homeowneruser?homeownerId=${data.homeownerId || data.id}`;
  let res = await Services.getCall(url, true);
  return { data: res.data, status: res.status };
}
export const addHomeownerAdditionalUser = async data =>{
  const url =`${config.HOME_OWNER_BASE_URL}company/${data.companyId}/homeowneruser`;
  let res = await Services.postCall(url,data,true);
  return { data: res.data, status: res.status };
}

export const deleteAdditionalUsers = async data =>{
  const url =`${config.HOME_OWNER_BASE_URL}company/${data.companyId}/homeowneruser/${data.userId}?email=${data.userEmail}`;
  let res = await Services.deleteCall(url, true,{});
  return { data: res.data, status: res.status };
}

