import React, { Component } from 'react';
import { connect }          from 'react-redux';
import {bindActionCreators} from 'redux';
import { AddHardware }    from "./AddCompanyHardware"
import {DeleteModal}        from "../../../Common/DeleteModal";
import {
  requestGetHardwareData,
  requestSetModuleData,
  receiveSetModuleData,
  requestDeleteModuleData,
  receiveDeleteModuleData,
  requestDeleteInverterData,
  receiveDeleteInverterData,
  receiveSetInverterData,
  requestSetInverterData
} from "../../../Admin/Actions/setting";
import deleteIcon from "../../../../Assets/Images/delete-icon.png"

class Hardware extends Component {
  constructor(props) {
    super(props);
    this.state = {
      addPopUp : false,
      deleteTrigger : false,
      viewName:'Module'
    };
  }

  componentDidMount(){
    this.props.setLoader({loader:true});
    this.props.requestGetHardwareData(this.props.companyId);
  }

  componentWillReceiveProps(nextProps) {
    if(nextProps.hardwareData && this.props.loader){
      nextProps.setLoader({loader:false});
    }
    if(nextProps.setModule && nextProps.setModule.status==200){
       nextProps.requestGetHardwareData(this.props.companyId);
       nextProps.receiveSetModuleData({});
    }
    if(nextProps.setInveter && nextProps.setInveter.status==200){
      nextProps.requestGetHardwareData(this.props.companyId);
      nextProps.receiveSetInverterData({});
   }
   if(nextProps.deleteModuleBrand && nextProps.deleteModuleBrand.status==200){
      nextProps.requestGetHardwareData(this.props.companyId);
      nextProps.receiveDeleteModuleData({});
   }
   if(nextProps.deleteInverter && nextProps.deleteInverter.status==200){
      nextProps.requestGetHardwareData(this.props.companyId);
      nextProps.receiveDeleteInverterData({});
   }
  }
   updateState=(key,values)=>{
      if(key==="formData"){
         this.props.setLoader({loader: true});
          let data = [];
          values.labels.forEach((ele, index) => {
            let temp = {
              label: ele,
              name: values.names[index]
            };
            data.push(temp);
          });

          if(this.state.viewName==='Module'){
            this.props.requestSetModuleData({
               companyId: this.props.companyId,
               values: data
             });
          }
          else  if(this.state.viewName==='Inverter'){
            this.props.requestSetInverterData({
               companyId: this.props.companyId,
               values: data
             });
          }
      }
      else{
         this.setState({
            [key] : values
         })
      }
   }

   deletePopUpProp = (value) =>{
      this.setState({deleteTrigger: false});
      let { actionId } = this.state;
      if(value=='cancel'){
        return;
      }
      else{
         if(this.state.viewName==="Module"){
            let data= {
               companyId   : this.props.companyId,
               moduleId    :  actionId
            };
            this.props.setLoader({loader:true});
            this.props.requestDeleteModuleData(data);
         }
         else if(this.state.viewName==='Inverter'){
            let data= {
               companyId   : this.props.companyId,
               inverterId  :  actionId
            };
            this.props.setLoader({loader:true});
            this.props.requestDeleteInverterData(data);
         }
      }
  }


   renderDeletePopUp = () =>{
      let headerText = 'DO YOU REALLY WANT TO DELETE THIS ITEM?';
      let subText = "Deleting this item cannot be undone";
      return(
           <DeleteModal deletePopUpProp={this.deletePopUpProp} headerText={headerText} subText={subText}/>
      )
  }

  render(){
     let {hardwareData} = this.props;
     let renderData= hardwareData && (this.state.viewName==='Inverter' ? hardwareData.companyInverterBrands : 
     hardwareData.companyModuleBrand);

     return (
        <>
         {this.state.deleteTrigger && this.renderDeletePopUp()}
           {this.state.addPopUp && <AddHardware updateState={this.updateState} {...this.state}/>}
           <div className="internal-pnl-cmn box-Shadow content-box">
              <div className="utility-tab-main-pnl">
                 <div className="utility-inner-pnl">
                    <ul className="nav nav-tabs" role="tablist">
                       <li className="nav-item" onClick={() => this.setState({ viewName: 'Module' })}>
                          <a className={`nav-link clearfix ${this.state.viewName == 'Module' && 'active'}`}>
                             Modules
                       <span className="addutility-btn" onClick={()=>{
                          this.setState({addPopUp:true,viewName: 'Module' })
                       }}> + </span>
                          </a>
                       </li>
                       <li className="nav-item" onClick={() => this.setState({ viewName: 'Inverter' })}>
                          <a className={`nav-link clearfix ${this.state.viewName == 'Inverter' && 'active'}`}>
                             Inverters
                       <span className="addutility-btn" onClick={()=>{
                          this.setState({addPopUp:true,viewName: 'Inverter' })
                       }}> + </span>
                          </a>
                       </li>
                    </ul>

                    <div className="tab-content tab-child-secction">
                       <div id="home" className="tab-pane active p-0">
                          <div className="inner-tab-pannel">
                             <div className="filter-div-pnl seprator-right">
                                <span>
                                   {this.state.viewName} name
                             </span>

                             </div>
                             <div className="filter-div-pnl">
                                <span>
                                 Customer facing name
                             </span>

                             </div>
                             <div className="cmn-table-format">
                                <table className="table table-bordered m-0 metrics-table">
                                   <tbody>
                                      {
                                         renderData && renderData.map((ele, index) => {
                                            return (
                                               <tr key={index} className="metrics-row">
                                                  <td className="metrics-data seprator-right">{ele.name}</td>
                                                  <td className="metrics-data border-none">{ele.label}
                                                   <img className="icon"
                                                      onClick={()=>{
                                                         this.setState({actionId:ele.id,deleteTrigger:true})
                                                      }}
                                                       src={deleteIcon}
                                                   />
                                                  </td>
                                               </tr>
                                            )
                                         })
                                      }
                                   </tbody>
                                </table>
                             </div>
                          </div>
                       </div>
                    </div>
                 </div>
              </div>
           </div>
        </>
      )
  }
}

const mapStatetoProps = state => {
  return {
    hardwareData  : state.adminSetting.getHardwareData.data,
    setInveter    : state.adminSetting.setInveter,
    setModule     : state.adminSetting.setModule,
    deleteModuleBrand: state.adminSetting.deleteModule[state.adminSetting.deleteModule.length - 1],
    deleteInverter   : state.adminSetting.deleteInverter[state.adminSetting.deleteInverter.length - 1],
  };
};

const mapDispatchToProps = dispatch =>
  bindActionCreators({ 
    requestGetHardwareData,
    requestSetModuleData,
    receiveSetModuleData,
    requestDeleteModuleData,
    receiveDeleteModuleData,
    requestDeleteInverterData,
    receiveDeleteInverterData,
    requestSetInverterData,
    receiveSetInverterData
    }, 
  dispatch);

export default connect(
  mapStatetoProps,
  mapDispatchToProps
)(Hardware);
