import React, { Component } from "react";
import { BrowserRouter as Router } from "react-router-dom";
import { Provider } from "react-redux";
import { LoadingOutlined } from '@ant-design/icons';

import packageData from '../package.json';
import VersionController from "./VersionController";
import store from "./store";

const loadingCompenent = (
  <div className="home-loading-container"><LoadingOutlined style={{ fontSize: 120 }} spin /></div>
);

const buildVersion = packageData.version
/**
 * scrub all values package.json except "version" to ensure nothing is exposed in the build
 */
Object.keys(packageData).map(key => {
  if (key !== 'version') {
    delete packageData[key]
  }
})

export default function Hoc(HocComponent) {
  return class extends Component {
    render() {
      return (
        <Provider store={store}>
          <Router>
            <VersionController
                currentVersion={ buildVersion }
                isEnabled={ true }
                isVerboseMode={ true }
                loadingComponent={ loadingCompenent }
            >
              <HocComponent />
            </VersionController>
          </Router>
        </Provider>
      );
    }
  };
}
