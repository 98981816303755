export function filterHomeownerTouches(touchHistory,activeCampaign,functionName,companyJourney,magicData) {
    if(!companyJourney || JSON.stringify(companyJourney)=='{}')
        return [];
    let tempData = [];
    if ( touchHistory.length !== 0 ) {
        tempData = touchHistory.map((data, index) => {
            if (!data)
                return
            if (typeof data === 'string')
                data = JSON.parse(data); 
            data.phaseName = companyJourney[data.touchName.toLowerCase().replace(/ /g, '')] && companyJourney[data.touchName.toLowerCase().replace(/ /g, '')].phaseName

            data["index"] = index;
            return data;
        }).filter(data => {
            if (data && (data.isActive == true || data.isActive == "true")) {
                return true;
            }
            else {
                return false;
            }
        }).concat(activeCampaign,magicData || []);

        if (functionName === 'whatsNext') {
            tempData = tempData.sort((touch1, touch2) => {

                if (Number(touch2.createdAt) > Number(touch1.createdAt))
                    return 1;
                else {
                    if (Number(touch2.createdAt) === Number(touch1.createdAt)) {
                        let lowerTouchName2 = companyJourney[touch2.touchName.toLowerCase().replace(/ /g, '')] && companyJourney[touch2.touchName.toLowerCase().replace(/ /g, '')].newTouchNo || 0;
                        let lowerTouchName1 = companyJourney[touch1.touchName.toLowerCase().replace(/ /g, '')] && companyJourney[touch1.touchName.toLowerCase().replace(/ /g, '')].newTouchNo || 0;
                        if (Number(touch2.phase) > Number(touch1.phase)) {
                            return 1;
                        }
                        else if (Number(touch2.phase) === Number(touch1.phase) && Number(lowerTouchName2) > Number(lowerTouchName1)) {
                            return 1;
                        }
                    }
                }
                return -1
            });
        }
        else if(functionName === 'activityFeed'){
            tempData = tempData.sort((touch1, touch2) => {
                {
                    if (Number(touch2.createdAt) > Number(touch1.createdAt))
                        return 1;
                    else if (Number(Math.floor(touch2.createdAt / 1000)) === Number(Math.floor(touch1.createdAt / 1000))) {
                        let lowerTouchName2 = companyJourney[touch2.touchName.toLowerCase().replace(/ /g, '')] && companyJourney[touch2.touchName.toLowerCase().replace(/ /g, '')].newTouchNo || 0;
                        let lowerTouchName1 = companyJourney[touch1.touchName.toLowerCase().replace(/ /g, '')] && companyJourney[touch1.touchName.toLowerCase().replace(/ /g, '')].newTouchNo || 0;
                        if (Number(touch2.phase) > Number(touch1.phase)) {
                            return 1;
                        }
                        else if (Number(touch2.phase) === Number(touch1.phase) && Number(lowerTouchName2) > Number(lowerTouchName1)) {
                            return 1;
                        }
                    }
                }
                return -1;
            });
        }
        else if (functionName === 'notifyTime'){
            tempData = tempData.sort((touch1, touch2) => {
                {
                    if (Number(Math.floor(touch2.createdAt / 1000)) === Number(Math.floor(touch1.createdAt / 1000))) {
                        let lowerTouchName2 = companyJourney[touch2.touchName.toLowerCase().replace(/ /g, '')] && companyJourney[touch2.touchName.toLowerCase().replace(/ /g, '')].newTouchNo || 0;
                        let lowerTouchName1 = companyJourney[touch1.touchName.toLowerCase().replace(/ /g, '')] && companyJourney[touch1.touchName.toLowerCase().replace(/ /g, '')].newTouchNo || 0;
                        if (Number(touch2.phase) > Number(touch1.phase)) {
                            return 1;
                        }
                        else if (Number(touch2.phase) === Number(touch1.phase) && Number(lowerTouchName2) > Number(lowerTouchName1)) {
                            return 1;
                        }
                    } else if (Number(touch2.createdAt) > Number(touch1.createdAt))
                        return 1;
                }
                return -1;
            });
            tempData = tempData.sort((touch1, touch2) => {
                if (Number(touch2.phase) > Number(touch1.phase))
                    return 1;
                else
                    return -1;
            });
        }
        else {
            tempData = tempData.sort((touch1, touch2) => {
                {
                    if (Number(touch2.phase) > Number(touch1.phase))
                        return 1;
                    else if(Number(touch2.phase) == Number(touch1.phase)){
                       if(Number(Math.floor(touch2.createdAt / 1000)) > Number(Math.floor(touch1.createdAt / 1000))){
                            return 1;
                        } else{
                            return -1;
                        }
                    }
                    else if (Number(Math.floor(touch2.createdAt / 1000)) === Number(Math.floor(touch1.createdAt / 1000))) {
                        let lowerTouchName2 = touch2.touchName && companyJourney[touch2.touchName.toLowerCase().replace(/ /g, '')] && companyJourney[touch2.touchName.toLowerCase().replace(/ /g, '')].newTouchNo || 0;
                        let lowerTouchName1 = touch1.touchName && companyJourney[touch1.touchName.toLowerCase().replace(/ /g, '')] && companyJourney[touch1.touchName.toLowerCase().replace(/ /g, '')].newTouchNo || 0;
                        if (Number(touch2.phase) > Number(touch1.phase)) {
                            return 1;
                        }
                        else if (Number(touch2.phase) === Number(touch1.phase) && Number(lowerTouchName2) > Number(lowerTouchName1)) {
                            return 1;
                        }
                    }
                }
                return -1;
            });
            // tempData = tempData.sort((touch1, touch2) => {
            //     let lowerTouchName2 = touch2.touchName && companyJourney[touch2.touchName.toLowerCase().replace(/ /g, '')] && companyJourney[touch2.touchName.toLowerCase().replace(/ /g, '')].newTouchNo || 0;
            //     let lowerTouchName1 = touch1.touchName && companyJourney[touch1.touchName.toLowerCase().replace(/ /g, '')] && companyJourney[touch1.touchName.toLowerCase().replace(/ /g, '')].newTouchNo || 0;

            //     if (Number(touch2.phase) > Number(touch1.phase))
            //         return 1;
            //     else if (Number(touch2.phase) === Number(touch1.phase) && Number(lowerTouchName2) > Number(lowerTouchName1)) {
            //         return 1;
            //     } else
            //         return -1;
            // });
        }
            
    }
    return tempData;
}

export function activityLogFilter(touchHistory,activeCampaign,functionName,companyJourney,magicData,activityLog,surveyActivity) {
    if(!companyJourney || JSON.stringify(companyJourney)=='{}')
        return [];
    let tempData = [];
    touchHistory = touchHistory.concat(activityLog);

    if ( touchHistory.length !== 0 ) {
        tempData = touchHistory.map((data, index) => {
            if (!data)
                return
            if (typeof data === 'string')
                data = JSON.parse(data); 
            data.phaseName = data.touchName && companyJourney[data.touchName.toLowerCase().replace(/ /g, '')] && companyJourney[data.touchName.toLowerCase().replace(/ /g, '')].phaseName;
            data["index"] = index;
            return data;
        }).filter(data => {
            if (data && (data.event || data.isActive == true || data.isActive == "true")) {
                return true;
            }
            else {
                return false;
            }
        }).concat(activeCampaign,magicData || [],surveyActivity);

        if (functionName === 'activityFeed') {
            tempData = tempData.sort((touch1, touch2) => {
                if (Number(touch2.phase) > Number(touch1.phase))
                    return 1;
                else {
                    if (Number(Math.floor(touch2.createdAt / 1000)) === Number(Math.floor(touch1.createdAt / 1000))) {
                        let lowerTouchName2 = touch2.touchName && companyJourney[touch2.touchName.toLowerCase().replace(/ /g, '')] && companyJourney[touch2.touchName.toLowerCase().replace(/ /g, '')].newTouchNo || 0;
                        let lowerTouchName1 = touch1.touchName && companyJourney[touch1.touchName.toLowerCase().replace(/ /g, '')] && companyJourney[touch1.touchName.toLowerCase().replace(/ /g, '')].newTouchNo || 0;
                        if (Number(touch2.phase) > Number(touch1.phase)) {
                            return 1;
                        }
                        else if (Number(touch2.phase) === Number(touch1.phase) && Number(lowerTouchName2) > Number(lowerTouchName1)) {
                            return 1;
                        }
                    } else if (Number(touch2.createdAt) > Number(touch1.createdAt))
                        return 1;
                }
                return -1
            });
        }
            
    }
    return tempData;
}