import React, { Component }         from 'react';
import {Layout, Menu,Form,Tooltip } from 'antd';
import { connect }                  from 'react-redux';
import { bindActionCreators }       from 'redux';
import { installerSettingKey }      from '../../../../constants/routes'
import { requestCompanyDetail }     from '../../actions/profile';
import { setMetricsType }           from "../../actions/dashboard";
import withRouter                   from '../../../../shared/withRouter';
import { jwtDecode }                from "../../../../shared/jwt";
import { getToken }                 from '../../../../shared/util';
import JourneyPhaseMenu             from "../../container/companySettings/JourneyPhase"
import {removeClass}                from "../../../../shared/removeClass";
import {ReactComponent as 
      CompInfo_Icon_Active}                from "../../../../Assets/Images/compInfo_Icon_Active.svg";
import {ReactComponent as 
  Content_Icon_Active}                     from "../../../../Assets/Images/content_icon_Active.svg";
import {ReactComponent as 
  EmployeeAcct_Icon_Active}                from "../../../../Assets/Images/employeeAcct_icon_Active.svg";
import {ReactComponent as 
  Hardware_Icon_Active}                    from "../../../../Assets/Images/hardware_icon_Active.svg";
import {ReactComponent as 
  Integration_Icon_Active}                 from "../../../../Assets/Images/integration_icon_Active.svg";
import {ReactComponent as 
  PlanDetail_Icon_Active}                  from "../../../../Assets/Images/planDetail_icon_Active.svg";
import {ReactComponent as 
  Utility_Icon_Active}                     from "../../../../Assets/Images/utility_icon_Active.svg";
import {ReactComponent as 
    CompInfo_Icon}                    from "../../../../Assets/Images/compInfo_Icon.svg";
import {ReactComponent as 
    Content_Icon}                     from "../../../../Assets/Images/content_icon.svg";
import {ReactComponent as 
    EmployeeAcct_Icon}                from "../../../../Assets/Images/employeeAcct_icon.svg";
import {ReactComponent as 
    Hardware_Icon}                    from "../../../../Assets/Images/hardware_icon.svg";
import {ReactComponent as 
    Integration_Icon}                 from "../../../../Assets/Images/integration_icon.svg";
import {ReactComponent as 
    PlanDetail_Icon}                  from "../../../../Assets/Images/planDetail_icon.svg";
import {ReactComponent as 
    Utility_Icon}                     from "../../../../Assets/Images/utility_icon.svg";
import {ReactComponent as 
  Campaign_Icon}                      from "../../../../Assets/Images/campaign_icon.svg";
import {ReactComponent as 
  Campaign_Icon_Active}               from "../../../../Assets/Images/campaign_icon_Active.svg";
import {ReactComponent as 
  Journey_Icon}                      from "../../../../Assets/Images/journey_icon.svg";
import {ReactComponent as 
  Journey_Icon_Active}               from "../../../../Assets/Images/journey_icon_Active.svg";

const { Sider } = Layout;
const { SubMenu } = Menu;

class SiderMenuForm extends Component {
  constructor(props) {  
    super(props);
    this.state = {
      siderActive : 'isCompany',
      siderOptionActive : 'isCompany',
      collapsed : localStorage.getItem('siderBarState') == "true" ? true : false
    };
  }

  componentWillMount() {
    let token = getToken()
    this.installerData = jwtDecode(token);
    this.admin = this.installerData.role ? false : true;
    this.companyId = this.props.router.params.companyId || this.installerData.companyId;
    this.props.requestCompanyDetail({ companyId: this.companyId, admin: this.admin });
  }

  componentWillReceiveProps(nextProps) {
    /**
     * @todo: verify this change: `.match.path` to `.router.location.pathname`
     */
    if (!nextProps.router.location.pathname.includes('companysetting')) {
      this.setState({siderActive:'', siderOptionActive:''});
    }
    if (nextProps && nextProps.companyData && nextProps.companyData.companyDetail && nextProps.companyData.companyDetail.slug) {
      this.slug = nextProps.companyData.companyDetail.slug;
    }
  }

  componentDidMount() {
    var d = document.getElementsByTagName('body');
    if (this.installerData && this.installerData.role === "installer"){
      removeClass("installer-view");
      removeClass("sider-collapse");
    } else if (localStorage.getItem('siderBarState') == "true") {
      removeClass("installer-view");
      if (d && d[0]) {
        d[0].className = "sider-collapse harmonia";
      }
    } else {
      removeClass("sider-collapse");
      if (d && d[0]) {
        d[0].className = "installer-view harmonia";
      }
    }
  }

  redirect = data => {
    window.scroll({top: 0});
    this.props.router.navigate(`/${this.slug}/${this.companyId}/companysetting?${installerSettingKey}=${data.companySettingState}`)
  }

  toggle = () => {
    this.setState({
      collapsed: !this.state.collapsed,
    }, () => {
      var d = document.getElementsByTagName('body');
      localStorage.setItem('siderBarState', this.state.collapsed);
      if (this.state.collapsed) {
        removeClass("installer-view");
        if (d && d[0]) {
          d[0].className = "sider-collapse harmonia";
        }
      } else {
        removeClass("sider-collapse");
        if(d && d[0]){
          d[0].className = "installer-view harmonia";
        }
      }
    });
  };

  renderResourceImg(item) {
    let { hoverSider, siderActive } = this.state;
    switch (item) {
     case 'isCompany':
       if(siderActive==="isCompany" || hoverSider==="isCompany")
        return <CompInfo_Icon_Active className="sider-icon"/>; 
       else
        return <CompInfo_Icon className="sider-icon"/>;
      case 'isPlan':
        if(siderActive==="isPlan"  || hoverSider==="isPlan")
        return <PlanDetail_Icon_Active className="sider-icon"/>; 
       else
        return <PlanDetail_Icon className="sider-icon"/>;
      case 'isIntegrations':
        if(siderActive==="isIntegrations"  || hoverSider==="isIntegrations")
        return <Integration_Icon_Active className="sider-icon"/>; 
       else
        return <Integration_Icon className="sider-icon"/>;
      case 'isEmployee':
        if(siderActive==="isEmployee"  || hoverSider==="isEmployee")
        return <EmployeeAcct_Icon_Active className="sider-icon"/>; 
       else
        return <EmployeeAcct_Icon className="sider-icon"/>;
        case 'isHardware':
          if(siderActive==="isHardware"  || hoverSider==="isHardware")
          return <Hardware_Icon_Active className="sider-icon"/>; 
         else
        return <Hardware_Icon className="sider-icon"/>;
      case 'isUtilities':
        if(siderActive==="isUtilities"  || hoverSider==="isUtilities")
        return <Utility_Icon_Active className="sider-icon utilityIcon"/>; 
       else
        return <Utility_Icon className="sider-icon utilityIcon"/>;
      case 'content':
        if(siderActive==="content"  || hoverSider==="content")
        return <Content_Icon_Active className="sider-icon"/>; 
       else
        return <Content_Icon className="sider-icon"/>;
      case 'isCampaign':
        if(siderActive==="isCampaign"  || hoverSider==="isCampaign")
        return <Campaign_Icon_Active className="sider-icon"/>; 
       else
        return <Campaign_Icon className="sider-icon"/>;
      case 'isJourney':
        if(siderActive==="isJourney"  || hoverSider==="isJourney")
        return <Journey_Icon_Active className="sider-icon"/>; 
       else
        return <Journey_Icon className="sider-icon"/>;
    }
  }

  render() {

    if (this.installerData.role && !this.installerData.role.includes("admin")){
      return <></>
    }

    let subMenu1 = [
      {
        display:"Educational resources",
        key:"isEdResource"
      },
      {
        display:"System notifications",
        key:"isSystemNotification"
      },
      {
        display:"Referrals",
        key:"isReferralMessage"
      },
      {
        display:"More info",
        key:"isPhaseInfo"
      },
      {
        display:"FAQs",
        key:"isFAQ"
      }
    ]

    let option = [
      {
        display : "Company information",
        key : "isCompany"
      },
      {
        display : "Plan details",
        key : "isPlan"
      },{
        display : "Integrations",
        key : "isIntegrations"
      },
      {
        display : "Employee accounts",
        key : "isEmployee"
      },{
        display : "Hardware",
        key : "isHardware"
      },
      {
        display : "Utilities",
        key : "isUtilities"
      },
      // {
      //   display : "Campaigns",
      //   key : "isCampaign"
      // }
    ]

    let isCollapse = this.state.collapsed;
    return (
      <>
      <div className={"sider-button " +  (isCollapse ? 'sider2-collapse' : '')} onClick={()=>this.toggle()}></div>
      <Sider trigger={null}
      className="bodhi-sider" collapsible onCollapse={this.toggle}  collapsed={isCollapse} width="251">        
        <div className="dashboard-nav-wrapper">
        {
          this.props.installerData && this.props.installerData.data && this.props.installerData.data.isProfileCreated ?
            <a
              className={"navbar-brand installerView " + (isCollapse ? 'sider-collapse' : '') }
              onClick={() => {
                this.props.setMetricsType(null);
                this.props.router.history.push(`/${this.slug}/${this.companyId}/dashboard`)
              }}
            />
            :
            <a
              className={"navbar-brand installerView " + (isCollapse ? 'sider-collapse' : '')}
            />
        }
        </div>

        <Menu theme="dark" className="left-menu" mode="inline" inlineCollapsed={true} defaultSelectedKeys={['0']}>
          {
            option.map((ele,index) => {
              return(
                <Menu.Item className={this.state.siderOptionActive===ele.key ? 'siderActive' : ''} key={index} onMouseOut={()=>this.setState({hoverSider:''})} onMouseOver={()=>this.setState({hoverSider:ele.key})} onClick={() => {
                  this.setState({siderActive: ele.key, siderOptionActive: ele.key})
                  this.redirect({ companySettingState: ele.key })
                }}>
                  {
                    this.renderResourceImg(ele.key)
                  }
                  {
                    !this.state.collapsed ? <div>{ele.display}  </div> : ''
                  }
                </Menu.Item>
              )
            })
          }

          <SubMenu className="content-menu" key="sub1" title={
            <>
            {this.renderResourceImg('content')}
              {!this.state.collapsed ?  <div>Content</div> : ''}
            </>
          }>
            {
              subMenu1.map((ele, i)=>{
               return <Menu.Item key={`menu-${i}`} className={`shiftRight ${this.state.siderOptionActive===ele.key ? 'siderActive':''}`} onMouseOut={()=>this.setState({hoverSider:''})} onMouseOver={()=>this.setState({hoverSider:'content'})} onClick={() => {this.setState({siderActive:'content',siderOptionActive:ele.key}); this.redirect({companySettingState:ele.key}); }}>{ele.display}</Menu.Item>
              })
            }     
          </SubMenu>

          <SubMenu className="content-menu" key="sub2" title={this.addJourney()} onMouseOut={()=>this.setState({hoverSider:''})} onMouseOver={()=>this.setState({hoverSider:''})} onTitleClick={()=>this.setState({siderActive:'isJourney'})}>
          <JourneyPhaseMenu {...this.props} isMenuCollapse={isCollapse} siderOptionActive={this.state.siderOptionActive} updateStateFromProps={this.updateStateFromProps} redirect={this.redirect}/>
            </SubMenu>

        </Menu>
      </Sider>
      </>
    )
  }

  updateStateFromProps = data => {
    this.setState(data);
  }
  
  addJourney = () => {
    return(
      <>
        {
          this.renderResourceImg('isJourney')
        }
        {!this.state.collapsed ? <><div>Bodhi journeys</div> 
        <Tooltip overlayClassName="yellow-tooltip" onMouseOut={()=>this.setState({hoverSider:''})} onMouseOver={()=>this.setState({hoverSider:''})} title={'Create new Bodhi journey'}>
          <span className="add-icon"
            onClick={(e) => { 
              e.stopPropagation();
              this.setState({siderActive:''});
              this.redirect({ companySettingState: 'addJourney', editJourneyData: "empty" }) }}
          >+</span>
        </Tooltip></>
        : ''}
      </>
    )
  }

}

const mapStatetoProps = state => {
  return {
    installerData: state.header.getInstaller,
    companyData: state.setting.companyDetail.data || {}
  };
};

const mapDispatchToProps = dispatch =>
  bindActionCreators({ 
    requestCompanyDetail,
    setMetricsType
   }, dispatch);

export default withRouter(connect(
  mapStatetoProps,
  mapDispatchToProps
)(SiderMenuForm));
