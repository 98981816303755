import React, { Component } from "react";
import { reduxForm } from "redux-form";
import withRouter from '../../../../shared/withRouter'
import { envImageUrl,checkImageValid}  from "../setUserBackgroud"
import termsCloseIcon from "./../../../../Assets/Images/terms-close.png"

class PrivacyAndPolicyComponent extends Component {
  constructor(props) {
    super(props);
    this.image = "";
    this.slug = "";
    this.state = {};
  }
  componentWillMount() {
    this.props.init();
    this.slug = localStorage.getItem("slug")
  }

  componentDidMount(){
    checkImageValid('test-bg').then((response)=>{
      if (response && !this.state.defaultLoginImage) {
        this.setState({
          defaultLoginImage: "https://prod-17terawatts.s3.amazonaws.com/public/logo/desktopLogin_BG.jpg"
        })
      }
    });
  }

  componentWillReceiveProps(nextProps, nextState) {
    if (nextProps.image && nextProps.image.status === 200) {
      this.image = nextProps.image.data.logo;
    }
  }

  onClickClose = () => {
      this.props.router.navigate('/');
  }

  render() {
    const { image } = this.props;
    let css = {
      "backgroundImage": `url(${this.state.defaultLoginImage ||  envImageUrl(this.slug)})`
    }
    return (
      <React.Fragment>
        <title>Privacy Policy - Bodhi</title>
        <div id="test-bg" className="bg-black login-bg body-height"  style={css}>
        <div id="login-container" className="h-100">
          <div className="login-box" style={{height:"100%"}}>
              <div className="d-inline-block justify-content-end w-100 text-right">
                <div className="white-logo d-inline-block" />
              </div>
            <div className="box-Shadow center-white-box termsCondition">
              <img
                src={termsCloseIcon}
                alt='terms-close.png'
                className="dev-close-btn" onClick={() => this.onClickClose()}
              />
              <div className="forgotpassword">
                  <div className="logo-blue" style={{ 'backgroundImage': `url(${(image && image.data && image.data.mobileLogo) || ''})` }} />
                  <p className="mobile-h2">Welcome to Bodhi.<br />
                    Your partner on the solar journey
                    </p>
                <div>
                  <h3>Introduction</h3>
                  <p className="privacy-and-policy">
                  17TeraWatts, Inc. (“17TeraWatts” or “we” or “us”) has created this Privacy Policy in order to disclose its use of consumer data collected about you. This Privacy Policy is effective as of March 7, 2019. Any questions regarding this policy should be directed by email to 17TeraWatts.com. You may also contact 17TeraWatts at info@17terawatts.com.  The following discloses 17TeraWatts’s information gathering and dissemination practices for its  applications and website.
                  </p>

                  <h3>Information Collected and Received</h3>
                  <p className="privacy-and-policy">
                  17TeraWatts may collect or receive information about each person who registers with the 17TeraWatts and Mybodhiapp website and/or applications including, but not limited to, user name, password, first and last name, email address, street address, gender, occupation, solar production, home energy consumption, and interests. 17TeraWatts also collects information on the pages you access and other information you may volunteer, such as survey information.  17TeraWatts may also collect user location information, as further described in the “Global Positioning System” paragraph below.
                  </p>

                  <h3>Uses of Information</h3>
                  <p>
                    <strong>
                    WE WILL NEVER SELL YOUR EMAIL ADDRESS, OR ANY OTHER PERSONAL INFORMATION, TO ANY THIRD PARTY WITHOUT YOUR PERMISSION. EVER.
                    </strong>
                  </p>

                  <p className="privacy-and-policy">
                  Except as described in this section, we will not disclose your personal information to any third party without notifying you of our intent to share the information and giving you an opportunity to prevent your information from being shared.
                  </p>

                  <p className="privacy-and-policy">
                  From time to time, we may partner with companies based on the interests of our users. These partner companies will never see your email address or any other information that could identify you or be used to contact you directly.
                  </p>

                  <p className="privacy-and-policy">
                  Any exceptions to this policy of sharing your name, address or email address with a partner company will be done only with your permission.
                  </p>

                  <p className="privacy-and-policy">
                  17TeraWatts reserves the right to disclose information when required by law.
                  </p>

                  <p className="privacy-and-policy">
                  We occasionally use other companies to perform services necessary to our operations. In the course of providing these services, those companies may have access to your personal information. By contractual agreement, those companies must treat your information in accordance with this Privacy Policy. However, we will not be liable for any damages that may result from the misuse of your personal information by these companies.
                  </p>

                  <p className="privacy-and-policy">
                  17TeraWatts may use your IP address to help diagnose problems with 17TeraWatts’s server and to administer 17TeraWatts’s web site. Your IP address may also be used to help identify you and your online profile and to gather broad demographic information.
                  </p>
                  <p className="privacy-and-policy">
                  The 17TeraWatts site registration form requires users or users service provider to give 17TeraWatts contact information (such as your name and email address) and demographic information (such as your zip code, age, or income level). 17TeraWatts uses your contact information from the registration form to send you information about 17TeraWatts and promotional material from some of 17TeraWatts’s partners. Your contact information may also be used to contact you when necessary. 17TeraWatts may use demographic and/or profile data to tailor the your experience on the 17TeraWatts website and/or applications, show you content that 17TeraWatts thinks you may be interested in, and display content according to your preferences. The demographic and profile data will be shared with third parties ONLY on an aggregate basis.  Furthermore, 17TeraWatts shall have the right to retain records of all data pertaining to use of the website and applications including, but not limited to, usage, activity logs, and click-throughs.  17TeraWatts may disclose such data to third parties provided it is grouped with other 17TeraWatts users’ data and is presented in an aggregate form.
                  </p>
                  <p className="privacy-and-policy">
                  We may also disclose, on an anonymous basis, statements made by our users.
                  </p>
                </div>
                <div>
                  <h3>Children’s Privacy</h3>
                  <p className="privacy-and-policy">
                  We do not knowingly collect any information from anyone who we know to be under the age of 13. If you are under the age of 18, you should use this website only with the involvement of a parent or guardian and should not submit any personal information to us. If we discover that a person under the age of 13 has provided us with any personal information, we will use commercially reasonable efforts to delete such person's personal information from all 17TeraWatts systems.
                  </p>
                  </div>
                  <div>
                  <h3>Global Positioning System</h3>
                  <p className="privacy-and-policy">
                  Global Positioning System (GPS) tracking technology may need to be enabled in 17TeraWatts products and services in order to determine the location (latitude and longitude) of users of the 17TeraWatts products and services. This information is transmitted to 17TeraWatts, 17TeraWatts’s users, and temporarily stored by 17TeraWatts. 17TeraWatts does not provide this information to any other third party.  This information is used in conjunction with 17TeraWatts’s applications.
                  </p>
                  </div>
                  <div>
                  <h3>Security</h3>
                  <p className="privacy-and-policy">
                  17TeraWatts uses industry-standard technologies when transferring and receiving consumer data exchanged between 17TeraWatts and other companies to help ensure its security. This site has security measures in place to protect the loss, misuse and alteration of the information under 17TeraWatts’s control. 17TeraWatts’s servers are backed up regularly and protected by security systems.
                  </p>
                  </div>
                  <div>
                  <h3>Cookies</h3>
                  <p className="privacy-and-policy">
                  “Cookies” are small pieces of information that are stored by your browser on your computer's hard drive. The 17TeraWatts site uses cookies to keep track of your session, shopping cart, and advertising delivery. 17TeraWatts may also use cookies to deliver content specific to your interests and to save your password. 17TeraWatts may use an outside ad company to display ads on the 17TeraWatts site. These ads may also contain cookies. While 17TeraWatts uses cookies in other parts of its web site, cookies received with banner ads are collected by 17TeraWatts’s ad company and 17TeraWatts does not have access to this information.
                  </p>
                  <p className="privacy-and-policy">
                  We may use standard Internet technology, such as web beacons and other similar technologies, to track your use on our sites and software. We also may include web beacons in promotional e-mail messages or newsletters to determine whether messages have been opened and acted upon. The information we obtain in this manner enables us to customize the services we offer our users to deliver targeted advertisements and to measure the overall effectiveness of our online advertising, content, programming or other activities.
                  </p>
                  <p className="privacy-and-policy">
                  We may allow third parties, including our authorized service providers, advertising companies, and ad networks, to display advertisements on our site and/or software. 17TeraWatts and these companies may use tracking technologies, such as cookies, to collect information about users who view or interact with these advertisements and connect to the 17TeraWatts properties and/or software. Our properties do not provide any personal information to these third parties. This information allows 17TeraWatts and these companies to deliver targeted advertisements and gauge their effectiveness.
                  </p>
                  </div>
                  <div>
                  <h3>Links</h3>
                  <p className="privacy-and-policy">
                  17TeraWatts may create links to other web sites. 17TeraWatts will make a reasonable effort to link only to sites that meet similar standards for maintaining each individual's right to privacy. However, many other sites that are not associated or authorized by 17TeraWatts may have links leading to our site. 17TeraWatts cannot control these links and 17TeraWatts is not responsible for any content appearing on these sites.
                  </p>
                  </div>
                  <div>
                  <h3>Amendments</h3>
                  <p className="privacy-and-policy">
                  17TeraWatts may amend this policy at any time. If 17TeraWatts is going to use personally identifiable information collected through the 17TeraWatts site in a manner materially different from that stated at the time of collection, 17TeraWatts will notify users via email and/or by posting a notice on the 17TeraWatts site for thirty (30) days prior to such use. This Privacy Policy may not be otherwise amended except in a writing that specifically refers to this Privacy Policy and is physically signed by both parties.
                  </p>
                  </div>
                  <div>
                  <h3>Successors and Assigns</h3>
                  <p className="privacy-and-policy">
                  This Privacy Policy inures to the benefit of successors and assigns of 17TeraWatts.
                  </p>
                  </div>
                  <div>
                  <h3>Contacts</h3>
                  <p className="privacy-and-policy">
                  If you have any questions about this Privacy Policy, the practices of this site, or your dealings with this web site, you can contact info@17terawatts.com. Upon request, 17TeraWatts will provide you with access to information (e.g., name, address, phone number) that 17TeraWatts collects and maintains about you. This site gives you the following options for changing and modifying information previously provided: (i) email: info@17terawatts.com; or (ii) visit 17terawatts.com.
                  </p>
                  </div>
                  <div>
                  <h3>Opt-Out</h3>
                  <p className="privacy-and-policy">
                  The 17TeraWatts site provides users the opportunity to opt-out of receiving communications from 17TeraWatts, except transactional correspondence. To opt-out of receiving our communications, you can (i) send email to info@17terawatts.com; or (ii) send postal mail to: 17TeraWatts, Inc., 2400 Homedale Cir. Austin, TX 78704.
                  </p>
                  </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      </React.Fragment>
    );
  }
}

// Decorate the form component
export default reduxForm({
  form: "PrivacyAndPolicyComponent"
})(withRouter(PrivacyAndPolicyComponent));
