import React, { Component }         from "react";
import { Form, Input, Button, useForm }       from "antd";
import { connect }                  from 'react-redux'
import { bindActionCreators }       from "redux";
import * as toast     from "../../../../shared/notification";
import {setLoader}    from "../../../Common/Loader/action"
import { requestFaqList, requestFaqPost } from '../../actions/profile'
import removeImg from "../../../../Assets/Images/remove.svg"
import addIcon from "../../../../Assets/Images/addIcon.svg"

const FormItem = Form.Item;
const { TextArea } = Input;

let id = 0;
let editableClass = false;

const formItemLayout = {
  labelCol: {
    xs: { span: 24 },
    sm: { span: 4 }
  },
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 24 }
  }
};

const FAQForm = (props) => {
  const [form] = Form.useForm();
  return (
    <Form form = { form } onFinish = { props.handleSubmit(form) }>
      <Form.List name="keys">
        {
          (fields, { add, remove }) => (
            <>
            {fields.map((field, index) => {
              return (<React.Fragment key={index}>
                <FormItem
                  name={[field.name, "question"]}
                  { ...formItemLayout }
                  required={false}
                  
                  className="faq-form"
                  rules = { [ {
                    required: true,
                    whitespace: true,
                    message: "Please input your Question."
                  } ] }
                >
                  <div>
                    <span className="question-ans-box font-size16">
                      Q:
                    </span>
                    <TextArea
                      placeholder="Please input your Question."
                      autoSize
                      className="faq-textarea"
                    />
                  </div>
                </FormItem>
                <FormItem
                  name={[field.name, "answer"]}
                  { ...formItemLayout }
                  required={false}
                  key={`${index}-b`}
                  className="faq-form"
                  rules = { [ {
                    required: true,
                    whitespace: true,
                    message: "Please input your answer."
                  } ] }
                >
                  <div>
                    <span className="question-ans-box font-size16">
                      A:
                    </span>
                    <TextArea
                      placeholder="Please input your Answers."
                      autoSize
                      className="faq-textarea"
                    />
                    <Button
                      className="dynamic-delete-button"
                      onClick={ () => remove(field.name) }
                    >
                      Delete
                    </Button>
                  </div>
                </FormItem>
              </React.Fragment>)
            })}
              <FormItem>
                <Button type="dashed" onClick={ () => add() } className="faq-add-btn">
                  <img className="plus" src={addIcon} alt="Add question" />
                  Add Q&A
                </Button>
              </FormItem>
            </>
          )
        }
      </Form.List>
      <div className="text-center margt70">
        <button className="btn btn-primary">Save</button>
      </div>
    </Form>  
  )
}

class FAQCompanySettingForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isUpdate: false,
      isEditable: true,
      isCheck : false
    }
    this.quesAnswerList = [];
    this.showToast = false;
  }

  componentWillMount() {
    this.props.requestFaqPost({});
    this.props.requestFaqList(this.props.companyId);
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.faqList && nextProps.faqList[nextProps.faqList.length - 1]) {
      this.quesAnswerList =
        nextProps.faqList[nextProps.faqList.length - 1].data;
    }

    if (nextProps.faqPost) {
      if (nextProps.faqPost.status === 200 || nextProps.faqPost.status === 201) {
        if (this.showToast) {
          toast.openNotificationWithIcon(
            "success",
            "FAQ updated successfully.",
            " Success "
          );
          this.showToast = false;
          this.props.requestFaqList(this.props.companyId);
        }
        this.props.requestFaqPost({});
        this.quesAnswerList = [];
      }
    }
  }

  onChangeQuestion = (index, e) => {
    this.newQuestion = undefined;
    this.newQuestion = e.target.value;
    this.quesAnswerList[index].question = e.target.value;
    this.setState({isCheck:!this.state.isCheck})
  };

  onChangeAnswer = (index, e) => {
    this.quesAnswerList[index].answer = e.target.value;
    this.setState({isCheck:!this.state.isCheck})
  };

  editQuestion = (index, data) => {
    this.quesAnswerList[index]['isEditable'] = true
    this.setState({
      isEditable: false,
      isUpdate: true,
    })
    editableClass = !editableClass;
  };

  removequestion = index => {
    this.quesAnswerList[index]['isDelete'] = true
    this.setState({
      isUpdate: true
    });
    this.forceUpdate();
  };

  handleSubmit = () => {
    const formDecorator = form => {
      const self = this
      return values => {
        self.setState({
          isEditable: true
        })

        self.showToast = true
        let FaqList = [];
        const temp = self.quesAnswerList
        self.quesAnswerList.forEach((item, index) => {
          let obj = { question: item.question, answer: item.answer };
          if (!item.isDelete) {
            FaqList.push(obj);
          }
        });

        if (values.keys) {
          values.keys.map((item, index) => {
            FaqList.push({
              question: item.question,
              answer: item.answer
            });
          });
        }

        self.quesAnswerList = [];
        
        if (self.state.isUpdate || temp.length !== FaqList.length) {
          self.props.setLoader({loader: true});
          self.props.requestFaqPost({
            faqData: FaqList,
            companyId: self.props.companyId
          });
          self.setState({
            isUpdate: false
          });
        } else {
          toast.openNotificationWithIcon('error', 'Please add or update any FAQ.', 'Failed')
        }

        form.setFieldsValue({
          keys: []
        });
        form.setFieldsValue({
          question: []
        });
        form.setFieldsValue({
          answer: []
        });

      }
    }

    return formDecorator.bind(this)
  };

  renderQuestionAnswer = (data, index) => {
    if (!data.isDelete) {
      return (
          <div className="ques-ans-box" key={index}>
            <div className="ques box">
              <span className="question-ans-box">Q:</span>
              <TextArea
                autosize={{ minRows: 2, maxRows: 100 }}
                onChange={e => {
                  this.onChangeAnswer(index, e);
                  this.editQuestion(index, data)
                }}
                value = {data.question}
              >
                {data.question}
              </TextArea>
            </div>
            <div className="ans box">
              <span className="question-ans-box">A:</span>
              <TextArea
                autosize={{ minRows: 2, maxRows: 100 }}
                onChange={e => {
                  this.onChangeAnswer(index, e);
                  this.editQuestion(index, data)
                }}
                value={data.answer}
              >
                {data.answer}
              </TextArea>
            </div>
            <span className="faq-action-btn">
            <img src={removeImg}
              onClick={() => this.removequestion(index)}
              alt="Remove question"
            />
            </span>
          </div>
      );
    }
  };

  render() {
    return (
      <div>
        <div className="faq-setting-wrap">
          {
            this.quesAnswerList &&
            this.quesAnswerList.map((item, i) =>
              this.renderQuestionAnswer(item, i)
            )
          }
          <FAQForm handleSubmit={ this.handleSubmit() } />
        </div>
      </div>
    );
  }
}

const mapStatetoProps = state => {
  return {
    auth: state.auth,
    faqList: state.setting.faqList,
    faqPost: state.setting.faqPost
  }
}

const mapDispatchToProps = dispatch => (
  bindActionCreators({ requestFaqList, requestFaqPost, setLoader }, dispatch)
)

export default connect(mapStatetoProps, mapDispatchToProps)(FAQCompanySettingForm)
