// import npm packages
import React, { Component }         from 'react';
import { Form, Input,Slider }       from 'antd';
import { GoogleApiWrapper }         from 'google-maps-react';
import { connect }                  from 'react-redux';

// import local files
import withRouter                   from '../../../../../shared/withRouter';
import TwoLayerDonutChart           from '../../../../Common/DonutChart/TwoLayerDonutChart';
import { parseJson }                from '../../../../../shared/parseJson';
import { currencyFormater }         from '../../../../../shared/currencyFormater';
import googleMap                    from '../../../../../environments/index';
import ErrorPage                    from '../../../../Common/ErrorPage';
import { getLoadingIcon }           from "../../../../User/components/setUserBackgroud";
import LandingPage                  from '../../../../Common/LandingPage';
import { openNotificationWithIcon } from '../../../../../../src/shared/notification';
import {checkIsMobile}              from '../../../../../shared/checkIsMobile';

const FormItem = Form.Item;

class MonitoringLandingForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      errorObj: {},
      address: '',
      sliderStatus: 3000,
      utilityRate: null,
      isInvalid:false
    };

    this.marks = {
      500: {
        label: <span className="sunburst-slider">500 sqft</span>,
    },
      1800: {
        label: <span className="sunburst-slider">1800</span>,
    },
      3000:{
        label: <span className="sunburst-slider">3000 sqft</span>,
    },
      4000: {
        label: <span className="sunburst-slider">4000</span>,
    },
      5000: {
          label: <span className="sunburst-slider mobile-color premiumLinks">5000+</span>,
      },
  };
  }

  componentWillReceiveProps(nextProps) {
    let data = nextProps.referralDetails.postHomeownerReferrals.data;
    if (data) {
      this.props.receivePostReferralDetails('');
    }
     let temp = nextProps.referralDetails
     && nextProps.referralDetails.postHomeownerAddress
     && nextProps.referralDetails.postHomeownerAddress.data
     && nextProps.referralDetails.postHomeownerAddress.data.data;

      if (temp && (!temp.lat || !temp.lng) && !this.state.isInvalid) {
        nextProps.setLoader({ loader: false });
        openNotificationWithIcon('error', 'Please enter valid address', 'Failed');
        this.setState({isInvalid:true})
      } else if (temp && temp.utilityRate && temp.lat) {
      let urlParam = this.props.router.params
      nextProps.setLoader({ loader: false });
      this.props.router.navigate(`/${urlParam.slug}/referral/${urlParam.referralId}/${urlParam.share}/${urlParam.method}/calculate`);
    }
  }
  
  shouldComponentUpdate() {
    if (!this.state.renderSunburstGraph) {
        return true;
    } else {
        return false;
    }
}
  handleSlider = (val) => {
    this.setState({
        sliderStatus: val
    })
  }

  renderCO2() {
    let lbs = 0;
    if (this.props.energyReport && this.props.energyReport.monthlyProduction) {
      lbs = parseInt(this.props.energyReport.monthlyProduction * 1.6);
      return `${Math.ceil((lbs / 10500) * 12)} sq ft`;
    }
    return '0 sq ft';
  }

  render2Form(postHomeownerAddressForm){
    return(
      <>
        <div className="enter-your-ad glance rating sunburst">What is the size of your home?</div>
      <FormItem
        hasFeedback
        name="rating"
        rules = { [
            {
              required: true
            }
          ] }
      >
          <div>
            <Slider marks={this.marks}
              defaultValue={postHomeownerAddressForm.slider ? postHomeownerAddressForm.slider : this.state.sliderStatus}
              onChange={val => { this.handleSlider(val) }}
              min={500} max={5000}
              className="slider-mark premiumLinks" />

          </div>
      </FormItem>
      </>
    )
  }

  render1Form(){
    return(
      <div className="form-group ">
        <div className="enter-your-ad glance sunburst">Please enter your address</div>
        <Input
          type="text"
          className="form-control"
          id="exampleInputEmail1"
          onChange={e => { this.setState({ add: e.target.value }) }}
          className="form-control input-color address premiumSecondaryText"
          placeholder="Street Address, City, State, Zip Code"
        />
      </div>
    )
  }

  renderReferralForm(){
    const {
      referralDetails :{postHomeownerAddressForm},
      loggedInUser
    } = this.props; 
    const companyname  =   loggedInUser 
                           && loggedInUser.homeownerData 
                           && loggedInUser.homeownerData.companyName
                           ? (loggedInUser.homeownerData.companyName).toUpperCase():'';
    return(
      <Form className="monitoring-form premiumSecondaryText">

                    <h6 className="explore-savings sunburst premiumSecondaryText">Explore Your Savings</h6>
                    {!this.props.loggedInUser.homeownerData.calculatorUrl && this.render1Form(postHomeownerAddressForm)}
                    <div className="form-group">
                    {!this.props.loggedInUser.homeownerData.calculatorUrl && this.render2Form(postHomeownerAddressForm)}
                        <div className="text-center">
                            {this.props.loggedInUser.homeownerData.calculatorUrl ?
                                <a className="btn yellow-btn premiumButtonFill premiumButtonText rating-slider-btn monitor" href={this.props.loggedInUser.homeownerData.calculatorUrl} target="_blank">
                                    {/* <button className="btn yellow-btn premiumButtonFill premiumButtonText rating-slider-btn"  ></button> */}
                                    {`Calculate with `+ companyname }
                                </a>                                      
                                :
                                <button type="submit " className="btn yellow-btn premiumButtonFill premiumButtonText rating-slider-btn" onClick={(e) => {
                                    this.handleSubmitCalculate();
                                }}
                                >Calculate</button>}
                        </div>
                    </div>
          </Form>

    )
  }

  renderLanding() {
    let details = this.props.referralDetails.homeownerReferrals.data;
    let yearlyProduction = details && details.yearlyProduction;
    return (
      <>
        <h1 className="monitoring-heading glance">
                YOUR SOLAR <br/> COMMUNITY IS WAITING
        </h1>
         <div className="monitoring-content monitoring-desktop-view-only">
                {details && (
                  <p className="referral-style-p">
                   Did you know {details.firstName} {yearlyProduction ? this.calculateGlacier(yearlyProduction):''}
                {" "}{details.totalSaving ? `saves $${details.totalSaving} on electricity every year, and `:''} is 
                   one of {details.totalNeighbour} solar families in {details.firstName.trim()}'s neighborhood? 
                   The graph shows {details.firstName.trim()}'s solar production (yellow) and home energy 
                   consumption (blue).
                  </p>
                )}

              <p>
              Most people are amazed that they can go solar with $0 down while also saving on their electricity bill.
             </p>
                {this.renderReferralForm()}
          </div>
              <div className="monitoring-mobile-view-only">
                  <h1 className="referral-heading-sunburst">
                            Your solar community is waiting
                  </h1>
                <div className="row">
                  <div className="col-md-5 offset-md-1 ">
                    {details && (
                        <p className="referralLanding-content">
                            Did you know {details.firstName} has preserved {' '}
                            {yearlyProduction ? this.calculateGlacier(yearlyProduction) : ''}{' '}sqft  
                            saves ${details.totalSaving} on electricity every year, 
                            and is one of {details.totalNeighbour} solar families 
                            in {details.firstName.trim()}'s neighborhood? The graph shows 
                            {details.firstName.trim()}'s solar production (yellow) and home energy 
                            consumption (blue).
                        </p>
                    )}
                  </div>
                </div>
          </div> 
      </>
    );
  }

  handleSubmitCalculate = e => {
    if (this.state.add) {
      let bodydata ={
          "add":this.state.add,
          "slider" :this.state.sliderStatus
      }
      this.props.receivePostAddressFormData(bodydata);
      let data = {
        "path": this.state.add
      };
      this.props.setLoader({ loader: true });
      this.props.requestPostAddressDetails(data);
      this.setState({isInvalid:false});
    } else {
      openNotificationWithIcon('error', 'Address is needed to share the sun', 'Please try again');
    }
  };

  calculateGlacier(value){
    value = value*1;
    let glacier = (value * 1.6* 32)/2000;
    let calc = glacier.toFixed(1);
    if(calc>0.9){
      return ` has preserved ${calc} sq ft of glaciers, `
    } 
    else{
      return ''
    }
  }

  renderSunburstGraph =(value) =>{
    if(value===true)
      this.setState({renderSunburstGraph : true});
  }

  renderSunBurst(details){
    return(
      <div className="col-md-7 monitorignGraph">
        <div className="sunburts-postion glanceGraph">
          <div className="row">
            <div className="col-sm-12">
              <div className="text-center d3-chart-wrapper glance" style={{
                      width : '90%'
                    }}>
                {this.props.energyReport.historyData && <TwoLayerDonutChart
                  energyReport={this.props.energyReport}
                  maxSystemSize = {details && details.systemSize}
                  graphSize ={!checkIsMobile() && {
                    width     : 552,
                    heigth    : 514,
                    radius    : 160,
                    className : "referral",
                    styleMedia :{
                      width : '100%'
                    }
                  }}
                  offset={
                    details && details.offset ? details.offset : null
                  }
                  renderSunburst = {this.renderSunburstGraph}
                />}
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-sm-6 col-6">
              <h4 className="ho-setting-h4 glance">SOLAR PRODUCTION</h4>
              <p className="ho-setting-month-style glance margt0 margb0">
                Saved this month
          </p>
              <div className="price ho-setting-yellow-value glance">
                $
            {this.props.energyReport.totalSaving
                  ? currencyFormater(this.props.energyReport.totalSaving)
                  : 0}
              </div>
            </div>
            <div className="col-sm-6 home-consumption col-6">
              <h4 className="ho-setting-h4 glance">IMPACT</h4>
              <p className="ho-setting-month-style glance margt0 margb0">
                Glaciers preserved
          </p>
              <div className="price ho-setting-blue-value premiumBackgroundText">
                <p className="price ho-setting-blue-value premiumBackgroundText glance">
                  {this.renderCO2()}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    )}

  render() {
    let peakData = {};
    let homeownersList = this.props.loggedInUser && this.props.loggedInUser.homeownerData;
    let mobileImage = homeownersList && homeownersList.icon;
    let image = homeownersList && homeownersList.logo;

    let details = this.props.referralDetails.homeownerReferrals.data;
    
    if (this.props.energyReport && this.props.energyReport.peakData) {
      peakData = this.props.energyReport.peakData;
      if (typeof this.props.energyReport.peakData === 'string') {
        peakData = parseJson(this.props.energyReport.peakData);
      }
    }
    if (details) {
      return (
        <>
       <title>Watch {details.firstName.trim()}'s savings in real time!</title>
        <div className="referral-wrap">
          <div className="row glance premiumBackground premiumSecondaryText">
            <div className="col-md-5 monitoring-bg premiumBackground">
              <img
                style={ image && {width: 'auto'} }
                src={image}
                alt="" className="desktop-img"
              />

                <img
                  style={ image && {width: 'auto'} }
                  src={mobileImage}
                  alt="" className="mobile-img"
                />

              {this.renderLanding()}
            </div>
            {this.renderSunBurst(details)}
                   
                <div className="monitoring-form monitoring-mobile-view-only slider">
                  <div className="col-md-5 text-left calc-gap">
                     <div className="form-group">
                      {this.renderReferralForm()}
                    </div>
                </div>
              </div>
               
          </div>
        </div>
      </>
      );
    }
    //  else if (!this.props.loader) {
    //   return <ErrorPage />;
    // }
     else {
      return <LandingPage loadingIcon={getLoadingIcon(this.props.router.params.slug)} role={'homeowner123'}/>
    }
  }
}

const mapStatetoProps = state => {
  let loggedInUser = {};
  loggedInUser['homeownerData'] =
    state.homeownerReferralDetails &&
    state.homeownerReferralDetails.homeownerReferrals &&
    state.homeownerReferralDetails.homeownerReferrals.data;
  return {
    auth: state.auth,
    loggedInUser: loggedInUser
  };
};

export default withRouter(
  connect(
    mapStatetoProps,
    null
  )(
    GoogleApiWrapper({
      apiKey: googleMap.envConfig.googleMap.API_KEY
    })(MonitoringLandingForm)
  )
);
