import {
	REQUEST_LOGGED_IN_HOMEOWNER,
    RECEIVE_LOGGED_IN_HOMEOWNER,
    RECEIVE_HOMEOWNER_DATA,
    REQUEST_UPDATE_HOMEOWNER,
    RECEIVE_UPDATE_HOMEOWNER,
    REQUEST_HOMEOWNER_ENERGY_REPORT,
    RECEIVE_HOMEOWNER_ENERGY_REPORT,
    REQUEST_HOMEOWNER_SPECIAL_CARDDATA,
    RECEIVE_HOMEOWNER_SPECIAL_CARDDATA,
    REQUEST_HOMEOWNER_INSTALLER_NOTIFIY,
    REQUEST_DEVICE_MAGIC,
    RECEIVE_DEVICE_MAGIC
} from "../types/homeowner";

// logged-in homeowner action
    export const requestLoggedInHomeownerAction = data => ({
        type: REQUEST_LOGGED_IN_HOMEOWNER, data
    });

    export const receiveLoggedInHomeownerAction = data => ({
        type: RECEIVE_LOGGED_IN_HOMEOWNER, data
    });

    export const receiveHomeownerData = data => ({
        type: RECEIVE_HOMEOWNER_DATA, data
    });

    // get homeowner energy report
    export const requestHomeownerEnergyData = data => ({
        type: REQUEST_HOMEOWNER_ENERGY_REPORT, data
    });

    export const receiveHomeownerEnergyData = data => ({
        type: RECEIVE_HOMEOWNER_ENERGY_REPORT, data
    });

    export const requestHomeownerSpecialCardData = data => ({
        type: REQUEST_HOMEOWNER_SPECIAL_CARDDATA, data
    });

    export const receiveHomeownerSpecialCardData = data => ({
        type: RECEIVE_HOMEOWNER_SPECIAL_CARDDATA, data
    });

    export const requestHomeownerInstallerNotify = data => ({
        type:REQUEST_HOMEOWNER_INSTALLER_NOTIFIY,data
    })
    export const requestUpdateHomeOwner = data => ({
        type: REQUEST_UPDATE_HOMEOWNER, data
    });
  
    export const receiveUpdateHomeOwner = data => ({
        type: RECEIVE_UPDATE_HOMEOWNER, data
    });

    export const requestDeviceMagic = data => ({
        type: REQUEST_DEVICE_MAGIC, data
    });
  
    export const receiveDeviceMagic = data => ({
        type: RECEIVE_DEVICE_MAGIC, data
    });

